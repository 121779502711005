import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCAdminIncidents from "../../component/incident/GCAdminIncidents";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";

const GCDivisionIncidents: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  const projWhere = { gc_division_id: { _eq: divisionId } };
  const emp = userData.employee;
  if (!emp) throw new Error("Employee not found ");
  return (
    <GCAdminIncidents
      organizationLevel="division"
      organizationId={divisionId}
      optionsQueryVars={{
        projWhere,
        gcDivisionWhere: { id: { _is_null: true } },
        gcBuWhere: emp.general_contractor.hierarchy_business_unit_name
          ? { gc_division_id: { _eq: divisionId } }
          : { id: { _is_null: true } },
        gcOfficeWhere: emp.general_contractor.hierarchy_office_name
          ? { gc_division_id: { _eq: divisionId } }
          : { id: { _is_null: true } },
      }}
      projWhere={projWhere}
      severityUserFilter={{ division_id: { _eq: divisionId } }}
      toInsertWith={{
        gc_id: emp.general_contractor.id,
        division_id: divisionId,
      }}
      gcEmployeeWhere={{
        gc_divisions: { gc_division_id: { _eq: divisionId } },
      }}
    />
  );
};
export default withDivisionId(GCDivisionIncidents);
