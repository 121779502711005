import { Button, Form, Modal, Select } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { ConnectionHandler } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  AddGCProjectTeamModalQuery,
  general_contractor_employee_bool_exp,
} from "src/common/types/generated/relay/AddGCProjectTeamModalQuery.graphql";
import { AddGCProjectTeamModal_insertProjectEmployee_Mutation } from "src/common/types/generated/relay/AddGCProjectTeamModal_insertProjectEmployee_Mutation.graphql";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import * as uuid from "uuid";

const query = graphql`
  query AddGCProjectTeamModalQuery(
    $gcEmployeeWhere: general_contractor_employee_bool_exp!
  ) {
    general_contractor_employee_connection(
      where: $gcEmployeeWhere
      order_by: { user: { name: asc } }
      first: 10000
    )
      @connection(
        key: "AddGCProjectTeamModal_general_contractor_employee_connection"
        filters: []
      ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          uid
          user {
            id
            name
            profile_picture {
              id
              url
            }
          }
        }
      }
    }
  }
`;

const insertProjectEmployeeMutation = graphql`
  mutation AddGCProjectTeamModal_insertProjectEmployee_Mutation(
    $objects: [project_employee_insert_input!]!
  ) {
    insert_project_employee(
      objects: $objects
      on_conflict: {
        constraint: project_employee_project_id_employee_id_key
        update_columns: [direct_project_assign]
      }
    ) {
      returning {
        id
        pk: id @__clientField(handle: "pk")
        status
        employee {
          id
          employee_title {
            id
            name {
              en
            }
          }
          user {
            id
            name
            profile_picture {
              id
              url
            }
          }
        }
      }
    }
  }
`;

interface AddGCProjectTeamFormValues {
  gcUserIds: Array<string>;
}

interface AddGCProjectTeamModalProps {
  modalClose: () => void;
  modalVisible: boolean;
  projects: string[];
  onClickInvite: () => void;
  directProjectTeam: boolean;
  onInviteSuccess: (userIds: string[]) => Promise<void> | void;
  fetchKey?: number;
  excludeEmployeeWhere: general_contractor_employee_bool_exp;
}

const AddGCProjectTeamModal: React.FC<AddGCProjectTeamModalProps> = (props) => {
  const [addTeamMemberForm] = Form.useForm();
  const [insertProjectEmployee] =
    useAsyncMutation<AddGCProjectTeamModal_insertProjectEmployee_Mutation>(
      insertProjectEmployeeMutation,
    );
  const { userData } = useUserData();
  const data = useLazyLoadQuery<AddGCProjectTeamModalQuery>(
    query,
    {
      gcEmployeeWhere: {
        general_contractor_id: userData.employee
          ? { _eq: userData.employee.general_contractor.id }
          : { _is_null: true },
        _not: props.excludeEmployeeWhere,
      },
    },
    {
      fetchKey: props.fetchKey ?? 0,
      fetchPolicy: "store-and-network",
      networkCacheConfig: {
        force: true,
      },
    },
  );

  const handleAddGCProjectTeamSubmit = async () => {
    const values: AddGCProjectTeamFormValues =
      await addTeamMemberForm.validateFields();
    if (!values) return;
    await insertProjectEmployee({
      variables: {
        objects: props.projects.flatMap((projectId) =>
          values.gcUserIds.map((id) => ({
            employee_id: id,
            project_id: projectId,
            status: props.directProjectTeam ? "Active" : "Inactive",
            direct_project_assign: props.directProjectTeam,
          })),
        ),
      },
      updater: (store) => {
        const insertedProjectEmployee = store.getRootField(
          "insert_project_employee",
        );
        const conn = ConnectionHandler.getConnection(
          store.getRoot(),
          "GCProjectTeam_project_employee_connection",
        );

        const gcConn = ConnectionHandler.getConnection(
          store.getRoot(),
          "AddGCProjectTeamModal_general_contractor_employee_connection",
        );

        if (conn) {
          insertedProjectEmployee.getLinkedRecords("returning").forEach((r) => {
            if (gcConn) {
              const gcId = r.getLinkedRecord("employee").getValue("id");
              const gcNode = gcConn
                .getLinkedRecords("edges")
                ?.find((e) => {
                  return e.getLinkedRecord("node")?.getValue(`id`) === gcId;
                })
                ?.getLinkedRecord("node");
              if (gcNode) {
                ConnectionHandler.deleteNode(gcConn, gcNode.getDataID());
              }
            }
            const edge = store.create(uuid.v4(), "edge");
            edge.setLinkedRecord(r, "node");
            ConnectionHandler.insertEdgeAfter(conn, edge);
          });
        }
      },
    });
    props.onInviteSuccess(values.gcUserIds || []);
    props.modalClose();
    addTeamMemberForm.resetFields();
  };

  return (
    <Modal
      title={`Add Team Members`}
      open={props.modalVisible}
      onCancel={props.modalClose}
      onOk={handleAddGCProjectTeamSubmit}
      okButtonProps={{ hidden: false }}
      footer={
        <div className="flex flex-1 flex-row">
          <div className="flex flex-col items-start flex-1">
            <Button
              className="text-interactive-primary bg-none border-interactive-secondary hover:border-interactive-primary"
              onClick={() => {
                // setFetchKey((prev) => prev + 1);
                props.onClickInvite();
              }}
            >
              {"Invite New +"}
            </Button>
          </div>
          <Button onClick={props.modalClose}>{"Cancel"}</Button>
          <Button
            className="text-white bg-interactive-primary border-interactive-primary ml-0"
            onClick={handleAddGCProjectTeamSubmit}
          >
            {"OK"}
          </Button>
        </div>
      }
    >
      <Form
        form={addTeamMemberForm}
        layout="vertical"
        name="form_in_modal"
        initialValues={{ modifier: "public" }}
      >
        <Form.Item
          name="gcUserIds"
          label="Project Admins"
          initialValue={[]}
          rules={[{ required: true, message: "Select Project Admins" }]}
        >
          <Select
            optionFilterProp="label"
            listItemHeight={10}
            mode="multiple"
            style={{
              width: "100%",
            }}
            options={data.general_contractor_employee_connection.edges.map(
              (gc) => ({
                value: gc.node.pk,
                key: gc.node.pk,
                label: gc.node.user.name,
              }),
            )}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default AddGCProjectTeamModal;
