import React from "react";
import { useParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { GCReportsNavigationQuery } from "src/common/types/generated/relay/GCReportsNavigationQuery.graphql";
import useAuthUser from "src/common/hooks/useAuthUser";
import DownloadOutlined from "@ant-design/icons/lib/icons/DownloadOutlined";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "../../../../common/components/layouts/tabs/CustomTabsRouted";

interface GCReportsNavigationProps {}

export const reportsNavigationQuery = graphql`
  query GCReportsNavigationQuery($projectId: uuid!, $empId: uuid!) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          is_visitor_log_and_tracking_active
          visitor_sign_waiver
          visitor_waiver_text
        }
      }
    }
    general_contractor_connection(
      where: { employees: { uid: { _eq: $empId } } }
    ) {
      edges {
        node {
          visitor_waiver_text
        }
      }
    }
  }
`;
const GCReportsNavigation: React.FunctionComponent<
  GCReportsNavigationProps
> = () => {
  const { projectId } = useParams();
  const authUser = useAuthUser();
  if (!projectId) throw new Error("ProjectId not found");
  const data = useLazyLoadQuery<GCReportsNavigationQuery>(
    reportsNavigationQuery,
    { projectId: projectId, empId: authUser.uid },
    //refreshedQueryOptions ?? {}
  );

  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "performance",
      label: "Performance",
    },
    {
      path: "daily",
      label: "Daily Reports",
    },
    {
      path: "permits",
      label: "Permits",
    },
    {
      path: "pretaskplans",
      label: "Pre-Task Plans",
    },
    {
      path: "toolboxtalks",
      label: "Toolbox Talks",
    },
    ...(data.project_connection.edges &&
    data.project_connection.edges[0].node.is_visitor_log_and_tracking_active
      ? [
          {
            path: "visitors",
            label: "Visitors",
          },
        ]
      : []),
    {
      path: "reports-download",
      label: "Reports",
      icon: <DownloadOutlined />,
    },
    {
      path: "covid19",
      label: "Covid 19",
    },
    {
      path: "settings",
      label: "Settings",
    },
  ];

  const basePath = `/gce/projects/${projectId}/reports`;
  return (
    <BasicWrapper scrollable>
      <CustomTabsRouted tabs={tabs} basePath={basePath} />
    </BasicWrapper>
  );
};

export default GCReportsNavigation;
