import { graphql } from "babel-plugin-relay/macro";
import React from "react";
import CreateProjectModal from "src/common/components/dialogs/CreateProjectModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  GCAddProjectMutation,
  project_insert_input,
} from "src/common/types/generated/relay/GCAddProjectMutation.graphql";
import * as uuid from "uuid";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import useAuthUser from "src/common/hooks/useAuthUser";

interface GCAddProjectProps {
  onCancel: () => void;
  toInsertWith?: Partial<project_insert_input>;
  onCreate: () => void;
}

const GCAddProject: React.FunctionComponent<GCAddProjectProps> = (props) => {
  const [insertProject] = useAsyncMutation<GCAddProjectMutation>(
    graphql`
      mutation GCAddProjectMutation(
        $projectObject: project_insert_input!
        $projectLocalCityobjects: [project_local_city_insert_input!]!
      ) {
        insert_project_one(object: $projectObject) {
          pk: id @__clientField(handle: "pk")
          completed
          name
          project_employees {
            id
          }
        }
        insert_project_local_city(objects: $projectLocalCityobjects) {
          returning {
            city_id
            city {
              name
            }
          }
        }
      }
    `,
  );
  const { userData } = useUserData();
  const authUser = useAuthUser();
  const emp = userData.employee;
  if (!emp) throw new Error("No Logged in user Found");
  return (
    <CreateProjectModal
      disableGc
      visible={true}
      onCreate={async ({ values }) => {
        const id = uuid.v4();
        await insertProject({
          variables: {
            projectObject: {
              ...(props.toInsertWith || {}),
              general_contractor_id: emp.general_contractor.id,
              general_contractors: {
                data: [
                  {
                    general_contractor_id: emp.general_contractor.id,
                  },
                ],
              },
              id,

              union_labor: values.union,
              // require_dob: employee.general_contractor.require_dob,
              // require_phone: employee.general_contractor.require_phone,
              // agc_universal_orientation:
              //   employee.general_contractor.agc_universal_orientation,
              orientation_project_id: id,
              name: values.name,
              external_id: values.externalId,
              address: {
                data: {
                  line_1: values.addressLine1,
                  zip_code: values.zip,
                  city: values.city,
                  state_code: values.stateCode,
                },
              },
              start_date: values.startDate,
              end_date: values.endDate,
              // project_orientation_settings: {
              //   data: employee.general_contractor.gc_orientations.map(
              //     (gco) => ({ orientation_id: gco.pk }),
              //   ),
              // },
              project_employees: {
                data: [
                  {
                    employee_id: authUser.uid,
                  },
                ],
              },
            },
            projectLocalCityobjects: (values.local_cities || []).map(
              (ci: string) => ({
                city_id: ci,
                project_id: id,
              }),
            ),
          },
          updater: (store, data) => {
            const project = store.getRootField("insert_project_one");
            const proj_data = data?.insert_project_one;
            if (!proj_data) return;
            const conn = store
              .getRoot()
              .getLinkedRecord("general_contractor_employee_connection", {
                where: { uid: { _eq: authUser.uid } },
              });
            if (conn) {
              const edges = conn.getLinkedRecords("edges");
              if (edges && edges.length > 0) {
                const node = edges[0].getLinkedRecord("node");
                if (node) {
                  const emp_projects =
                    node.getLinkedRecords("employee_projects");
                  const proj_employee = store.create(
                    uuid.v4(),
                    "employee_project",
                  );
                  proj_employee.setLinkedRecord(project, "project");
                  node.setLinkedRecords(
                    [...(emp_projects || []), proj_employee],
                    "employee_projects",
                  );
                }
              }
            }
          },
        });
        props.onCreate();
      }}
      onCancel={props.onCancel}
    />
  );
};

export default GCAddProject;
