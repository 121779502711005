import { Button, message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCDisabledProjectSubcontractorTableDeleteMutation } from "src/common/types/generated/relay/GCDisabledProjectSubcontractorTableDeleteMutation.graphql";
import { GCDisabledProjectSubcontractorTableQuery } from "src/common/types/generated/relay/GCDisabledProjectSubcontractorTableQuery.graphql";
import { GCDisabledProjectSubcontractorTable_workers$data } from "src/common/types/generated/relay/GCDisabledProjectSubcontractorTable_workers.graphql";

type ColumnKeys = "name" | "trade";
const CONNECTION_NAME = "gc_disabled_project_subcontractor_connection";
type DConnection =
  GCDisabledProjectSubcontractorTable_workers$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  GCDisabledProjectSubcontractorTableQuery,
  string
> & {
  allowSubDelete?: boolean;
  onSubDelete?: () => void;
  allowSubReAdd?: boolean;
  subIdReAdd?: string;
  onSubReAdd?: () => void;
};

type Ref = DataScrollTableRef | null;
const GCDisabledProjectSubcontractorTable = forwardRef<Ref, Props>(
  (
    {
      title = "Safety Report Reminders Disabled Subs",
      allowSubDelete = false,
      allowSubReAdd = false,
      subIdReAdd,
      onSubDelete,
      onSubReAdd,
      onRowClick,
      ...props
    },
    ref,
  ) => {
    const table = useRef<DataScrollTableRef>(null);
    const [selectedKeys, setSelectedKeys] = useState<Array<string>>([]);
    const [deleteSub] =
      useAsyncMutation<GCDisabledProjectSubcontractorTableDeleteMutation>(graphql`
        mutation GCDisabledProjectSubcontractorTableDeleteMutation(
          $where: gc_disabled_project_subcontractor_bool_exp!
        ) {
          delete_gc_disabled_project_subcontractor(where: $where) {
            affected_rows
          }
        }
      `);

    useImperativeHandle<Ref, Ref>(ref, () => ({
      refetch: () => table.current?.refetch(),
    }));

    return (
      <div className="">
        {selectedKeys.length > 0 && allowSubDelete && (
          <BPopconfirm
            onConfirm={() => {
              deleteSub({
                variables: {
                  where: {
                    project_subcontractor_id: { _in: selectedKeys },
                  },
                },
              }).then(() => {
                table.current?.refetch();
                message.success("Updated");
                setSelectedKeys([]);
              });
            }}
          >
            <Button>Enable selected ({selectedKeys.length})</Button>
          </BPopconfirm>
        )}
        <DataScrollTable<
          DConnection,
          ColumnKeys,
          GCDisabledProjectSubcontractorTableQuery
        >
          // newCustomTableLook
          {...props}
          ref={table}
          title={title}
          onRowClick={(tbt) => {
            onRowClick && onRowClick(tbt.pk);
          }}
          connectionName={CONNECTION_NAME}
          totalCountConnectionName={"allSubsConnection"}
          rowSelection={{
            selectedRowKeys: selectedKeys,
            onChange: (keys) => {
              setSelectedKeys(keys.map((k) => k.toString()));
            },
          }}
          columns={[
            {
              title: "Name",
              key: "name",
              sortable: true,
              size: "md",
              defaultSortOrder: "asc",
              dataIndex: ["project_subcontractor", "subcontractor", "name"],
              searchDataIndex: [
                "project_subcontractor",
                "subcontractor",
                "name",
              ],
            },
            {
              title: "Trade",
              key: "trade",
              size: "md",
              dataIndex: ["project_subcontractor", "trade", "name"],
            },
          ]}
          queryNode={graphql`
            query GCDisabledProjectSubcontractorTableQuery(
              $first: Int!
              $after: String
              $where: gc_disabled_project_subcontractor_bool_exp!
              $order_by: [gc_disabled_project_subcontractor_order_by!]!
            ) {
              ...GCDisabledProjectSubcontractorTable_workers
                @arguments(
                  first: $first
                  after: $after
                  where: $where
                  order_by: $order_by
                )
              ...GCDisabledProjectSubcontractorTable_total
                @arguments(where: $where)
            }
          `}
          totalCountNode={graphql`
            fragment GCDisabledProjectSubcontractorTable_total on query_root
            @argumentDefinitions(
              where: { type: "gc_disabled_project_subcontractor_bool_exp!" }
            )
            @refetchable(
              queryName: "GCDisabledProjectSubcontractorTableTotalRefetchableQuery"
            ) {
              allSubsConnection: gc_disabled_project_subcontractor_connection(
                where: $where
              ) {
                edges {
                  node {
                    id
                  }
                }
              }
            }
          `}
          paginationNode={graphql`
            fragment GCDisabledProjectSubcontractorTable_workers on query_root
            @argumentDefinitions(
              first: { type: "Int!" }
              after: { type: "String" }
              where: { type: "gc_disabled_project_subcontractor_bool_exp!" }
              order_by: {
                type: "[gc_disabled_project_subcontractor_order_by!]!"
              }
            )
            @refetchable(
              queryName: "GCDisabledProjectSubcontractorTableRefetchableQuery"
            ) {
              gc_disabled_project_subcontractor_connection(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
                @connection(
                  key: "GCDisabledProjectSubcontractorTable_gc_disabled_project_subcontractor_connection"
                  filters: ["where", "order_by"]
                ) {
                edges {
                  node {
                    id
                    pk: id @__clientField(handle: "pk")
                    project_subcontractor {
                      subcontractor {
                        name
                        trade {
                          name
                        }
                      }
                    }
                  }
                }
              }
            }
          `}
        />
      </div>
    );
  },
);

export default GCDisabledProjectSubcontractorTable;
