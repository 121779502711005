import React from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { Image } from "antd";
import TextareaAutosize from "react-textarea-autosize";
import { useSuspenseQuery } from "@apollo/client";
import {
  GetPtpQrReviewHazardsQuery,
  GetPtpQrReviewHazardsQueryVariables,
  GetPtpQrReviewHazardsDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLanguage } from "src/utility-features/i18n/context/languageHooks";

export interface ReviewHazardsForPtpSubmissionProps
  extends SiteOrientationStepProps {}

const ReviewHazardsForPtpSubmission: React.FC<
  ReviewHazardsForPtpSubmissionProps
> = (props) => {
  const { ptpQrUser, setPtpQrUser } = usePtpQr();
  const user = ptpQrUser.userInfo;
  const lang = useCurrentLanguage();
  if (!user?.company) throw new Error("Subcontractor not found");
  if (!user.projectWorkerId) throw new Error("Project Worker not found");

  const getNow = () => {
    const ticks = Date.now();
    return new Date(ticks - (ticks % 1800000));
  };

  const { data } = useSuspenseQuery<
    GetPtpQrReviewHazardsQuery,
    GetPtpQrReviewHazardsQueryVariables
  >(GetPtpQrReviewHazardsDocument, {
    variables: {
      subId: user.company,
      nowDate: getNow().toISOString(),
      projWorkerId: user.projectWorkerId,
    },
  });

  const awarenessQuestionsData = data.get_awareness_question;
  const projectHazardsData = data.project_hazard;

  const onUpdateAwarenessQuestion = (questionId: string, answer: string) => {
    setPtpQrUser((prevState) => ({
      ...prevState,
      projectHazardAndAwareness: {
        ...prevState.projectHazardAndAwareness,
        awarenessQuestions: {
          ...prevState.projectHazardAndAwareness.awarenessQuestions,
          [questionId]: answer,
        },
      },
    }));
  };

  const onProjectHazardUpdate = (questionId: string, answer: string) => {
    setPtpQrUser((prevState) => ({
      ...prevState,
      projectHazardAndAwareness: {
        ...prevState.projectHazardAndAwareness,
        projectHazards: {
          ...prevState.projectHazardAndAwareness.projectHazards,
          [questionId]: answer,
        },
      },
    }));
  };

  const questionLang =
    lang === "fr" || lang === "zh_hans" || lang === "it" ? "en" : lang;

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        props.onNext();
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={
        Object.values(ptpQrUser.projectHazardAndAwareness.awarenessQuestions)
          .length < 2
      }
    >
      <SiteFeatureStepsInputLayout>
        {awarenessQuestionsData.map((data) => (
          <div key={data.id}>
            <div className="mt-1 mb-1">{data.question[questionLang]}</div>
            <TextareaAutosize
              // className="p-1 w-full min-h-6 text-gray-500 border-black-300 border border-b-2 border-supplementary-3 flex-1 outline-none"
              className={`rounded-0.5 border-px w-full border-suplementary-3 flex-1 resize-none outline-none p-0.75 whitespace-pre-wrap break-word`}
              onChange={(e) =>
                onUpdateAwarenessQuestion(data.id, e.target.value)
              }
              value={
                ptpQrUser.projectHazardAndAwareness.awarenessQuestions[
                  data.id
                ] || ""
              }
            />
          </div>
        ))}

        {projectHazardsData.map((data) => (
          <div className="mt-2" key={data.id}>
            {data.images.map((image) => (
              <Image
                src={image.url}
                alt="Hazard Image"
                className="w-4 h-4"
                key={image.url}
              />
            ))}

            <div className="flex">{data.description.en}</div>
            <TextareaAutosize
              className={`rounded-0.5 border-px w-full border-suplementary-3 flex-1 resize-none outline-none p-0.75 whitespace-pre-wrap break-word`}
              // className={`rounded-0.5 border-px mr-4 mt-1 w-full h-5 border-suplementary-3 flex-1 resize-none outline-none p-0.75 whitespace-pre-wrap break-word`}
              onChange={(e) => onProjectHazardUpdate(data.id, e.target.value)}
              value={
                ptpQrUser.projectHazardAndAwareness.projectHazards[data.id] ||
                ""
              }
            />
          </div>
        ))}
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default ReviewHazardsForPtpSubmission;
