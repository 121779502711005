import React, { useEffect } from "react";
import * as uuid from "uuid";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import {
  CompleteInPersonOrientation_MarkUserAsCompletedMutation,
  user_orientation_update_column,
} from "src/common/types/generated/relay/CompleteInPersonOrientation_MarkUserAsCompletedMutation.graphql";
import { upsertOrientationResultsMutation_UpsertOrientationResult_Mutation } from "src/common/types/generated/relay/upsertOrientationResultsMutation_UpsertOrientationResult_Mutation.graphql";
import upsertOrientationResultsMutation from "../../../../../../../utils/upsertOrientationResultsMutation";
import {
  OrientationEmailType,
  useEmailGcUsersToVerifyNewGcUserMutation,
  useEmailOrientationConfirmationQrCodeMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSearchParams } from "react-router-dom";
import dayjs from "dayjs";
import { auth } from "src/common/functions/firebase";
import { CompleteInPersonOrientationQuery } from "src/common/types/generated/relay/CompleteInPersonOrientationQuery.graphql";
import useUpdateHH from "src/domain-features/siteorientation/utils/useUpdateHH";
import { getNextHardHatNumber } from "src/common/functions/hardHatHelpers";
import { notification } from "antd";

const markAsCompletedMutation = graphql`
  mutation CompleteInPersonOrientation_MarkUserAsCompletedMutation(
    $workerUpdateColumns: [user_orientation_update_column!]!
    $orientationResultObjects: [orientation_result_insert_input!]!
    $orientationObjects: [user_orientation_insert_input!]!
  ) {
    insert_user_orientation(
      objects: $orientationObjects
      on_conflict: {
        constraint: user_orientation_user_id_project_id_key
        update_columns: $workerUpdateColumns
      }
    ) {
      returning {
        id @__clientField(handle: "pk")
        project_id
        user_id
        in_person_orientated_at
        selected
      }
    }
    insert_orientation_result(
      objects: $orientationResultObjects
      on_conflict: {
        constraint: orientation_result_pkey
        update_columns: [
          total_slides
          viewed_slides
          signature_url
          status
          expired_at
          completed_at
          group_id
          result_inserted_by_uid
        ]
      }
    ) {
      affected_rows
    }
  }
`;

const query = graphql`
  query CompleteInPersonOrientationQuery(
    $projectId: uuid!
    $userId: uuid!
    $now: timestamptz!
  ) {
    project_connection(where: { id: { _eq: $projectId } }) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          name
          next_hard_hat_number
          assign_hard_hat
          automatically_assign_hard_hat
          general_contractor {
            pk: id @__clientField(handle: "pk")
            name
          }
          linked_orientation_projects {
            pk: id @__clientField(handle: "pk")
          }
        }
      }
    }
    user_orientation_connection(
      where: { project_id: { _eq: $projectId }, user_id: { _eq: $userId } }
    ) {
      edges {
        node {
          orientated_at
          orientation_group_id
        }
      }
    }
    user_connection(where: { id: { _eq: $userId } }) {
      edges {
        node {
          name
          role
          employee {
            employee_projects(
              where: {
                project: { orientation_project_id: { _eq: $projectId } }
              }
              order_by: { hard_hat_number: asc_nulls_first }
            ) {
              pk: id @__clientField(handle: "pk")
              hard_hat_number
              project_id
            }
          }
          worker {
            worker_projects(
              where: {
                project: { orientation_project_id: { _eq: $projectId } }
                subcontractor_worker: {}
              }
              order_by: { hard_hat_number: asc_nulls_first }
            ) {
              pk: id @__clientField(handle: "pk")
              hard_hat_number
              project_id
            }
          }
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        project_orientations: {
          required_by_all_workers: { _eq: true }
          project_id: { _eq: $projectId }
        }
        general_contractor: { projects: { id: { _eq: $projectId } } }
        _or: [
          { project_id: { _is_null: true } }
          { project_id: { _eq: $projectId } }
        ]
      }
    ) {
      edges {
        node {
          pk: id @__clientField(handle: "pk")
          duration_valid
          completedOnOtherProjectResults: orientation_results(
            where: {
              user_id: { _eq: $userId }
              status: { _eq: "completed" }
              expired_at: { _gte: $now }
            }
          ) {
            id
          }
        }
      }
    }
  }
`;

export interface CompleteInPersonOrientationProps {
  userId: string;
  projectId: string;
  signatureImageUrl: string;
  onFinish: () => void;
}

const CompleteInPersonOrientation: React.FC<
  CompleteInPersonOrientationProps
> = (props) => {
  const { projectId, userId, signatureImageUrl } = props;
  const data = useLazyLoadQuery<CompleteInPersonOrientationQuery>(query, {
    projectId,
    userId,
    now: dayjs().endOf("d").format(),
  });
  const environment = useRelayEnvironment();
  const [updateHH] = useUpdateHH();

  const [markAsCompleted] =
    useAsyncMutation<CompleteInPersonOrientation_MarkUserAsCompletedMutation>(
      markAsCompletedMutation,
    );
  const [upsertOrientationResults] =
    useAsyncMutation<upsertOrientationResultsMutation_UpsertOrientationResult_Mutation>(
      upsertOrientationResultsMutation,
    );
  const [emailGCUsersToVerifyNewGCUser] =
    useEmailGcUsersToVerifyNewGcUserMutation();

  const [emailOrientationConfirmationQrCode, { loading: isSendingEmail }] =
    useEmailOrientationConfirmationQrCodeMutation();
  const [searchParams] = useSearchParams();
  const completionType = searchParams.get("completionType");
  const project = data.project_connection.edges[0]?.node;
  if (!project) {
    throw new Error("Project does not exist");
  }
  const user = data.user_connection.edges[0]?.node;
  if (!user) {
    throw new Error("User does not exist");
  }
  const userOrientation = data.user_orientation_connection.edges[0];
  const project_users =
    user.worker?.worker_projects || user.employee?.employee_projects || [];
  const projectsToInsertResult =
    user.role === "employee"
      ? project.linked_orientation_projects.map((p) => p.pk)
      : project_users.map((p) => p.project_id);
  if (!project_users.length) {
    throw new Error("Project does not exist");
  }
  const orientationsToGiveCreditFor = data.orientation_connection.edges.filter(
    (o) => o.node.completedOnOtherProjectResults.length === 0,
  );
  const hasSlides = data.orientation_connection.edges.length > 0;
  const inPersonCompleteType = completionType === "inpersonComplete";
  const fields = [
    "in_person_orientated_at",
    "in_person_signature_id",
    "selected",
  ] as Array<user_orientation_update_column>;
  if (
    !hasSlides ||
    (inPersonCompleteType &&
      (!userOrientation || !userOrientation.node.orientated_at))
  )
    fields.push("orientated_at");

  if (inPersonCompleteType) fields.push("completed_at");
  if (
    hasSlides &&
    inPersonCompleteType &&
    (!userOrientation || !userOrientation.node.orientated_at)
  )
    fields.push("orientation_group_id");

  const completeInPersonOrientation = async () => {
    try {
      const orientationDate = dayjs().toISOString();
      const groupId = userOrientation
        ? userOrientation.node.orientation_group_id ?? uuid.v4()
        : uuid.v4();

      await markAsCompleted({
        variables: {
          workerUpdateColumns: fields,
          orientationObjects: projectsToInsertResult.map(
            (projectToInsertResultId) => ({
              project_id: projectToInsertResultId,
              user_id: userId,
              in_person_orientated_at: orientationDate,
              ...(hasSlides &&
              inPersonCompleteType &&
              (!userOrientation || !userOrientation.node.orientated_at)
                ? { orientation_group_id: groupId }
                : {}),
              in_person_signature_image: {
                data: {
                  url: signatureImageUrl,
                  lg_url: signatureImageUrl,
                  created_by_user_id: userId,
                },
              },
              ...(inPersonCompleteType
                ? { completed_at: orientationDate }
                : {}),
              selected: true,
              ...(!hasSlides ||
              (inPersonCompleteType &&
                (!userOrientation || !userOrientation.node.orientated_at))
                ? { orientated_at: orientationDate }
                : {}),
            }),
          ),
          orientationResultObjects:
            inPersonCompleteType && orientationsToGiveCreditFor.length
              ? orientationsToGiveCreditFor.map((o) => ({
                  status: "completed",
                  group_id: groupId,
                  id: uuid.v4(),
                  project_id: projectId,
                  user_id: userId,
                  signature_url: signatureImageUrl,
                  orientation_id: o.node.pk,
                  result_inserted_by_uid: auth.currentUser?.uid,
                  expired_at: dayjs()
                    .add(o.node.duration_valid, "months")
                    .toISOString(),
                  completed_at: dayjs().toISOString(),
                }))
              : [],
        },
      });

      if (project.assign_hard_hat && project.automatically_assign_hard_hat) {
        let hardHat = project_users.find(
          (pw) => pw.hard_hat_number,
        )?.hard_hat_number;

        //only one of the lists will exist so either hardHat will be for worker or for oac user
        if (!hardHat) {
          const nextHH = project?.next_hard_hat_number || 1;
          hardHat =
            "" + (await getNextHardHatNumber(environment, projectId, nextHH));
        }
        if (project_users.length > 0) {
          await updateHH({
            variables: {
              projectId,
              hardHat,
              userId,
            },
          }).catch(console.error); //if there's a problem  with HH assignment we should not stop the whole func, instead still send the confirmation email
        }
      }

      if (user.role === "employee") {
        // email GC to ask if a user is an employee of the company
        emailGCUsersToVerifyNewGCUser({
          variables: { input: { projectId, userId } },
        }).catch((e) => console.error(e));
      } else {
        // inform a worker about the orientation completion by email
        emailOrientationConfirmationQrCode({
          variables: {
            input: {
              userId,
              projectId,
              orientationEmailType: OrientationEmailType.ProjectOrientation,
            },
          },
        }).catch((e) => {
          console.error("Email send failed", e);
          notification.error({
            message: "Email Confirmation Error",
            description: e.message,
          });
        });
      }
    } catch (err) {
      console.log(err);
      notification.error({
        description: err instanceof Error ? err.message : JSON.stringify(err),
        message: "Could not complete orientation",
      });
    }

    props.onFinish();
  };

  useEffect(() => {
    completeInPersonOrientation();
  }, []);

  return null;
};

export default CompleteInPersonOrientation;
