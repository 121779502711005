/**
 * @generated SignedSource<<410f5b8379ffbd6fe5d21801e94e85eb>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { order_by, project_crew_bool_exp, project_crew_order_by } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type CrewTableQuery$variables = {
  after?: string | null | undefined;
  first: number;
  order_by: ReadonlyArray<project_crew_order_by>;
  where: project_crew_bool_exp;
};
export type CrewTableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"CrewTable_project_crew" | "CrewTable_total">;
};
export type CrewTableQuery = {
  response: CrewTableQuery$data;
  variables: CrewTableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "after"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "first"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "order_by"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "where"
},
v4 = {
  "kind": "Variable",
  "name": "where",
  "variableName": "where"
},
v5 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "after"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "first"
  },
  {
    "kind": "Variable",
    "name": "order_by",
    "variableName": "order_by"
  },
  (v4/*: any*/)
],
v6 = [
  (v4/*: any*/)
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v9 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v11 = [
  (v7/*: any*/),
  (v8/*: any*/),
  (v9/*: any*/),
  (v10/*: any*/)
],
v12 = {
  "_is_null": true
},
v13 = [
  (v7/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CrewTableQuery",
    "selections": [
      {
        "args": (v5/*: any*/),
        "kind": "FragmentSpread",
        "name": "CrewTable_project_crew"
      },
      {
        "args": (v6/*: any*/),
        "kind": "FragmentSpread",
        "name": "CrewTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/),
      (v3/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "CrewTableQuery",
    "selections": [
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "project_crewConnection",
        "kind": "LinkedField",
        "name": "project_crew_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_crewEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_crew",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "deleted_at",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "order_by",
                        "value": {
                          "user": {
                            "name": "asc"
                          }
                        }
                      },
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "subcontractor_worker": {}
                        }
                      }
                    ],
                    "concreteType": "project_worker",
                    "kind": "LinkedField",
                    "name": "project_workers",
                    "plural": true,
                    "selections": [
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "worker_role",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "hard_hat_number",
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker_title",
                        "kind": "LinkedField",
                        "name": "title",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "concreteType": "text_translation",
                            "kind": "LinkedField",
                            "name": "translation",
                            "plural": false,
                            "selections": [
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "en",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "es",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "pt",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "original",
                                "storageKey": null
                              },
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": "clientText",
                                "args": null,
                                "kind": "ScalarField",
                                "name": "original",
                                "storageKey": null
                              },
                              {
                                "alias": "clientText",
                                "args": null,
                                "filters": null,
                                "handle": "clientText",
                                "key": "",
                                "kind": "ScalarHandle",
                                "name": "original"
                              }
                            ],
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      },
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "email",
                            "storageKey": null
                          },
                          (v7/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "project_workers(order_by:{\"user\":{\"name\":\"asc\"}},where:{\"subcontractor_worker\":{}})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractor",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project",
                    "kind": "LinkedField",
                    "name": "project",
                    "plural": false,
                    "selections": (v11/*: any*/),
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "closed_at": (v12/*: any*/)
                        }
                      }
                    ],
                    "concreteType": "project_subcontractor_crew_onsite_period",
                    "kind": "LinkedField",
                    "name": "project_subcontractor_crew_onsite_periods",
                    "plural": true,
                    "selections": (v13/*: any*/),
                    "storageKey": "project_subcontractor_crew_onsite_periods(where:{\"closed_at\":{\"_is_null\":true}})"
                  },
                  {
                    "alias": null,
                    "args": [
                      {
                        "kind": "Literal",
                        "name": "where",
                        "value": {
                          "deleted_at": (v12/*: any*/),
                          "subcontractor_worker": {}
                        }
                      }
                    ],
                    "concreteType": "project_worker_aggregate",
                    "kind": "LinkedField",
                    "name": "project_workers_aggregate",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "project_worker_aggregate_fields",
                        "kind": "LinkedField",
                        "name": "aggregate",
                        "plural": false,
                        "selections": [
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "count",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": "project_workers_aggregate(where:{\"deleted_at\":{\"_is_null\":true},\"subcontractor_worker\":{}})"
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "project_worker",
                    "kind": "LinkedField",
                    "name": "lead_foreman_project_worker",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "worker",
                        "kind": "LinkedField",
                        "name": "subcontractor_worker",
                        "plural": false,
                        "selections": (v13/*: any*/),
                        "storageKey": null
                      },
                      (v8/*: any*/),
                      (v9/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v8/*: any*/),
                          (v9/*: any*/),
                          (v10/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "role",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "__typename",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "cursor",
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "concreteType": "PageInfo",
            "kind": "LinkedField",
            "name": "pageInfo",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "endCursor",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "hasNextPage",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v5/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "CrewTableQuery_project_crew_connection",
        "kind": "LinkedHandle",
        "name": "project_crew_connection"
      },
      {
        "alias": "allProjectCrewsConnection",
        "args": (v6/*: any*/),
        "concreteType": "project_crewConnection",
        "kind": "LinkedField",
        "name": "project_crew_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_crewEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_crew",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v13/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "5c8b48d7f96bd68dc85583b9b4d609e9",
    "id": null,
    "metadata": {},
    "name": "CrewTableQuery",
    "operationKind": "query",
    "text": "query CrewTableQuery(\n  $first: Int!\n  $after: String\n  $where: project_crew_bool_exp!\n  $order_by: [project_crew_order_by!]!\n) {\n  ...CrewTable_project_crew_4g04uc\n  ...CrewTable_total_3FC4Qo\n}\n\nfragment CrewTable_project_crew_4g04uc on query_root {\n  project_crew_connection(first: $first, after: $after, where: $where, order_by: $order_by) {\n    edges {\n      node {\n        id\n        pk: id\n        name\n        deleted_at\n        project_workers(where: {subcontractor_worker: {}}, order_by: {user: {name: asc}}) {\n          id\n          pk: id\n          worker_role\n          hard_hat_number\n          title {\n            translation {\n              id\n              en\n              es\n              pt\n              original\n              pk: id\n              clientText: original\n            }\n            id\n          }\n          user {\n            name\n            email\n            id\n          }\n        }\n        subcontractor {\n          id\n          pk: id\n          name\n        }\n        project {\n          id\n          pk: id\n          name\n        }\n        project_subcontractor_crew_onsite_periods(where: {closed_at: {_is_null: true}}) {\n          id\n        }\n        project_workers_aggregate(where: {subcontractor_worker: {}, deleted_at: {_is_null: true}}) {\n          aggregate {\n            count\n          }\n        }\n        lead_foreman_project_worker {\n          id\n          subcontractor_worker {\n            id\n          }\n          pk: id\n          user {\n            id\n            pk: id\n            name\n            username\n            role\n          }\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment CrewTable_total_3FC4Qo on query_root {\n  allProjectCrewsConnection: project_crew_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "52a60716707b17ad9a9aae3be767d6c3";

export default node;
