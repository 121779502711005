import { useGetIncidentReportAndChartOptionsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { Flex } from "antd";
import GCAdminIncidentsHeader from "src/root/routes/views/general-contractor/hierarchy/component/incident/header/GCAdminIncidentsHeader";
import useGetIncidentFilters from "src/root/routes/views/general-contractor/hierarchy/component/incident/utils/useGetIncidentFilters";
import GCIncidentCharts from "../components/chart/GCIncidentCharts";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import LargeTableSkeleton from "src/common/components/tables/basic/LargeTableSkeletion";

const GCProjectIncidentDashboard: React.FC<{ projectId: string }> = ({
  projectId,
}) => {
  const { data: options, error: optionsError } =
    useGetIncidentReportAndChartOptionsQuery({
      variables: {
        projWhere: { id: { _eq: projectId } },
        gcBuWhere: { id: { _is_null: true } },
        gcOfficeWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
      },
    });
  if (optionsError) throw optionsError;
  const {
    selectFilters: incidentSelectFilters,
    bodyPartsFilter,
    dateFilter,
    incidentTypesFilter,
    injuryCauseFilter,
    injuryTypesFilter,
    companiesFilter,
    severityFilter,
    setDateFilter,
  } = useGetIncidentFilters(options);
  return (
    <Flex gap={"large"} vertical>
      <GCAdminIncidentsHeader
        reportCreatorButtonProps={{
          options,
          organizationId: projectId,
          organizationLevel: "project",
        }}
        filters={{
          selectFilters: incidentSelectFilters,
          dates: {
            selectedDates: dateFilter,
            onSelectedDatesChange: setDateFilter,
          },
        }}
      />
      <GCIncidentCharts
        projWhere={{ id: { _eq: projectId } }}
        options={options}
        currentFilters={{
          bodyPartsFilter,
          companiesFilter,
          dateFilter,
          incidentTypesFilter,
          injuryCauseFilter,
          injuryTypesFilter,
          severityFilter,
        }}
      />
    </Flex>
  );
};
export default withCustomSuspense(GCProjectIncidentDashboard, {
  fallback: <LargeTableSkeleton />,
});
