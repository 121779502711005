import { IconPlus } from "@tabler/icons";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { ConnectionHandler } from "relay-runtime";
import Button from "src/common/components/general/Button";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation } from "src/common/types/generated/relay/SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation.graphql";
import * as uuid from "uuid";
import SitedeliveryTimeBlockModal, {
  SitedeliveryTimeBlockModalValues,
} from "./SitedeliveryTimeBlockModal";
import useAuthUser from "src/common/hooks/useAuthUser";

const insertSubBlockMutation = graphql`
  mutation SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation(
    $object: project_delivery_sub_block_insert_input!
    $deleteFilterCalendarWhere: user_project_filter_calendar_bool_exp!
  ) {
    insert_project_delivery_sub_block_one(object: $object) {
      id
      pk: id @__clientField(handle: "pk")
      start_time
      end_time
      weekday
      calendars {
        calendar {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
          color_hex
        }
      }
      subcontractors {
        subcontractor {
          id
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
    delete_user_project_filter_calendar(where: $deleteFilterCalendarWhere) {
      returning {
        id
        calendar_id
      }
    }
  }
`;

interface SitedeliveryAddTimeBlockModalProps {
  timezone?: string;
}

const SitedeliveryAddTimeBlockModal: React.FC<
  SitedeliveryAddTimeBlockModalProps
> = (props) => {
  const [visible, setVisible] = useState(false);

  const { projectId } = useParams();
  if (!projectId) {
    throw new Error("project id param is missing");
  }
  const authUser = useAuthUser();
  const [insertSubBlock, loading] =
    useAsyncMutation<SitedeliveryAddTimeBlockModal_insertSubBlock_Mutation>(
      insertSubBlockMutation,
    );

  const onSubmit = async (values: SitedeliveryTimeBlockModalValues) => {
    await insertSubBlock({
      variables: {
        object: {
          start_time: dayjs(values.startTime)
            .tz(props.timezone, true)
            .format("HH:mm"),
          end_time: dayjs(values.endTime)
            .tz(props.timezone, true)
            .format("HH:mm"),
          project_id: projectId,
          weekday: values.weekday,
          created_by_user_id: authUser.uid,
          calendars: {
            data: values.calendarIds.map((c) => ({
              calendar_id: c,
            })),
          },

          subcontractors: {
            data: values.subcontractorIds.map((s) => ({
              subcontractor_id: s,
            })),
          },
        },
        deleteFilterCalendarWhere: {
          calendar_id: { _in: values.calendarIds },
          filter_type: { _eq: "web-hidden-calendar" },
          user_id: { _eq: authUser.uid },
          project_id: { _eq: projectId },
        },
      },
      updater: (store) => {
        const subBlock = store.getRootField(
          "insert_project_delivery_sub_block_one",
        );
        const conn = ConnectionHandler.getConnection(
          store.getRoot(),
          "GCProjectCalendarSitedeliverySubBlocks_project_delivery_sub_block_connection",
        );
        const conn1 = ConnectionHandler.getConnection(
          store.getRoot(),
          "SitedeliveryTimeBlockModal_project_delivery_sub_block_connection",
        );
        const calendarFilterConn = ConnectionHandler.getConnection(
          store.getRoot(),
          "GCProjectCalendarSitedeliverySubBlocks_user_project_filter_calendar_connection",
        );
        const deletedFilterCalendars = store.getRootField(
          "delete_user_project_filter_calendar",
        );

        if (calendarFilterConn && deletedFilterCalendars) {
          deletedFilterCalendars
            .getLinkedRecords("returning")
            .forEach((calendar) => {
              ConnectionHandler.deleteNode(
                calendarFilterConn,
                calendar.getValue("id"),
              );
            });
        }

        if (conn) {
          const edge = store.create(uuid.v4(), "edge");
          edge.setLinkedRecord(subBlock, "node");
          ConnectionHandler.insertEdgeAfter(conn, edge);
        }
        if (conn1) {
          const edge = store.create(uuid.v4(), "edge");
          edge.setLinkedRecord(subBlock, "node");
          ConnectionHandler.insertEdgeAfter(conn1, edge);
        }
      },
    });
  };

  return (
    <>
      <Button
        icon={IconPlus}
        onClick={() => {
          setVisible(true);
        }}
        label={`Add Sub Block`}
      />
      {visible && (
        <SitedeliveryTimeBlockModal
          modalClose={() => {
            setVisible(false);
          }}
          modalVisible={visible}
          onSubmit={onSubmit}
          loading={loading}
        />
      )}
    </>
  );
};

export default SitedeliveryAddTimeBlockModal;
