import React from "react";
import { useParams } from "react-router-dom";
import GCObservationCharts from "../charts/GCObservationCharts";
import { useGetAdminObservationOptionsQuery } from "src/common/types/generated/apollo/graphQLTypes";
import GCAdminObservationsHeader from "src/root/routes/views/general-contractor/hierarchy/component/observation/header/GCAdminObservationsHeader";
import useGetObservationFilters from "src/root/routes/views/general-contractor/hierarchy/component/observation/utils/useGetObservationFilters";
import { Flex } from "antd";

const GCProjObsDashboard: React.FunctionComponent = () => {
  const projectId = useParams().projectId;
  if (!projectId) throw new Error("projectId not found");
  const projWhere = { id: { _eq: projectId } };
  const { data: options } = useGetAdminObservationOptionsQuery({
    variables: {
      projWhere,
      includeProjSetting: true,
      gcBuWhere: { id: { _is_null: true } },
      gcDivisionWhere: { id: { _is_null: true } },
      gcOfficeWhere: { id: { _is_null: true } },
    },
  });
  const {
    categoriesFilter,
    dateFilter,
    companiesFilter,
    riskLevelFilter,
    selectFilters: obsSelectFilters,
    setDateFilter,
    subCategoriesFilter,
  } = useGetObservationFilters(options);
  return (
    <Flex gap={"large"} vertical>
      <GCAdminObservationsHeader
        reportCreatorButtonProps={{
          options,
          organizationId: projectId,
          organizationLevel: "project",
        }}
        filters={{
          selectFilters: obsSelectFilters,
          dates: {
            selectedDates: dateFilter,
            onSelectedDatesChange: setDateFilter,
          },
        }}
      />
      <GCObservationCharts
        projWhere={projWhere}
        options={options}
        currentFilters={{
          categoriesFilter,
          dateFilter,
          companiesFilter,
          subCategoriesFilter,
          riskLevelFilter,
        }}
      />
    </Flex>
  );
};
export default GCProjObsDashboard;
