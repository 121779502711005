import React from "react";
import { useParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "../../../../../../common/components/layouts/tabs/CustomTabsRouted";

interface GCProjectPartnersProps {}

const GCProjectPartners: React.FunctionComponent<
  GCProjectPartnersProps
> = () => {
  const { projectId } = useParams();
  const basePath = `/gce/projects/${projectId}/workers`;

  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "table",
      label: "Workers",
    },
    {
      path: "overview",
      label: "Overview",
    },
  ];

  return (
    <BasicWrapper scrollable>
      <CustomTabsRouted tabs={tabs} basePath={basePath} />
    </BasicWrapper>
  );
};

export default GCProjectPartners;
