import React, { useState } from "react";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";
import SiteFeatureStepsUIImages from "src/common/components/general/images/SiteFeatureStepsUIImages";
import { ImageType } from "src/common/components/general/images/SiteFeatureStepsUIImages";
import uploadImage from "src/common/functions/upload-utility/uploadImage";

export interface PtpQrPhotoUploadProps extends SiteOrientationStepProps {}

const PtpQrPhotoUpload: React.FC<PtpQrPhotoUploadProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const { ptpQrUser, setPtpQrUser } = usePtpQr();
  const { pictures } = ptpQrUser;
  const [loading, setLoading] = useState(false);

  const onNext = async (images: ImageType[]) => {
    const uploadedImages = [...ptpQrUser.pictures];
    const uploadPromises = images
      .filter(
        (img) => !uploadedImages.find((u) => u.id === img.id) || img.edited,
      )
      .map(async (image) => {
        if (image.url) {
          const uploadedImg = await uploadImage(image.url);
          if (uploadedImg) {
            const replacingIndex = uploadedImages.findIndex(
              (img) => img.id === image.id,
            );
            if (replacingIndex === -1) {
              uploadedImages.push({ ...uploadedImg, id: image.id });
            } else {
              uploadedImages[replacingIndex] = {
                ...uploadedImg,
                id: image.id,
              };
            }
          }
        }
      });
    setLoading(true);
    await Promise.allSettled(uploadPromises).then((results) => {
      results.map((result) => {
        console.log(result.status);
      });
    });

    setPtpQrUser((prev) => ({ ...prev, pictures: uploadedImages }));
    setLoading(false);
    props.onNext();
  };

  return (
    <SiteFeatureStepsUIImages
      images={pictures}
      onBack={props.onBack}
      onNext={async (newImages) => {
        await onNext(newImages);
        props.onNext();
      }}
      label={
        ptpQrUser.reportManagement.safetyReportAwarenessDisabled
          ? langStrings.strings.takePictureOfHuddleBoard
          : langStrings.strings.takePhotoOfYourCrew
      }
      loading={loading}
      required={true}
    />
  );
};

export default PtpQrPhotoUpload;
