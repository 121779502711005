import React, { useState } from "react";
import { Popover } from "antd";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { auth } from "src/common/functions/firebase";
import { roleVar } from "src/common/api/apollo/cache";
import { usePtpQrUserSignInUsingEmailMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { signInWithCustomToken } from "firebase/auth";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";
import hasApolloErrorCode from "src/utility-features/error-handling/hasApolloErrorCode";

export interface PtpQrHomePageProps extends SiteOrientationStepProps {
  onLoginSuccess: (userId: string) => Promise<void>;
}

const PtpQrHomePage: React.FC<PtpQrHomePageProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const { ptpQrUser, projectId } = usePtpQr();
  const { userInfo } = ptpQrUser;
  const [ptpQrCodeSignIn] = usePtpQrUserSignInUsingEmailMutation();
  const name = userInfo?.name ?? "";
  const email = userInfo?.email;
  const firstName = name.split(" ")[0];
  const [loading, setLoading] = useState(false);

  const handleSignIn = async () => {
    if (!userInfo?.projectWorkerId && email) {
      setLoading(true);
      try {
        const { data } = await ptpQrCodeSignIn({
          variables: {
            input: {
              email: email,
              projectId,
            },
          },
        });

        if (data?.ptpQrUserSignInUsingEmail) {
          const { workerId, token } = data.ptpQrUserSignInUsingEmail;
          if (token) {
            roleVar("none");
            await auth.signOut();
            await signInWithCustomToken(auth, token);
            await auth.currentUser?.getIdToken(true);
          }
          await props.onLoginSuccess(workerId);
          props.onNext();
        }
      } catch (err) {
        if (hasApolloErrorCode(err, "user-does-not-exist")) {
          props.onNext();
        } else {
          throw err;
        }
      } finally {
        setLoading(false);
      }
    } else {
      props.onNext();
    }
  };

  return (
    <SiteFeatureStepLayout
      loading={loading}
      onNextButtonClick={handleSignIn}
      onBackButtonClick={props.onBack}
    >
      <Popover
        open={!userInfo?.projectWorkerId}
        content={
          <div
            className="text-1.2 text-interactive-primary hover:underline cursor-pointer"
            onClick={() => {
              window.location.reload();
              localStorage.removeItem("ptpQrUserEmail");
              localStorage.removeItem("ptpQrUserName");
            }}
          >
            {langStrings.strings.notNameClickHere(name)}
          </div>
        }
      />
      <SiteFeatureStepsInputLayout>
        <div className="text-2">
          {langStrings.strings.hiReadyToSubmitPtp(firstName)}
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrHomePage;
