import React, { FC, useEffect, useState, useMemo } from "react";
import {
  Checkbox,
  Form,
  Input,
  Modal,
  Select,
  message,
  notification,
} from "antd";
import {
  Order_By,
  useConvertWorkerToEmployeeMutation,
  useGetGeneralContractorsQuery,
  useInsertCsrEditMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ConnectionHandler } from "relay-runtime";
import { useGetEmployeeTitlesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { useParams } from "react-router-dom";
import { auth } from "src/common/functions/firebase";
import { graphql } from "babel-plugin-relay/macro";
import { ConvertWorkerToEmployeeModalQuery } from "src/common/types/generated/relay/ConvertWorkerToEmployeeModalQuery.graphql";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { getSameEmailUser } from "src/common/functions/sameEmailUsers";
import hasApolloErrorCode from "src/utility-features/error-handling/hasApolloErrorCode";
import { commitLocalUpdate } from "relay-runtime";
import withCustomSuspense from "../general/withCustomSuspense";
import {
  ConvertWorkerDataType,
  SingleWorkerDataType,
} from "./performWorkerTypes";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
const query = graphql`
  query ConvertWorkerToEmployeeModalQuery($projectId: uuid!) {
    general_contractor_project_connection(
      where: { project_id: { _eq: $projectId } }
      order_by: { general_contractor: { name: asc } }
    ) {
      edges {
        node {
          general_contractor_id
          general_contractor {
            name
          }
        }
      }
    }
  }
`;

export type ConvertWorkerToEmployeeModalDataType = Array<ConvertWorkerDataType>;

const { Option } = Select;

type FormVals = {
  worker: string;
  titleId: string;
  confirm: boolean;
  generalContractor: string;
  workerEmail: string;
  workerPhone?: string;
};

const ConvertWorkerToEmployeeModal: FC<
  {
    visible: boolean;
    refetch: () => void;
    onClose: () => void;
    onCancel: () => void;
    removeWorkerFromCache?: (workerIdToDelete: string) => void;
  } & (
    | { type: "selectWorker"; data: ConvertWorkerToEmployeeModalDataType }
    | {
        type: "single";
        worker: SingleWorkerDataType;
        insertCsrEdit?: boolean;
      }
  )
> = ({ visible, onClose, refetch, onCancel, ...props }) => {
  const [form] = Form.useForm<FormVals>();
  const [insertCsrEdit] = useInsertCsrEditMutation();

  const projectId = useParams()["projectId"] as string;
  const { userData } = useUserData();
  const environment = useRelayEnvironment();
  const [convertWorkerToEmployee, { loading }] =
    useConvertWorkerToEmployeeMutation();
  const { data: titleData } = useGetEmployeeTitlesQuery();
  const { data: gcData } = useGetGeneralContractorsQuery({
    variables: {
      where: {
        ...(userData.employee
          ? { employees: { uid: { _eq: userData.uid } } }
          : {}),
        gc_projects: { project_id: { _eq: projectId } },
      },
      limit: 1000,
      offset: 0,
      order_by: { name: Order_By.Asc },
    },
  });
  const gcNameIdMapping = useMemo(
    () =>
      new Map((gcData?.general_contractor || []).map((gc) => [gc.id, gc.name])),
    [gcData],
  );
  const relayEnv = useRelayEnvironment();
  const [workerEmailDisabled, setWorkerEmailDisabled] = useState(true);
  const [workerPhoneDisabled, setWorkerPhoneDisabled] = useState(true);
  useEffect(() => {
    if (props.type === "single") {
      form.setFieldsValue({
        worker: props.worker.id,
        workerEmail: props.worker.email,
        workerPhone: props.worker.phone_number || undefined,
      });
    }
  }, [props]); // THIS IS precaution in case someone modifying logic in future
  return (
    <>
      <Modal
        onCancel={onClose}
        title={`Convert ${
          props.type === "single" ? props.worker.name : "a Worker"
        } to Your Team`}
        width={950}
        okText="Convert"
        destroyOnClose
        open={visible}
        confirmLoading={loading}
        onOk={async () => {
          const vals = await form.validateFields();
          if (!vals.confirm) {
            message.error("Please accept to continue");
            return;
          }
          if (props.type === "selectWorker") {
            const email = props.data.find(
              (worker) => worker.id === vals.worker,
            )?.email;
            if (!email || email.trim() !== vals.workerEmail.trim()) {
              const emailUserData = await getSameEmailUser(
                vals.workerEmail,
                environment,
              );

              if (
                emailUserData.user_connection.edges.length > 0 &&
                (!email ||
                  emailUserData.user_connection.edges[0].node.pk !==
                    vals.worker)
              ) {
                throw Error(
                  "This email is already registered with another user",
                );
              }
            }
          } else {
            vals.worker = props.worker.id;
            vals.workerEmail = props.worker.email;
            vals.workerPhone = props.worker.phone_number || undefined;
          }
          const input = {
            generalContractorId: vals.generalContractor,
            titleId: vals.titleId,
            worker_id: vals.worker,
            email: vals.workerEmail,
            projectIds: [projectId],
            generalContractorName: gcNameIdMapping.get(vals.generalContractor)!,
            phoneNumber: vals.workerPhone,
          };
          await convertWorkerToEmployee({ variables: { input } }).catch(
            (error) => {
              if (hasApolloErrorCode(error, "email-send-error")) {
                return false;
              } else {
                throw error;
              }
            },
          );

          message.success("convert Worker To Employee Successfull");
          props.removeWorkerFromCache?.(vals.worker);

          // message.success("Worker Converted Successfully");
          form.resetFields();
          onClose();
          refetch();
          onCancel();
          if (props.type === "single" && props.insertCsrEdit)
            await insertCsrEdit({
              variables: {
                csrEdits: [
                  {
                    change_log: [input],
                    name: "convertWorkerToEmployee",
                    csr_user_id: userData.uid,
                    edit_text: "Merged two workers into one",
                    entity_id: vals.worker,
                    operation_name: "resolver_call",
                  },
                ],
              },
            });
        }}
      >
        <Form form={form} layout="vertical">
          {props.type === "selectWorker" && (
            <>
              <div>
                Select the worker you want to add to your Team &nbsp;&nbsp;
                <br />
              </div>
              <Form.Item
                name="worker"
                label={"Select Worker"}
                rules={[{ required: true, message: "Choose a Worker" }]}
              >
                <Select
                  showSearch
                  filterOption={(input, option) => {
                    if (!option || !option.props.children[0]) return false;
                    return (
                      option.props.children[0]
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    );
                  }}
                  onChange={(value) => {
                    const selectedWorker = props.data.find(
                      (u) => u.id === value,
                    );
                    if (selectedWorker) {
                      form.setFieldsValue({
                        workerEmail: selectedWorker.email || "",
                        workerPhone: selectedWorker.phoneNumber,
                      });
                      setWorkerEmailDisabled(!!selectedWorker.email);
                      setWorkerPhoneDisabled(!!selectedWorker.phoneNumber);
                    }
                  }}
                >
                  {props.data.map((u) => {
                    const list = [
                      u.name,
                      u.hh,
                      u.company,
                      u.trade,
                      u.email,
                    ].filter((s) => s);
                    return (
                      <Option value={u.id} key={u.id}>
                        {list.join(", ")}&nbsp;&nbsp;
                        <span className="font-accent text-interactive-primary">
                          {u.createdPassword ? " PASSWORD SET" : ""}
                        </span>
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
              <Form.Item
                name="workerEmail"
                label="Email"
                rules={[
                  {
                    required: true,
                    message: "Email required for an employee",
                  },
                ]}
              >
                <Input
                  disabled={workerEmailDisabled}
                  // defaultValue={workerEmail || enteredEmail}
                  // value={workerEmailDisabled ? workerEmail : enteredEmail}
                  onChange={(e) =>
                    form.setFieldsValue({ workerEmail: e.target.value })
                  }
                />
              </Form.Item>
              <Form.Item name="workerPhone" label="Phone Number">
                <Input
                  disabled={workerPhoneDisabled}
                  // defaultValue={workerEmail || enteredEmail}
                  // value={workerPhone}
                  onChange={(e) =>
                    form.setFieldsValue({ workerPhone: e.target.value })
                  }
                />
              </Form.Item>
            </>
          )}
          <Form.Item
            name="generalContractor"
            label="Select General Contractor"
            rules={[{ required: true, message: "Choose a General Contractor" }]}
            initialValue={
              gcData && gcData.general_contractor.length === 1
                ? gcData.general_contractor[0].id
                : undefined
            }
          >
            {gcData && (
              <Select
                style={{ width: "100%" }}
                placeholder="Select General Contractor"
                options={gcData.general_contractor.map((gc) => ({
                  label: gc.name,
                  value: gc.id,
                }))}
              ></Select>
            )}
          </Form.Item>
          <Form.Item
            name="titleId"
            label="Title"
            rules={[{ required: true, message: "Choose a title" }]}
          >
            <Select style={{ width: "100%" }}>
              {titleData?.employee_title.map((w) => (
                <Select.Option key={w.id} value={w.id}>
                  {w.name.en}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <p>
            By converting, this person will be added to your Operation’s Team.
            They will have access to ALL reports and information on your
            project. If this person is a Worker (Foreman or Crew Lead) DO NOT
            take this action. Instead, change the Worker’s employer to your Self
            Perform Subcontractor. <br></br>
            <i>
              Typically this is when the individual selected the wrong user type
              during Orientation.
            </i>
          </p>
          <br />
          <Form.Item name="confirm" valuePropName="checked">
            <Checkbox>I Understand</Checkbox>
          </Form.Item>
          <br />
        </Form>
      </Modal>
    </>
  );
};
export default withCustomSuspense(ConvertWorkerToEmployeeModal);
