/**
 * @generated SignedSource<<3d9406aef2aeb3d339d27e742b8c2ae5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type QuickLookWorkerQuery$variables = {
  reference: string;
};
export type QuickLookWorkerQuery$data = {
  readonly user_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly universal_orientations: ReadonlyArray<{
          readonly universal_orientated_at: string;
        }>;
      };
    }>;
  };
};
export type QuickLookWorkerQuery = {
  response: QuickLookWorkerQuery$data;
  variables: QuickLookWorkerQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "reference"
  }
],
v1 = [
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "reference"
          }
        ],
        "kind": "ObjectValue",
        "name": "reference_number"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v3 = [
  {
    "kind": "Literal",
    "name": "limit",
    "value": 1
  },
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "universal_orientated_at": "desc"
    }
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "universal_orientated_at",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "QuickLookWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "user_universal_orientation",
                    "kind": "LinkedField",
                    "name": "universal_orientations",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/)
                    ],
                    "storageKey": "universal_orientations(limit:1,order_by:{\"universal_orientated_at\":\"desc\"})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "QuickLookWorkerQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "userConnection",
        "kind": "LinkedField",
        "name": "user_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "userEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "user",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  {
                    "alias": null,
                    "args": (v3/*: any*/),
                    "concreteType": "user_universal_orientation",
                    "kind": "LinkedField",
                    "name": "universal_orientations",
                    "plural": true,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/)
                    ],
                    "storageKey": "universal_orientations(limit:1,order_by:{\"universal_orientated_at\":\"desc\"})"
                  },
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "7e628d76643b1f8e23515c5139073c69",
    "id": null,
    "metadata": {},
    "name": "QuickLookWorkerQuery",
    "operationKind": "query",
    "text": "query QuickLookWorkerQuery(\n  $reference: String!\n) {\n  user_connection(where: {reference_number: {_eq: $reference}}) {\n    edges {\n      node {\n        name\n        universal_orientations(limit: 1, order_by: {universal_orientated_at: desc}) {\n          universal_orientated_at\n          id\n        }\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "71b37597c62dea1d874078d0213ece0c";

export default node;
