import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import GCAdminIncidents from "../../component/incident/GCAdminIncidents";

const GCCorporateIncidents: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const projWhere = {
    general_contractor_id: { _eq: employee.general_contractor.id },
  };
  return (
    <GCAdminIncidents
      projWhere={projWhere}
      organizationLevel="gc"
      organizationId={employee.general_contractor.id}
      optionsQueryVars={{
        projWhere,
        gcBuWhere: employee.general_contractor.hierarchy_business_unit_name
          ? { general_contractor_id: { _eq: employee.general_contractor.id } }
          : { id: { _is_null: true } },
        gcDivisionWhere: employee.general_contractor.hierarchy_division_name
          ? { general_contractor_id: { _eq: employee.general_contractor.id } }
          : { id: { _is_null: true } },
        gcOfficeWhere: employee.general_contractor.hierarchy_office_name
          ? { general_contractor_id: { _eq: employee.general_contractor.id } }
          : { id: { _is_null: true } },
      }}
      severityUserFilter={{
        gc_id: { _eq: employee.general_contractor.id },
        user: { employee: { is_corporate_admin: { _eq: true } } },
      }}
      toInsertWith={{ gc_id: employee.general_contractor.id }}
      gcEmployeeWhere={{
        is_corporate_admin: { _eq: true },
        general_contractor_id: { _eq: employee.general_contractor.id },
      }}
    />
  );
};
export default withCorporateAdminWrapper(GCCorporateIncidents);
