import { FC } from "react";
import {
  useUpdateVehicleIncidentMutation,
  Vehicle_Incident_Set_Input,
  useGetMonetarySeverityLevelsQuery,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentCommonUser from "../incident-users/IncidentCommonUser";
import { Card } from "antd";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import useUploadIncidentTypeDocument from "../../utils/useUploadIncidentDocuments";
import useDeleteIncidentTypeDocument from "../../utils/useDeleteIncidentDocument";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import { useSearchParams } from "react-router-dom";
import IncidentTextField from "../basic/IncidentTextField";
import IncidentInputField from "../basic/IncidentInputField";
import IncidentSelectField from "../basic/IncidentSelectField";
import dayjs from "dayjs";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import useUpdateIncidentCommonUser from "../../utils/useUpdateIncidentCommonUser";
import useUpdateIncidentGeneralPerson from "../../utils/useUpdateIncidentGeneralPerson";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";

const VehicleIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();
  const [updateIncidentCommonUser] = useUpdateIncidentCommonUser();
  const [updateGeneralPerson] = useUpdateIncidentGeneralPerson();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();

  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const monetarySeverityLevels = monetarySeverity?.incident_severity;
  const [uploadIncidentDocument] = useUploadIncidentTypeDocument();
  const [deleteIncidentDocument] = useDeleteIncidentTypeDocument();
  const [insertIncidentType] = useInsertIncidentType();
  const [updateVehicleIncident] = useUpdateVehicleIncidentMutation();
  const vehicle = incident.vehicle_incident;
  const documents = getIncidentDocuments(incident, "vehicle");
  if (!vehicle) {
    throw new Error("vehicle_incident is missing");
  }
  const [updateMonetarySeverity] = useUpdateMonetarySeverity();

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_type",
      incident,
      type: "vehicle",
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_type",
      incident: incident,
      objects: objects,
      type: "vehicle",
    });
  };

  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "vehicle");
  };

  const updateVehicle = async (
    _set: Omit<Vehicle_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      vehicle_incident: { ...vehicle, ..._set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updateVehicleIncident({
      variables: {
        incidentId: incident.id,
        _set: _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "vehicle-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_vehicle_incident_by_pk: {
          ...vehicle,
          incident_id: incident.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const personInvolved = vehicle.person_involved;
  const vehicleOwner = vehicle.vehicle_owner;
  const driver = vehicle.driver;

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Vehicle/Road Incident</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <IncidentCommonUser
          projectId={incident.project_id}
          title={"Who was involved in the accident"}
          user={personInvolved ?? undefined}
          onUpdateUser={async (id, name) => {
            updateVehicle(
              { person_involved_user_id: id },
              `Updated Vehicle/Road Incident - "Who was involved in the accident" to "${name}"`,
            );
          }}
          onUpdateUserInfo={async (id, key, val) => {
            if (personInvolved) {
              const updatedIncident = {
                ...incident,
                vehicle_incident: {
                  ...vehicle,
                  person_involved: {
                    ...personInvolved,
                    [key]: val,
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const label = key === "phone_number" ? "phone number" : "email";
              const comment = `Updated Vehicle/Road Incident - ${label} of the "Who was involved in the accident" to ${val}`;
              updateIncidentCommonUser(
                id,
                { [key]: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          onUpdateGeneralPerson={async (val) => {
            if (personInvolved && personInvolved.general_person) {
              const updatedIncident = {
                ...incident,
                vehicle_incident: {
                  ...vehicle,
                  person_involved: {
                    ...personInvolved,
                    general_person: {
                      ...personInvolved.general_person,
                      employer: val,
                    },
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const comment = `Updated Vehicle/Road Incident - employer of "Who was involved in the accident" to ${val}`;
              updateGeneralPerson(
                personInvolved.id,
                { employer: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
        />
        <IncidentCommonUser
          projectId={incident.project_id}
          title={"Vehicle Owner"}
          user={vehicle.vehicle_owner ?? undefined}
          onUpdateUser={async (id, name) => {
            updateVehicle(
              { vehicle_owner_user_id: id },
              `Updated Vehicle/Road Incident - "Vehicle Owner" to "${name}"`,
            );
          }}
          onUpdateUserInfo={async (id, key, val) => {
            if (vehicleOwner) {
              const updatedIncident = {
                ...incident,
                reported_by_user: { ...vehicleOwner, [key]: val },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const label = key === "phone_number" ? "phone number" : "email";
              const comment = `Updated Vehicle/Road Incident -  ${label} of the "Vehicle Owner" to ${val}`;
              updateIncidentCommonUser(
                id,
                { [key]: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          onUpdateGeneralPerson={async (val) => {
            if (vehicleOwner && vehicleOwner.general_person) {
              const updatedIncident = {
                ...incident,
                vehicle_incident: {
                  ...vehicle,
                  vehicle_owner: {
                    ...vehicleOwner,
                    general_person: {
                      ...vehicleOwner.general_person,
                      employer: val,
                    },
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const comment = `Updated Vehicle/Road Incident - employer of "Vehicle Owner" to ${val}`;
              updateGeneralPerson(
                vehicleOwner.id,
                { employer: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
        />
        <IncidentCommonUser
          projectId={incident.project_id}
          title={"Who was Driving"}
          user={driver ?? undefined}
          onUpdateUser={async (id, name) => {
            updateVehicle(
              { driver_user_id: id },
              `Updated Vehicle/Road Incident - "Who was Driving" to "${name}"`,
            );
          }}
          onUpdateUserInfo={async (id, key, val) => {
            if (driver) {
              const updatedIncident = {
                ...incident,
                reported_by_user: { ...driver, [key]: val },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const label = key === "phone_number" ? "phone number" : "email";
              const comment = `Updated Vehicle/Road Incident - ${label} of the "Who was Driving" to ${val}`;
              updateIncidentCommonUser(
                id,
                { [key]: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
          onUpdateGeneralPerson={async (val) => {
            if (driver && driver.general_person) {
              const updatedIncident = {
                ...incident,
                vehicle_incident: {
                  ...vehicle,
                  driver: {
                    ...driver,
                    general_person: {
                      ...driver.general_person,
                      employer: val,
                    },
                  },
                },
              };
              const patch = createIncidentPatch(updatedIncident, incident);
              const comment = `Updated Vehicle/Road Incident - employer of "Who was Driving" to ${val}`;
              updateGeneralPerson(
                driver.id,
                { employer: val },
                comment,
                incident.id,
                patch,
              );
            }
          }}
        />
        <Card className="w-4/5">
          <IncidentSelectField
            title="Monetary Severity"
            options={
              monetarySeverityLevels?.map((severity) => ({
                label: severity.name.en,
                value: severity.id,
              })) ?? []
            }
            value={incident.monetary_severity_id ?? undefined}
            onChange={(option) => {
              if (typeof option.value === "string") {
                updateMonetarySeverity(incident, option.value, option.label);
              }
            }}
          />
        </Card>

        <Card className="w-4/5">
          <div className="w-10">Party responsible for damage</div>

          <IncidentInputField
            label="Employer Name"
            onSave={(val) => {
              if (typeof val === "string")
                updateVehicle(
                  {
                    party_responsible_employer_name: val,
                  },
                  `Updated Vehicle/Road Incident - "Employer Name" to "${val}"`,
                );
            }}
            text={vehicle.party_responsible_employer_name ?? undefined}
          />

          <div className="mt-1">
            <IncidentInputField
              label="Contact Information"
              onSave={(val) => {
                if (typeof val === "string") {
                  updateVehicle(
                    {
                      party_responsible_employer_phone_number: val,
                    },
                    `Updated Vehicle/Road Incident - "Contact Information of Employer" to "${val}"`,
                  );
                }
              }}
              text={
                vehicle.party_responsible_employer_phone_number ?? undefined
              }
            />
          </div>
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="Description of Damage"
            text={vehicle.description.en}
            textId={vehicle.description.id}
            saveIncidentField={insertIncidentTypeLink}
            fieldTypeKey="vehicle"
            field="description"
            incident={incident}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="* Cause of Damage "
            text={vehicle.cause.en}
            textId={vehicle.cause.id}
            saveIncidentField={insertIncidentTypeLink}
            fieldTypeKey="vehicle"
            field="cause"
            incident={incident}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentInputField
            label="Estimated Cost of Damages (in USD)"
            validateAsNumber={true}
            onSave={(val) => {
              const damage = typeof val === "string" ? parseInt(val) : val;
              updateVehicle(
                { estimated_cost_of_damage: damage },
                `Updated Vehicle/Road Incident - "Estimated Cost of Damages" to ${val}`,
              );
            }}
            text={vehicle.estimated_cost_of_damage ?? undefined}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentInputField
            label="Actual Cost of Damages (in USD)"
            validateAsNumber={true}
            onSave={(val) => {
              const damage = typeof val === "string" ? parseInt(val) : val;
              updateVehicle(
                { actual_cost_of_damage: damage },
                `Updated Vehicle/Road Incident - "Actual Cost of Damages" to ${val}`,
              );
            }}
            text={vehicle.actual_cost_of_damage ?? undefined}
          />
        </Card>

        {incident.incident_types.findIndex(
          (indexType) => indexType.type_value === "vehicle",
        ) !== -1 && (
          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={documents}
            groupId={incident.id}
            uploadDocument={uploadDocument}
            type="vehicle"
          />
        )}

        <IncidentBottomButtons
          saveDisabled={!vehicle.cause.en}
          onNextClick={() => {
            const next = getNextPage(incident, "vehicle");
            setSearchParams({ subview: next });
          }}
          onSave={() => {
            updateVehicle(
              { completed_at: dayjs().format() },
              `Vehicle/Road Incident Section marked as completed`,
            );
          }}
        />
      </div>
    </>
  );
};

export default VehicleIncident;
