import { RightCircleFilled } from "@ant-design/icons";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import CustomTable from "src/common/components/tables/basic/CustomTable";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";

const SCProjectChildSubs: React.FC<{
  childSubs: SCProjectQuery$data["project_subcontractor_to_subcontractor_access_connection"]["edges"];
  projectId: string;
}> = ({ childSubs, projectId }) => {
  const navigate = useNavigate();
  return (
    <CustomTable
      dataSource={childSubs}
      columns={[
        { title: "Name", dataIndex: ["node", "target_subcontractor", "name"] },
        {
          title: "Swtich",
          dataIndex: ["node", "target_subcontractor", "pk"],
          render: (pk, row) => (
            <BPopconfirm
              title={`Switch to ${row.node.target_subcontractor.name} to see their reports?`}
              onConfirm={() =>
                navigate(`/sub/projects/${projectId}/child-sub/${pk}`)
              }
            >
              <Button icon={<RightCircleFilled />} type="primary"></Button>
            </BPopconfirm>
          ),
        },
      ]}
    />
  );
};
export default SCProjectChildSubs;
