/**
 * @generated SignedSource<<56083c5efef7014b8e5952ea16103801>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_crew_bool_exp, project_crew_set_input, project_worker_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type CrewTableActionPopover_DeleteCrewMutation$variables = {
  _setCrew?: project_crew_set_input | null | undefined;
  crewWhere: project_crew_bool_exp;
  removeWorkerWhere: project_worker_bool_exp;
};
export type CrewTableActionPopover_DeleteCrewMutation$data = {
  readonly update_project_crew: {
    readonly returning: ReadonlyArray<{
      readonly deleted_at: string | null | undefined;
      readonly id: string;
      readonly lead_foreman_project_worker: {
        readonly id: string;
        readonly pk: string;
        readonly subcontractor_worker: {
          readonly id: string;
        } | null | undefined;
        readonly user: {
          readonly id: string;
          readonly name: string;
          readonly pk: string;
          readonly role: string;
          readonly username: string | null | undefined;
        } | null | undefined;
      } | null | undefined;
      readonly name: string;
      readonly pk: string;
      readonly project: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
      readonly project_subcontractor_crew_onsite_periods: ReadonlyArray<{
        readonly id: string;
      }>;
      readonly project_workers: ReadonlyArray<{
        readonly hard_hat_number: string | null | undefined;
        readonly id: string;
        readonly pk: string;
        readonly title: {
          readonly translation: {
            readonly clientText: string;
            readonly en: string;
            readonly es: string;
            readonly id: string;
            readonly original: string;
            readonly pk: string;
            readonly pt: string;
          };
        } | null | undefined;
        readonly user: {
          readonly email: string | null | undefined;
          readonly name: string;
        } | null | undefined;
        readonly worker_role: string;
      }>;
      readonly project_workers_aggregate: {
        readonly aggregate: {
          readonly count: number;
        } | null | undefined;
      };
      readonly subcontractor: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  } | null | undefined;
  readonly update_project_worker: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type CrewTableActionPopover_DeleteCrewMutation = {
  response: CrewTableActionPopover_DeleteCrewMutation$data;
  variables: CrewTableActionPopover_DeleteCrewMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "_setCrew"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "crewWhere"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removeWorkerWhere"
},
v3 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "_setCrew"
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "crewWhere"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "deleted_at",
  "storageKey": null
},
v8 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "user": {
        "name": "asc"
      }
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "subcontractor_worker": {}
    }
  }
],
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_role",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hard_hat_number",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "es",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "pt",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "original",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v16 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/)
],
v17 = {
  "_is_null": true
},
v18 = [
  (v4/*: any*/)
],
v19 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "closed_at": (v17/*: any*/)
      }
    }
  ],
  "concreteType": "project_subcontractor_crew_onsite_period",
  "kind": "LinkedField",
  "name": "project_subcontractor_crew_onsite_periods",
  "plural": true,
  "selections": (v18/*: any*/),
  "storageKey": "project_subcontractor_crew_onsite_periods(where:{\"closed_at\":{\"_is_null\":true}})"
},
v20 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "deleted_at": (v17/*: any*/),
        "subcontractor_worker": {}
      }
    }
  ],
  "concreteType": "project_worker_aggregate",
  "kind": "LinkedField",
  "name": "project_workers_aggregate",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "project_worker_aggregate_fields",
      "kind": "LinkedField",
      "name": "aggregate",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "count",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": "project_workers_aggregate(where:{\"deleted_at\":{\"_is_null\":true},\"subcontractor_worker\":{}})"
},
v21 = {
  "alias": null,
  "args": null,
  "concreteType": "worker",
  "kind": "LinkedField",
  "name": "subcontractor_worker",
  "plural": false,
  "selections": (v18/*: any*/),
  "storageKey": null
},
v22 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v23 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "role",
  "storageKey": null
},
v24 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "_set",
      "value": {
        "project_crew_id": null
      }
    },
    {
      "kind": "Variable",
      "name": "where",
      "variableName": "removeWorkerWhere"
    }
  ],
  "concreteType": "project_worker_mutation_response",
  "kind": "LinkedField",
  "name": "update_project_worker",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "affected_rows",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v25 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v26 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v27 = [
  (v4/*: any*/),
  (v25/*: any*/),
  (v26/*: any*/),
  (v6/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "CrewTableActionPopover_DeleteCrewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_crew_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_crew",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_crew",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "project_workers",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker_title",
                    "kind": "LinkedField",
                    "name": "title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "translation",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v5/*: any*/),
                          {
                            "alias": "clientText",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "__original_clientText",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "project_workers(order_by:{\"user\":{\"name\":\"asc\"}},where:{\"subcontractor_worker\":{}})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v16/*: any*/),
                "storageKey": null
              },
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "lead_foreman_project_worker",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v21/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v5/*: any*/),
                      (v6/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v24/*: any*/)
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "CrewTableActionPopover_DeleteCrewMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "project_crew_mutation_response",
        "kind": "LinkedField",
        "name": "update_project_crew",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_crew",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v4/*: any*/),
              (v25/*: any*/),
              (v26/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": (v8/*: any*/),
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "project_workers",
                "plural": true,
                "selections": [
                  (v4/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  (v9/*: any*/),
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker_title",
                    "kind": "LinkedField",
                    "name": "title",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "text_translation",
                        "kind": "LinkedField",
                        "name": "translation",
                        "plural": false,
                        "selections": [
                          (v4/*: any*/),
                          (v11/*: any*/),
                          (v12/*: any*/),
                          (v13/*: any*/),
                          (v14/*: any*/),
                          (v25/*: any*/),
                          (v26/*: any*/),
                          {
                            "alias": "clientText",
                            "args": null,
                            "kind": "ScalarField",
                            "name": "original",
                            "storageKey": null
                          },
                          {
                            "alias": "clientText",
                            "args": null,
                            "filters": null,
                            "handle": "clientText",
                            "key": "",
                            "kind": "ScalarHandle",
                            "name": "original"
                          }
                        ],
                        "storageKey": null
                      },
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v6/*: any*/),
                      (v15/*: any*/),
                      (v4/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "project_workers(order_by:{\"user\":{\"name\":\"asc\"}},where:{\"subcontractor_worker\":{}})"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "subcontractor",
                "plural": false,
                "selections": (v27/*: any*/),
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": (v27/*: any*/),
                "storageKey": null
              },
              (v19/*: any*/),
              (v20/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project_worker",
                "kind": "LinkedField",
                "name": "lead_foreman_project_worker",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v21/*: any*/),
                  (v25/*: any*/),
                  (v26/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v25/*: any*/),
                      (v26/*: any*/),
                      (v6/*: any*/),
                      (v22/*: any*/),
                      (v23/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      (v24/*: any*/)
    ]
  },
  "params": {
    "cacheID": "3a428836774d1121f40b63aab951a9a2",
    "id": null,
    "metadata": {},
    "name": "CrewTableActionPopover_DeleteCrewMutation",
    "operationKind": "mutation",
    "text": "mutation CrewTableActionPopover_DeleteCrewMutation(\n  $crewWhere: project_crew_bool_exp!\n  $removeWorkerWhere: project_worker_bool_exp!\n  $_setCrew: project_crew_set_input\n) {\n  update_project_crew(where: $crewWhere, _set: $_setCrew) {\n    returning {\n      id\n      pk: id\n      name\n      deleted_at\n      project_workers(where: {subcontractor_worker: {}}, order_by: {user: {name: asc}}) {\n        id\n        pk: id\n        worker_role\n        hard_hat_number\n        title {\n          translation {\n            id\n            en\n            es\n            pt\n            original\n            pk: id\n            clientText: original\n          }\n          id\n        }\n        user {\n          name\n          email\n          id\n        }\n      }\n      subcontractor {\n        id\n        pk: id\n        name\n      }\n      project {\n        id\n        pk: id\n        name\n      }\n      project_subcontractor_crew_onsite_periods(where: {closed_at: {_is_null: true}}) {\n        id\n      }\n      project_workers_aggregate(where: {subcontractor_worker: {}, deleted_at: {_is_null: true}}) {\n        aggregate {\n          count\n        }\n      }\n      lead_foreman_project_worker {\n        id\n        subcontractor_worker {\n          id\n        }\n        pk: id\n        user {\n          id\n          pk: id\n          name\n          username\n          role\n        }\n      }\n    }\n  }\n  update_project_worker(where: $removeWorkerWhere, _set: {project_crew_id: null}) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "df136aba0be3993d1b547b9928662378";

export default node;
