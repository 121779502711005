/**
 * @generated SignedSource<<92ccc620acf8c45945401cf361181e1a>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_toolbox_talk_not_require_period_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation$variables = {
  object: project_subcontractor_toolbox_talk_not_require_period_insert_input;
};
export type usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation$data = {
  readonly insert_project_subcontractor_toolbox_talk_not_require_period_one: {
    readonly id: string;
  } | null | undefined;
};
export type usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation = {
  response: usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation$data;
  variables: usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      }
    ],
    "concreteType": "project_subcontractor_toolbox_talk_not_require_period",
    "kind": "LinkedField",
    "name": "insert_project_subcontractor_toolbox_talk_not_require_period_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "33dc252b70970838a6a29d4e1db0bc2c",
    "id": null,
    "metadata": {},
    "name": "usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentReportRequireSettingsSub_TBTNotRequire_InsertMutation(\n  $object: project_subcontractor_toolbox_talk_not_require_period_insert_input!\n) {\n  insert_project_subcontractor_toolbox_talk_not_require_period_one(object: $object) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "64fab96d1ab88359d7586cbc944384e2";

export default node;
