import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import randomHexColor from "src/common/functions/randomHexColor";
import {
  GetAdminObservationOptionsQuery,
  GetObservationChartsDataDocument,
  GetObservationChartsDataQuery,
  GetObservationChartsDataQueryVariables,
  Project_Bool_Exp,
} from "src/common/types/generated/apollo/graphQLTypes";
import { graphColors } from "../../../../../common/constants/graphColors";
import { useSuspenseQuery } from "@apollo/client";
import { ShowPieChartProps } from "../../../../../common/components/charts/ShowPieChart";
import ChartTitleNumbers, {
  ChartTitleNumbersProps,
} from "../../../../../common/components/charts/ChartTitleNumbers";
import GCObservationChartsUI from "./GCObservationChartsUI";
import { Flex, Typography } from "antd";
import filterOnProjectAndHierarchy from "src/root/routes/views/general-contractor/hierarchy/utils/filterOnProjectAndHierarchy";
import colorList from "src/common/components/lists/chartColorList";

const { Text } = Typography;

const GCObservationCharts: React.FunctionComponent<{
  projWhere: Project_Bool_Exp;
  currentFilters?: {
    projectStatusFilter?: string | undefined;
    projectsFilter?: Set<string>;
    subCategoriesFilter: Set<string>;
    riskLevelFilter: Set<string>;
    companiesFilter: Set<string>;
    categoriesFilter: Set<string>;
    businessUnitsFilter?: Set<string>;
    divisionsFilter?: Set<string>;
    officesFilter?: Set<string>;
    dateFilter: [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;
  };
  options?: GetAdminObservationOptionsQuery;
}> = ({ projWhere, currentFilters, options }) => {
  const [subChartSelectedStatus, setSubSelectedStatus] = useState("all");
  const [barChartSelectedPerson, setBarChartSelectedPerson] =
    useState<string>();
  const { data } = useSuspenseQuery<
    GetObservationChartsDataQuery,
    GetObservationChartsDataQueryVariables
  >(GetObservationChartsDataDocument, {
    variables: {
      obsWhere: {
        status: { _neq: "draft" },
        project: projWhere,
        created_by_user: { employee: {} },
      },
    },
  });

  const filteredObs = useMemo(
    () =>
      !currentFilters
        ? data.observation
        : data.observation.filter((obs) => {
            const project = obs.project;
            if (!project) return false;
            const dateFiltering =
              !currentFilters.dateFilter ||
              ((!currentFilters.dateFilter[0] ||
                currentFilters.dateFilter[0].isSameOrBefore(
                  dayjs(obs.observation_date),
                )) &&
                (!currentFilters.dateFilter[1] ||
                  currentFilters.dateFilter[1].isSameOrAfter(
                    dayjs(obs.observation_date),
                  )));

            return (
              dateFiltering &&
              filterOnProjectAndHierarchy(project, currentFilters) &&
              (!currentFilters.companiesFilter.size ||
                (obs.subcontractor_id &&
                  currentFilters.companiesFilter.has(obs.subcontractor_id))) &&
              (!currentFilters.riskLevelFilter.size ||
                (obs.risk_level_value &&
                  currentFilters.riskLevelFilter.has(obs.risk_level_value))) &&
              (!currentFilters.subCategoriesFilter.size ||
                (obs.observation_category &&
                  currentFilters.subCategoriesFilter.has(
                    obs.observation_category.id,
                  ))) &&
              (!currentFilters.categoriesFilter.size ||
                (obs.observation_category &&
                  currentFilters.categoriesFilter.has(
                    obs.observation_category.type_id,
                  )))
            );
          }),
    [data.observation, currentFilters],
  );
  const projectsFilter = currentFilters?.projectsFilter;
  const subOptions = useMemo(() => {
    return !options
      ? []
      : !projectsFilter || projectsFilter.size === 0
      ? options?.subcontractor
      : options?.subcontractor.filter((sub) =>
          sub.subcontractor_projects.some((ps) =>
            projectsFilter.has(ps.project_id),
          ),
        );
  }, [options?.subcontractor, projectsFilter]);
  type DataMapType = { [key: string]: { id: string; value: number } };
  const { businessUnitsList, officesList, divisionsList } = useMemo(() => {
    const officesMap: DataMapType = {};
    const businessUnitsMap: DataMapType = {};
    const divisionsMap: DataMapType = {};

    if (options) {
      filteredObs.forEach((obs) => {
        const proj = obs.project;
        if (proj) {
          if (proj.gc_office_id && options.gc_office.length) {
            if (officesMap[proj.gc_office_id]) {
              officesMap[proj.gc_office_id].value += 1;
            } else {
              const office = options.gc_office.find(
                (office) => office.id === proj.gc_office_id,
              );
              if (office) {
                officesMap[proj.gc_office_id] = {
                  value: 1,
                  id: office.name,
                };
              }
            }
          }
          if (proj.gc_business_unit_id && options.gc_business_unit.length) {
            console.log(proj);
            if (businessUnitsMap[proj.gc_business_unit_id]) {
              businessUnitsMap[proj.gc_business_unit_id].value += 1;
            } else {
              const bu = options.gc_business_unit.find(
                (bu) => bu.id === proj.gc_business_unit_id,
              );
              if (bu) {
                businessUnitsMap[proj.gc_business_unit_id] = {
                  value: 1,
                  id: bu.name,
                };
              }
            }
          }

          if (proj.gc_division_id && options.gc_division.length) {
            if (divisionsMap[proj.gc_division_id]) {
              divisionsMap[proj.gc_division_id].value += 1;
            } else {
              const division = options.gc_division.find(
                (division) => division.id === proj.gc_division_id,
              );
              if (division) {
                divisionsMap[proj.gc_division_id] = {
                  value: 1,
                  id: division.name,
                };
              }
            }
          }
        }
      });
    }
    return {
      businessUnitsList: Object.values(businessUnitsMap).map(
        ({ id, value }, i) => ({
          value,
          name: id,
          color: colorList[i] || randomHexColor(),
        }),
      ),
      officesList: Object.values(officesMap).map(({ id, value }, i) => ({
        value,
        name: id,
        color: colorList[i] || randomHexColor(),
      })),
      divisionsList: Object.values(divisionsMap).map(({ id, value }, i) => ({
        value,
        name: id,
        color: colorList[i] || randomHexColor(),
      })),
    };
  }, [filteredObs, options]);
  const [severityList, userOptionsList] = useMemo(() => {
    const severityMap: {
      [key: string]: ShowPieChartProps["dataSource"][number];
    } = {};
    const userOptionsMap: {
      [key: string]: { name: string; id: string };
    } = {};
    if (data && options)
      filteredObs.forEach((ob) => {
        if (!userOptionsMap[ob.created_by_uid])
          userOptionsMap[ob.created_by_uid] = {
            name: ob.created_by_user.name,
            id: ob.created_by_uid,
          };
        if (ob.risk_level_value && ob.risk_level_value !== "safe") {
          if (severityMap[ob.risk_level_value]) {
            severityMap[ob.risk_level_value].value += ob.number_of_findings;
          } else {
            const risk = options.risk_level.find(
              (r) => r.value === ob.risk_level_value,
            )!;
            severityMap[ob.risk_level_value] = {
              value: ob.number_of_findings,
              name: risk.name,
              color: risk.color_hex,
            };
          }
        }
      });

    return [Object.values(severityMap), Object.values(userOptionsMap)] as const;
  }, [filteredObs, options]);
  const statusList = useMemo(() => {
    const statusMap = {
      safe: { name: "Safe", value: 0, color: graphColors.green },
      corrected: {
        name: "Unsafe Corrected",
        value: 0,
        color: graphColors.purple,
      },
      open: { name: "Unsafe - Open", value: 0, color: graphColors.red },
    };
    if (data)
      filteredObs.forEach((ob) => {
        if (ob.risk_level_value) {
          const key =
            ob.risk_level_value === "safe"
              ? "safe"
              : ob.status === "open"
              ? "open"
              : ob.status === "closed"
              ? "corrected"
              : undefined;

          if (key) statusMap[key].value += ob.number_of_findings;
        }
      });

    return Object.values(statusMap);
  }, [filteredObs]);
  const subsList = useMemo(() => {
    const subsMap: {
      [key: string]: { id: string; value: number };
    } = {};
    if (filteredObs) {
      filteredObs.forEach((ob) => {
        const filteredIn =
          subChartSelectedStatus === "all" ||
          (subChartSelectedStatus === "safe" &&
            ob.risk_level_value === "safe") ||
          (subChartSelectedStatus === "unsafe-closed" &&
            ob.risk_level_value !== "safe" &&
            ob.status === "closed") ||
          (subChartSelectedStatus === "unsafe-open" &&
            ob.risk_level_value !== "safe" &&
            ob.status === "open");

        if (ob.subcontractor_id && filteredIn) {
          if (subsMap[ob.subcontractor_id]) {
            subsMap[ob.subcontractor_id].value += ob.number_of_findings;
          } else {
            if (ob.subcontractor_id)
              subsMap[ob.subcontractor_id] = {
                id: ob.subcontractor_id,
                value: ob.number_of_findings,
              };
          }
        }
      });
    }

    const subsList: ShowPieChartProps["dataSource"] = [];
    Object.values(subsMap).forEach((subData, i) => {
      const sub = subOptions.find((sub) => sub.id === subData.id);
      if (sub)
        subsList.push({
          ...subData,
          name: sub.name,
          color: colorList[i] || randomHexColor(),
        });
    });
    return subsList;
  }, [filteredObs, subChartSelectedStatus, subOptions]);
  const dateData = useMemo(() => {
    const dateMap: {
      [key: string]: {
        xField: string;
        date: string;
        value: number;
        type: "safe" | "unsafe" | "not-defined";
        typeColor: string;
      };
    } = {};

    if (data)
      filteredObs
        .filter(
          (ob) =>
            !barChartSelectedPerson ||
            ob.created_by_uid === barChartSelectedPerson,
        )
        .forEach((ob) => {
          const date = dayjs(ob.observation_date)
            .startOf("week")
            .format("YYYY-MM-DD");
          const eow = dayjs(ob.observation_date)
            .endOf("week")
            .format("YYYY-MM-DD");
          const risk =
            ob.risk_level_value === "safe"
              ? "safe"
              : ob.risk_level_value
              ? "unsafe"
              : "not-defined";
          if (dateMap[date + risk]) {
            dateMap[date + risk].value += ob.number_of_findings;
          } else {
            dateMap[date + risk] = {
              xField: `${date} - ${eow}`,
              date,
              value: ob.number_of_findings,
              type: risk,
              typeColor:
                ob.risk_level_value === "safe"
                  ? graphColors.green
                  : ob.risk_level_value
                  ? "red"
                  : "not defined",
            };
          }
        });

    return Object.values(dateMap).sort(
      (a1, a2) =>
        dayjs(a1.date).valueOf() - dayjs(a2.date).valueOf() ||
        a1.type.localeCompare(a2.type),
    );
  }, [filteredObs, barChartSelectedPerson]);
  const { timeToSolve, total } = useMemo(() => {
    let timeToSolve = 0,
      total = 0;
    filteredObs.forEach((ob) => {
      const unsafe = ob.unsafe_observation;
      if (
        ob.risk_level_value &&
        ob.risk_level_value !== "safe" &&
        ob.status === "closed" &&
        unsafe?.corrected_on &&
        unsafe.due_on
      ) {
        const correctedHoursDiff = unsafe?.corrected_on
          ? dayjs(unsafe.corrected_on).diff(dayjs(ob.observation_date), "hours")
          : null;
        if (correctedHoursDiff && correctedHoursDiff > 2) {
          total += 1;
          timeToSolve += correctedHoursDiff;
        }
      }
    });
    return { timeToSolve, total };
  }, [data, filteredObs]);

  const obsOpen = filteredObs
    .filter((ob) => ob.status === "open")
    .reduce((sum, ob) => sum + ob.number_of_findings, 0);

  const titleCardsToShow: ChartTitleNumbersProps["items"] = [
    {
      title: "Total Observations",
      info: filteredObs.reduce((sum, ob) => sum + ob.number_of_findings, 0),
    },
    {
      title: "Total Open",
      info: obsOpen,
      borderColorClassName: !!obsOpen ? `border-semantic-negative` : undefined,
    },
    {
      title: "Avg. Completion Time",
      info: (
        <>
          {total ? (timeToSolve / total).toFixed(1) : 0}
          <Text>hour</Text>
        </>
      ),
      borderColorClassName: `border-purple`,
    },
  ];
  return (
    <Flex vertical gap={"middle"}>
      <ChartTitleNumbers items={titleCardsToShow} />
      {filteredObs.length === 0 ? null : (
        <GCObservationChartsUI
          {...{
            subsList,
            severityList,
            statusList,
            userOptionsList,
            dateData: dateData.map((item) => ({
              xField: item.xField,
              value: item.value,
              valueTitle: item.type,
              color: item.typeColor,
            })),
            subChartSelectedStatus,
            setSubSelectedStatus,
            barChartSelectedPerson,
            setBarChartSelectedPerson,
            businessUnitsList,
            divisionsList,
            officesList,
          }}
        />
      )}
    </Flex>
  );
};
export default GCObservationCharts;
