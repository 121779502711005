/**
 * @generated SignedSource<<5e1147e6326943addddb09fd6715d9b9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_set_input, worker_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkerProfileMutation$variables = {
  userSet?: user_set_input | null | undefined;
  workerId: string;
  workerSet?: worker_set_input | null | undefined;
};
export type WorkerProfileMutation$data = {
  readonly update_user_by_pk: {
    readonly birth_date: string | null | undefined;
    readonly email: string | null | undefined;
    readonly name: string;
    readonly phone_number: string | null | undefined;
    readonly profile_picture_id: string | null | undefined;
    readonly username: string | null | undefined;
  } | null | undefined;
  readonly update_worker_by_pk: {
    readonly current_worker_role: string;
    readonly hire_date: string | null | undefined;
    readonly id: string;
    readonly subcontractor_id: string | null | undefined;
    readonly worker_title_id: string | null | undefined;
  } | null | undefined;
};
export type WorkerProfileMutation = {
  response: WorkerProfileMutation$data;
  variables: WorkerProfileMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "userSet"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "workerSet"
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": [
    {
      "kind": "Variable",
      "name": "_set",
      "variableName": "workerSet"
    },
    {
      "fields": [
        {
          "kind": "Variable",
          "name": "uid",
          "variableName": "workerId"
        }
      ],
      "kind": "ObjectValue",
      "name": "pk_columns"
    }
  ],
  "concreteType": "worker",
  "kind": "LinkedField",
  "name": "update_worker_by_pk",
  "plural": false,
  "selections": [
    (v3/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hire_date",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "worker_title_id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "current_worker_role",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "subcontractor_id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "_set",
    "variableName": "userSet"
  },
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "workerId"
      }
    ],
    "kind": "ObjectValue",
    "name": "pk_columns"
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "username",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "birth_date",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "profile_picture_id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerProfileMutation",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "update_user_by_pk",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "WorkerProfileMutation",
    "selections": [
      (v4/*: any*/),
      {
        "alias": null,
        "args": (v5/*: any*/),
        "concreteType": "user",
        "kind": "LinkedField",
        "name": "update_user_by_pk",
        "plural": false,
        "selections": [
          (v6/*: any*/),
          (v7/*: any*/),
          (v8/*: any*/),
          (v9/*: any*/),
          (v10/*: any*/),
          (v11/*: any*/),
          (v3/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "d1459cf04996bdc26689896522258471",
    "id": null,
    "metadata": {},
    "name": "WorkerProfileMutation",
    "operationKind": "mutation",
    "text": "mutation WorkerProfileMutation(\n  $workerId: uuid!\n  $workerSet: worker_set_input\n  $userSet: user_set_input\n) {\n  update_worker_by_pk(pk_columns: {uid: $workerId}, _set: $workerSet) {\n    id\n    hire_date\n    worker_title_id\n    current_worker_role\n    subcontractor_id\n  }\n  update_user_by_pk(pk_columns: {id: $workerId}, _set: $userSet) {\n    email\n    name\n    username\n    phone_number\n    birth_date\n    profile_picture_id\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "8ff2b574ff0bd7269cd79fefe505b46b";

export default node;
