import { GetOrientationDashboardDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import { setDemographicDataMaps } from "src/domain-features/siteorientation/utils/setDemographicDataMaps";

type DataType = { name: string; count: number; color?: string };
export type MapType = Map<string, DataType>;
export type ZipMapDataType = NonNullable<
  GetOrientationDashboardDataQuery["project_worker"][number]["worker"]["city_zip_code"]
> & {
  count: number;
};
export type StateMapDataType = {
  count: number;
  id: string;
  code: string;
  name: string;
  latitude: number;
  longitude: number;
};
const getDashboardDataMaps = (
  data: GetOrientationDashboardDataQuery | undefined,
  localCities: Set<string>,
) => {
  const veteranTypeMap: MapType = new Map();
  const ethnicityMap: MapType = new Map();
  const genderMap: MapType = new Map();
  const roleMap: MapType = new Map();
  const cityMap: MapType = new Map();
  const yearsInConstructionMap: MapType = new Map();
  const yearsWithEmployerMap: MapType = new Map();

  const zipMap: Map<string, ZipMapDataType> = new Map();
  const stateMap: Map<string, StateMapDataType> = new Map();

  (data?.project_worker || []).forEach((p) => {
    const currRole = roleMap.get(p.worker_role_detail.value);
    if (currRole) {
      currRole.count += 1;
    } else {
      roleMap.set(p.worker_role_detail.value, {
        name: p.worker_role_detail.translation.en,
        count: 1,
      });
    }
    setDemographicDataMaps({
      worker: p.worker,
      cityMap,
      ethnicityMap,
      genderMap,
      localCities,
      stateMap,
      veteranTypeMap,
      yearsInConstructionMap,
      yearsWithEmployerMap,
      zipMap,
    });
  });
  return {
    roleMap,
    veteranTypeMap,
    ethnicityMap,
    genderMap,
    cityMap,
    zipMap,
    stateMap,
    yearsInConstructionMap,
    yearsWithEmployerMap,
  };
};
export default getDashboardDataMaps;
