import React from "react";
import ImageAutomaticSize from "../../../../../../../../../common/components/general/images/ImageAutomaticSize";
import remToPx from "../../../../../../../../../common/functions/remToPx";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import SiteOrientationRegistrationStepsHello from "../../../../../../../components/stepsUI/hello/SiteOrientationRegistrationStepsHello";
import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";

interface SiteOrientationWorkerRegistrationStepsHelloProps
  extends SiteOrientationStepProps {
  showLangSelector?: boolean;
  projectName?: string;
  gcName?: string;
  gcLogoUrl?: string | null;
}

const SiteOrientationInPersonWorkerRegistrationStepsHello: React.FC<
  SiteOrientationWorkerRegistrationStepsHelloProps
> = (props) => {
  const langStrings = useCurrentLangStrings();

  return (
    <SiteOrientationRegistrationStepsHello
      onNext={props.onNext}
      projectName={props.projectName}
    >
      <div className={`flex items-start flex-col gap-2`}>
        {props.gcLogoUrl || props.gcName ? (
          <div className={`flex flex-col gap-0.5`}>
            <div className={`flex flex-col gap-0.25`}>
              {props.gcLogoUrl ? (
                <ImageAutomaticSize
                  perfectImageSidePx={remToPx(6)}
                  imageProps={{ src: props.gcLogoUrl }}
                />
              ) : (
                <p className={`text-1.5`}>{props.gcName}</p>
              )}
            </div>
          </div>
        ) : null}
        <div>
          <p className={`font-accent`}>
            {langStrings.strings.letsOnboardYouToThisProject}
          </p>
        </div>
      </div>
    </SiteOrientationRegistrationStepsHello>
  );
};

export default SiteOrientationInPersonWorkerRegistrationStepsHello;
