import { useContext } from "react";
import PtpQrUserContext from "./ptpQrUserContext";

const usePtpQr = () => {
  const value = useContext(PtpQrUserContext);
  if (!value)
    throw new Error(
      "usePtpQr must be wrapped inside PtpQrUserContext Provider",
    );
  return value;
};

export default usePtpQr;

export const usePtpQrUser = () => {
  const context = usePtpQr();
  return [context.ptpQrUser, context.setPtpQrUser] as const;
};
