import { DownloadOutlined } from "@ant-design/icons";
import { Button, Drawer, Form, Radio, Select } from "antd";
import { useEffect } from "react";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import {
  useGenerateInspectionsSummaryPdfMutation,
  useGetInspectionsLazyQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

type FormValsType = {
  inspectionIds: string[];
  sorter: string;
  order: string;
};
const InspectionSummaryCreator: React.FC<{
  inspection?: { id: string; name: string };
  showLocation: boolean;
  projectId: string;
  open: boolean;
  onClose: () => void;
}> = ({ showLocation, inspection, projectId, open, onClose }) => {
  const [getInspections, { data }] = useGetInspectionsLazyQuery();
  useEffect(() => {
    if (!inspection)
      getInspections({
        variables: {
          where: { project_id: { _eq: projectId }, inspection_results: {} },
        },
        fetchPolicy: "cache-first",
      });
  }, [inspection, projectId]);
  const [generateInspectionsSummaryPdf, { loading }] =
    useGenerateInspectionsSummaryPdfMutation();
  const [form] = Form.useForm<FormValsType>();
  const initialVals: FormValsType = {
    inspectionIds: [],
    sorter: "date",
    order: "asc",
  };
  const onCloseDrawer = () => {
    form.setFieldsValue(initialVals);
    onClose();
  };
  return (
    <>
      <Drawer
        open={open}
        title={
          inspection
            ? `Download Inspection: ${inspection.name}`
            : "Download Inspection"
        }
        destroyOnClose
        onClose={onCloseDrawer}
        footer={
          <div className="flex gap-1">
            <Button onClick={onCloseDrawer} loading={loading}>
              Cancel
            </Button>
            <Button
              htmlType="submit"
              type="primary"
              loading={loading}
              icon={<DownloadOutlined />}
              iconPosition="end"
              onClick={async () => {
                const vals = await form.validateFields();
                console.log(vals);
                const { data: res } = await generateInspectionsSummaryPdf({
                  variables: {
                    input: {
                      inspectionIds: inspection
                        ? [inspection.id]
                        : vals.inspectionIds || [],
                      projectId,
                      order: vals.order,
                      sorter: vals.sorter,
                    },
                  },
                });
                if (res) downloadFromUrl(res.generateInspectionsSummaryPdf);
                onCloseDrawer();
              }}
            >
              Download
            </Button>
          </div>
        }
      >
        <Form layout="vertical" form={form} initialValues={initialVals}>
          {!inspection && (
            <Form.Item name="inspectionIds" label="Inspection">
              <Select
                mode="multiple"
                filterOption={getNormalSelectOptionsFilter}
                showSearch
                placeholder="All Inspections Selected"
                options={(data?.inspection_instance || []).map((ins) => ({
                  value: ins.id,
                  label: ins.name.en,
                }))}
              />
            </Form.Item>
          )}
          <Form.Item
            name="sorter"
            label="Select sorting method"
            rules={[{ required: true, message: "select one sorting method" }]}
          >
            <Select
              filterOption={getNormalSelectOptionsFilter}
              showSearch
              options={[
                { label: "Model", value: "model" },
                ...(showLocation
                  ? [{ label: "Location", value: "location" }]
                  : []),
                { label: "Inspector", value: "inspector" },
                { label: "Company", value: "company" },
                { label: "Date Inspected", value: "date" },
              ]}
            />
          </Form.Item>
          <Form.Item
            name="order"
            label="Select Sorting order"
            rules={[{ required: true, message: "Select sorting order" }]}
          >
            <Radio.Group>
              <Radio.Button value="asc">Ascending</Radio.Button>
              <Radio.Button value="desc">Descending</Radio.Button>
            </Radio.Group>
          </Form.Item>
        </Form>
      </Drawer>
    </>
  );
};
export default InspectionSummaryCreator;
