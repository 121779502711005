import {
  GetAgcDashboardDataQuery,
  GetOrientationDashboardDataQuery,
} from "src/common/types/generated/apollo/graphQLTypes";

type DataType = { name: string; count: number; color?: string };
export type DashboardDataMapType = Map<string, DataType>;
export type ZipMapDataType = NonNullable<
  NonNullable<
    (
      | GetAgcDashboardDataQuery["user"]
      | GetOrientationDashboardDataQuery["project_worker"]
    )[number]["worker"]
  >["city_zip_code"]
> & {
  count: number;
};
export type StateMapDataType = {
  count: number;
  id: string;
  code: string;
  name: string;
  latitude: number;
  longitude: number;
};
export const setDemographicDataMaps = ({
  veteranTypeMap,
  ethnicityMap,
  genderMap,
  cityMap,
  zipMap,
  stateMap,
  yearsInConstructionMap,
  yearsWithEmployerMap,
  worker,
  localCities,
}: {
  veteranTypeMap: DashboardDataMapType;
  ethnicityMap: DashboardDataMapType;
  genderMap: DashboardDataMapType;
  cityMap: DashboardDataMapType;
  zipMap: Map<string, ZipMapDataType>;
  stateMap: Map<string, StateMapDataType>;
  yearsInConstructionMap: DashboardDataMapType;
  yearsWithEmployerMap: DashboardDataMapType;
  localCities?: Set<string>;
  worker: {
    gender?: string | null;
    veteran_type?: string | null;
    ethnicity?: string | null;
    years_in_construction?: string | null;
    years_with_employer?: string | null;
    city_zip_code?: {
      id: string;
      latitude: number;
      longitude: number;
      zip_code: string;
      city: {
        name: string;
        id: string;
        state: {
          name: string;
          code: string;
          latitude?: number | null;
          longitude?: number | null;
        };
      };
    } | null;
  };
}) => {
  const genderVal = worker.gender;
  if (genderVal) {
    const currGen = genderMap.get(genderVal);
    if (currGen) {
      currGen.count += 1;
    } else {
      genderMap.set(genderVal, {
        name:
          genderVal === "M"
            ? "Male"
            : genderVal === "F"
            ? "Female"
            : genderVal === "O"
            ? "Other"
            : "Undefined val", //non-reachable
        count: 1,
      });
    }
  }

  const veteranType = worker.veteran_type;
  if (veteranType) {
    const currVeter = veteranTypeMap.get(veteranType);
    if (currVeter) {
      currVeter.count += 1;
    } else {
      veteranTypeMap.set(veteranType, {
        name:
          veteranType === "veteran"
            ? "Veteran"
            : veteranType === "disabledVeteran"
            ? "Disabled Veteran"
            : veteranType === "nonVeteran"
            ? "Non Veteran"
            : "Undefined Value", // should be non-reachable
        count: 1,
      });
    }
  }

  const years_in_construction = worker.years_in_construction;
  if (years_in_construction) {
    const currYearsInConst = yearsInConstructionMap.get(years_in_construction);
    if (currYearsInConst) {
      currYearsInConst.count += 1;
    } else {
      yearsInConstructionMap.set(years_in_construction, {
        name: years_in_construction,
        count: 1,
      });
    }
  }
  const years_with_employer = worker.years_with_employer;
  if (years_with_employer) {
    const currYearsWithEmployer = yearsWithEmployerMap.get(years_with_employer);
    if (currYearsWithEmployer) {
      currYearsWithEmployer.count += 1;
    } else {
      yearsWithEmployerMap.set(years_with_employer, {
        name: years_with_employer,
        count: 1,
      });
    }
  }
  const ethnicity = worker.ethnicity;
  if (ethnicity) {
    const currEth = ethnicityMap.get(ethnicity);
    if (currEth) {
      currEth.count += 1;
    } else {
      ethnicityMap.set(ethnicity, {
        name: ethnicity,
        count: 1,
      });
    }
  }
  const cityZip = worker.city_zip_code;
  if (cityZip) {
    const currZip = zipMap.get(cityZip.id);
    if (currZip) {
      currZip.count++;
    } else {
      zipMap.set(cityZip.id, {
        count: 1,
        ...cityZip,
      });
    }
    const state = cityZip.city.state;
    const currState = stateMap.get(state.code);

    if (currState) {
      currState.count++;
    } else if (state.latitude && state.longitude) {
      stateMap.set(state.code, {
        count: 1,
        id: state.code,
        latitude: state.latitude,
        longitude: state.longitude,
        name: state.name,
        code: state.code,
      });
    }
    if (localCities?.size) {
      const cityToMap = localCities.has(cityZip.city.id)
        ? "Local"
        : "Non Local";

      const currCity = cityMap.get(cityToMap);
      if (currCity) {
        currCity.count += 1;
      } else {
        cityMap.set(cityToMap, {
          name: cityToMap,
          count: 1,
        });
      }
    }
  }
};
