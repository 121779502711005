import { Divider, Flex, Space } from "antd";
import GCAdminHeaderFilterButton, {
  GCAdminHeaderFilterButtonProps,
} from "../../filter-inputs/GCAdminHeaderFilterButton";
import GCAdminIncidentsReportCreatorButton, {
  GCAdminIncidentsReportCreatorButtonProps,
} from "./GCAdminIncidentsReportCreatorButton";
import GCAdminIncidentsSettingsButton from "./GCAdminIncidentsSettingsButton";
import GCAdminHeaderFilterTags from "../../filter-inputs/GCAdminHeaderFilterTags";
import { IncidentSeverityMappingProps } from "../IncidentSeverityMapping";

interface GCAdminIncidentsHeaderProps {
  reportCreatorButtonProps: GCAdminIncidentsReportCreatorButtonProps;
  incidentSeverityMappingProps?: IncidentSeverityMappingProps;
  filters?: GCAdminHeaderFilterButtonProps["filters"];
}

const GCAdminIncidentsHeader: React.FC<GCAdminIncidentsHeaderProps> = (
  props,
) => {
  return (
    <Space direction={"vertical"}>
      <Flex justify={"end"}>
        <Space>
          {props.filters && (
            <GCAdminHeaderFilterButton filters={props.filters} />
          )}
          <GCAdminIncidentsReportCreatorButton
            {...props.reportCreatorButtonProps}
          />
          {props.incidentSeverityMappingProps && (
            <GCAdminIncidentsSettingsButton
              {...props.incidentSeverityMappingProps}
            />
          )}
        </Space>
      </Flex>
      <Divider />
      {props.filters && <GCAdminHeaderFilterTags filters={props.filters} />}
    </Space>
  );
};

export default GCAdminIncidentsHeader;
