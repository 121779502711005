import React, { useState } from "react";
import { Button, Flex, Form, Input, InputNumber, Space, Table } from "antd";

export interface ChartsTableDemoDataType {
  key: string;
  name: string;
  value: number;
  valueTitle?: string;
}

interface ChartsDemoTableProps {
  dataSource: ChartsTableDemoDataType[];
  sharedValueTitle?: string;
  onSharedValueTitleChange?: (value: string) => void;
  onDataSourceChange: (data: ChartsTableDemoDataType[]) => void;
}

const ChartsDemoTable: React.FC<ChartsDemoTableProps> = (props) => {
  const [count, setCount] = useState(0);

  const valueTitleIsShared = props.sharedValueTitle !== undefined;

  const handleDelete = (key: string) => {
    const newData = props.dataSource.filter((item) => item.key !== key);
    props.onDataSourceChange(newData);
  };

  const handleAdd = () => {
    const newData: ChartsTableDemoDataType = {
      key: count.toString(),
      name: "",
      value: 0,
    };
    props.onDataSourceChange([...props.dataSource, newData]);
    setCount(count + 1);
  };

  const columns = [
    {
      title: "Name",
      dataIndex: "name",

      editable: true,
      render: (text: string, record: ChartsTableDemoDataType) => (
        <Input
          value={text}
          onChange={(e) => {
            const newData = [...props.dataSource];
            const index = newData.findIndex((item) => record.key === item.key);
            newData[index].name = e.target.value;
            props.onDataSourceChange(newData);
          }}
        />
      ),
    },
    {
      title: "Value",
      dataIndex: "value",
      editable: true,
      render: (text: number, record: ChartsTableDemoDataType) => (
        <InputNumber
          value={text}
          onChange={(value) => {
            const newData = [...props.dataSource];
            const index = newData.findIndex((item) => record.key === item.key);
            newData[index].value = value || 0;
            props.onDataSourceChange(newData);
          }}
        />
      ),
    },
    // TODO add text input for value title. Make it disabled if valueTitleIsStatic is true
    {
      title: "Value Title",
      dataIndex: "valueTitle",
      editable: true,
      render: (text: string, record: ChartsTableDemoDataType) => (
        <Input
          value={valueTitleIsShared ? props.sharedValueTitle : text}
          disabled={valueTitleIsShared}
          onChange={(e) => {
            const newData = [...props.dataSource];
            const index = newData.findIndex((item) => record.key === item.key);
            newData[index].valueTitle = e.target.value;
            props.onDataSourceChange(newData);
          }}
        />
      ),
    },

    {
      title: "Action",
      dataIndex: "action",
      render: (_: any, record: ChartsTableDemoDataType) =>
        props.dataSource.length >= 1 ? (
          <Button onClick={() => handleDelete(record.key)} danger>
            Delete
          </Button>
        ) : null,
    },
  ];

  const randomizeData = () => {
    const newData: ChartsTableDemoDataType[] = [];
    for (let i = 0; i < Math.floor(Math.random() * 20) + 1; i++) {
      newData.push({
        key: i.toString(),
        name: String.fromCharCode(65 + i).repeat(3),
        value: Math.floor(Math.random() * 100),
      });
    }
    props.onDataSourceChange(newData);
  };

  const clearData = () => {
    props.onDataSourceChange([]);
  };

  return (
    <Flex vertical gap={"small"}>
      <Form.Item layout={"vertical"} label="Shared Value Title">
        <Input
          placeholder="Text"
          value={props.sharedValueTitle}
          disabled={!valueTitleIsShared}
          onChange={(e) => props.onSharedValueTitleChange?.(e.target.value)}
        />
      </Form.Item>
      <Space>
        <Button onClick={handleAdd} type="primary">
          Add a row
        </Button>
        <Button onClick={randomizeData}>Randomize</Button>
        <Button onClick={clearData}>Clear</Button>
      </Space>
      <Table
        pagination={false}
        dataSource={props.dataSource}
        columns={columns}
        bordered
      />
    </Flex>
  );
};

export default ChartsDemoTable;
