import { message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useRef } from "react";
import {
  AddInspectionsToQRModal_InsertQR_Mutation,
  inspection_instance_qr_code_insert_input,
} from "src/common/types/generated/relay/AddInspectionsToQRModal_InsertQR_Mutation.graphql";
import FModal, { FModalRef } from "src/common/components/dialogs/FModal";
import { OptionType } from "../../utils/siteInspectionTypes";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import newQRCodeInsertionUpdater from "../../utils/newQRCodeInsertionUpdater";
import useAuthUser from "src/common/hooks/useAuthUser";

const AddInspectionsToQRModal: React.FC<{
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  qrOptions: Array<OptionType>;
  projectId: string;
  selectedInspectionIds: string[];
  refetchTable: () => void;
  open: boolean;
}> = ({
  setOpenModal,
  qrOptions,
  projectId,
  refetchTable,
  open,
  selectedInspectionIds,
}) => {
  const modal =
    useRef<FModalRef<{ selectedQrs?: Array<string>; newQr?: string }>>(null);
  const authUser = useAuthUser();
  const [insertInspectionQRs, isInserting] =
    useAsyncMutation<AddInspectionsToQRModal_InsertQR_Mutation>(
      graphql`
        mutation AddInspectionsToQRModal_InsertQR_Mutation(
          $objects: [inspection_instance_qr_code_insert_input!]!
        ) {
          insert_inspection_instance_qr_code(
            objects: $objects
            on_conflict: {
              constraint: inspection_instance_qr_code_inspection_qr_code_id_inspection_in
              update_columns: []
            }
          ) {
            returning {
              inspection_qr_code_id
              inspection_qr_code {
                name
                id
                pk: id @__clientField(handle: "pk")
              }
            }
          }
        }
      `,
    );
  return (
    <FModal
      visible={open}
      ref={modal}
      confirmLoading={isInserting}
      title="Add to QR(s)"
      onCancel={() => setOpenModal(false)}
      onOk={async () => {
        try {
          const vals = await modal.current?.form.validateFields();
          if (!vals) {
            throw new Error("Form not submitted successfully");
          }
          const objects: inspection_instance_qr_code_insert_input[] =
            selectedInspectionIds.flatMap((inspId) =>
              (vals.selectedQrs || []).map((qrId) => ({
                inspection_qr_code_id: qrId,
                inspection_instance_id: inspId,
              })),
            );
          if (vals.newQr) {
            selectedInspectionIds.forEach((inspectionId) => {
              objects.push({
                inspection_instance_id: inspectionId,
                inspection_qr_code: {
                  data: {
                    created_by_uid: authUser.uid,
                    project_id: projectId,
                    name: vals.newQr,
                  },
                },
              });
            });
          }
          if (objects.length > 0)
            await insertInspectionQRs({
              variables: { objects },
              updater: (store) => {
                const returning = store
                  .getRootField("insert_inspection_instance_qr_code")
                  .getLinkedRecords("returning", {
                    where: {
                      inspection_qr_code: {
                        deleted_at: { _is_null: true },
                      },
                    },
                  });
                if (vals.newQr) {
                  newQRCodeInsertionUpdater(store, qrOptions, returning);
                }
              },
            });
          refetchTable();
          setOpenModal(false);
        } catch (err) {
          message.error("Couldn't Add inspections to QR");
          throw err;
        }
      }}
      okText={"Add +"}
    >
      <FModal.Select
        name={"selectedQrs"}
        label="Select QRs you want to add these inspections to"
        props={{
          mode: "multiple",
          options: qrOptions,
        }}
      />
      OR/AND
      <FModal.Text
        name={"newQr"}
        label={
          <div>
            Create a new QR and add these inspections to it
            <br />
            NOTE: Keep it empty if you do not want to create a new QR
          </div>
        }
      ></FModal.Text>
    </FModal>
  );
};
export default AddInspectionsToQRModal;
