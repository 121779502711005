import React from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Select } from "antd";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import { useSuspenseQuery } from "@apollo/client";
import {
  GetProjectSubcontractorsForPtpQuery,
  GetProjectSubcontractorsForPtpQueryVariables,
  GetProjectSubcontractorsForPtpDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface PtpQrCodeSubcontractorProps
  extends Omit<SiteOrientationStepProps, "onNextButtonClick"> {}

const PtpQrCodeSubcontractor: React.FC<PtpQrCodeSubcontractorProps> = (
  props,
) => {
  const langStrings = useCurrentLangStrings();
  const { ptpQrUser, setPtpQrUser, projectId } = usePtpQr();
  const { data } = useSuspenseQuery<
    GetProjectSubcontractorsForPtpQuery,
    GetProjectSubcontractorsForPtpQueryVariables
  >(GetProjectSubcontractorsForPtpDocument, {
    variables: {
      where: { project_id: { _eq: projectId } },
      order_by: { subcontractor: { name: Order_By.Asc } },
    },
  });
  if (!data) {
    throw new Error("Project Subcontractor Data Not Found");
  }
  const userInfo = ptpQrUser.userInfo;
  const options = data.project_subcontractor.map((ps) => ({
    label: ps.subcontractor.name,
    value: ps.subcontractor.id,
  }));

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={props.onNext}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!userInfo?.company}
    >
      <SiteFeatureStepsInputLayout headline={langStrings.strings.yourCompany}>
        <div className="text-grey text-1">
          {langStrings.strings.selectSubcontractor}
        </div>

        <Select
          value={userInfo?.company}
          options={options}
          onChange={(val) => {
            setPtpQrUser((prev) => ({
              ...prev,
              userInfo: { ...prev.userInfo, company: val },
            }));
          }}
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrCodeSubcontractor;
