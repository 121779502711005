import React from "react";
import { Select } from "antd";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { useGetProjectWorkerRolesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface PtpQrWorkerRoleProps
  extends Omit<SiteOrientationStepProps, "onNextButtonClick"> {}

const PtpQrWorkerRole: React.FC<PtpQrWorkerRoleProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const { ptpQrUser, setPtpQrUser } = usePtpQr();
  const getWorkerRoles = useGetProjectWorkerRolesQuery();
  const options =
    getWorkerRoles.data?.worker_role.map((role) => ({
      label: role.translation.en,
      value: role.value,
    })) || [];
  const specialRoles = [
    "foreman_crew_leader",
    "journeyman_worker",
    "apprentice_training",
  ];
  const specialRoleOptions = options.filter((option) =>
    specialRoles.includes(option.value),
  );
  const remainingRoleOptions = options
    .filter((option) => !specialRoles.includes(option.value))
    .sort((a, b) => a.label.localeCompare(b.label));
  const roleOptions = [...specialRoleOptions, ...remainingRoleOptions];

  const userInfo = ptpQrUser.userInfo;

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={props.onNext}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!userInfo?.role}
    >
      <SiteFeatureStepsInputLayout headline={langStrings.strings.yourRole}>
        <div className="text-grey text-1">{langStrings.strings.selectRole}</div>

        <Select
          value={userInfo?.role}
          options={roleOptions}
          onChange={(val) =>
            setPtpQrUser((prev) => ({
              ...prev,
              userInfo: { ...prev.userInfo, role: val },
            }))
          }
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrWorkerRole;
