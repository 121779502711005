import {
  useInsertIncidentDocumentsMutation,
  Document_Insert_Input,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
  DocumentFragmentFragment,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import { produce } from "immer";

type IncidentFieldKey =
  | "incident_detail"
  | "injury_detail"
  | "insurance_detail"
  | "incident_type"
  | "injured_user"
  | "root_cause_analysis"
  | "witness_detail";

type IncidentType =
  | "environmental"
  | "equipment"
  | "near_miss"
  | "property_damage"
  | "vehicle"
  | "utility"
  | "theft";

type UploadDocumentsProps = {
  objects: Document_Insert_Input[];
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
  incidentFieldKey: IncidentFieldKey;
  incidentUserId?: string;
  type?: IncidentType;
};

export const getIncidentFieldValue = (
  draft: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
  incidentFieldKey: IncidentFieldKey,
  incidentUserId?: string,
  type?: IncidentType,
) => {
  const incidentUser =
    incidentFieldKey === "witness_detail"
      ? draft.injured_users
      : draft.injured_users;
  const incidentUserName = incidentUser.find((iu) => iu.id === incidentUserId)
    ?.user.name;

  switch (incidentFieldKey) {
    case "incident_detail":
      return "incident detail";
    case "witness_detail":
      return `witness detail for the user ${incidentUserName}`;
    case "incident_type":
      const incidentTypeName =
        type === "property_damage"
          ? "property damage"
          : type === "near_miss"
          ? "near miss"
          : type;

      return `${incidentTypeName} incident`;
    case "root_cause_analysis":
      return "root cause analysis";
    case "insurance_detail":
      return `insurance detail for the injured user ${incidentUserName}`;
    case "injury_detail":
      return `injury detail for the injured user ${incidentUserName}`;
    default:
      throw new Error(`Unknown entity: ${incidentFieldKey}`);
  }
};

const useUploadIncidentDocuments = () => {
  const [insertDocumentsAndIncidentEdit] = useInsertIncidentDocumentsMutation();
  const authUser = useAuthUser();

  const getUpdatedIncident = (
    draft: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
    incidentFieldKey: IncidentFieldKey,
    docsToInsert: DocumentFragmentFragment[],
    incidentUserId?: string,
    type?: IncidentType,
  ) => {
    switch (incidentFieldKey) {
      case "incident_detail":
        draft.attached_files = [...draft.attached_files, ...docsToInsert];
        break;
      case "witness_detail":
        draft.witnesses = draft.witnesses.map((witness) => {
          if (witness.id === incidentUserId) {
            witness.attached_files = [
              ...(witness.attached_files || []),
              ...docsToInsert,
            ];
          }
          return witness;
        });
        break;
      case "incident_type":
        const incidentTypeName =
          type === "property_damage"
            ? "property damage"
            : type === "near_miss"
            ? "near miss"
            : type;
        draft.incident_types = draft.incident_types.map((incidentType) => {
          if (incidentType.type_value === type) {
            incidentType.documents = [
              ...incidentType.documents,
              ...docsToInsert,
            ];
          }
          return incidentType;
        });
        break;
      case "root_cause_analysis":
        if (draft.root_cause_analysis) {
          draft.root_cause_analysis.document = [
            ...draft.root_cause_analysis.document,
            ...docsToInsert,
          ];
        }
        break;
      case "insurance_detail":
        draft.injured_users = draft.injured_users.map((injuredUser) => {
          if (
            injuredUser.id === incidentUserId &&
            injuredUser.injury_detail &&
            injuredUser.injury_detail.insurance_claim
          ) {
            injuredUser.injury_detail.insurance_claim.attached_files = [
              ...(injuredUser.injury_detail.insurance_claim?.attached_files ||
                []),
              ...docsToInsert,
            ];
          }
          return injuredUser;
        });
        break;
      case "injury_detail":
        draft.injured_users = draft.injured_users.map((injuredUser) => {
          if (injuredUser.id === incidentUserId && injuredUser.injury_detail) {
            injuredUser.injury_detail.attached_files = [
              ...(injuredUser.injury_detail.attached_files || []),
              ...docsToInsert,
            ];
          }
          return injuredUser;
        });
        break;
      default:
        throw new Error(`Unknown entity: ${incidentFieldKey}`);
    }

    return draft;
  };

  const uploadDocuments = async ({
    incident,
    incidentFieldKey,
    objects,
    incidentUserId,
    type,
  }: UploadDocumentsProps) => {
    const insertedDocs: Array<{ url: string; name: string }> = [];
    const docsToInsert: DocumentFragmentFragment[] = [];
    objects.forEach((doc) => {
      if (doc.url && doc.id) {
        docsToInsert.push({
          ...doc,
          created_at: dayjs().format(),
          url: doc.url,
          id: doc.id,
          image:
            doc.document_type === "image" && doc.url
              ? { url: doc.url, id: doc.id }
              : null,
        });

        insertedDocs.push({
          url: doc.url,
          name: doc.name ?? "document",
        });
      }
    });

    const incidentFieldValue = getIncidentFieldValue(
      incident,
      incidentFieldKey,
      incidentUserId,
      type,
    );

    const comment = `Documents added for ${incidentFieldValue}`;

    const updatedIncident = produce(incident, (draft) => {
      getUpdatedIncident(
        draft,
        incidentFieldKey,
        docsToInsert,
        incidentUserId,
        type,
      );
    });

    const patch = createIncidentPatch(updatedIncident, incident);

    await insertDocumentsAndIncidentEdit({
      variables: {
        objects: objects,
        incidentEditObjects: {
          patch: patch,
          comment: comment,
          document_edit: [
            {
              field: incidentFieldValue,
              action: "added",
              documents: insertedDocs,
            },
          ],
          incident_id: incident.id,
          edited_by_uid: authUser.uid,
          edit_type: "document-upload",
        },
      },
      update(cache, result) {
        const insertedDocs = result.data?.insert_document?.returning;
        if (!insertedDocs) return;

        const updatedIncident = produce(incident, (draft) => {
          getUpdatedIncident(
            draft,
            incidentFieldKey,
            insertedDocs,
            incidentUserId,
            type,
          );
        });

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          data: { __typename: "query_root", incident_by_pk: updatedIncident },
          query: GetIncidentByPkDocument,
        });
      },
    });
  };

  return [uploadDocuments] as const;
};

export default useUploadIncidentDocuments;
