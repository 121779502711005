import clsx from "clsx";
import React, { useMemo } from "react";
import capitalize from "src/common/functions/capitalize";
import HoverHint from "../HoverHint";
import Icon, { IconType } from "./Icon";
import { Popover } from "antd";

export type ButtonProps = {
  icon?: IconType;
  label?: string;
  textColor?: "red" | "green" | "grey";
  delete?: boolean;
  interactiveOnHover?: boolean;
  secondary?: boolean;
  grey?: boolean;
  secondaryIcon?: IconType;
  fullWidth?: boolean;
  loading?: boolean;
  hint?: string | JSX.Element;
  green?: boolean;
  wrapText?: boolean;
  fake?: boolean; // a button element behaves like always but doesn't run any logic on interactions
  children?: React.ReactNode;
} & ({ tiny: true } | { small: true } | { large: boolean } | {}) &
  (
    | {
        onClick: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
      }
    | {
        onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
        fake: true;
      }
  );

const Button: React.FunctionComponent<ButtonProps> = (props) => {
  let label =
    props.label || (typeof props.children === "string" ? props.children : null);
  label && (label = capitalize(label));
  const small = "small" in props;
  const large = "large" in props;
  const tiny = "tiny" in props;

  const textColor = useMemo(() => {
    let textColor = undefined;
    switch (props.textColor) {
      case "red":
        textColor = "semantic-negative";
        break;
      case "green":
        textColor = "semantic-positive-green";
        break;
      case "grey":
        textColor = "grey";
        break;
      default:
        textColor = undefined;
    }
    return textColor;
  }, [props]);

  const colorClasses = clsx(
    !tiny
      ? props.grey
        ? "text-white bg-grey border-grey "
        : props.delete
        ? "text-white bg-semantic-negative border-semantic-negative "
        : props.green
        ? "text-white bg-semantic-positive-green border-semantic-positive-green "
        : !props.secondary
        ? "text-white bg-interactive-primary border-interactive-primary"
        : `text-${textColor || "interactive-primary"} bg-none border-${
            textColor || "interactive-secondary"
          } `
      : `text-interactive-primary hover:underline`,
    "outline-none focus-visible:outline-none",
  );

  const element = (
    <button
      disabled={props.loading}
      onClick={(e) => {
        if (!props.fake && props.onClick) {
          e.stopPropagation();
          props.onClick(e);
        }
      }}
      className={clsx(
        `relative flex flex-row items-center justify-center font-accent transition-all`,
        !props.wrapText && `whitespace-nowrap`,
        !tiny && "rounded-2 border button-before",
        colorClasses,
        small
          ? "gap-0.25 p-0.5"
          : tiny
          ? "p-0 gap-0.25"
          : "py-0.5 px-1 gap-0.5",
        props.fullWidth ? "w-full " : "w-auto",
        props.loading ? "cursor-wait opacity-50" : "cursor-pointer",
        props.interactiveOnHover
          ? ` hover:border-${textColor} hover:border-solid hover:border-0.125`
          : "",
      )}
    >
      {props.icon ? (
        <Icon
          {...{
            secondaryIcon: props.secondaryIcon,
            icon: props.icon,
            color: !props.secondary && !tiny ? "white" : "interactive",
            size: small || tiny ? "small" : large ? "large" : "regular",
          }}
        />
      ) : null}
      <span
        className={clsx(
          `block`,
          !props.wrapText && `truncate`,
          !label && "hidden",
        )}
      >
        {label}
      </span>
    </button>
  );
  return !!props.hint ? (
    typeof props.hint === "string" ? (
      <HoverHint text={props.hint}>{element}</HoverHint>
    ) : (
      <Popover
        content={props.hint}
        placement="bottomLeft"
        trigger={"click"}
        zIndex={10}
      >
        {element}
      </Popover>
    )
  ) : (
    element
  );
};

export default Button;
