import { useSuspenseQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import AnonProjectWorkerTable from "src/common/components/tables/AnonProjectWorkerTable";
import {
  GetGcAdminWorkersDocument,
  GetGcAdminWorkersQuery,
  GetGcAdminWorkersQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";

const GCBusinessUnitWorkers: React.FC<BusinessUnitProps> = ({
  businessUnitId,
}) => {
  const { userData } = useUserData();
  if (!userData.employee) throw new Error("employee not found");
  const { data } = useSuspenseQuery<
    GetGcAdminWorkersQuery,
    GetGcAdminWorkersQueryVariables
  >(GetGcAdminWorkersDocument, {
    variables: {
      gcId: userData.employee.general_contractor.id,
      projWhere: {
        gc_business_unit_id: { _eq: businessUnitId },
      },
    },
  });
  return (
    <div className="bg-white h-full w-full">
      <AnonProjectWorkerTable
        excludedKeys={["crew", "corporateDate"]}
        where={{
          project: { gc_business_unit_id: { _eq: businessUnitId } },
          is_last: { _eq: true },
        }}
        projectsOptions={data.project}
      />
    </div>
  );
};
export default withBusinessUnitId(GCBusinessUnitWorkers);
