import React from "react";
import { Layout, Menu } from "antd";
import developmentPlaygroundNavigationItems from "./developmentPlaygroundNavigationItems";
import { Outlet, useNavigate } from "react-router-dom";

const { Content, Sider } = Layout;

const items = developmentPlaygroundNavigationItems.map((item) => ({
  key: item.pathName,
  icon: item.icon,
  label: item.title,
}));

const DevelopmentPlayground: React.FC = () => {
  const navigate = useNavigate();
  return (
    <Layout hasSider>
      <div className={` sticky overflow-auto h-screen top-0 left-0`}>
        <Sider theme={"light"} className={`h-full`}>
          <Menu
            // selectedKeys={selectedMenuItem ? [selectedMenuItem] : []}
            className={`h-full`}
            mode="inline"
            items={items.map((item) => ({
              key: item.key,
              label: item.label,
            }))}
            onSelect={({ key }) => navigate(key)}
          />
        </Sider>
      </div>
      <Content style={{ padding: "0 24px", minHeight: 280 }}>
        <div className={`w-full p-3`}>
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
};

export default DevelopmentPlayground;
