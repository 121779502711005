import * as React from "react";
import CustomTabsBase from "./CustomTabsBase";

type CustomTabType = {
  id: string;
  label: React.ReactNode;
  icon?: React.ReactNode;
  content?: React.ReactNode;
};

export interface CustomTabsProps {
  tabs: CustomTabType[];
  selectedTabId?: string;
  onTabSelect: (id: string) => void;
}

const CustomTabs: React.FunctionComponent<CustomTabsProps> = (props) => {
  const onTabChange = (key: string) => {
    props.onTabSelect(key);
  };

  return (
    <CustomTabsBase
      onChange={onTabChange}
      activeKey={props.selectedTabId}
      items={props.tabs.map((tab) => ({
        key: tab.id,
        label: tab.label,
        children: tab.content,
        icon: tab.icon,
      }))}
    />
  );
};

export default CustomTabs;
