import { FC, useRef, useState, useMemo } from "react";
import { useParams } from "react-router-dom";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import dayjs from "dayjs";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import TasksTable from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TasksTable";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { GCTasksQuery } from "src/common/types/generated/relay/GCTasksQuery.graphql";
import { task_bool_exp } from "src/common/types/generated/relay/TasksTableQuery.graphql";
import GCProjectSubcontractors from "src/root/routes/views/general-contractor/projects/subcontractors/GCProjectSubcontractors";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import AddTaskModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/AddTaskModal";
import { auth } from "src/common/functions/firebase";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCTasksMutation } from "src/common/types/generated/relay/GCTasksMutation.graphql";
import { message, Drawer } from "antd";
import UploadTaskModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskModal";
import UploadTaskToSiteFormModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskToSiteformModal";
import SelectSubAdminsToUploadJHAsModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/SelectSubAdminsToUploadJHAsModal";
import {
  Task_Set_Input,
  useGenerateGcjhaSpreadsheetMutation,
  useGenerateMultipleJhaPdfMutation,
  useUpdateTasksMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import DownloadJHAModal, {
  DownloadJHAModalRef,
} from "src/common/components/dialogs/DownloadJHAModal";
import JHAUploadOptionsModal, {
  JHAUploadOptionsModalRef,
} from "src/common/components/dialogs/JHAUploadOptionsModal";
import TaskInfo from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/TaskInfo";
import PendingTaskView from "src/domain-features/sitesafety/job-hazard-analysis/components/task-view/PendingTaskView";
import topBarButtons from "src/domain-features/sitesafety/job-hazard-analysis/components/basic/topBarButtons";

interface GCTasksProps {}

const GCTasks: FC<GCTasksProps> = () => {
  const { projectId } = useParams();
  const data = useLazyLoadQuery<GCTasksQuery>(
    graphql`
      query GCTasksQuery($projectId: uuid!) {
        ppe_type_connection(order_by: { name: { en: asc } }) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
            }
          }
        }
        permit_type_connection(order_by: { name: { en: asc } }) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
            }
          }
        }

        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              name
              general_contractor_id
              general_contractor {
                name
              }
            }
          }
        }

        project_subcontractor_connection(
          where: { project_id: { _eq: $projectId } }
          order_by: { subcontractor: { name: asc } }
        ) {
          edges {
            node {
              subcontractor_id
              subcontractor {
                name

                subcontractor_workers(distinct_on: worker_id) {
                  worker_id
                  worker {
                    user {
                      name
                    }
                  }
                }

                subcontractor_employees(distinct_on: user_id) {
                  user_id
                  user {
                    name
                  }
                }
              }
            }
          }
        }

        task_connection(
          where: {
            project_id: { _eq: $projectId }
            subcontractor_id: { _is_null: false }
            report_id: { _is_null: true }
            deleted_at: { _is_null: true }
          }
        ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              subcontractor_id
              subcontractor {
                name
              }
              task_creation_request {
                subcontractor_id
                subcontractor {
                  name
                }
              }
              show_type
              task_admins {
                general_contractor_employee {
                  user {
                    pk: id @__clientField(handle: "pk")
                    name
                  }
                }
              }
              task_signatures(
                where: { is_active: { _eq: true } }
                order_by: { created_at: desc }
              ) {
                created_at
                signature_image_id
                user {
                  role
                  id
                  pk: id @__clientField(handle: "pk")
                  name
                }
              }
            }
          }
        }
      }
    `,
    {
      projectId: projectId!,
    },
  );

  const [updateTasks] = useUpdateTasksMutation();
  const [insertTasks] = useAsyncMutation<GCTasksMutation>(graphql`
    mutation GCTasksMutation($objects: [task_insert_input!]!) {
      insert_task(objects: $objects) {
        affected_rows
      }
    }
  `);

  const [selectedTask, setSelectedTask] = useState<{
    taskId: string;
    subcontractorId?: string;
    type: "active" | "pending";
    requestId?: string;
  }>();
  const [isAddNewTaskModalOpen, setIsAddNewTaskModalOpen] = useState(false);
  const [isOpenUploadTaskModal, setIsOpenUploadTaskModal] = useState(false);
  const [isOpenUploadTaskToSiteformModal, setIsOpenUploadTaskToSiteformModal] =
    useState(false);

  const [
    isOpenSelectSubadminsToUploadJhas,
    setIsOpenSelectSubadminsToUploadJhas,
  ] = useState(false);
  const [start, setStart] = useState<dayjs.Dayjs | undefined>(undefined);
  const [statusFilter, setStatusFilter] = useState<task_bool_exp>({});

  const activeJhaWhere: task_bool_exp = {
    archived_at: { _is_null: true },
    is_pending_acceptance: { _eq: false },
    request_state: { _is_null: true },
  };
  const processingJhaWhere: task_bool_exp = {
    request_state: { _eq: "processing" },
  };
  const [selectedPpe, setSeletedPpe] = useState<Array<string>>([]);
  const { approvedJhas, signedJhas, gcReviews, jhaAdmins } = useMemo(() => {
    const approvedJhas = new Set<string>();
    const signedJhas = new Set<string>();
    const gcReviews: Array<{ id: string; name: string }> = [];
    const jhaAdmins: Array<{ id: string; name: string }> = [];
    data.task_connection.edges.forEach((task) => {
      task.node.task_signatures.forEach((s) => {
        if (
          s.user?.role === "employee" &&
          gcReviews.findIndex((gc) => gc.id === s.user?.pk) === -1
        ) {
          gcReviews.push({ id: s.user.pk, name: s.user.name });
        } else if (s.signature_image_id) {
          signedJhas.add(task.node.pk);
        } else {
          approvedJhas.add(task.node.pk);
        }
      });
      task.node.task_admins.forEach((admin) => {
        if (
          jhaAdmins.findIndex(
            (jhaAdmin) =>
              jhaAdmin.id === admin.general_contractor_employee.user.pk,
          ) === -1
        ) {
          jhaAdmins.push({
            id: admin.general_contractor_employee.user.pk,
            name: admin.general_contractor_employee.user.name,
          });
        }
      });
    });

    return { approvedJhas, signedJhas, gcReviews, jhaAdmins };
  }, [data.task_connection]);

  const [selectedSubcontractor, setSelectedSubcontractor] = useState<
    Array<string>
  >([]);
  const [selectedGcUser, setSelectedGCUser] = useState<Array<string>>([]);
  const [selectedJhaAdmin, setSelectedJhaAdmin] = useState<Array<string>>([]);

  const jhaTableRef = useRef<DataScrollTableRef>(null);

  const [getJHASpreadSheet, { loading: JHASpreadSheetLoading }] =
    useGenerateGcjhaSpreadsheetMutation();
  const [selectedRows, setSelectedRows] = useState<Array<string>>([]);
  const [generatePdf, { loading: generatingPdf }] =
    useGenerateMultipleJhaPdfMutation();
  const jhaLogModalOpen = useRef<DownloadJHAModalRef>(null);
  const uploadModalOpen = useRef<JHAUploadOptionsModalRef>(null);

  if (!projectId) return <></>;

  const updateTaskInfo = async (_set: Task_Set_Input) => {
    await updateTasks({
      variables: { where: { id: { _in: selectedRows } }, _set: _set },
    });

    jhaTableRef.current?.refetch();
  };

  const downloadMultipleJhas = async () => {
    message.loading({
      content: "Download in progress",
      duration: 0,
    });

    try {
      const { data: download } = await generatePdf({
        variables: {
          input: {
            taskIds: selectedRows,
            type: isNotTurnersProject ? "siteform" : "turner",
            jhaNum: selectedRows.map((key, i) => i + 1),
          },
        },
      });
      if (download?.generateMultipleJHAsPdf) {
        downloadFromUrl(download.generateMultipleJHAsPdf);
      }
    } finally {
      message.destroy();
    }
  };

  const ppeOptions = data.ppe_type_connection.edges;
  const permitOptions = data.permit_type_connection.edges;
  const subcontractorOptions = data.task_connection.edges;
  const uniqueSubs: Array<{ id: string; name: string }> = [];

  subcontractorOptions.forEach((option) => {
    if (
      option.node.subcontractor_id &&
      option.node.subcontractor?.name &&
      uniqueSubs.findIndex((sub) => sub.id === option.node.subcontractor_id) ===
        -1
    ) {
      uniqueSubs.push({
        id: option.node.subcontractor_id,
        name: option.node.subcontractor.name,
      });
    } else if (
      option.node.task_creation_request?.subcontractor_id &&
      option.node.task_creation_request.subcontractor.name &&
      uniqueSubs.findIndex(
        (sub) => sub.id === option.node.task_creation_request?.subcontractor_id,
      ) === -1
    ) {
      uniqueSubs.push({
        id: option.node.task_creation_request.subcontractor_id,
        name: option.node.task_creation_request.subcontractor.name,
      });
    }
  });

  uniqueSubs.sort((a, b) => a.name.localeCompare(b.name));
  const projectInfo = data.project_connection.edges[0].node;

  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const isNotTurnersProject = projectInfo.general_contractor_id !== turnerGCId;

  return (
    <>
      <DownloadJHAModal
        ref={jhaLogModalOpen}
        onCancel={() => jhaLogModalOpen.current?.close()}
        onCreate={async (values: string[], includeArchieved: boolean) => {
          jhaLogModalOpen.current?.close();
          message.loading({
            content: "Download in progress",
            duration: 0,
          });
          await getJHASpreadSheet({
            variables: {
              input: {
                projectId,
                subIds: values,
                includeArchieved,
              },
            },
          })
            .then((data) => {
              if (data.data?.generateGCJHASpreadsheet) {
                downloadFromUrl(data.data?.generateGCJHASpreadsheet);
              }
              message.destroy();
            })
            .catch((e) => {
              if (e instanceof Error) {
                message.error(e.message);
              }
            });
        }}
        subs={uniqueSubs}
      />
      <JHAUploadOptionsModal
        ref={uploadModalOpen}
        onCancel={() => uploadModalOpen.current?.close()}
        onInstantTaskUploadClick={() => {
          setIsOpenUploadTaskModal(true);
        }}
        onNonInstantTaskUploadClick={() => {
          setIsOpenUploadTaskToSiteformModal(true);
        }}
        onRequestJHAFromSubClick={() => {
          setIsOpenSelectSubadminsToUploadJhas(true);
        }}
        onAddNewJHAClick={() => {
          setIsAddNewTaskModalOpen(true);
        }}
      />

      <AddTaskModal
        subcontractorOptions={data.project_subcontractor_connection.edges}
        visible={isAddNewTaskModalOpen}
        onCancel={() => setIsAddNewTaskModalOpen(false)}
        onCreate={async (values) => {
          try {
            await insertTasks({
              variables: {
                objects: [
                  {
                    id: values.id,
                    description: {
                      data: {
                        original: values.description,
                        en: values.description,
                      },
                    },
                    created_by_uid: auth.currentUser?.uid,
                    subcontractor_id: values.subcontractorId,
                    project_id: projectId,
                  },
                ],
              },
            });
            uploadModalOpen.current?.close();
            setIsAddNewTaskModalOpen(false);
            message.success("New Task has been added");

            setSelectedTask({
              subcontractorId: values.subcontractorId,
              taskId: values.id,
              type: "active",
            });
          } catch (err) {
            message.error(
              `ERROR: ${
                err instanceof Error ? err.message : JSON.stringify(err)
              }`,
            );
          }
        }}
      />

      <UploadTaskModal
        visible={isOpenUploadTaskModal}
        isNotTurnersProject={isNotTurnersProject}
        onCancel={() => setIsOpenUploadTaskModal(false)}
        refetch={() => jhaTableRef.current?.refetch()}
        subcontractorOptions={data.project_subcontractor_connection.edges}
        ppeData={ppeOptions.map((p) => ({
          name: p.node.name.en,
          id: p.node.pk,
        }))}
        permitData={permitOptions.map((p) => ({
          name: p.node.name.en,
          id: p.node.pk,
        }))}
        projectId={projectId}
        setIsOpenUploadModal={setIsOpenUploadTaskModal}
      />

      <UploadTaskToSiteFormModal
        visible={isOpenUploadTaskToSiteformModal}
        subcontractorId={null}
        onClose={() => setIsOpenUploadTaskToSiteformModal(false)}
        onSubmit={() => setIsOpenUploadTaskToSiteformModal(false)}
        projectId={projectId}
        subcontractorOptions={data.project_subcontractor_connection.edges}
      />

      <SelectSubAdminsToUploadJHAsModal
        type="jha_log"
        iniviteText={false}
        subcontractorOptions={data.project_subcontractor_connection.edges}
        sentFromCompanyName={projectInfo.general_contractor.name}
        projectName={projectInfo.name}
        projectId={projectId}
        visible={isOpenSelectSubadminsToUploadJhas}
        onCancel={() => setIsOpenSelectSubadminsToUploadJhas(false)}
      />

      <Drawer
        width={`85%`}
        open={!!selectedTask}
        placement="right"
        onClose={() => setSelectedTask(undefined)}
      >
        {selectedTask?.type === "active" && (
          <TaskInfo
            projectId={projectId}
            subcontractorId={selectedTask.subcontractorId}
            taskId={selectedTask.taskId}
            type="gce"
            key={selectedTask.taskId}
          />
        )}

        {selectedTask?.type === "pending" && (
          <PendingTaskView
            type={"gc-proj"}
            taskId={selectedTask.taskId}
            projectId={projectId}
            requestId={selectedTask.requestId}
          />
        )}
      </Drawer>

      <div className="flex flex-col items-start gap-0.5 h-full w-full p-2 bg-white rounded-2">
        <div className="w-full h-full">
          <TasksTable
            ref={jhaTableRef}
            setSelectedRows={setSelectedRows}
            excludedKeys={[
              "addToProject",
              "addToMulipleProjects",
              "delete",
              "ppe",
              "permit",
            ]}
            onRowItemClick={(row) => {
              setSelectedTask({
                subcontractorId: row.subcontractor_id ?? undefined,
                taskId: row.pk,
                requestId: row.creation_request_id ?? undefined,
                type: row.is_pending_acceptance ? "pending" : "active",
              });
            }}
            topBarButtons={topBarButtons({
              onAddJhaClick: () => {
                uploadModalOpen.current?.open();
              },
              onArchiveJhasClick:
                selectedRows.length > 0
                  ? () => updateTaskInfo({ archived_at: dayjs().toISOString() })
                  : undefined,
              onDeleteJhasClick:
                selectedRows.length > 0
                  ? () => updateTaskInfo({ deleted_at: dayjs().toISOString() })
                  : undefined,
              onDownloadJhaLogClick: () => {
                jhaLogModalOpen.current?.open();
              },
              onDownloadJhasClick:
                selectedRows.length > 0
                  ? () => downloadMultipleJhas()
                  : undefined,
            })}
            actionsAllowed={[
              "acceptJHA",
              "archiveOrActivate",
              "delete",
              "excelDownload",
              "pdfDownload",
              "requestTeamReview",
              "viewFile",
              "resendForAcceptance",
              "requestSubcontractorApproval",
              "unflagJHA",
            ]}
            isNotTurnersProject={isNotTurnersProject}
            projectId={projectId}
            title="Project JHAs"
            where={{
              project_id: { _eq: projectId },
              subcontractor_id: selectedSubcontractor.length
                ? { _in: selectedSubcontractor }
                : { _is_null: false },
              report_id: { _is_null: true },
              deleted_at: { _is_null: true },

              ...(selectedGcUser.length > 0
                ? {
                    task_signatures: {
                      user_id: { _in: selectedGcUser },
                      is_active: { _eq: true },
                    },
                  }
                : {}),

              ...(selectedJhaAdmin.length > 0
                ? {
                    task_admins: {
                      empolyee_id: { _in: selectedJhaAdmin },
                    },
                  }
                : {}),

              ...statusFilter,

              ...(start
                ? {
                    report_tasks: {
                      report: {
                        created_at: { _gte: start.format("YYYY-MM-DD") },
                      },
                    },
                  }
                : {}),

              ...(selectedPpe.length
                ? {
                    task_ppe_types: { ppe_type_id: { _in: selectedPpe } },
                  }
                : {}),
            }}
            customFilters={[
              {
                type: "checkbox",
                title: "Subcontractors",
                removeFilter: () => setSelectedSubcontractor([]),
                options: uniqueSubs.map((sub) => ({
                  option: sub.name,
                  optionType: "checkbox",
                  onApply: () =>
                    setSelectedSubcontractor((prev) => [...prev, sub.id]),
                  onCancel: () =>
                    setSelectedSubcontractor((prev) =>
                      prev.filter((subId) => subId !== sub.id),
                    ),
                })),
              },
              {
                type: "checkbox",
                title: "GC Review",
                removeFilter: () => setSelectedGCUser([]),
                options: gcReviews.map((review) => ({
                  option: review.name,
                  optionType: "checkbox",
                  onApply: () =>
                    setSelectedGCUser((prev) => [...prev, review.id]),
                  onCancel: () =>
                    setSelectedGCUser((prev) =>
                      prev.filter((id) => id !== review.id),
                    ),
                })),
              },
              {
                type: "checkbox",
                title: "JHA Leads",
                removeFilter: () => setSelectedJhaAdmin([]),
                options: jhaAdmins.map((review) => ({
                  option: review.name,
                  optionType: "checkbox",
                  onApply: () =>
                    setSelectedJhaAdmin((prev) => [...prev, review.id]),
                  onCancel: () =>
                    setSelectedJhaAdmin(
                      selectedJhaAdmin.filter((id) => id !== review.id),
                    ),
                })),
              },
              {
                type: "checkbox",
                title: "Status",
                removeFilter: () => setStatusFilter({}),
                options: [
                  {
                    option: "Active",
                    onApply: () => {
                      setStatusFilter((prev) => {
                        const { _and, ...rest } = prev;
                        if (_and && _and.length > 0) {
                          return {
                            ...rest,
                            _and: [processingJhaWhere, activeJhaWhere],
                          };
                        } else {
                          return {
                            ...rest,
                            _and: [activeJhaWhere],
                          };
                        }
                      });
                    },
                    onCancel: () => {
                      setStatusFilter((prev) => {
                        const { _and, ...rest } = prev;
                        if (_and?.length === 2) {
                          return {
                            ...rest,
                            _and: [processingJhaWhere],
                          };
                        } else {
                          return rest;
                        }
                      });
                    },
                  },
                  {
                    option: "Approved",
                    onApply: () => {
                      setStatusFilter((prev) => {
                        const { id, ...rest } = prev;
                        let tempJhas = prev.id?._in;
                        if (tempJhas)
                          tempJhas = tempJhas.concat(Array.from(approvedJhas));
                        else tempJhas = Array.from(approvedJhas);
                        return {
                          id: { _in: tempJhas },
                          ...rest,
                        };
                      });
                    },
                    onCancel: () => {
                      setStatusFilter((prev) => {
                        const { id, ...rest } = prev;
                        let tempJhas = prev.id?._in;
                        tempJhas = tempJhas?.filter(
                          (id) => !approvedJhas.has(id),
                        );

                        if (tempJhas && tempJhas.length > 0) {
                          return { id: { _in: tempJhas }, ...rest };
                        } else {
                          return rest;
                        }
                      });
                    },
                  },
                  {
                    option: "Archived",
                    onApply: () => {
                      setStatusFilter((prev) => {
                        return { ...prev, archived_at: { _is_null: false } };
                      });
                    },
                    onCancel: () => {
                      setStatusFilter((prev) => {
                        const { archived_at, ...rest } = prev;
                        return rest;
                      });
                    },
                  },
                  {
                    option: "Pending Acceptance",
                    onApply: () => {
                      setStatusFilter((prev) => {
                        return {
                          ...prev,
                          subcontractor_id: { _is_null: true },
                        };
                      });
                    },
                    onCancel: () => {
                      setStatusFilter((prev) => {
                        const { subcontractor_id, ...rest } = prev;
                        return rest;
                      });
                    },
                  },
                  {
                    option: "Processing",
                    onApply: () => {
                      setStatusFilter((prev) => {
                        const { _and, ...rest } = prev;
                        if (_and && _and.length > 0) {
                          return {
                            ...rest,
                            _and: [processingJhaWhere, activeJhaWhere],
                          };
                        } else {
                          return {
                            ...rest,
                            _and: [processingJhaWhere],
                          };
                        }
                      });
                    },
                    onCancel: () => {
                      setStatusFilter((prev) => {
                        const { _and, ...rest } = prev;
                        if (_and?.length === 2) {
                          return {
                            ...rest,
                            _and: [activeJhaWhere],
                          };
                        } else {
                          return rest;
                        }
                      });
                    },
                  },
                  {
                    option: "Requested",
                    onApply: () => {
                      setStatusFilter((prev) => {
                        return {
                          ...prev,
                          request_state: { _eq: "requested" },
                        };
                      });
                    },
                    onCancel: () => {
                      setStatusFilter((prev) => {
                        const { request_state, ...rest } = prev;
                        return rest;
                      });
                    },
                  },
                  {
                    option: "Reviewed",
                    onApply: () => {
                      setStatusFilter((prev) => {
                        return {
                          ...prev,
                          reviewed_at: { _is_null: false },
                        };
                      });
                    },
                    onCancel: () => {
                      setStatusFilter((prev) => {
                        const { reviewed_at, ...rest } = prev;
                        return rest;
                      });
                    },
                  },
                  {
                    option: "Signed",
                    onApply: () => {
                      setStatusFilter((prev) => {
                        let tempJhas = prev.id?._in;
                        if (tempJhas)
                          tempJhas = tempJhas.concat(Array.from(signedJhas));
                        else tempJhas = Array.from(signedJhas);
                        return {
                          id: { _in: tempJhas },
                        };
                      });
                    },
                    onCancel: () => {
                      setStatusFilter((prev) => {
                        let tempJhas = prev.id?._in;
                        tempJhas = tempJhas?.filter(
                          (id) => !signedJhas.has(id),
                        );
                        if (tempJhas && tempJhas.length > 0)
                          return { id: { _in: tempJhas } };
                        else {
                          return {};
                        }
                      });
                    },
                  },
                ],
              },
              {
                type: "radio",
                title: "Last Used",
                removeFilter: () => setStart(undefined),
                options: [
                  {
                    option: "Used Today",
                    onClick: () => {
                      setStart(dayjs().startOf("day"));
                    },
                  },
                  {
                    option: "Last 15 days",
                    onClick: () =>
                      setStart(dayjs().startOf("day").subtract(15, "days")),
                  },
                  {
                    option: "Last 30 days",
                    onClick: () =>
                      setStart(dayjs().startOf("day").subtract(30, "days")),
                  },
                  {
                    option: "Last 60 days",
                    onClick: () =>
                      setStart(dayjs().startOf("day").subtract(60, "days")),
                  },
                  {
                    option: "Last 90 days",
                    onClick: () =>
                      setStart(dayjs().startOf("day").subtract(90, "days")),
                  },
                ],
              },
              {
                type: "checkbox",
                title: "PPE",
                removeFilter: () => setSeletedPpe([]),
                options: ppeOptions.map((ppe) => ({
                  option: ppe.node.name.en,
                  optionType: "checkbox",
                  onApply: () =>
                    setSeletedPpe((prev) => [...prev, ppe.node.pk]),
                  onCancel: () =>
                    setSeletedPpe(
                      selectedPpe.filter((id) => id !== ppe.node.pk),
                    ),
                })),
              },
            ]}
          />
        </div>
      </div>
    </>
  );
};

const GCTasksWrapper: FC = () => {
  const { projectId, subcontractorId } = useParams();
  if (projectId && subcontractorId) {
    return (
      <BasicWrapper scrollable>
        <GCProjectSubcontractors />
      </BasicWrapper>
    );
  }
  return <GCTasks />;
};

export default withCustomSuspense(GCTasksWrapper);
