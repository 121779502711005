/**
 * @generated SignedSource<<eee3bf744ea7c8e6d7e2e89da8935c54>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_bool_exp, worker_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type SCWorkersMutation$variables = {
  _set?: worker_set_input | null | undefined;
  where: worker_bool_exp;
};
export type SCWorkersMutation$data = {
  readonly update_worker: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly current_worker_role: string;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type SCWorkersMutation$rawResponse = {
  readonly update_worker: {
    readonly affected_rows: number;
    readonly returning: ReadonlyArray<{
      readonly current_worker_role: string;
      readonly id: string;
    }>;
  } | null | undefined;
};
export type SCWorkersMutation = {
  rawResponse: SCWorkersMutation$rawResponse;
  response: SCWorkersMutation$data;
  variables: SCWorkersMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "worker_mutation_response",
    "kind": "LinkedField",
    "name": "update_worker",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "concreteType": "worker",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          },
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "current_worker_role",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCWorkersMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCWorkersMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "ec62fcbc13e4639603f7db4bccc06686",
    "id": null,
    "metadata": {},
    "name": "SCWorkersMutation",
    "operationKind": "mutation",
    "text": "mutation SCWorkersMutation(\n  $_set: worker_set_input\n  $where: worker_bool_exp!\n) {\n  update_worker(_set: $_set, where: $where) {\n    affected_rows\n    returning {\n      id\n      current_worker_role\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "0c15e6928900a5a64d9d8d3c927e2b0e";

export default node;
