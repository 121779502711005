import { IconInfoSquare } from "@tabler/icons";
import { Button, Form, Select, Tag } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useState, useMemo } from "react";
import { ConnectionHandler } from "relay-runtime";
import DataDetailDescriptions from "src/common/components/layouts/DataDetailDescriptions";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { ProjectSubChildSubs_ChangeChildSubs_Mutation } from "src/common/types/generated/relay/ProjectSubChildSubs_ChangeChildSubs_Mutation.graphql";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import compareTwoLists from "src/common/components/ComparingTwoLists";
import { PlusOutlined } from "@ant-design/icons";

const updateProjectSubWorkrerTitleMutation = graphql`
  mutation ProjectSubChildSubs_ChangeChildSubs_Mutation(
    $toDeleteWhere: parent_child_project_subcontractor_bool_exp!
    $objects: [parent_child_project_subcontractor_insert_input!]!
  ) {
    delete_parent_child_project_subcontractor(where: $toDeleteWhere) {
      affected_rows
      returning {
        id
      }
    }
    insert_parent_child_project_subcontractor(
      objects: $objects
      on_conflict: {
        constraint: parent_child_project_subcontractor_parent_subcontractor_id_chil
        update_columns: []
      }
    ) {
      returning {
        id
        child_subcontractor {
          id
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
  }
`;
const ProjectSubChildSubs: React.FC<{
  projectId: string;
  subcontractorId: string;
  currentChildSubs: GCSubcontractorQuery$data["parent_child_project_subcontractor_connection"]["edges"];
  subSelectOptions: { label: string; value: string }[];
}> = ({ projectId, subcontractorId, currentChildSubs, subSelectOptions }) => {
  const [editChildSubs, setEditChildSubs] = useState(false);
  const [updateChildSubs, loading] =
    useAsyncMutation<ProjectSubChildSubs_ChangeChildSubs_Mutation>(
      updateProjectSubWorkrerTitleMutation,
    );
  const currChildSubIds = useMemo(
    () => currentChildSubs.map((c) => c.node.child_subcontractor.pk),
    [currentChildSubs],
  );
  const changeChildSubs = async (values: { newChildSubIds: Array<string> }) => {
    if (!values.newChildSubIds) throw new Error("Form field not found");
    const [toInsert, toDelete] = compareTwoLists(
      values.newChildSubIds,
      currChildSubIds,
    );
    if (!toInsert.length && !toDelete.length) {
      setEditChildSubs(false);
      return;
    }
    try {
      await updateChildSubs({
        variables: {
          objects: toInsert.map((newSubId) => ({
            project_id: projectId,
            child_subcontractor_id: newSubId,
            parent_subcontractor_id: subcontractorId,
          })),
          toDeleteWhere: {
            project_id: { _eq: projectId },
            child_subcontractor_id: { _in: toDelete },
            parent_subcontractor_id: { _eq: subcontractorId },
          },
        },
        updater: (store) => {
          const insertedRes = store
            .getRootField("insert_parent_child_project_subcontractor")
            .getLinkedRecords("returning");
          const deletedRes = store
            .getRootField("delete_parent_child_project_subcontractor")
            .getLinkedRecords("returning");
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCSubcontractorQuery_parent_child_project_subcontractor_connection",
          );
          if (conn) {
            deletedRes.forEach((node) =>
              ConnectionHandler.deleteNode(conn, node.getDataID()),
            );
            insertedRes.forEach((res) => {
              const edge = ConnectionHandler.createEdge(
                store,
                conn,
                res,
                "edge",
              );
              console.log(edge, res);
              ConnectionHandler.insertEdgeBefore(conn, edge);
            });
          }
        },
      });
    } finally {
      setEditChildSubs(false);
    }
  };
  return (
    <DataDetailDescriptions
      title={" "}
      items={[
        {
          label: "2nd Tier Sub(s)",
          iconProps: {
            icon: IconInfoSquare,
            color: "interactive",
            hoverContent: {
              content: (
                <div className="w-24">
                  This subcontractor is the prime and any added subs are 2nd
                  tier or sub-of-a-sub.
                  <br />
                  The Prime sub, and their foremen/app users, will have access
                  to the 2nd tier sub’s reports (PTPs, Daily Reports, TBTs,
                  Permits, etc.)
                </div>
              ),
              title: "Add 2nd tier subcontractors to this Subcontractor",
            },
          },
          value: editChildSubs ? (
            <Form
              onFinish={async (values) => await changeChildSubs(values)}
              initialValues={{ newChildSubIds: currChildSubIds }}
            >
              <Form.Item name="newChildSubIds">
                <Select
                  mode="multiple"
                  showSearch
                  filterOption={getNormalSelectOptionsFilter}
                  options={subSelectOptions}
                />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                size="small"
                loading={loading}
                className="rounded-2 font-accent"
              >
                Done
              </Button>
            </Form>
          ) : (
            <>
              {currentChildSubs.map(
                ({ node: { child_subcontractor: childSub } }) => (
                  <Tag
                    key={childSub.pk}
                    className="bg-suplementary-3 text-semantic-pending-dark rounded-2"
                  >
                    {childSub.name}
                  </Tag>
                ),
              )}
              <Button
                onClick={async () => setEditChildSubs(true)}
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                loading={loading}
                className="rounded-2 font-accent"
              >
                Add
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};

export default ProjectSubChildSubs;
