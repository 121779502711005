import { useSearchParams, useParams, useNavigate } from "react-router-dom";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import SCProjectSDS from "src/domain-features/sitesafety/safety-data-sheets/components/SCProjectSDS";
import SCProjectSafetyPlan from "src/domain-features/sitesafety/siteSafetyPlan/components/SCProjectSafetyPlan";
import SCProjectPocs from "../SCProjectPocs";
import SCReportsPTP from "src/domain-features/sitesafety/entryRoutes/scReports/routes/ptp/SCReportsPTP";
import SCProjectToolboxTalks from "../SCProjectToolboxTalks";
import SCReportsDaily from "src/domain-features/sitesafety/entryRoutes/scReports/routes/daily/SCReportsDaily";
import SCChildSubJHAs from "./SCChildSubJHAs";
import { HomeOutlined } from "@ant-design/icons";
import { Button } from "antd";
import { useGetSubcontractorQuery } from "src/common/types/generated/apollo/graphQLTypes";

const SCChildSubsView: React.FC = () => {
  const { projectId, childSubId } = useParams();
  if (!projectId) throw new Error("ProjectId not found");
  if (!childSubId) throw new Error("childSubId not found");
  const { data, error } = useGetSubcontractorQuery({
    variables: { subcontractorId: childSubId, hideBillingSetting: true },
  });
  if (error) throw error;
  const [searchParams, setSearchParams] = useSearchParams();
  const subview = searchParams.get("childSubView") || "pre-task-plans";
  const handleReportsTabClick = (tabId: string) => {
    setSearchParams({ childSubView: tabId });
  };
  const sub = data?.subcontractor_by_pk;
  const navigate = useNavigate();
  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "jhas",
      label: "JHAs",
      content: (
        <SCChildSubJHAs {...{ projectId, subcontractorId: childSubId }} />
      ),
    },
    {
      id: "sds",
      label: "SDS",
      content: (
        <SCProjectSDS
          {...{ projectId, subcontractorId: childSubId, viewOnly: true }}
        />
      ),
    },
    {
      id: "poc",
      label: "POC(s)",
      content: (
        <SCProjectPocs {...{ projectId, subcontractorId: childSubId }} />
      ),
    },
    {
      id: "pre-task-plans",
      label: "Pre-Task Plans",
      content: <SCReportsPTP />,
    },
    { id: "daily", label: "Daily Reports", content: <SCReportsDaily /> },
    {
      id: "toolbox-talks-completed",
      label: "Completed TBTs",
      content: <SCProjectToolboxTalks type="completed" />,
    },
    {
      id: "sssp",
      label: "Safety Manuals",
      content: (
        <SCProjectSafetyPlan
          {...{ projectId, subcontractorId: childSubId, viewOnly: true }}
        />
      ),
    },
  ];
  return (
    <BasicWrapper scrollable>
      <div className="flex gap-2 items-center">
        <Button
          type="primary"
          shape="circle"
          icon={<HomeOutlined />}
          onClick={() => {
            navigate(`/sub/projects/${projectId}/your-subs`);
          }}
        />
        <div className="text-grey text-1.2">
          {sub ? `${sub.name}${sub.trade ? ` - ${sub.trade.name}` : ""}` : ""}
        </div>
      </div>
      <CustomTabs
        onTabSelect={handleReportsTabClick}
        tabs={tabs}
        selectedTabId={subview}
      />
    </BasicWrapper>
  );
};
export default SCChildSubsView;
