import { FC, useMemo } from "react";
import {
  useUpdateNearMissIncidentMutation,
  Near_Miss_Incident_Set_Input,
  useGetMonetarySeverityLevelsQuery,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import { Card } from "antd";
import useInsertIncidentType from "../../utils/useInsertIncidentType";
import useDeleteIncidentTypeDocument from "../../utils/useDeleteIncidentDocument";
import getIncidentDocuments from "../../utils/getIncidentDocuments";
import getNextPage from "../../utils/getNextPage";
import IncidentSelectField from "../basic/IncidentSelectField";
import IncidentTextField from "../basic/IncidentTextField";
import dayjs from "dayjs";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import IncidentBottomButtons from "../basic/IncidentBottomButtons";
import { useSearchParams } from "react-router-dom";
import useUpdateMonetarySeverity from "../../utils/useUpdateMonetarySeverity";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";

const NearMissIncident: FC<IncidentTypeProps> = ({ incident }) => {
  const [_, setSearchParams] = useSearchParams();
  const authUser = useAuthUser();

  const { data: monetarySeverity } = useGetMonetarySeverityLevelsQuery({
    fetchPolicy: "cache-first",
  });
  const monetarySeverityLevels = monetarySeverity?.incident_severity;
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [updateMonetarySeverity] = useUpdateMonetarySeverity();
  const [deleteIncidentDocument] = useDeleteIncidentTypeDocument();
  const [insertIncidentType] = useInsertIncidentType();
  const [updateNearMiss] = useUpdateNearMissIncidentMutation();
  const nearMiss = incident.near_miss_incident;
  const documents = getIncidentDocuments(incident, "near_miss");
  if (!nearMiss) {
    throw new Error("near_miss_incident is missing");
  }

  const { severity, type, typeTranslationId } = useMemo(() => {
    const severity = nearMiss.severity ?? undefined;
    const type = nearMiss.type.en;
    const typeTranslationId = nearMiss.type.id;

    return { severity, type, typeTranslationId };
  }, [nearMiss]);
  const insertIncidentTypeLink = async () => {
    await insertIncidentType(incident, incident.id, "near_miss");
  };

  const updateNearMissIncident = async (
    _set: Omit<Near_Miss_Incident_Set_Input, "incident_id">,
    comment: string,
  ) => {
    const updatedIncident = {
      ...incident,
      near_miss_incident: { ...nearMiss, ..._set },
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    await insertIncidentTypeLink();
    await updateNearMiss({
      variables: {
        incidentId: incident.id,
        _set,
        objects: {
          patch: patch,
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
          edit_type: "near-miss-edit",
          comment: comment,
        },
      },
      optimisticResponse: {
        update_near_miss_incident_by_pk: {
          ...nearMiss,
          incident_id: incident.id,
          ..._set,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  const uploadDocuments = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "incident_type",
      incident: incident,
      objects: objects,
      type: "near_miss",
    });
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incidentFieldKey: "incident_type",
      incident,
      type: "near_miss",
    });
  };

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Near Miss Incident</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <Card className="w-4/5">
          <IncidentSelectField
            options={[
              { value: "low", label: "Low" },
              { value: "medium", label: "Medium" },
              { value: "high", label: "High" },
              { value: "sifp", label: "SIFp" },
            ]}
            title="* Severity of near miss"
            value={severity}
            onChange={(option) => {
              if (typeof option.value === "string")
                updateNearMissIncident(
                  { severity: option.value },
                  `Updated Near Miss Incident - "Severity of near miss" to ${option.label}`,
                );
            }}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentSelectField
            title="Monetary Severity"
            options={
              monetarySeverityLevels?.map((severity) => ({
                label: severity.name.en,
                value: severity.id,
              })) ?? []
            }
            value={incident.monetary_severity_id ?? undefined}
            onChange={(option) => {
              if (typeof option.value === "string") {
                updateMonetarySeverity(incident, option.value, option.label);
              }
            }}
          />
        </Card>

        <Card className="w-4/5">
          <IncidentTextField
            label="* Details"
            text={type}
            textId={typeTranslationId}
            saveIncidentField={insertIncidentTypeLink}
            fieldTypeKey={"near_miss"}
            field={"type"}
            incident={incident}
          />
        </Card>

        {incident.incident_types.findIndex(
          (indexType) => indexType.type_value === "near_miss",
        ) !== -1 && (
          <IncidentDocViewAndUpload
            deleteDocument={deleteDocument}
            documents={documents}
            groupId={incident.id}
            uploadDocument={uploadDocuments}
            type="near_miss"
          />
        )}

        <IncidentBottomButtons
          saveDisabled={!severity}
          onNextClick={() => {
            const next = getNextPage(incident, "near_miss");
            setSearchParams({ subview: next });
          }}
          onSave={() => {
            updateNearMissIncident(
              { completed_at: dayjs().format() },
              `Near Miss section marked as completed`,
            );
          }}
        />
      </div>
    </>
  );
};

export default NearMissIncident;
