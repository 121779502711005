import {
  General_Contractor_Employee_Bool_Exp,
  GetAdminObservationOptionsQueryVariables,
  Project_Bool_Exp,
  useGetAdminObservationOptionsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import GCObservationCharts from "src/domain-features/observation/entryRoutes/gc/charts/GCObservationCharts";
import { Flex } from "antd";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import GCAdminObservationsHeader from "./header/GCAdminObservationsHeader";
import useGetGCAdminCommonFilters from "../../utils/useGetGCAdminCommonFilters";
import useGetObservationFilters from "./utils/useGetObservationFilters";
import { OrganizationLevelName } from "../../utils/hierarchyTypes";

const GCAdminObservations: React.FC<{
  organizationLevel: OrganizationLevelName;
  organizationId: string;
  gcEmployeeWhere: General_Contractor_Employee_Bool_Exp;
  projWhere: Project_Bool_Exp;
  optionsQueryVars: Omit<
    GetAdminObservationOptionsQueryVariables,
    "includeProjSetting"
  >;
}> = ({ organizationLevel, organizationId, optionsQueryVars, projWhere }) => {
  const { data: options, error: optionsError } =
    useGetAdminObservationOptionsQuery({
      variables: { ...optionsQueryVars, includeProjSetting: false },
    });
  const {
    categoriesFilter,
    dateFilter,
    companiesFilter,
    riskLevelFilter,
    selectFilters: obsSelectFilters,
    setDateFilter,
    subCategoriesFilter,
  } = useGetObservationFilters(options);
  const {
    projectStatusFilter,
    projectsFilter,
    radioFilters,
    selectFilters: commonSelectFilters,
    businessUnitsFilter,
    divisionsFilter,
    officesFilter,
  } = useGetGCAdminCommonFilters(options);

  if (optionsError) throw optionsError;
  return (
    <>
      <BasicWrapper scrollable>
        <Flex gap={"large"} vertical>
          <GCAdminObservationsHeader
            reportCreatorButtonProps={{
              options,
              organizationId,
              organizationLevel,
            }}
            // observationSettingsButtonProps={{
            //   projWhere,
            //   levelId:organizationId,
            //   levelName:`${organizationLevel}_id`,
            //   gcEmployeeWhere,
            //   withOptions: undefined,
            // }}
            filters={{
              radioFilters: [...radioFilters],
              selectFilters: [...commonSelectFilters, ...obsSelectFilters],

              dates: {
                selectedDates: dateFilter,
                onSelectedDatesChange: setDateFilter,
              },
            }}
          />

          <GCObservationCharts
            projWhere={projWhere}
            options={options}
            currentFilters={{
              subCategoriesFilter,
              companiesFilter,
              dateFilter,
              riskLevelFilter,
              categoriesFilter,
              projectsFilter,
              projectStatusFilter,
              businessUnitsFilter,
              divisionsFilter,
              officesFilter,
            }}
          />
        </Flex>
      </BasicWrapper>
    </>
  );
};
export default GCAdminObservations;
