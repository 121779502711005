import { RcFile } from "antd/es/upload";
import uploadImage from "./uploadImage";
import { readFileAsBase64 } from "./readFileAsBase64";
import separateFailedTasks from "./separateFailedTasks";

const uploadImageFiles = (files: Array<{ file: File }>) =>
  separateFailedTasks(
    files.map(async ({ file }) => {
      const base64 = await readFileAsBase64(file);
      const res = await uploadImage(base64);
      return { ...res, name: file.name, type: file.type };
    }),
  );

export default uploadImageFiles;
