import React from "react";
import { useParams, useSearchParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import SCReportsPTPSingle from "./components/SCReportsPTPSingle";
import SCReportsPTPTable from "./components/SCReportsPTPTable";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import { Drawer } from "antd";

interface SCReportsPTPProps {}

const SCReportsPTP: React.FunctionComponent<SCReportsPTPProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { projectId, childSubId } = useParams();

  const subcontractorId = useSubcontractorId();
  const reportsSubId = projectId && childSubId ? childSubId : subcontractorId;

  const reportId = searchParams.get("reportId");

  return reportsSubId ? (
    reportId ? (
      <Drawer
        open={true}
        title="Pre-Task Plan"
        width="85%"
        onClose={() => {
          searchParams.delete("reportId");
          setSearchParams(searchParams);
        }}
      >
        <SCReportsPTPSingle
          {...{ reportId, subcontractorId: reportsSubId, projectId }}
        />
      </Drawer>
    ) : (
      <SCReportsPTPTable {...{ subcontractorId: reportsSubId, projectId }} />
    )
  ) : null;
};

export default SCReportsPTP;
