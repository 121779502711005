import ProjectTable from "src/root/routes/views/general-contractor/hierarchy/component/ProjectTable";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";
import { useRef, useState } from "react";
import GCAddProject from "../../component/GCAddProject";
import { IconPlus } from "@tabler/icons";
import { message } from "antd";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";

const GCCorporateProjects: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const [open, setOpen] = useState(false);
  const ref = useRef<DataScrollTableRef>(null);
  return (
    <div className={`w-full relative h-full p-2 bg-white rounded-2`}>
      {open && (
        <GCAddProject
          onCancel={() => setOpen(false)}
          onCreate={() => {
            message.success("Project Created Successfully");
            setOpen(false);
          }}
        />
      )}
      <ProjectTable
        showLevels={["business-unit", "division", "office"]}
        ref={ref}
        topBarButtons={[
          {
            label: "Add New Project",
            icon: IconPlus,
            onClick: () => setOpen(true),
          },
        ]}
        excludedKeys={["gc"]}
        where={{
          general_contractors: {
            general_contractor_id: {
              _eq: employee.general_contractor.id,
            },
          },
        }}
      />
    </div>
  );
};
export default withCorporateAdminWrapper(GCCorporateProjects);
