import React, { FC, useEffect, useState } from "react";
import { Form, Input, Modal, Radio, Select, message, notification } from "antd";
import {
  useConvertWorkerToSubadminMutation,
  Order_By,
  useGetSubcontractorEmployeeTitlesQuery,
  useGetProjectSubcontractorsLazyQuery,
  useInsertCsrEditMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import { ConnectionHandler, commitLocalUpdate } from "relay-runtime";
import { useParams } from "react-router-dom";
import withCustomSuspense from "../general/withCustomSuspense";
import hasApolloErrorCode from "src/utility-features/error-handling/hasApolloErrorCode";
import { useRelayEnvironment } from "react-relay";
import { getSameEmailUser } from "src/common/functions/sameEmailUsers";
import {
  ConvertWorkerDataType,
  SingleWorkerDataType,
} from "./performWorkerTypes";
import useAuthUser from "src/common/hooks/useAuthUser";

export type ConvertWorkerToSubAdminModalDataType = Array<ConvertWorkerDataType>;
const { Option } = Select;
type FormVals = {
  worker: string;
  titleId: string;
  subcontractor: string;
  workerEmail: string;
  confirm: boolean;
  workerPhone?: string;
};

const ConvertWorkerToSubAdminModal: FC<
  {
    visible: boolean;
    refetch: () => void;
    onClose: () => void;
    onCancel: () => void;
    removeWorkerFromCache?: (workerIdToDelete: string) => void;
  } & (
    | { type: "selectWorker"; data: ConvertWorkerToSubAdminModalDataType }
    | {
        type: "single";
        insertCsrEdit?: boolean;
        worker: SingleWorkerDataType & {
          subName: string;
        };
      }
  )
> = ({ visible, onClose, refetch, onCancel, ...props }) => {
  const [insertCsrEdit] = useInsertCsrEditMutation();
  const authUser = useAuthUser();

  const [form] = Form.useForm<FormVals>();
  const projectId = useParams()["projectId"] as string;
  const [convertWorkerToSubadmin, { loading }] =
    useConvertWorkerToSubadminMutation();
  const { data: subTitles, loading: subTitleLoading } =
    useGetSubcontractorEmployeeTitlesQuery({
      fetchPolicy: "cache-first",
    });
  const [getProjSubs, { data: subs, loading: subsLoading }] =
    useGetProjectSubcontractorsLazyQuery({
      variables: {
        where: { project_id: { _eq: projectId } },
        order_by: { subcontractor: { name: Order_By.Asc } },
      },
      fetchPolicy: "cache-first",
    });
  useEffect(() => {
    if (props.type === "selectWorker") {
      getProjSubs();
    }
  }, [props.type]);
  const subNameIdMap = new Map<string, string>();
  subs?.project_subcontractor.forEach((sub) => {
    subNameIdMap.set(sub.subcontractor.id, sub.subcontractor.name);
  });
  const relayEnv = useRelayEnvironment();
  const [workerEmailDisabled, setWorkerEmailDisabled] = useState(true);
  const [subSelectDisabled, setSubSelectDisabled] = useState(true);
  const [subcontractor, setSubconractor] = useState("");
  const [workerPhoneDisabled, setWorkerPhoneDisabled] = useState(true);
  useEffect(() => {
    if (props.type === "single") {
      form.setFieldsValue({
        worker: props.worker.id,
        workerEmail: props.worker.email,
        workerPhone: props.worker.phone_number || undefined,
      });
    }
  }, [props]); // THIS IS precaution in case someone modifying logic in futurereturn (
  return (
    <Modal
      onCancel={onClose}
      title={`Convert ${
        props.type === "single" ? props.worker.name : "a Worker"
      } to a Subcontractor Administrator ${
        props.type === "single" ? `for ${props.worker.subName}` : ""
      }`}
      width={950}
      okText="Convert"
      open={visible}
      confirmLoading={loading}
      onOk={async () => {
        const vals = await form.validateFields();
        if (!vals.confirm) {
          message.error("Please accept to continue");
          return;
        }
        if (props.type === "selectWorker") {
          const email = props.data.find(
            (worker) => worker.id === vals.worker,
          )?.email;
          if (!email || email.trim() !== vals.workerEmail.trim()) {
            const emailUserData = await getSameEmailUser(
              vals.workerEmail,
              relayEnv,
            );

            if (
              emailUserData.user_connection.edges.length > 0 &&
              (!email ||
                emailUserData.user_connection.edges[0].node.pk !== vals.worker)
            ) {
              throw Error("This email is already registered with another user");
            }
          }
        } else {
          vals.worker = props.worker.id;
          vals.workerEmail = props.worker.email;
          vals.workerPhone = props.worker.phone_number || undefined;
        }
        const input = {
          workerId: vals.worker,
          projectId: projectId,
          userEmail: vals.workerEmail,
          subTitle: vals.titleId,
          subcontractor_id: vals.subcontractor,
          phoneNumber: vals.workerPhone,
        };
        await convertWorkerToSubadmin({ variables: { input } }).catch(
          (error) => {
            if (hasApolloErrorCode(error, "email-send-error")) {
              return false;
            } else {
              throw error;
            }
          },
        );
        message.success("convert Worker To Sub Administrator Successfull");
        props.removeWorkerFromCache?.(vals.worker);

        // message.success("User Converted Successfully");
        form.resetFields();
        onClose();
        // setToKeep(undefined);
        refetch();
        onCancel();
        if (props.type === "single" && props.insertCsrEdit)
          await insertCsrEdit({
            variables: {
              csrEdits: [
                {
                  change_log: [input],
                  name: "convertWorkerToSubadmin",
                  csr_user_id: authUser.uid,
                  edit_text: "Merged two workers into one",
                  entity_id: vals.worker,
                  operation_name: "resolver_call",
                },
              ],
            },
          });
      }}
    >
      <Form form={form} layout="vertical">
        {props.type === "selectWorker" && (
          <>
            {" "}
            <div>
              Select the worker to convert to an Administrator for their
              Subcontractor &nbsp;&nbsp;
            </div>
            <Form.Item
              name="worker"
              label={"Select Worker"}
              rules={[{ required: true, message: "Choose a Worker" }]}
            >
              <Select
                // onChange={(val) => setToKeep(val.toLocaleString())}
                showSearch
                filterOption={(input, option) => {
                  if (!option || !option.props.children[0]) return false;
                  return (
                    option.props.children[0]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }}
                onChange={(value) => {
                  const selectedWorker = props.data.find((u) => u.id === value);
                  if (selectedWorker) {
                    form.setFieldsValue({
                      workerEmail: selectedWorker.email || "",
                      workerPhone: selectedWorker.phoneNumber,
                      subcontractor: selectedWorker.subcontractorId,
                    });
                    setWorkerEmailDisabled(!!selectedWorker.email);
                    setWorkerPhoneDisabled(!!selectedWorker.phoneNumber);
                    setSubSelectDisabled(!!selectedWorker.subcontractorId);
                    setSubconractor(selectedWorker.subcontractorId || "");
                  }
                }}
              >
                {props.data.map((u) => {
                  const list = [
                    u.name,
                    u.hh,
                    u.company,
                    u.trade,
                    u.email,
                  ].filter((s) => s);
                  return (
                    <Option value={u.id} key={u.id}>
                      {list.join(", ")}&nbsp;&nbsp;
                      <span className="font-accent text-interactive-primary">
                        {u.createdPassword ? " PASSWORD SET" : ""}
                      </span>
                    </Option>
                  );
                })}
              </Select>
            </Form.Item>
            <Form.Item
              name="workerEmail"
              label="Worker Email"
              rules={[
                { required: true, message: "Please enter the worker's email" },
              ]}
            >
              <Input
                disabled={workerEmailDisabled}
                // defaultValue={workerEmail || enteredEmail}
                // value={workerEmailDisabled ? workerEmail : enteredEmail}
                onChange={(e) =>
                  form.setFieldsValue({ workerEmail: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item name="workerPhone" label="Phone Number">
              <Input
                disabled={workerPhoneDisabled}
                // defaultValue={workerEmail || enteredEmail}
                // value={workerPhone}
                onChange={(e) =>
                  form.setFieldsValue({ workerPhone: e.target.value })
                }
              />
            </Form.Item>
            <Form.Item
              name="subcontractor"
              label="Subcontractor"
              rules={[{ required: true, message: "Choose a subcontractor" }]}
              initialValue={subcontractor}
            >
              {subSelectDisabled == true ? (
                <Select
                  style={{ width: "100%" }}
                  value={subcontractor}
                  key={subcontractor}
                  disabled
                >
                  <Select.Option value={subcontractor} key={subcontractor}>
                    {subNameIdMap.get(subcontractor)}
                  </Select.Option>
                </Select>
              ) : (
                <Select
                  style={{ width: "100%" }}
                  placeholder="Select the Subcontractor they work for"
                >
                  {(subs?.project_subcontractor || [])
                    .filter((sub) => sub.subcontractor)
                    .sort((a, b) =>
                      a.subcontractor.name.localeCompare(b.subcontractor.name),
                    )
                    .map((sub) => (
                      <Select.Option
                        key={sub.subcontractor.id}
                        value={sub.subcontractor.id}
                        // disabled={subSelectDisabled}
                      >
                        {sub.subcontractor.name}
                      </Select.Option>
                    ))}
                </Select>
              )}
            </Form.Item>
          </>
        )}
        <Form.Item
          name="titleId"
          label="Title"
          rules={[{ required: true, message: "Choose a title" }]}
        >
          <Select style={{ width: "100%" }}>
            {subTitles?.subcontractor_employee_title.map((w) => (
              <Select.Option key={w.id} value={w.id}>
                {w.name.en}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <div>
          {" "}
          By confirming, this worker will be converted to an Administrator for
          this Subcontractor and will have access to their Company’s overall
          profile, which includes access to all projects and information. They
          will still have access to the mobile app and be able to submit
          reports.
          <br />
          <i>
            {" "}
            This is necessary when the Subcontractor’s safety director or
            project manager completed orientation on your project. A foreman or
            crew leader typically does not need this access
          </i>
        </div>
        <br />
        <br />
        <div>This cannot be undone</div>
        <Form.Item name="confirm">
          <Radio.Group>
            <Radio value={true}>I understand</Radio>
          </Radio.Group>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default withCustomSuspense(ConvertWorkerToSubAdminModal);
