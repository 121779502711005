/**
 * @generated SignedSource<<768501b2eb3edd94d6a93cbfc1488bad>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_crew_toolbox_talk_not_require_period_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation$variables = {
  currentTime: string;
  where: project_subcontractor_crew_toolbox_talk_not_require_period_bool_exp;
};
export type usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation$data = {
  readonly update_project_subcontractor_crew_toolbox_talk_not_require_period: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
    }>;
  } | null | undefined;
};
export type usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation = {
  response: usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation$data;
  variables: usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "currentTime"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "closed_at",
            "variableName": "currentTime"
          }
        ],
        "kind": "ObjectValue",
        "name": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "project_subcontractor_crew_toolbox_talk_not_require_period_mutation_response",
    "kind": "LinkedField",
    "name": "update_project_subcontractor_crew_toolbox_talk_not_require_period",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "project_subcontractor_crew_toolbox_talk_not_require_period",
        "kind": "LinkedField",
        "name": "returning",
        "plural": true,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "40f86a81d825f8c64ef4bc99ca9e0efd",
    "id": null,
    "metadata": {},
    "name": "usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation usePermanentReportRequireSettingsCrew_TBTNotRequire_UpdateMutation(\n  $currentTime: timestamptz!\n  $where: project_subcontractor_crew_toolbox_talk_not_require_period_bool_exp!\n) {\n  update_project_subcontractor_crew_toolbox_talk_not_require_period(where: $where, _set: {closed_at: $currentTime}) {\n    returning {\n      id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2ae73bfa658ba5ec358b190536426250";

export default node;
