import { Divider, Flex, Space } from "antd";
import GCAdminObservationsReportCreatorButton, {
  GCAdminObservationsReportCreatorButtonProps,
} from "./GCAdminObservationsReportCreatorButton";
import GCAdminObservationsSettingsButton, {
  GCAdminObservationsSettingsButtonProps,
} from "./GCAdminObservationsSettingsButton";
import GCAdminHeaderFilterTags, {
  GCAdminHeaderFilterTagsProps,
} from "../../filter-inputs/GCAdminHeaderFilterTags";
import { dateFormat } from "../../../../../../../../common/functions/getDateStr";
import GCAdminHeaderFilterButton, {
  GCAdminHeaderFilterButtonProps,
} from "../../filter-inputs/GCAdminHeaderFilterButton";

interface GCAdminObservationsHeaderProps {
  reportCreatorButtonProps: GCAdminObservationsReportCreatorButtonProps;
  // observationSettingsButtonProps: GCAdminObservationsSettingsButtonProps;
  filters?: GCAdminHeaderFilterButtonProps["filters"];
}

const GCAdminObservationsHeader: React.FC<GCAdminObservationsHeaderProps> = (
  props,
) => {
  return (
    <Space direction={"vertical"}>
      <Flex justify={"end"}>
        <Space>
          {props.filters && (
            <GCAdminHeaderFilterButton filters={props.filters} />
          )}
          <GCAdminObservationsReportCreatorButton
            {...props.reportCreatorButtonProps}
          />
          {/* <GCAdminObservationsSettingsButton
            {...props.observationSettingsButtonProps}
          /> */}
        </Space>
      </Flex>
      <Divider />
      {props.filters && <GCAdminHeaderFilterTags filters={props.filters} />}
    </Space>
  );
};

export default GCAdminObservationsHeader;
