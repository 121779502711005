/**
 * @generated SignedSource<<b7655feab17d5c2111f83981f60c03fc>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { project_subcontractor_employee_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$variables = {
  objects: ReadonlyArray<project_subcontractor_employee_insert_input>;
};
export type AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$data = {
  readonly insert_project_subcontractor_employee: {
    readonly returning: ReadonlyArray<{
      readonly emergency_contact: boolean;
      readonly id: string;
      readonly pk: string;
      readonly project: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
      readonly subcontractor_employee: {
        readonly employee_title: {
          readonly name_text: string;
        } | null | undefined;
        readonly user: {
          readonly created_password: boolean;
          readonly email: string | null | undefined;
          readonly invites: ReadonlyArray<{
            readonly accepted_at: string | null | undefined;
            readonly id: string;
          }>;
          readonly name: string;
          readonly phone_number: string | null | undefined;
        };
      };
      readonly subcontractor_employee_id: string;
    }>;
  } | null | undefined;
};
export type AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation = {
  response: AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$data;
  variables: AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "objects",
    "variableName": "objects"
  },
  {
    "kind": "Literal",
    "name": "on_conflict",
    "value": {
      "constraint": "project_subcontractor_employe_project_id_subcontractor_empl_key",
      "update_columns": ([]/*: any*/)
    }
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "subcontractor_employee_id",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "email",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "phone_number",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_password",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "limit",
      "value": 1
    },
    {
      "kind": "Literal",
      "name": "where",
      "value": {
        "accepted_at": {
          "_is_null": true
        },
        "claiming_account": {
          "_eq": true
        }
      }
    }
  ],
  "concreteType": "invite",
  "kind": "LinkedField",
  "name": "invites",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "accepted_at",
      "storageKey": null
    },
    (v2/*: any*/)
  ],
  "storageKey": "invites(limit:1,where:{\"accepted_at\":{\"_is_null\":true},\"claiming_account\":{\"_eq\":true}})"
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name_text",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "emergency_contact",
  "storageKey": null
},
v12 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v13 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractor_employee_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_subcontractor_employee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractor_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee",
                "kind": "LinkedField",
                "name": "subcontractor_employee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor_employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "project_subcontractor_employee_mutation_response",
        "kind": "LinkedField",
        "name": "insert_project_subcontractor_employee",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_subcontractor_employee",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              (v12/*: any*/),
              (v13/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "project",
                "kind": "LinkedField",
                "name": "project",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v12/*: any*/),
                  (v13/*: any*/),
                  (v4/*: any*/)
                ],
                "storageKey": null
              },
              (v5/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor_employee",
                "kind": "LinkedField",
                "name": "subcontractor_employee",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "user",
                    "kind": "LinkedField",
                    "name": "user",
                    "plural": false,
                    "selections": [
                      (v4/*: any*/),
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/),
                      (v9/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "subcontractor_employee_title",
                    "kind": "LinkedField",
                    "name": "employee_title",
                    "plural": false,
                    "selections": [
                      (v10/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v2/*: any*/)
                ],
                "storageKey": null
              },
              (v11/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c1e1324620169dfbc5c8ff57a7e26895",
    "id": null,
    "metadata": {},
    "name": "AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation",
    "operationKind": "mutation",
    "text": "mutation AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation(\n  $objects: [project_subcontractor_employee_insert_input!]!\n) {\n  insert_project_subcontractor_employee(objects: $objects, on_conflict: {constraint: project_subcontractor_employe_project_id_subcontractor_empl_key, update_columns: []}) {\n    returning {\n      id\n      pk: id\n      project {\n        id\n        pk: id\n        name\n      }\n      subcontractor_employee_id\n      subcontractor_employee {\n        user {\n          name\n          email\n          phone_number\n          created_password\n          invites(where: {claiming_account: {_eq: true}, accepted_at: {_is_null: true}}, limit: 1) {\n            accepted_at\n            id\n          }\n          id\n        }\n        employee_title {\n          name_text\n          id\n        }\n        id\n      }\n      emergency_contact\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "a4c18d4340d4353c219baa01535871b1";

export default node;
