import * as React from "react";
import { useEffect } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import CustomTabsBase from "./CustomTabsBase";
import CustomSuspense from "../../general/CustomSuspense";

// TODO (suggestion)
// Find a way to define basePath automatically
// Or...
// Pass a dynamic route as a basePath.
// E.g. `/projects/:projectId/tabs` instead of `/projects/1234/tabs`

type CustomRoutedTabType = {
  path: string; // relative path
  label: React.ReactNode;
  icon?: React.ReactNode;
};

export interface CustomTabsRoutedProps {
  tabs: CustomRoutedTabType[];
  basePath: string;
}

const CustomTabsRouted: React.FunctionComponent<CustomTabsRoutedProps> = (
  props,
) => {
  const [selectedTabRoute, setSelectedTabRoute] = React.useState<
    string | undefined
  >(undefined);
  const navigate = useNavigate();
  const location = useLocation();
  const onTabChange = (key: string) => {
    navigate(key);
  };

  useEffect(() => {
    const matchingTab = props.tabs.find((tab) =>
      location.pathname.startsWith(`${props.basePath}/${tab.path}`),
    );
    if (matchingTab) {
      setSelectedTabRoute(matchingTab.path);
    }
  }, [location.pathname]);

  return (
    <CustomTabsBase
      onChange={onTabChange}
      activeKey={selectedTabRoute}
      items={props.tabs.map((tab) => ({
        key: tab.path,
        label: tab.label,
        children: (
          <CustomSuspense>
            <Outlet />
          </CustomSuspense>
        ),
        icon: tab.icon,
      }))}
    />
  );
};

export default CustomTabsRouted;
