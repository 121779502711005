import { Button, Card, message, Popover, Space } from "antd";
import CustomButton from "src/common/components/general/Button";
import React, { FC, useState } from "react";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import CounterLabel from "src/common/components/general/CounterLabel";
import Image from "src/common/components/general/images/Image";
import Separator from "src/common/components/general/Separator";
import StyledContent from "src/common/components/layouts/StyledContent";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import ViewAndEditTask from "./ViewAndEditTask";
import { auth } from "src/common/functions/firebase";
import {
  useEmailFlaggedJhatoUnflagMutation,
  useGenerateJhaPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import viewAndEditJhaQuery from "src/common/api/relay/queries/ViewAndEditJha";
import { ViewAndEditJhaQuery } from "src/common/types/generated/relay/ViewAndEditJhaQuery.graphql";
import { IconDotsVertical, IconFlag } from "@tabler/icons";
import TaskPdfDownload from "../action-buttons/TaskPdfDownload";
import TaskExcelDownload from "../action-buttons/TaskExcelDownload";
import ArchiveOrActivateTask from "../action-buttons/ArchiveOrActivateTask";
import ViewAndEditTaskType from "src/common/types/manual/ViewAndEditTaskType";
import TaskFlagForAction from "../action-buttons/TaskFlagForAction";
import TaskRequestSubApproval from "../action-buttons/TaskRequestSubApproval";
import { getJHAApprovalData } from "src/common/api/relay/queries/GetJHAApprovalModalData";
import SendTaskForApprovalModal from "../modals/SendTaskForApprovalModal";
import { GetJHAApprovalModalDataQuery$data } from "src/common/types/generated/relay/GetJHAApprovalModalDataQuery.graphql";
import useFlagOrUnFlagTask from "src/common/api/relay/mutationHooks/useFlagOrUnflagTask";
import TaskRequestToRemoveFlag from "../action-buttons/TaskRequestToRemoveFlag";
import { NULL_ID } from "src/common/functions/nullId";
import TaskAdminInfo from "../action-buttons/TaskAdminInfo";
import AddTaskAdminModal from "../modals/AddTaskAdminModal";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { TaskInfo_update_task_admin_Mutation } from "src/common/types/generated/relay/TaskInfo_update_task_admin_Mutation.graphql";
import { graphql } from "babel-plugin-relay/macro";
import { ConnectionHandler } from "relay-runtime";
import * as uuid from "uuid";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

type TaskInfoProps = {
  taskId?: string;
  type: "gce" | "sub_project_jha" | "sub_company_jha";
  subcontractorId?: string;
  viewOnly?: boolean;
} & (
  | { type: "gce" | "sub_project_jha"; projectId: string }
  | {
      type: "sub_company_jha";
      projectId?: never;
    }
);
// TODO show which crews using task
const TaskInfo: FC<TaskInfoProps> = ({ taskId, subcontractorId, ...props }) => {
  if (!taskId) {
    throw new Error("task id not found");
  }
  if (!subcontractorId) {
    throw new Error("subcontractor id not found");
  }

  const [updateTaskAdmin] =
    useAsyncMutation<TaskInfo_update_task_admin_Mutation>(
      graphql`
        mutation TaskInfo_update_task_admin_Mutation(
          $objects: [task_admin_insert_input!]!
          $deleteWhere: task_admin_bool_exp!
        ) {
          delete_task_admin(where: $deleteWhere) {
            returning {
              id
              general_contractor_employee {
                employee_title {
                  name {
                    en
                  }
                }
                user {
                  pk: id @__clientField(handle: "pk")
                  name
                }
              }
            }
          }
          insert_task_admin(objects: $objects) {
            returning {
              id
              general_contractor_employee {
                employee_title {
                  name {
                    en
                  }
                }
                user {
                  pk: id @__clientField(handle: "pk")
                  name
                }
              }
            }
          }
        }
      `,
    );

  const [generatePdf, { loading: generatingPdf }] = useGenerateJhaPdfMutation();
  const environment = useRelayEnvironment();
  const [requestToUnflag] = useEmailFlaggedJhatoUnflagMutation();

  const queryData = useLazyLoadQuery<ViewAndEditJhaQuery>(viewAndEditJhaQuery, {
    taskId: taskId,
    projectId: props.type === "gce" ? props.projectId : NULL_ID,
    currentUserId: auth.currentUser?.uid ?? NULL_ID,
    subId: subcontractorId,
    emailRequestWhere: {
      task_id: { _eq: taskId },
      user_id: { _eq: auth.currentUser?.uid },
    },
  });

  const taskAdmins = queryData.task_admin_connection.edges.map((edge) => ({
    id: edge.node.general_contractor_employee.user.pk,
    name: edge.node.general_contractor_employee.user.name,
    title: edge.node.general_contractor_employee.employee_title?.name.en,
  }));

  const gcUsers = queryData.project_employee_connection.edges.map((user) => ({
    id: user.node.employee_id,
    name: user.node.employee.user.name,
  }));
  const taskData = queryData.task_connection.edges[0].node;
  const taskStepsData = queryData.task_step_connection.edges;
  const viewAndEditTaskData: ViewAndEditTaskType = {
    description: taskData.description.en,
    descriptionId: taskData.description_id,
    ecms: queryData.ecm_type_connection.edges.map((ecm) => ({
      id: ecm.node.pk,
      description: ecm.node.name?.original ?? "",
    })),
    id: taskId,
    permits: queryData.permit_type_connection.edges.map((permit) => ({
      id: permit.node.pk,
      description: permit.node.name.en,
    })),
    ppeCategories: queryData.ppe_category_connection.edges.map(
      (ppeCategory) => ({
        id: ppeCategory.node.id,
        description: ppeCategory.node.name.en,
        ppes: ppeCategory.node.ppe_types.map((ppe) => ({
          id: ppe.pk,
          description: ppe.name.en,
        })),
      }),
    ),
    steps: taskStepsData.map((step) => ({
      description: step.node.description.en,
      id: step.node.pk,
      hazards: step.node.task_step_hazards.map((haz) => ({
        id: haz.pk,
        description: haz.description.en,
        control: haz.control.en,
        ecms: haz.task_hazard_ecm_types
          ? haz.task_hazard_ecm_types.map((ecm) => ({
              id: ecm.ecm_type_id,
              description: ecm.ecm_type?.name?.en ?? "",
            }))
          : [],
      })),
    })),

    taskPpes: queryData.task_ppe_type_connection.edges.map((ppe) => ({
      id: ppe.node.ppe_type.pk,
      description: ppe.node.ppe_type.name.en,
    })),

    taskPermits: queryData.task_permit_type_connection.edges.map((permit) => ({
      id: permit.node.permit_type.pk,
      description: permit.node.permit_type.name.en,
    })),

    queryData: queryData,
  };
  const withProjectId: boolean =
    queryData.task_connection.edges[0].node.project_id != null;
  const type =
    props.type === "gce"
      ? withProjectId
        ? "gce_project_jha"
        : "gce_company_jha"
      : props.type;
  const isEditable = withProjectId && !props.viewOnly;
  const myData = queryData.task_connection.edges[0].node;
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const isNotTurnersProject =
    myData.project?.general_contractor_id !== turnerGCId;
  const getPopupContainer = (triggerNode: HTMLElement) => {
    return triggerNode.parentNode as HTMLElement;
  };
  const [taskForApprovalModal, setTaskForApprovalModal] = useState<{
    type: "flag-jha-request" | "approval-request" | "review-request";
    data: GetJHAApprovalModalDataQuery$data | undefined;
  }>({ type: "approval-request", data: undefined });
  const [taskAdminModalOpen, setTaskAdminModalOpen] = useState(false);
  const [flagOrUnflagTask] = useFlagOrUnFlagTask();
  const [isPopUpVisible, setIsPopUpVisible] = useState(false);
  const handleVisibleChange = (visible: boolean) => {
    setIsPopUpVisible(visible);
  };

  return (
    <>
      {!!taskForApprovalModal.data && (
        <SendTaskForApprovalModal
          data={taskForApprovalModal.data}
          isNotTurnersProject={isNotTurnersProject}
          onClose={() => {
            setTaskForApprovalModal((prev) => ({
              type: prev.type,
              data: undefined,
            }));
          }}
          type={taskForApprovalModal.type}
          taskId={taskId}
          visible={!!taskForApprovalModal.data}
        />
      )}

      <AddTaskAdminModal
        taskAdminOptions={gcUsers}
        taskAdmins={taskAdmins}
        initialValue={taskAdmins.map((admin) => admin.id)}
        visible={taskAdminModalOpen}
        onCancel={() => setTaskAdminModalOpen(false)}
        onCreate={async (values) => {
          try {
            setTaskAdminModalOpen(false);
            await updateTaskAdmin({
              variables: {
                deleteWhere: { task_id: { _eq: taskId } },
                objects: values.user.map((id) => ({
                  assigned_at: dayjs().format(),
                  task_id: taskId,
                  empolyee_id: id,
                })),
              },

              updater: (store) => {
                const deleteTaskAdmin = store.getRootField("delete_task_admin");
                const taskAdminConn = ConnectionHandler.getConnection(
                  store.getRoot(),
                  "ViewAndEditJha_task_admin_connection",
                );

                if (taskAdminConn) {
                  deleteTaskAdmin
                    .getLinkedRecords("returning")
                    .forEach((record) => {
                      ConnectionHandler.deleteNode(
                        taskAdminConn,
                        record.getDataID(),
                      );
                    });
                }
                const insertTaskAdmin = store.getRootField("insert_task_admin");
                if (taskAdminConn) {
                  insertTaskAdmin
                    .getLinkedRecords("returning")
                    .forEach((record) => {
                      const edge = store.create(uuid.v4(), "edge");
                      edge.setLinkedRecord(record, "node");
                      ConnectionHandler.insertEdgeBefore(taskAdminConn, edge);
                    });
                }
              },
            });
            message.success("Task Assiged Successfully");
          } catch (err) {
            message.error("Error while assigning task" + err);
          }
        }}
      />

      {myData.show_type === "images" && myData.task_creation_request ? (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <div style={{ display: "flex" }}>
            <div style={{ width: "40%" }}>
              <h3
                style={{
                  fontSize: "17px",
                  marginBottom: 3,
                  fontWeight: 500,
                }}
              >
                Job Hazard Analysis (JHA)
              </h3>
              <h2 style={{ fontSize: "20px", fontWeight: 665 }}>
                {queryData.task_connection.edges[0].node.description.en}
              </h2>
              <br />
              (JHA content has not been or cannot be transposed to SiteForm)
            </div>
            <div style={{ width: "50%", fontWeight: "bold" }}>
              {!!myData.created_by_user?.name && (
                <h3>Created by - {myData.created_by_user?.name}</h3>
              )}
            </div>
            <Button
              type="primary"
              loading={generatingPdf}
              onClick={async (a) => {
                if (typeof taskId !== "string") return;
                const { data: download } = await generatePdf({
                  variables: {
                    input: {
                      type: isNotTurnersProject ? "siteform" : "turner",
                      jhaNum: 1,
                      taskId: taskId,
                    },
                  },
                });
                if (download?.generateJHAPdf) {
                  downloadFromUrl(download.generateJHAPdf);
                }
              }}
            >
              Download PDF JHA
            </Button>
          </div>
          <div>
            <div className="font-accent">
              Images({myData.task_creation_request.attached_files.length}):
            </div>
            <div className="flex flex-row overflow-x-scroll">
              {myData.task_creation_request.attached_files.map((o, x) => (
                <div className="w-3/12 font-accent" key={x}>
                  <Card
                    hoverable
                    className="w-11/12 ml-1"
                    cover={
                      <div>
                        <Image src={o.url} alt={"Image " + (x + 1)} preview />
                      </div>
                    }
                  ></Card>
                </div>
              ))}
            </div>
          </div>
        </Space>
      ) : (
        <Space direction="vertical" size="large" style={{ width: "100%" }}>
          <StyledContent padding backgroundColor="white">
            <div className="flex-row-reverse">
              <div className="float-right mr-3">
                <Popover
                  open={isPopUpVisible}
                  onOpenChange={handleVisibleChange}
                  getPopupContainer={getPopupContainer}
                  placement="bottomLeft"
                  content={
                    <div onClick={() => setIsPopUpVisible(false)}>
                      {isEditable && props.type == "gce" && (
                        <TaskFlagForAction
                          key={5}
                          status={myData.flagged_at ? "flagged" : "unflagged"}
                          taskId={taskId}
                          onClick={async () => {
                            const data = await getJHAApprovalData(
                              environment,
                              taskId,
                              subcontractorId,
                              props.projectId,
                            );
                            setTaskForApprovalModal({
                              type: "flag-jha-request",
                              data: data,
                            });
                          }}
                        />
                      )}

                      {props.type == "sub_project_jha" && myData.flagged_at && (
                        <TaskRequestToRemoveFlag key={4} taskId={taskId} />
                      )}

                      {isEditable && props.type == "gce" && (
                        <TaskAdminInfo
                          onClick={() => setTaskAdminModalOpen(true)}
                          title="Assign Internally"
                        />
                      )}

                      {isEditable && props.type == "gce" && (
                        <TaskRequestSubApproval
                          key={7}
                          onClick={async () => {
                            const data = await getJHAApprovalData(
                              environment,
                              taskId,
                              subcontractorId,
                              props.projectId,
                            );
                            setTaskForApprovalModal({
                              type: "review-request",
                              data: data,
                            });
                          }}
                          title="Internal Team Review"
                        />
                      )}

                      {isEditable && props.type == "gce" && (
                        <TaskRequestSubApproval
                          key={6}
                          onClick={async () => {
                            const data = await getJHAApprovalData(
                              environment,
                              taskId,
                              subcontractorId,
                              props.projectId,
                            );
                            setTaskForApprovalModal({
                              type: "approval-request",
                              data: data,
                            });
                          }}
                          title="Request Sub Approval"
                        />
                      )}

                      {
                        <TaskExcelDownload
                          key={3}
                          taskId={taskId}
                          type={isNotTurnersProject ? "siteform" : "turner"}
                        />
                      }
                      {
                        <TaskPdfDownload
                          key={2}
                          taskIds={[taskId]}
                          type={isNotTurnersProject ? "siteform" : "turner"}
                          jhaNum={[1]}
                        />
                      }

                      {isEditable && (
                        <ArchiveOrActivateTask
                          key={1}
                          status={myData.archived_at ? "archived" : "active"}
                          taskId={taskId}
                        />
                      )}
                    </div>
                  }
                  trigger={["click"]}
                >
                  <CustomButton
                    icon={IconDotsVertical}
                    secondary
                    onClick={() => setIsPopUpVisible(!isPopUpVisible)}
                  />
                </Popover>
              </div>

              <div className="float-right mr-2">
                <div className="text-1.25">
                  {taskAdmins.length === 0
                    ? `No JHA Lead`
                    : taskAdmins.length > 1
                    ? `JHA Leads (${taskAdmins.length}) `
                    : `JHA Lead`}
                </div>
                {taskAdmins.map((admin, index) => (
                  <div key={index}>
                    {admin.name} {!!admin.title ? `(${admin.title})` : ``}
                  </div>
                ))}
              </div>

              {myData.flagged_at && (
                <div className="float-right mr-1">
                  <Popover
                    trigger={"click"}
                    placement="bottomLeft"
                    content={
                      props.type === "gce" ? (
                        <div className="w-32">
                          <div className="mb-1">
                            {" "}
                            <b>JHA FLAGGED FOR ACTION</b> by{" "}
                            {myData.flagged_by_user?.name} on{" "}
                            {dayjs(myData.flagged_at).format("MMM DD, YYYY")}.{" "}
                          </div>
                          <b> Reason for Flag:</b> <br />
                          {queryData &&
                          queryData.flaggedEdit &&
                          queryData.flaggedEdit.edges.length &&
                          queryData.flaggedEdit.edges[0].node.comment ? (
                            <span className="italic">
                              {queryData.flaggedEdit.edges[0].node.comment}
                            </span>
                          ) : (
                            "N/A"
                          )}
                          <div className="mt-1">
                            {" "}
                            <CustomButton
                              tiny
                              label="REMOVE FLAG"
                              onClick={() => flagOrUnflagTask(taskId, "unflag")}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="w-32">
                          This JHA has been FLAGGED FOR ACTION by{" "}
                          {taskData.flagged_by_user?.name} on{" "}
                          {dayjs(taskData.flagged_at).format("MMM DD, YYYY")}
                          <br />
                          <b>Reason To FLag:</b> <br />
                          {queryData.flaggedEdit &&
                          queryData.flaggedEdit.edges &&
                          queryData.flaggedEdit.edges.length &&
                          queryData.flaggedEdit.edges[0].node.comment
                            ? queryData.flaggedEdit.edges[0].node.comment
                            : "N/A"}
                          <div className="text-lightgrey mt-0.5">
                            How to Clear the Flag: Select the Edit button and
                            make necessary edits.
                            <div className="flex items-center">
                              {" "}
                              Once edits are complete, select
                              <span className="ml-0.25">
                                <CustomButton
                                  onClick={async () => {
                                    try {
                                      message.loading({
                                        content:
                                          "Request to Remove Flag in progress",
                                        duration: 0,
                                      });
                                      await requestToUnflag({
                                        variables: {
                                          input: { taskId: taskId },
                                        },
                                      });
                                      message.destroy();
                                      message.success("Email Sent");
                                    } catch (e) {
                                      if (e instanceof Error) {
                                        message.error(e.message);
                                      }
                                    }
                                  }}
                                  tiny
                                  label="Request To Remove Flag"
                                />
                              </span>
                            </div>
                          </div>
                        </div>
                      )
                    }
                  >
                    <CustomButton
                      icon={IconFlag}
                      delete
                      onClick={() => console.log("")}
                    />
                  </Popover>
                </div>
              )}

              <div>
                <div style={{ width: "40%" }}>
                  <h3
                    style={{
                      fontSize: "17px",
                      marginBottom: 3,
                      fontWeight: 500,
                      // borderRadius : 4,
                    }}
                  >
                    Job Hazard Analysis (JHA)
                  </h3>
                  <h2
                    style={{
                      fontSize: "20px",
                      marginBottom: 9,
                      fontWeight: 665,
                    }}
                  >
                    {
                      queryData.task_connection.edges[0].node.description
                        .original
                    }
                  </h2>
                </div>
                <div className="flex mr-2">
                  {!!myData.created_by_user?.name && (
                    <h3 style={{ width: "50%", fontWeight: "bold" }}>
                      Created by - {myData.created_by_user?.name}
                    </h3>
                  )}
                  {myData.task_creation_request &&
                    myData.task_creation_request.type === "images_for_one" && (
                      <CounterLabel
                        label="By Image Upload"
                        widthCssClass="w-8"
                        count={
                          myData.task_creation_request.attached_files.length
                        }
                        popover={{
                          placement: "bottom",
                          content: (
                            <div className="flex flex-col gap-1">
                              <div className="font-accent">Photos:</div>
                              <div className="flex flex-col h-48 overflow-y-auto gap-0.5 w-24">
                                {myData.task_creation_request.attached_files.map(
                                  (item, i) => (
                                    <div key={i}>
                                      <Separator />
                                      <div className="flex flex-col gap-1">
                                        <Image src={item.url} preview={true} />
                                      </div>
                                    </div>
                                  ),
                                )}
                              </div>
                            </div>
                          ),
                        }}
                      />
                    )}
                </div>
              </div>
            </div>
          </StyledContent>
          <StyledContent padding backgroundColor="white">
            <ViewAndEditTask
              taskId={taskId}
              projectId={props.projectId}
              subcontractorId={subcontractorId}
              type={type}
              viewOnly={props.viewOnly}
              isNotTurnersProject={isNotTurnersProject}
              data={viewAndEditTaskData}
            />
          </StyledContent>
        </Space>
      )}
    </>
  );
};

export default withCustomSuspense(TaskInfo);
