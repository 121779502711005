import React, { FunctionComponent } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import { SCToolboxTalksQuery } from "src/common/types/generated/relay/SCToolboxTalksQuery.graphql";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ToolboxTalkTable from "src/domain-features/sitesafety/components/toolbox-talks/ToolboxTalkTable";
import ManageToolboxTalks from "src/domain-features/sitesafety/components/toolbox-talks/ManageToolboxTalks";
import SCToolboxTalk from "./components/SCToolboxTalk";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";

const SCToolboxTalks: FunctionComponent = () => {
  const subcontractorId = useSubcontractorId();

  const data = useLazyLoadQuery<SCToolboxTalksQuery>(
    graphql`
      query SCToolboxTalksQuery($subcontractorId: uuid!) {
        project_subcontractor_connection(
          where: { subcontractor_id: { _eq: $subcontractorId } }
          order_by: { project: { name: asc } }
        ) {
          edges {
            node {
              project_id
              project {
                name
              }
            }
          }
        }
      }
    `,
    { subcontractorId },
  ).project_subcontractor_connection.edges;
  const { subview } = useParams();
  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const toolboxTalkId = searchParams.get("toolboxTalkId");

  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "manage",
      label: "Manage Company TBTs",
      content: (
        <ManageToolboxTalks
          subId={subcontractorId}
          projectsOption={data.map((ps) => ({
            name: ps.node.project.name,
            id: ps.node.project_id,
          }))}
        />
      ),
    },
    {
      id: "completed",
      label: "Completed TBTs",
      content: (
        <ToolboxTalkTable
          showDateRangeFilter={true}
          excludedKeys={["subcontractor"]}
          where={{
            subcontractor_id: {
              _eq: subcontractorId,
            },
          }}
          onRowClick={(pk) => setSearchParams({ toolboxTalkId: pk })}
        />
      ),
    },
  ];
  return (
    <BasicWrapper scrollable>
      {toolboxTalkId ? (
        <SCToolboxTalk {...{ subcontractorId, toolboxTalkId }} />
      ) : (
        <CustomTabs
          onTabSelect={handleReportsTabClick}
          tabs={tabs}
          selectedTabId={subview || "completed"}
        />
      )}
    </BasicWrapper>
  );
};

export default SCToolboxTalks;
