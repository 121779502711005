import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import useSiteOrientationGcDashboardData from "./utils/useSiteOrientationGcDashboardData";
import GCProjectOrientationStatus from "./routes/status/GCProjectOrientationStatus";
import GCProjectOrientationDrugTest from "./routes/drugTest/GCProjectOrientationDrugTest";
import GCProjectOrientationSettings from "./routes/settings/GCProjectOrientationSettings";
import GCProjectOrientationBackgroundCheck from "./routes/status/components/GCProjectOrientationBackgroundCheck";
import GCProjectOrientationDashboard from "./routes/dashboard/GCProjectOrientationDashboard";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";

interface GCProjectOrientationsProps {
  projectId: string;
  subview: string;
}

const GCProjectOrientations: FC<GCProjectOrientationsProps> = ({
  projectId,
  subview,
}) => {
  // $projectId: uuid!
  const data = useSiteOrientationGcDashboardData(projectId);
  const navigate = useNavigate();

  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  if (!data.project_connection.edges?.[0]) {
    return <>Unknown Project Id</>;
  }
  const performDrugtest =
    data.project_connection.edges[0].node.perform_drugtest;
  const backgroundTracker =
    data.project_connection.edges[0].node.background_check_tracker;

  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "dashboard",
      label: "Dashboard",
      content: <GCProjectOrientationDashboard projectId={projectId} />,
    },
    {
      id: "status",
      label: "Orientation",
      content: <GCProjectOrientationStatus projectId={projectId} data={data} />,
    },
    ...(performDrugtest
      ? [
          {
            id: "drugtest",
            label: "Drug Tests",
            content: (
              <GCProjectOrientationDrugTest projectId={projectId} data={data} />
            ),
          },
        ]
      : []),
    ...(backgroundTracker
      ? [
          {
            id: "backgroundcheck",
            label: "Background Check",

            content: (
              <GCProjectOrientationBackgroundCheck
                projectId={projectId}
                data={data}
              />
            ),
          },
        ]
      : []),
    {
      id: "settings",
      label: "Settings",
      content: (
        <GCProjectOrientationSettings projectId={projectId} data={data} />
      ),
    },
  ];

  return (
    <BasicWrapper scrollable>
      <CustomTabs
        onTabSelect={handleReportsTabClick}
        tabs={tabs}
        selectedTabId={subview}
      />
    </BasicWrapper>
  );
};

export default withCustomSuspense(GCProjectOrientations);
