import { FC } from "react";
import LoadingContent from "src/common/components/general/LoadingContent";
import ReportDetail from "src/domain-features/sitesafety/components/ReportDetail";
import { useGetReportByPkQuery } from "src/common/types/generated/apollo/graphQLTypes";

interface Props {
  projectId: string;
  reportId: string;
}

const GCProjectReportsPTPsDetail: FC<Props> = ({ reportId, projectId }) => {
  const { data } = useGetReportByPkQuery({
    variables: { reportId },
  });
  if (!data?.report_by_pk) {
    return <LoadingContent />;
  }

  return (
    <ReportDetail
      showBackButton
      routeId={{ type: "gce", id: projectId }}
      report={data.report_by_pk as any}
    />
  );
};

export default GCProjectReportsPTPsDetail;
