import { IconEye, IconEyeOff } from "@tabler/icons";
import clsx from "clsx";
import dayjs from "dayjs";
import React, { useState } from "react";
import Button from "src/common/components/general/Button";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import datesFromRange from "src/common/functions/datesFromRange";
import { DatesRangeType } from "src/common/types/manual/DatesRange";
import SitedeliveryAddTimeBlockModal from "../components/SitedeliveryAddTimeBlockModal";
import SitedeliveryCalendarDay from "../components/SitedeliveryCalendarDay";
import SitedeliveryCalendarWeekChangeButton from "../components/SitedeliveryCalendarWeekChangeButton";
import { SitedeliveryCalendarType } from "../utilities/sitedeliveryTypes";
import SitedeliveryCalendarTimeline from "src/root/routes/views/general-contractor/projects/calendar/sitedelivery/components/SitedeliveryCalendarTimelineNew";

export interface GCProjectCalendarSitedeliverySubBlocksUIProps {
  calendars: SitedeliveryCalendarType[];
  datesRange: DatesRangeType;
  onDatesRangeChange: (newDatesRange: DatesRangeType) => void;
  hiddenCalendarIds: string[];
  onCalendarHideClick: (calendarId: string) => void;
  onCalendarShowClick: (calendarId: string) => void;
  loading?: boolean;
  timezone?: string;
}

const GCProjectCalendarSitedeliverySubBlocksUI: React.FC<
  GCProjectCalendarSitedeliverySubBlocksUIProps
> = (props) => {
  const [deliveriesHidden, setDeliveriesHidden] = useState<boolean>(false);

  const calendars = props.calendars.map((calendar) => ({
    ...calendar,
    hidden: props.hiddenCalendarIds.includes(calendar.id),
  }));

  const shownCalendars: SitedeliveryCalendarType[] = [];
  const hiddenCalendars: { id: string; title: string }[] = [];

  calendars.forEach((calendar) => {
    if (
      props.hiddenCalendarIds.some((calendarId) => calendar.id === calendarId)
    ) {
      hiddenCalendars.push({ id: calendar.id, title: calendar.title });
    } else {
      shownCalendars.push(calendar);
    }
  });

  const goToPrevWeek = () => {
    const from = dayjs(props.datesRange.from).subtract(7, "days");
    const to = dayjs(props.datesRange.to).subtract(7, "days");
    props.onDatesRangeChange({
      from,
      to,
    });
  };

  const goToNextWeek = () => {
    const to = dayjs(props.datesRange.to).add(7, "days");
    const from = dayjs(props.datesRange.from).add(7, "days");
    props.onDatesRangeChange({
      from,
      to,
    });
  };

  const toggleHideDeliveries = () => {
    setDeliveriesHidden((prevState) => !prevState);
  };

  return (
    <BasicWrapper>
      <div
        className={clsx(
          "relative w-full h-full",
          props.loading && "cursor-wait pointer-events-none",
        )}
      >
        <div className="relative w-full h-full overflow-x-scroll">
          <div className="flex flex-row h-full w-auto gap-0.25 relative">
            <div
              style={{ height: "200%" }}
              className="flex flex-row h-full w-auto gap-0.25 relative"
            >
              <SitedeliveryCalendarTimeline paddingTop={`4.5rem`} />
              <SitedeliveryCalendarWeekChangeButton
                prev
                onClick={goToPrevWeek}
              />
              {datesFromRange(props.datesRange).map((date) => {
                return (
                  <SitedeliveryCalendarDay
                    key={date.weekday()}
                    date={date}
                    shownCalendars={shownCalendars.map((calendar) => ({
                      ...calendar,
                      events: calendar.events.filter(
                        (event) =>
                          !deliveriesHidden ||
                          (event.type !== `delivery` &&
                            (event.from.isSame(date, "day") ||
                              event.to.isSame(date, "day"))),
                      ),
                    }))}
                    hiddenCalendars={hiddenCalendars}
                    onCalendarHide={props.onCalendarHideClick}
                    onCalendarShow={props.onCalendarShowClick}
                  />
                );
              })}
              <SitedeliveryCalendarWeekChangeButton
                next
                onClick={goToNextWeek}
              />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0.5 gap-1 left-0 right-0 z-50 mx-auto w-min flex flex-row justify-center items-cneter">
          <SitedeliveryAddTimeBlockModal timezone={props.timezone} />
          <div className="bg-white rounded-full">
            <Button
              onClick={toggleHideDeliveries}
              label={`${deliveriesHidden ? "Show" : "Hide"} Deliveries`}
              secondary
              icon={deliveriesHidden ? IconEye : IconEyeOff}
            />
          </div>
        </div>
      </div>
    </BasicWrapper>
  );
};

export default GCProjectCalendarSitedeliverySubBlocksUI;
