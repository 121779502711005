import ProjectTable from "src/root/routes/views/general-contractor/hierarchy/component/ProjectTable";

import { useRef, useState } from "react";
import GCAddProject from "../../component/GCAddProject";
import { IconPlus } from "@tabler/icons";
import { message } from "antd";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import { useParams } from "react-router-dom";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import withDivisionId, { DivisionProps } from "../../utils/withDivisionId";

const GCDivisionProjects: React.FC<DivisionProps> = ({ divisionId }) => {
  const { userData } = useUserData();
  if (!userData.employee) throw new Error("userData not found");
  const [open, setOpen] = useState(false);
  const ref = useRef<DataScrollTableRef>(null);
  return (
    <div className={`w-full relative h-full p-2 bg-white rounded-2`}>
      {open && (
        <GCAddProject
          onCancel={() => setOpen(false)}
          toInsertWith={{ gc_division_id: divisionId }}
          onCreate={() => {
            message.success("Project Created Successfully");
            setOpen(false);
          }}
        />
      )}
      <ProjectTable
        showLevels={["business-unit", "office"]}
        ref={ref}
        topBarButtons={[
          {
            label: "Add New Project",
            icon: IconPlus,
            onClick: () => setOpen(true),
          },
        ]}
        excludedKeys={["gc"]}
        where={{
          general_contractors: {
            general_contractor_id: {
              _eq: userData.employee.general_contractor.id,
            },
          },
          gc_division_id: { _eq: divisionId },
        }}
      />
    </div>
  );
};
export default withDivisionId(GCDivisionProjects);
