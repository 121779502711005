import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import FloatingContactButton from "src/common/components/contact-button/FloatingContactButton";

import NavigationWrapper from "src/common/components/layouts/NavigationWrapper";
import { NavigationMenuProps } from "src/common/components/navigation-menu/NavigationMenu";
import { getLevelsCommonNavigationItems } from "../utils/getLevelsCommonNavigationItems";
import withOfficeId from "../utils/withOfficeId";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";

const GCOffice: React.FunctionComponent = () => {
  const navigate = useNavigate();
  const { userData } = useUserData();
  const emp = userData.employee;
  if (!emp) throw new Error("employee not found");
  const gc = emp.general_contractor;
  const menu: NavigationMenuProps = {
    navigationItems: getLevelsCommonNavigationItems(navigate, gc),
  };
  return (
    <div className="w-full h-full py-0.5 pr-0.5 bg-suplementary-1 z-0">
      <NavigationWrapper menu={menu}>
        <Outlet />
      </NavigationWrapper>
      <FloatingContactButton />
    </div>
  );
};

export default withOfficeId(GCOffice);
