import clsx from "clsx";
import { Bar } from "@ant-design/plots";
import { DashboardDataMapType } from "src/domain-features/siteorientation/utils/setDemographicDataMaps";
import randomHexColor from "src/common/functions/randomHexColor";
import ChartWrapperCard from "./ChartWrapperCard";

//https://ant-design-charts.antgroup.com/en/examples/statistics/bar/#bar
export const graphChartColorsList = [
  "#67B4FE",
  "#2353B6",
  "#142D60",
  "#000033",
  "#929DAE",
  "#D3E4F8",
  "#E9EFF6",
  "#FFAB77",
  "#FF6C6C",
  "#FFDE59",
];

const BarChartToShow: React.FC<{
  data: DashboardDataMapType;
  title: string;
  full?: boolean;
  height?: number;
}> = ({ data, title, full, height }) => {
  const sortedData = Array.from(data.values())
    .sort((a, b) => b.count - a.count)
    .map((p, i) => ({
      ...p,
      color: graphChartColorsList[i] || randomHexColor(),
    }));
  if (!sortedData.length) return null;
  return (
    <ChartWrapperCard
      title={title}
      className={clsx(
        full ? "w-full" : "w-1/2",
        // "h-[33vh] object-contain"
      )}
    >
      <Bar
        {...{
          data: sortedData,
          xField: "name",
          yField: "count",
          barWidthRatio: 0.9,
          autoFit: true,
          height: !height || height < 300 ? 300 : height,
          paddingRight: 5,
          style: { fill: "color" },
          // colorField: "type",
          label: {
            position: "right",
            style: {
              fill: "#000",
              textAlign: "start",
              fontSize: 17,
            },
          },
        }}
      />
    </ChartWrapperCard>
  );
};

export default BarChartToShow;
