import { IconArrowBackUp, IconSettings } from "@tabler/icons";
import React from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import Button from "src/common/components/general/Button";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import SCReportsDailyDate from "./components/SCReportsDailyDate";
import SCReportsDailySingle from "./components/SCReportsDailySingle";
import SCReportsDailyTable from "./components/SCReportsDailyTable";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import { Drawer } from "antd";

interface SCReportsDailyProps {}

const SCReportsDaily: React.FunctionComponent<SCReportsDailyProps> = (
  props,
) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { projectId, childSubId } = useParams();

  const subcontractorId = useSubcontractorId();
  const reportsSubId = projectId && childSubId ? childSubId : subcontractorId;
  const workLogId = searchParams.get("workLogId");
  const date = searchParams.get("date");

  const handleSettingsButtonClick = () => {
    navigate("settings");
  };

  const handleOpenReport = (params: { date: string } | { id: string }) => {
    console.log(params);
    if ("date" in params) {
      searchParams.set("date", params.date);
      setSearchParams(searchParams);
    } else if ("id" in params) {
      searchParams.set("workLogId", params.id);
      setSearchParams(searchParams);
    }
  };

  const goBack = () => {
    if (workLogId) searchParams.delete("workLogId");
    else searchParams.delete("date");
    setSearchParams(searchParams);
  };

  return (
    <BasicWrapper scrollable>
      {reportsSubId && (
        <>
          <div className="flex flex-row-reverse gap-1 justify-between">
            {reportsSubId === subcontractorId && (
              <Button
                onClick={() =>
                  projectId
                    ? navigate(`/sub/projects/${projectId}/daily-settings`)
                    : handleSettingsButtonClick()
                }
                label={`Settings`}
                icon={IconSettings}
              />
            )}
            {date && (
              <Button label="back" icon={IconArrowBackUp} onClick={goBack} />
            )}
          </div>
          {workLogId ? (
            <Drawer
              open={true}
              onClose={goBack}
              width="85%"
              title="Daily Work Log"
            >
              <SCReportsDailySingle
                workLogId={workLogId}
                subId={reportsSubId}
              />
            </Drawer>
          ) : date ? (
            <SCReportsDailyDate
              openReport={handleOpenReport}
              {...{ date, subcontractorId: reportsSubId, projectId }}
            />
          ) : (
            <SCReportsDailyTable
              openReport={handleOpenReport}
              {...{ subcontractorId: reportsSubId, projectId }}
            />
          )}
        </>
      )}
    </BasicWrapper>
  );
};

export default SCReportsDaily;
