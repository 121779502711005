const languageToCountryCode = (lang: string) => {
  switch (lang) {
    case "es":
      return "mx";
    case "pt":
      return "pt";
    case "it":
      return "it";
    case "fr":
      return "fr";
    case "en":
      return "us";
    case "zh_hans":
      return "cn";
    default:
      return null;
  }
};

export default languageToCountryCode;
