import { useState } from "react";
import { uploadFilesWithPath } from "./uploadFiles";
import uploadImageFiles from "./uploadImageFiles";
import { UploadResult } from "./uploadImage";
import * as uuid from "uuid";
import { message } from "antd";
export type UploadImageOrDocType = {
  id: string;
  name: string;
  type: string;
  url: string;
} & ({ is_image: false } | { is_image: true; imageResult: UploadResult });

const useUploadImageOrDoc = (docType: "image" | "other") => {
  const [uploadingDoc, setUploadingDoc] = useState(false);
  const uploadImagesOrDocsFromFiles = async (
    uploadFiles: { originFileObj: File; name: string; type: string }[],
    directoryName: string,
  ) => {
    const files = uploadFiles.map(({ name, originFileObj, type }) => ({
      name,
      type,
      file: originFileObj,
      directoryName,
    }));
    setUploadingDoc(true);
    try {
      if (docType === "image") {
        const [uploadedImages, failed] = await uploadImageFiles(files);
        if (failed.length) message.error("");

        return uploadedImages.map((img): UploadImageOrDocType => ({
          is_image: true,
          id: uuid.v4(),
          url: img.url,
          imageResult: {
            url: img.url,
            lg_url: img.lg_url,
            sm_url: img.sm_url,
            md_url: img.md_url,
            blurhash: img.blurhash,
          },
          name: img.name,
          type: img.type,
        }));
      } else {
        const [uploadedFiles] = await uploadFilesWithPath(files);
        return uploadedFiles.map((file): UploadImageOrDocType => ({
          is_image: false,
          ...file,
        }));
      }
    } finally {
      setUploadingDoc(false);
    }
  };
  return { uploadingDoc, uploadImagesOrDocsFromFiles };
};
export default useUploadImageOrDoc;
