import { Tabs, TabsProps } from "antd";
import * as React from "react";

interface CustomTabsProps
  extends Pick<TabsProps, "items" | "onChange" | "activeKey"> {}

const CustomTabsBase: React.FC<CustomTabsProps> = (props) => {
  return (
    <Tabs
      className="w-full"
      type={"card"}
      size={"large"}
      onChange={props.onChange}
      activeKey={props.activeKey}
      items={props.items}
    />
  );
};

export default CustomTabsBase;
