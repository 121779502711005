import * as React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import FloatingContactButton from "src/common/components/contact-button/FloatingContactButton";
import NavigationWrapper from "src/common/components/layouts/NavigationWrapper";
import { NavigationMenuProps } from "src/common/components/navigation-menu/NavigationMenu";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "./withCorporateAdminWrapper";
import { getLevelsCommonNavigationItems } from "../utils/getLevelsCommonNavigationItems";

export interface GCCorporateProps extends CorporateAdminWrapperProps {}

const GCCorporate: React.FunctionComponent<GCCorporateProps> = ({
  employee,
}) => {
  const navigate = useNavigate();

  const menu: NavigationMenuProps = {
    navigationItems: getLevelsCommonNavigationItems(
      navigate,
      employee.general_contractor,
    ),
  };

  return (
    <div className="w-full h-full py-0.5 pr-0.5 bg-suplementary-1">
      <NavigationWrapper menu={menu}>
        <Outlet />
      </NavigationWrapper>
      <FloatingContactButton />
    </div>
  );
};

export default withCorporateAdminWrapper(GCCorporate);
