import React from "react";
import { Modal } from "antd";
import OpenQrCode from "src/common/components/OpenQrCode";

const PtpQrCodeModal: React.FC<{
  visible: boolean;
  projectId: string;
  onClose: () => void;
}> = ({ visible, projectId, onClose }) => {
  return (
    <Modal
      open={visible}
      title={"Submit One-off PTPs without the mobile app"}
      onCancel={onClose}
      onOk={onClose}
    >
      <div className="flex flex-col">
        <p className="text-center text-1.5 mt-2">PTP QR Code</p>
        <OpenQrCode
          destinationUrl={`${document.location.origin}/ptp/${projectId}`}
          alwaysVisible
          downloadableFileName="ptp_qr"
        />
      </div>
    </Modal>
  );
};

export default PtpQrCodeModal;
