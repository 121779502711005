import React from "react";
import { InspectionInstanceType } from "../tables/InspectionInstanceTable";
import { Button, Dropdown, message } from "antd";
import {
  CopyOutlined,
  DeleteOutlined,
  WarningFilled,
  MoreOutlined,
} from "@ant-design/icons";
import useUpdateInspectionInstance from "../../utils/useUpdateInspectionInstance";
import { ConnectionHandler } from "relay-runtime";
import useDuplicateInspectionInstance from "../../utils/useDuplicateInspectionInstance";
import inspectionOptionsDeleteUpdater from "../../utils/inspectionOptionsDeleteUpdater";
import dayjs from "dayjs";
import BPopconfirm from "../../../../common/components/dialogs/BPopconfirm";

const InspectionInstanceDropdown: React.FC<{
  data: InspectionInstanceType;
  projectId: string;
  onOpenNew: (data: InspectionInstanceType) => void;
}> = ({ data, projectId, onOpenNew }) => {
  const [duplicate, isDuplicating] = useDuplicateInspectionInstance(projectId);
  const [updateInspection, isUpdating] = useUpdateInspectionInstance();
  return (
    <Dropdown
      placement={"bottomRight"}
      menu={{
        items: [
          {
            key: "delete",
            label: (
              <BPopconfirm
                cancelText={"No"}
                icon={<WarningFilled color="red" />}
                onConfirm={async (e) => {
                  //delete worker
                  e?.stopPropagation();
                  await updateInspection({
                    variables: {
                      id: data.pk,
                      _set: { deleted_at: dayjs().toISOString() },
                    },
                    optimisticResponse: {
                      update_inspection_instance_by_pk: {
                        id: data.id,
                        pk: data.pk,
                        deleted_at: dayjs().toISOString(),
                      },
                    },
                    updater: (store) => {
                      const conn = ConnectionHandler.getConnection(
                        store.getRoot(),
                        "InspectionInstanceTable_inspection_instance_connection",
                      );
                      inspectionOptionsDeleteUpdater(data.id, store);
                      if (conn) {
                        const edges = conn.getLinkedRecords("edges");
                        if (edges) {
                          const newEdges = edges.filter((edge) => {
                            const node = edge.getLinkedRecord("node");
                            return node?.getValue("id") !== data.id;
                          });
                          conn.setLinkedRecords(newEdges, "edges");
                        }
                      }
                    },
                  });
                  message.success("Deleted Inspection Successfully");
                }}
                placement="topRight"
                title={
                  <div className="w-24">
                    <div className="text-semantic-negative font-accent">
                      Delete this Inspection
                    </div>
                    <div>
                      Do you want to delete this Inspection? deleting this means
                      you agree to remove this Inspection from all Inspection
                      QRs present on your project. <br />
                      Are you sure?
                    </div>
                  </div>
                }
              >
                <Button
                  size="large"
                  danger
                  type="primary"
                  loading={isUpdating}
                  icon={<DeleteOutlined />}
                />
              </BPopconfirm>
            ),
          },
          {
            key: "duplicate",
            label: (
              <BPopconfirm
                cancelText={"No"}
                icon={<WarningFilled color="red" />}
                onConfirm={async (e) => {
                  //delete worker
                  e?.stopPropagation();
                  const { insert_inspection_instance } = await duplicate(data);
                  message.success("Copied Inspection");
                  if (
                    insert_inspection_instance &&
                    insert_inspection_instance.returning[0]
                  )
                    onOpenNew(insert_inspection_instance.returning[0]);
                }}
                placement="topRight"
                title={
                  <div className="w-24">
                    <div>
                      Do you want to create another similar inspection to this?{" "}
                      <br />
                      Are you sure?
                    </div>
                  </div>
                }
              >
                <Button
                  type="primary"
                  size="large"
                  className="bg-semantic-positive-green text-white"
                  icon={<CopyOutlined />}
                  loading={isDuplicating}
                />
              </BPopconfirm>
            ),
          },
        ],
      }}
      trigger={["click"]}
    >
      <Button
        type="primary"
        ghost
        shape="circle"
        icon={<MoreOutlined />}
        onClick={(e) => e.stopPropagation()}
      />
    </Dropdown>
  );
};
export default InspectionInstanceDropdown;
