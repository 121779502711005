import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import {
  DatePicker,
  Form,
  Input,
  message,
  Modal,
  notification,
  Upload,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { FC, useEffect, useState } from "react";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { uploadFilesWithPath } from "src/common/functions/upload-utility/uploadFiles";
import {
  UploadSafetyPlanModal_InsertMutation,
  UploadSafetyPlanModal_InsertMutation$data,
} from "src/common/types/generated/relay/UploadSafetyPlanModal_InsertMutation.graphql";
import {
  UploadSafetyPlanModal_UpdateMutation,
  UploadSafetyPlanModal_UpdateMutation$data,
} from "src/common/types/generated/relay/UploadSafetyPlanModal_UpdateMutation.graphql";
import * as uuid from "uuid";
import { safetyPlan } from "./SafetyPlanTable";

interface UploadSafetyPlanModalProps {
  visible: boolean;
  safetyPlan?: safetyPlan;
  subcontractorId: string | null;
  onClose: () => void;
  onSubmit: () => void;
  projectId?: string | null;
}

type FormValues = {
  numberOfSafetyPlan: number;
  name: string;
  revisionDate: dayjs.Dayjs;
  revisionNumber: string;
  upload: { fileList: Array<{ originFileObj: File; name: string }> };
};

const handleInsertSafetyPlanUpdater = (
  store: RecordSourceSelectorProxy<UploadSafetyPlanModal_InsertMutation$data>,
) => {
  const insertSafetyPlan = store.getRootField("insert_safety_plan_one");

  const conn1 = ConnectionHandler.getConnection(
    store.getRoot(),
    "SafetyPlanTable_safety_plan_connection",
  );
  const conn2 = ConnectionHandler.getConnection(
    store.getRoot(),
    "SubProjectMobilizationView_safety_plan_connection",
  );
  if (conn1 && insertSafetyPlan) {
    const edge = store.create(uuid.v4(), "edge");
    edge.setLinkedRecord(insertSafetyPlan, "node");
    ConnectionHandler.insertEdgeAfter(conn1, edge);
  }
  if (conn2 && insertSafetyPlan) {
    const edge = store.create(uuid.v4(), "edge");
    edge.setLinkedRecord(insertSafetyPlan, "node");
    ConnectionHandler.insertEdgeAfter(conn2, edge);
  }
};

const handleUpdateSafetyPlanUpdater: (
  store: RecordSourceSelectorProxy<UploadSafetyPlanModal_UpdateMutation$data>,
  id: string,
  formValues: FormValues,
  fileUrl?: string,
) => void = (store, id, formValues, fileUrl) => {
  const conn = ConnectionHandler.getConnection(
    store.getRoot(),
    "SafetyPlanTable_safety_plan_connection",
  );
  if (conn) {
    const edges = conn.getLinkedRecords("edges") || [];
    edges.forEach((edge) => {
      const node = edge.getLinkedRecord("node");
      if (!node) {
        return;
      }
      if (
        node.getValue("id") == id ||
        node.getValue("company_safety_plan_id") == id
      ) {
        node.setValue(formValues.name, "title");
        node.setValue(
          formValues.revisionDate
            ? dayjs(formValues.revisionDate).toISOString()
            : null,
          "revision_date",
        );
        node.setValue(fileUrl ? fileUrl : node.getValue("url"), "url");
        node.setValue(formValues.revisionNumber, "revision_number");
      }
    });
    conn.setLinkedRecords(edges, "edges");
  }
};

const UploadSafetyPlanModal: FC<UploadSafetyPlanModalProps> = ({
  visible,
  safetyPlan,
  subcontractorId,
  onClose,
  onSubmit,
  projectId,
}) => {
  const [loading, setLoading] = useState(false);
  const [insertSafetyPlan, isUploading] =
    useAsyncMutation<UploadSafetyPlanModal_InsertMutation>(
      graphql`
        mutation UploadSafetyPlanModal_InsertMutation(
          $object: safety_plan_insert_input!
        ) {
          insert_safety_plan_one(object: $object) {
            ...SafetyPlanFrag @relay(mask: false)
          }
        }
      `,
    );
  const [updateSafetyPlan] =
    useAsyncMutation<UploadSafetyPlanModal_UpdateMutation>(
      graphql`
        mutation UploadSafetyPlanModal_UpdateMutation(
          $_set: safety_plan_set_input
          $where: safety_plan_bool_exp!
        ) {
          update_safety_plan(_set: $_set, where: $where) {
            affected_rows
          }
        }
      `,
    );
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ name: safetyPlan?.title });
    if (safetyPlan) {
      form.setFieldsValue({ revisionDate: dayjs() });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      confirmLoading={loading}
      title={
        <div>
          {safetyPlan
            ? "Upload a Revised Safety Manual or Section"
            : "Upload Company Safety Manual Section"}
          <br />
        </div>
      }
      okText={safetyPlan ? "REPLACE" : "ADD"}
      onCancel={() => {
        form.resetFields();
        onClose();
      }}
      onOk={async () => {
        setLoading(true);
        await form
          .validateFields()
          .then(async (v: FormValues) => {
            console.log(v);
            let fileUrl: string | undefined = undefined;
            if (v.upload && v.upload.fileList && v.upload.fileList.length > 0) {
              const [frontFiles] = await uploadFilesWithPath(
                v.upload.fileList.map((o) => ({
                  file: o.originFileObj,
                  directoryName: "safety-plan",
                })),
              );
              fileUrl = frontFiles[0]?.url;
            }
            if (safetyPlan) {
              await updateSafetyPlan({
                variables: {
                  where: {
                    _or: [
                      { id: { _eq: safetyPlan.pk } },
                      { company_safety_plan_id: { _eq: safetyPlan.pk } },
                    ],
                  },
                  _set: {
                    title: v.name,
                    revision_date: v.revisionDate
                      ? dayjs(v.revisionDate).toISOString()
                      : null,
                    url: fileUrl ? fileUrl : safetyPlan.url,
                    revision_number: v.revisionNumber,
                  },
                },
                updater: (store) => {
                  handleUpdateSafetyPlanUpdater(
                    store,
                    safetyPlan.id,
                    v,
                    fileUrl,
                  );
                },
              }).then((d) => {
                if (d.update_safety_plan?.affected_rows) {
                  message.success("Updated on SiteForm");
                  form.resetFields();
                  onSubmit();
                }
              });
            } else {
              await insertSafetyPlan({
                variables: {
                  object: {
                    id: uuid.v4(),
                    project_id: projectId,
                    created_by_uid: auth.currentUser?.uid,
                    subcontractor_id: subcontractorId,
                    title: v.name,
                    revision_date: v.revisionDate
                      ? dayjs(v.revisionDate).toISOString()
                      : null,
                    url: fileUrl,
                    revision_number: v.revisionNumber,
                  },
                },
                updater: handleInsertSafetyPlanUpdater,
              }).then((d) => {
                if (d.insert_safety_plan_one?.pk) {
                  message.success("Uploaded To SiteForm");
                  form.resetFields();
                  onSubmit();
                } else {
                  message.error("Failed to upload");
                }
              });
            }
          })
          .catch((e) => {
            notification.error({
              message: "Upload error",
              description: e.message,
              duration: null,
            });
            console.log(e);
          });
        setLoading(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ numberOfSds: 1 }}
        title="Upload Form"
      >
        <Form.Item
          className="mt-2"
          label={
            safetyPlan
              ? "Upload a revised or updated copy of this file"
              : "Upload your entire Company Safety Plan or a Section of it"
          }
          name="upload"
          rules={[
            {
              required: safetyPlan ? false : true,
              message: "File is required",
            },
          ]}
        >
          <Upload.Dragger
            accept=".pdf"
            maxCount={1}
            iconRender={() => <AntCloudOutlined />}
            customRequest={() => true}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>Click or drag file here to upload</p>
          </Upload.Dragger>
        </Form.Item>

        <label className="text-grey">
          {safetyPlan
            ? "Replacing this file with a revision will update the Safety Manual and automatically link it to any project that it was attached to. Only upload one (1) file at a time"
            : "You can upload your entire plan or just a Section of it. Sections will allow you to add only the necessary information for each project. For example, you may need a Silica Plan for a specific project, this could be a separate Section added to that project. Only upload one (1) file at a time"}
        </label>

        <Form.Item
          className="mt-2"
          label="Name (what you and your team will see in the SiteForm mobile app)"
          name="name"
          rules={[{ required: true, message: "Title required" }]}
        >
          <Input placeholder="Section Name like “Silica Plan”"></Input>
        </Form.Item>

        <Form.Item className="mt-2" label="Revision Date" name="revisionDate">
          <DatePicker
            format="ll"
            disabledDate={(current) => current.isAfter(dayjs().endOf("day"))}
            onChange={async (e) => {}}
          />
        </Form.Item>

        <Form.Item
          className="mt-2"
          label="Reference / Revision / Version #"
          name="revisionNumber"
        >
          <Input placeholder="Enter Reference / Revision #"></Input>
        </Form.Item>
      </Form>
    </Modal>
  );
};
export default UploadSafetyPlanModal;
