//TODO fix this so it doesn't hide errors but informs

const separateFailedTasks = async <T,>(tasks: Promise<T>[]) => {
  const waitres = await Promise.allSettled(tasks);
  const res: T[] = [];
  const failed: unknown[] = [];
  for (let i = 0; i < waitres.length; i++) {
    const task = waitres[i];
    if (task.status === "fulfilled") {
      res.push(task.value);
    } else if (task.status === "rejected") {
      failed.push({ reason: task.reason, index: i });
      console.log("upload failed", task.reason);
    }
  }
  return [res, failed] as const;
};
export default separateFailedTasks;
