import React, { FC } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ProjectSubcontractorTasksTable from "src/common/components/tables/ProjectSubcontractorTasksTable";
import { auth } from "src/common/functions/firebase";
import useAuthUser from "src/common/hooks/useAuthUser";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";

interface SCProjectJHAsProps {
  projectId: string;
  subcontractorId: string;
  refresh: () => void;
  data: SCProjectQuery$data;
}
const SCProjectJHAs: FC<SCProjectJHAsProps> = ({
  projectId,
  refresh,
  subcontractorId,
  data,
}) => {
  const authUser = useAuthUser();
  const projectData = data.project_connection.edges[0].node;
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const isNotTurnersProject = projectData.general_contractor.pk !== turnerGCId;
  const projectsOfSub = data.project_subcontractor_connection.edges.map(
    (proj) => ({ id: proj.node.project.pk, name: proj.node.project.name }),
  );
  return (
    <BasicWrapper scrollable>
      <ProjectSubcontractorTasksTable
        {...{
          projectId,
          refresh,
          subcontractorId,
          isNotTurnersProject,
          projectsOfSub,
          taskEdges: data.task_connection.edges,
          ppeOptions: data.ppe_type_connection.edges,
          permitOptions: data.permit_type_connection.edges,
        }}
      />
    </BasicWrapper>
  );
};
export default SCProjectJHAs;
