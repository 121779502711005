import React, { FC } from "react";
import DailyWorkLog from "src/root/routes/views/DailyWorkLog";

interface SCReportsDailySingleProps {
  workLogId: string;
  subId: string;
}

const SCReportsDailySingle: FC<SCReportsDailySingleProps> = (props) => {
  return <DailyWorkLog {...props} />;
};

export default SCReportsDailySingle;
