import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import { FC, forwardRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { SFRequestedJHAsQuery } from "src/common/types/generated/relay/SFRequestedJHAsQuery.graphql";
import { SFRequestedJHAs_add_task_request$data } from "src/common/types/generated/relay/SFRequestedJHAs_add_task_request.graphql";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

export type ColumnKeys =
  | "subcontractor_name"
  | "gc_name"
  | "jhaNum"
  | "project_name"
  | "requested_by"
  | "requested_at";
const CONNECTION_NAME = "add_task_request_connection";
type DConnection =
  SFRequestedJHAs_add_task_request$data[typeof CONNECTION_NAME];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  SFRequestedJHAsQuery,
  { worker_id: string | unknown; project_id: string | unknown }
> & {
  onRowClickPK: (pk: string) => void;
  searchString?: string;
  extraColumns?: DataScrollTableProps<
    DConnection,
    ColumnKeys,
    SFRequestedJHAsQuery
  >["columns"];
};
const SFRequestedJHAsTable = forwardRef<DataScrollTableRef, Props>(
  (
    {
      title = "Requests for New JHAs",
      onRowClickPK,
      searchString,
      where,
      customFilters,
      ...props
    },
    ref,
  ) => {
    return (
      <DataScrollTable<DConnection, ColumnKeys, SFRequestedJHAsQuery>
        {...props}
        ref={ref}
        title={title}
        onRowClick={({ pk }) => onRowClickPK?.(pk)}
        where={where}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allSubcontractorsToAddConnection"}
        newCustomTableLook={true}
        customFilters={customFilters}
        columns={[
          {
            title: "No. of JHAs",
            key: "jhaNum",
            width: 70,
            dataIndex: ["number_of_task_requested"],
            sortable: true,
            render: (a) => <div className="text-center">{a}</div>,
          },
          {
            title: "Project Name",
            key: "project_name",
            size: "md",
            dataIndex: ["project", "name"],
            searchDataIndex: ["project", "name"],
            searchValue: searchString,
            sortable: true,
          },
          {
            title: "GC Name",
            key: "gc_name",
            size: "md",
            dataIndex: ["project", "general_contractor", "name"],
            searchDataIndex: ["project", "general_contractor", "name"],
            sortable: true,
          },
          {
            title: "Subcontractor Name",
            key: "subcontractor_name",
            size: "md",
            dataIndex: ["subcontractor", "name"],
            searchDataIndex: ["subcontractor", "name"],
            sortable: true,
          },
          {
            title: "Requested By",
            key: "requested_by",
            size: "md",
            dataIndex: ["requested_by_user", "name"],
            searchDataIndex: ["requested_by_user", "name"],
            sortable: true,
          },
          {
            title: "Requested on",
            key: "requested_at",
            size: "md",
            sortable: true,
            defaultSortOrder: "desc",
            dataIndex: ["requested_at"],
            render: (a) => dayjs(a).format("LLL"),
          },
        ]}
        queryNode={graphql`
          query SFRequestedJHAsQuery(
            $first: Int!
            $after: String
            $where: add_task_request_bool_exp!
            $order_by: [add_task_request_order_by!]!
          ) {
            ...SFRequestedJHAs_add_task_request
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...SFRequestedJHAs_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment SFRequestedJHAs_total on query_root
          @argumentDefinitions(where: { type: "add_task_request_bool_exp!" })
          @refetchable(queryName: "SFRequestedJHAsTotalRefetchableQuery") {
            allJHAsToAddConnection: add_task_request_connection(where: $where) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment SFRequestedJHAs_add_task_request on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "add_task_request_bool_exp!" }
            order_by: { type: "[add_task_request_order_by!]!" }
          )
          @refetchable(queryName: "SFRequestedJHAsRefetchableQuery") {
            add_task_request_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "SFRequestedJHAs_add_task_request_connection"
                filters: ["where", "order_by"]
              ) {
              edges {
                node {
                  pk: id @__clientField(handle: "pk")
                  requested_at
                  number_of_task_requested
                  subcontractor {
                    name
                  }
                  project {
                    name
                    general_contractor {
                      name
                    }
                  }
                  requested_by_user {
                    name
                  }
                  attached_files {
                    id
                  }
                }
              }
            }
          }
        `}
      />
    );
  },
);

const SFRequestedJHAs: FC = () => {
  const [requestStatus, setRequestStatus] = useState<
    "pending" | "processed" | "rejected"
  >("pending");
  const navigate = useNavigate();

  return (
    <BasicWrapper scrollable={false}>
      <SFRequestedJHAsTable
        title={
          requestStatus === "pending"
            ? "Requests for New JHAs"
            : requestStatus === "processed"
            ? "Processed JHA Requests"
            : "Rejected JHA Requests"
        }
        where={
          requestStatus === "pending"
            ? {
                attached_files: {
                  jha_creation_request_verified_at: { _is_null: true },
                },
                rejected_at: { _is_null: true },
              }
            : requestStatus === "processed"
            ? {
                attached_files: {
                  jha_creation_request_verified_at: { _is_null: false },
                },
                rejected_at: { _is_null: true },
              }
            : {
                rejected_at: { _is_null: false },
              }
        }
        customFilters={[
          {
            type: "radio",
            title: "Status",
            removeFilter: () => {
              setRequestStatus("pending");
            },
            options: [
              {
                option: "Pending",
                onClick: () => {
                  setRequestStatus("pending");
                },
              },
              {
                option: "Rejected",
                onClick: () => {
                  setRequestStatus("rejected");
                },
              },
              {
                option: "Processed ",
                onClick: () => {
                  setRequestStatus("processed");
                },
              },
            ],
          },
        ]}
        onRowClickPK={(pk) => navigate(`/sfe/verify/jha/${pk}`)}
      />
    </BasicWrapper>
  );
};
export default withCustomSuspense(SFRequestedJHAs);
