import React, { useContext, useState } from "react";
import SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouUI from "./SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouUI";
import { SiteOrientationStepProps } from "../../../../../../../utils/siteOrientationTypes";
import dayjs from "dayjs";

import { useParams, useSearchParams } from "react-router-dom";
import {
  CompanyType,
  OacUserType,
  UserType,
  WorkersObjType,
} from "src/domain-features/siteorientation/utils/siteOrientationTypes";
import SiteOrientationOrienteeContext from "../../../../../../../utils/siteOrientationOrienteeContext";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import useGetSiteOrientationInPersonWorkerList from "src/domain-features/siteorientation/utils/useGetSiteOrientationInPersonWorkerList";
import useSiteOrientationOrientee from "../../../utils/siteOrientationHook";

export interface SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouProps
  extends SiteOrientationStepProps {
  onUserIsNew: (placeholderUser?: UserType) => void;
  onUserIdSelected: () => void;
}

const SiteOrientationInPersonWorkerRegistrationStepsWhoAreYou: React.FC<
  SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouProps
> = (props) => {
  const workerContext = useSiteOrientationOrientee();
  const projectId = useParams().projectId;
  if (!projectId) {
    throw new Error(
      "Project Id is missing at SiteOrientationInPersonWorkerRegistrationStepsWhoAreYou",
    );
  }

  const [selectedCompany, setSelectedCompany] = useState<CompanyType | null>(
    null,
  );
  const data = useGetSiteOrientationInPersonWorkerList({
    projectId,
    projectEmployeeWhere: {
      project: { orientation_project_id: { _eq: projectId } },
      user_orientation: {
        selected: { _eq: true },
        in_person_orientated_at: { _is_null: true },
        completed_at: { _is_null: true },
      },
    },
    projectWorkerWhere: {
      project: { orientation_project_id: { _eq: projectId } },
      subcontractor_worker: {},
      user: { role: { _eq: "worker" } },
      user_orientation: {
        selected: { _eq: true },
        // #edited so as to only completed non-completed ones here
        in_person_orientated_at: { _is_null: true },
        completed_at: { _is_null: true },
      },
    },
  });
  const project = data.project_connection.edges[0]?.node;
  if (!project) {
    throw new Error("Project does not exist");
  }
  const [searchParams] = useSearchParams();
  const completionType = searchParams.get("completionType");
  const projectHasUniversal =
    data.universalOrientations.edges.length > 0 &&
    project.agc_universal_orientation;
  const workers: WorkersObjType = {};
  const allCorporateCompleted: {
    [key: string]: boolean;
  } = {};
  data.corporateOrientation.edges.forEach((o) => {
    const showLate =
      o.node.project_orientations[0] &&
      (o.node.project_orientations[0].hide_but_give_credit ||
        (!o.node.project_orientations[0].play_during_in_person &&
          completionType === "inpersonComplete"));
    o.node.orientation_results.forEach((or) => {
      if (or.user_id && or.completed_at) {
        const moreThan1HourOldORShown = showLate
          ? dayjs(or.completed_at).isSameOrBefore(dayjs().subtract(1, "hour"))
          : true;
        //true as we the corporates palyed in siteform are still valid
        const notExpired = dayjs(or.completed_at)
          .add(o.node.duration_valid, "M")
          .isSameOrAfter(dayjs());
        //above we are using or.completed_at in dayjs so if it is undefined it will take current time but
        //doesn't matter as we'll check whether the or.completed_at is present before using these  values
        if (
          allCorporateCompleted[or.user_id] === null ||
          allCorporateCompleted[or.user_id] === undefined
        )
          allCorporateCompleted[or.user_id] = or.completed_at
            ? moreThan1HourOldORShown && notExpired
            : or.status === "completed";
        else
          allCorporateCompleted[or.user_id] =
            allCorporateCompleted[or.user_id] &&
            (or.completed_at
              ? moreThan1HourOldORShown && notExpired
              : or.status === "completed");
      }
    });
  });
  (data.project_worker_connection.edges || []).forEach((v) => {
    if (!workers[v.node.worker_id]) {
      workers[v.node.worker_id] = {
        userType: "worker",
        id: v.node.worker_id,
        name: {
          old: v?.node?.user?.name,
        },
        title: v.node.worker.worker_title
          ? {
              name: v.node.worker.worker_title.translation.en,
              id: v.node.worker.worker_title_id!,
            }
          : undefined,
        projectsToRegister: {},
        corporateOrientationPassed:
          !projectHasUniversal &&
          !!(data.corporateOrientation.edges.length > 0
            ? allCorporateCompleted[v.node.worker_id] ||
              (v.node.user_orientation?.orientation_provided_by_user_id &&
                v.node.user_orientation?.orientated_at)
            : false),
        universalCompleted: projectHasUniversal
          ? v.node.user!.universal_orientations.length > 0 &&
            dayjs(
              v.node.user!.universal_orientations[0].universal_orientated_at,
            ).isBefore(dayjs().subtract(1, "hour"))
          : false,
        company: {
          // we're only calling workers who are under a subcontractor for this project. so this relation will be present
          title: v.node.worker.subcontractor?.name ?? "",
          id: v.node.worker.subcontractor_id ?? "",
          companyWorkerTitles: [],
        },
      };
    }
  });
  const gcUsers: {
    [userId: string]: OacUserType;
  } = {};
  data.project_employee_connection.edges.forEach((pe) => {
    if (!gcUsers[pe.node.employee_id]) {
      gcUsers[pe.node.employee_id] = {
        userType: "oacUser",
        name: {
          old: pe.node.employee.user.name,
        },
        id: pe.node.employee_id,
        ...(pe.node.employee.oac_company
          ? {
              company: {
                title: pe.node.employee.oac_company.name,
                id: pe.node.employee.oac_company_id!,
                companyWorkerTitles: [],
              },
              title: pe.node.employee.oac_title
                ? {
                    name: pe.node.employee.oac_title.name,
                    id: pe.node.employee.oac_title_id!,
                  }
                : undefined,
            }
          : {
              company: {
                title:
                  pe.node.employee.general_contractor.name +
                  " - Office & Field Management Staff Only",
                id: pe.node.employee.general_contractor_id,
                companyWorkerTitles: [],
              },
              title: pe.node.employee.employee_title
                ? {
                    name: pe.node.employee.employee_title.name.en,
                    id: pe.node.employee.title_id!,
                  }
                : undefined,
            }),
        corporateOrientationPassed:
          !projectHasUniversal &&
          !!(data.corporateOrientation.edges.length > 0
            ? allCorporateCompleted[pe.node.employee_id] ||
              (pe.node.user_orientation?.orientation_provided_by_user_id &&
                pe.node.user_orientation?.orientated_at)
            : false),
        universalCompleted: !!(projectHasUniversal
          ? pe.node.employee.user.universal_orientations.length &&
            dayjs(
              pe.node.employee.user.universal_orientations[0]
                .universal_orientated_at,
            ).isBefore(dayjs().subtract(1, "hour"))
          : false),
      };
    }
  });

  const onUserIsNew = () => {
    props.onUserIsNew(
      selectedCompany
        ? {
            company: selectedCompany,
          }
        : undefined,
    );
    props.onNext();
  };

  const onUserSelect = (userId: string) => {
    props.onUserIdSelected();
    workerContext.updateSelectedUserId(userId);

    props.onNext();
  };
  // console.log(selectedCompany, workers);
  return (
    <SiteFeatureStepLayout onBackButtonClick={props.onBack}>
      <SiteOrientationInPersonWorkerRegistrationStepsWhoAreYouUI
        selectedCompany={selectedCompany}
        onCompanySelect={setSelectedCompany}
        workers={workers}
        oacUsers={gcUsers}
        onWorkerIsNew={onUserIsNew}
        onUserSelect={onUserSelect}
      />
    </SiteFeatureStepLayout>
  );
};

export default SiteOrientationInPersonWorkerRegistrationStepsWhoAreYou;
