import { useInsertProjectSubcontractorOnsitePeriodOneMutation } from "src/common/types/generated/apollo/graphQLTypes";

const useMarkSubOnsite = () => {
  const [insertProjectSubcontractorOnsitePeriod] =
    useInsertProjectSubcontractorOnsitePeriodOneMutation();

  return {
    markSubOnsite: ({
      projectId,
      subcontractorId,
      isSubOnsite,
      isMarkOnsiteEnabled,
    }: {
      projectId: string;
      subcontractorId?: string;
      isSubOnsite: boolean;
      isMarkOnsiteEnabled: boolean;
    }) => {
      if (!subcontractorId || isSubOnsite || !isMarkOnsiteEnabled) {
        return;
      }

      return insertProjectSubcontractorOnsitePeriod({
        variables: {
          object: {
            subcontractor_id: subcontractorId,
            project_id: projectId,
          },
        },
      });
    },
  };
};

export default useMarkSubOnsite;
