import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

import GCReportsType from "src/common/types/manual/GCReportsType";
import Covid19Reports from "../routes/covid19/components/GCProjectReportsCovid";
import DailyReports from "../routes/daily/components/GCProjectReportsDaily";
import PermitReports from "../routes/permits/components/GCProjectReportsPermits";
import PreTaskPlans from "../routes/preTaskPlans/components/GCProjectReportsPTPs";
import GCProjectReportDownload from "../routes/reportsDownload/components/GCProjectReportsDownload";
import ToolboxTalkReports from "../routes/toolboxtalks/components/GCProjectReportsTBTs";
import GCProjectReportManagement from "../routes/report-settings/gcProjectCalendarReports/GCProjectReportManagement";
import GCProjectReportVisitors from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/visitor/components/GCProjectReportsVisitors";

interface GCReportsProps {
  reportType: string;
}

const GCReports: React.FunctionComponent<GCReportsProps> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleTableRowClick = (params: object) => {
    setSearchParams(Object.entries(params));
  };
  const projectId = useParams().projectId;

  if (!projectId) {
    return <h1> No project... </h1>;
  }

  const GCReportsProps: GCReportsType = {
    openRow: handleTableRowClick,
    projectId: projectId,
    ...Object.fromEntries(searchParams),
  };

  switch (props.reportType) {
    case "daily":
      return <DailyReports {...GCReportsProps} />;
    case "pretaskplans":
      return <PreTaskPlans {...GCReportsProps} />;
    case "permits":
      return <PermitReports {...GCReportsProps} />;
    case "toolboxtalks":
      return <ToolboxTalkReports {...GCReportsProps} />;
    case "visitors":
      return <GCProjectReportVisitors {...GCReportsProps} />;
    case "reports-download":
      return <GCProjectReportDownload projectId={projectId} />;
    case "covid19":
      return <Covid19Reports {...GCReportsProps} />;

    default:
      if (props.reportType === "settings" || props.reportType === "performance")
        return <GCProjectReportManagement type={props.reportType} />;
      return null;
  }
};

export default GCReports;
