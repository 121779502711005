import { Drawer } from "antd";
import React, { FC } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import { useSubcontractorId } from "src/common/components/SubcontractorContext";
import ManageToolboxTalks from "src/domain-features/sitesafety/components/toolbox-talks/ManageToolboxTalks";
import ToolboxTalkTable from "src/domain-features/sitesafety/components/toolbox-talks/ToolboxTalkTable";
import SCToolboxTalk from "src/domain-features/sitesafety/entryRoutes/scToolboxTalks/components/SCToolboxTalk";

const SCProjectToolboxTalks: FC<{ type: "manage" | "completed" }> = ({
  type,
}) => {
  const { projectId, childSubId } = useParams();

  const subcontractorId = useSubcontractorId();
  const reportsSubId =
    type === "completed" && projectId && childSubId
      ? childSubId
      : subcontractorId;
  const [searchParams, setSearchParams] = useSearchParams();
  const toolboxTalkId = searchParams.get("toolboxTalkId");
  if (!reportsSubId || !subcontractorId) {
    return <></>;
  }

  return (
    <>
      {toolboxTalkId ? (
        <Drawer
          open={true}
          width="85%"
          title="Toolbox Talk"
          onClose={() => {
            searchParams.delete("toolboxTalkId");
            setSearchParams(searchParams);
          }}
        >
          <SCToolboxTalk
            {...{ subcontractorId: reportsSubId, toolboxTalkId }}
          />
        </Drawer>
      ) : (
        <div className={`flex-grow bg-white overflow-auto py-1 w-full`}>
          <div className="flex w-full h-full">
            {type === "completed" && (
              <ToolboxTalkTable
                showDateRangeFilter={true}
                excludedKeys={["subcontractor", "project"]}
                where={{
                  subcontractor_id: { _eq: reportsSubId },
                  project_id: { _eq: projectId },
                }}
                onRowClick={(pk) => setSearchParams({ toolboxTalkId: pk })}
              />
            )}
            {type === "manage" && (
              <ManageToolboxTalks subId={reportsSubId} projectId={projectId} />
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default SCProjectToolboxTalks;
