export type TranslationValue = {
  [key in "en" | "es" | "pt" | "it" | "fr" | "zh_hans"]: string;
};

export const translationStrings = {
  checklistItem: {
    en: "Checklist Item",
    es: "Elemento de la lista de verificación",
    pt: "Item da lista de verificação",
    fr: "Élément de la liste de contrôle",
    it: "Elemento della lista di controllo",
    zh_hans: "检查清单项目",
  },
  takePhotoOfSpecificItem: {
    en: "Take photos of the specific item or part related to this Checklist Item.",
    es: "Toma fotos del artículo o parte específica relacionada con este elemento de la lista de verificación.",
    pt: "Tire fotos do item ou parte específica relacionada a este item da lista de verificação.",
    fr: "Prenez des photos de l'article ou de la pièce spécifique liée à cet élément de la liste de contrôle.",
    it: "Scatta foto dell'articolo o parte specifica relativa a questo elemento della lista di controllo.",
    zh_hans: "拍摄与此检查项目相关的特定物品或部件的照片。",
  },
  selectJobLocation: {
    en: "Select Job Location",
    es: "Seleccionar ubicación de trabajo",
    pt: "Selecionar local de trabalho",
    fr: "Sélectionner le lieu de travail",
    it: "Seleziona la posizione di lavoro",
    zh_hans: "选择工作地点",
  },
  chooseYourLanguageToBeginOrientation: {
    en: "Choose your language to begin orientation",
    es: "Elija su idioma para comenzar la orientación",
    pt: "Escolha seu idioma para iniciar a orientação",
    fr: "Choisissez votre langue pour commencer l'orientation",
    it: "Scegli la tua lingua per iniziare l'orientamento",
    zh_hans: "选择你的语言开始入职培训",
  },
  nameAsShownOnYourID: {
    en: "Name as shown on your ID",
    es: "Nombre como aparece en su identificación",
    pt: "Nome conforme consta na sua identificação",
    it: "Nome come indicato sul tuo documento d'identità",
    fr: "Nom tel qu'il apparaît sur votre pièce d'identité",
    zh_hans: "身份证上的名字",
  },
  signatureImageNotFoundMessage: {
    en: "Signature Image not found. Please go back and try again",
    es: "Imagen de firma no encontrada. Por favor, regrese e intente nuevamente",
    pt: "Imagem de assinatura não encontrada. Por favor, volte e tente novamente",
    fr: "Image de signature non trouvée. Veuillez revenir et réessayer",
    it: "Immagine della firma non trovata. Torna indietro e riprova",
    zh_hans: "未找到签名图像，请返回并重试",
  },
  selectedInspectionNotFoundPleaseGoBackAndTryAgain: {
    en: "Selected Inspection not Found, please go back and try again",
    es: "Inspección seleccionada no encontrada, por favor regrese e intente nuevamente",
    pt: "Inspeção selecionada não encontrada, por favor, volte e tente novamente",
    fr: "Inspection sélectionnée non trouvée, veuillez revenir et réessayer",
    it: "Ispezione selezionata non trovata, torna indietro e riprova",
    zh_hans: "未找到选定的检查，请返回并重试",
  },
  inspect: {
    en: "Inspect",
    es: "Inspeccionar",
    pt: "Inspecionar",
    fr: "Inspecter",
    it: "Ispezionare",
    zh_hans: "检查",
  },
  addTheFirstIDModelNumberToStart: {
    en: "Add the First ID/Model Number to Start",
    es: "Agrega el primer número de ID/Modelo para comenzar",
    pt: "Adicione o primeiro número de ID/Modelo para começar",
    it: "Aggiungi il primo numero di ID/Modello per iniziare",
    fr: "Ajoutez le premier numéro d'ID/Modèle pour commencer",
    zh_hans: "添加第一个ID/型号以开始",
  },
  enterModelOrIdNumber: {
    en: "Enter Model or ID #",
    es: "Ingrese el número de modelo o ID",
    pt: "Digite o número do modelo ou ID",
    it: "Inserisci il numero di modello o ID",
    fr: "Entrez le numéro de modèle ou d'ID",
    zh_hans: "输入型号或ID编号",
  },
  addAnotherIdModelNumber: {
    en: "Add Another ID/Model Number",
    es: "Agregar otro número de ID/Modelo",
    pt: "Adicionar outro número de ID/Modelo",
    it: "Aggiungi un altro numero di ID/Modello",
    fr: "Ajouter un autre numéro d'ID/Modèle",
    zh_hans: "添加另一个ID/型号",
  },
  performMultipleInspections: {
    en: "Perform Multiple Inspections",
    es: "Realizar Inspecciones Múltiples",
    pt: "Realizar Inspeções Múltiplas",
    it: "Effettuare Ispezioni Multiple",
    fr: "Effectuer des Inspections Multiples",
    zh_hans: "执行多次检查",
  },
  seeChecklistItems: {
    en: "See Checklist Items",
    es: "Ver Elementos de la Lista de Verificación",
    pt: "Ver Itens da Lista de Verificação",
    it: "Visualizza Elementi della Checklist",
    fr: "Voir les Éléments de la Liste de Contrôle",
    zh_hans: "查看清单项目",
  },
  hideChecklistItems: {
    en: "Hide Checklist Items",
    es: "Ocultar Elementos de la Lista de Verificación",
    pt: "Esconder Itens da Lista de Verificação",
    it: "Nascondi Elementi della Checklist",
    fr: "Masquer les Éléments de la Liste de Contrôle",
    zh_hans: "隐藏清单项目",
  },
  result: {
    en: "Result",
    es: "Resultado",
    pt: "Resultado",
    it: "Risultato",
    fr: "Résultat",
    zh_hans: "结果",
  },
  date: {
    en: "Date",
    es: "Fecha",
    pt: "Data",
    it: "Data",
    fr: "Date",
    zh_hans: "日期",
  },
  completedBy: {
    en: "Completed By",
    es: "Completado por",
    pt: "Completado por",
    it: "Completato da",
    fr: "Complété par",
    zh_hans: "由...完成",
  },
  inspectionDetails: {
    en: "Inspection Details",
    es: "Detalles de la Inspección",
    pt: "Detalhes da Inspeção",
    it: "Dettagli dell'Ispezione",
    fr: "Détails de l'Inspection",
    zh_hans: "检查详情",
  },
  completeInspections: {
    en: "Complete Inspection(s)",
    es: "Inspección(es) Completada(s)",
    pt: "Inspeção(ões) Concluída(s)",
    it: "Ispezione(i) Completata(e)",
    fr: "Inspection(s) Complète(s)",
    zh_hans: "完成检查",
  },
  inspectionMultipleItems: {
    en: "Inspect multiple items",
    es: "Inspeccionar varios elementos",
    pt: "Inspecionar vários itens",
    it: "Ispeziona più elementi",
    fr: "Inspecter plusieurs éléments",
    zh_hans: "检查多个项目",
  },
  inspectionResults: {
    en: "Inspection Results",
    es: "Resultados de la Inspección",
    pt: "Resultados da Inspeção",
    it: "Risultati dell'Ispezione",
    fr: "Résultats de l'Inspection",
    zh_hans: "检查结果",
  },
  selectToViewInspectionResults: {
    en: "Select to view inspection results",
    es: "Seleccione para ver los resultados de la inspección",
    pt: "Selecionar para ver os resultados da inspeção",
    it: "Seleziona per visualizzare i risultati dell'ispezione",
    fr: "Sélectionnez pour voir les résultats de l'inspection",
    zh_hans: "选择查看检查结果",
  },
  selectInspection: {
    en: "Select Inspection",
    es: "Seleccionar Inspección",
    pt: "Selecionar Inspeção",
    it: "Seleziona Ispezione",
    fr: "Sélectionner l'Inspection",
    zh_hans: "选择检查",
  },
  doAnInspection: {
    en: "Do an inspection",
    es: "Realizar una inspección",
    pt: "Realizar uma inspeção",
    it: "Effettua un'ispezione",
    fr: "Faire une inspection",
    zh_hans: "进行检查",
  },
  viewInspectionResults: {
    en: "View Inspection Results",
    es: "Ver Resultados de la Inspección",
    pt: "Ver Resultados da Inspeção",
    it: "Visualizza i Risultati dell'Ispezione",
    fr: "Voir les Résultats de l'Inspection",
    zh_hans: "查看检查结果",
  },
  fail: {
    en: "Fail",
    es: "No pasar",
    pt: "Falhar",
    it: "Fallire",
    fr: "Échouer",
    zh_hans: "未通过",
  },
  pass: {
    en: "Pass",
    es: "Pasar",
    pt: "Passar",
    it: "Passare",
    fr: "Passer",
    zh_hans: "通过",
  },
  addPhotoOfYourDrugtest: {
    en: "Add photos of your Drug Test",
    es: "Agrega fotos de tu prueba de drogas",
    pt: "Adicione fotos do seu teste de drogas",
    it: "Aggiungi foto del tuo test antidroga",
    fr: "Ajoutez des photos de votre test de dépistage de drogue",
    zh_hans: "添加您的药物测试照片",
  },
  identificationNameOrNumber: {
    en: "Identification Name or Number",
    es: "Nombre o Número de Identificación",
    pt: "Nome ou Número de Identificação",
    it: "Nome o Numero di Identificazione",
    fr: "Nom ou Numéro d'Identification",
    zh_hans: "识别名称或号码",
  },
  locationOrModelNumber: {
    en: "Location or Model Number",
    es: "Ubicación o Número de Modelo",
    pt: "Localização ou Número do Modelo",
    it: "Posizione o Numero di Modello",
    fr: "Emplacement ou Numéro de Modèle",
    zh_hans: "位置或型号",
  },
  modelNumber: {
    en: "Model Number",
    es: "Número de Modelo",
    pt: "Número do Modelo",
    it: "Numero di Modello",
    fr: "Numéro de Modèle",
    zh_hans: "型号",
  },
  whatIsBeingInspected: {
    en: "What is being inspected?",
    es: "¿Qué se está inspeccionando?",
    pt: "O que está sendo inspecionado?",
    it: "Cosa viene ispezionata?",
    fr: "Qu'est-ce qui est inspecté ?",
    zh_hans: "正在检查什么？",
  },
  whatActionDidYouTake: {
    en: "What action did you take?",
    es: "¿Qué acción tomaste?",
    pt: "Que ação você tomou?",
    it: "Quale azione hai intrapreso?",
    fr: "Quelle action avez-vous prise ?",
    zh_hans: "你采取了什么行动？",
  },
  addImages: {
    en: "Add Images",
    es: "Agregar Imágenes",
    pt: "Adicionar Imagens",
    it: "Aggiungi Immagini",
    fr: "Ajouter des Images",
    zh_hans: "添加图片",
  },

  TypeAnyAdditionalInformationHere: {
    en: "Type additional info here",
    es: "Escribe información adicional aquí",
    pt: "Digite informações adicionais aqui",
    it: "Inserisci informazioni aggiuntive qui",
    fr: "Entrez des informations supplémentaires ici",
    zh_hans: "在这里输入附加信息",
  },
  additionalInformation: {
    en: "Additional Information",
    es: "Información Adicional",
    pt: "Informações Adicionais",
    it: "Informazioni Aggiuntive",
    fr: "Informations Additionnelles",
    zh_hans: "附加信息",
  },
  enterModelNumber: {
    en: "Enter location, model, serial, or ID #",
    es: "Ingrese la ubicación, modelo, número de serie o ID #",
    pt: "Digite a localização, modelo, número de série ou ID #",
    it: "Inserisci la posizione, il modello, il numero di serie o l'ID #",
    fr: "Entrez l'emplacement, le modèle, le numéro de série ou l'ID #",
    zh_hans: "输入位置、型号、序列号或ID号码",
  },
  confirmed: {
    en: "Confirmed",
    es: "Confirmado",
    pt: "Confirmado",
    it: "Confermato",
    fr: "Confirmé",
    zh_hans: "已确认",
  },
  pleaseSelectAnInspection: {
    en: "Please select an inspection",
    es: "Por favor, seleccione una inspección",
    pt: "Por favor, selecione uma inspeção",
    it: "Si prega di selezionare un'ispezione",
    fr: "Veuillez sélectionner une inspection",
    zh_hans: "请选择一项检查",
  },
  inspection: {
    en: "Inspection",
    es: "Inspección",
    pt: "Inspeção",
    it: "Ispezione",
    fr: "Inspection",
    zh_hans: "检查",
  },
  startAnInspection: {
    en: "Start an inspection",
    es: "Comenzar una inspección",
    pt: "Iniciar uma inspeção",
    it: "Inizia un'ispezione",
    fr: "Démarrer une inspection",
    zh_hans: "开始检查",
  },
  selectAnInspection: {
    en: "Select an inspection",
    es: "Seleccionar una inspección",
    pt: "Selecione uma inspeção",
    it: "Seleziona un'ispezione",
    fr: "Sélectionner une inspection",
    zh_hans: "选择一个检查",
  },
  selectOrTypeYourCompanyName: {
    en: "Select or type your company name",
    es: "Seleccione o escriba el nombre de su empresa",
    pt: "Selecione ou digite o nome da sua empresa",
    it: "Seleziona o digita il nome della tua azienda",
    fr: "Sélectionnez ou saisissez le nom de votre entreprise",
    zh_hans: "选择或输入您的公司名称",
  },
  inspectionCompleted: {
    en: "Inspection Completed",
    es: "Inspección Completada",
    pt: "Inspeção Concluída",
    it: "Ispezione Completata",
    fr: "Inspection Terminée",
    zh_hans: "检查完成",
  },
  checklistItemDeficient: {
    en: "Checklist Item Deficient",
    es: "Elemento de Lista de Verificación Deficiente",
    pt: "Item da Lista de Verificação Deficiente",
    it: "Elemento della Lista di Controllo Carente",
    fr: "Élément de la Liste de Contrôle Déficient",
    zh_hans: "清单项不足",
  },
  quiz: {
    en: "Quiz",
    es: "Cuestionario",
    pt: "Questionário",
    it: "Quiz",
    fr: "Quiz",
    zh_hans: "测验",
  },
  orientationQuiz: {
    en: "Orientation Quiz",
    es: "Cuestionario de Orientación",
    pt: "Questionário de Orientação",
    it: "Quiz di Orientamento",
    fr: "Questionnaire d'Orientation",
    zh_hans: "定向测验",
  },
  quizQR: {
    en: "Quiz QR",
    es: "Cuestionario QR",
    pt: "Questionário QR",
    it: "Quiz QR",
    fr: "Quiz QR",
    zh_hans: "测验二维码",
  },
  scanQrCodeToTakeQuiz: {
    en: "Scan the QR code to take the quiz",
    es: "Escanear el código QR para realizar el cuestionario",
    pt: "Digitalize o código QR para fazer o questionário",
    it: "Scansiona il codice QR per fare il quiz",
    fr: "Scannez le code QR pour passer le quiz",
    zh_hans: "扫描二维码参加测验",
  },
  projecOrientation: {
    en: "Project Orientation",
    es: "Orientación del Proyecto",
    pt: "Orientação do Projeto",
    it: "Orientamento del Progetto",
    fr: "Orientation du Projet",
    zh_hans: "项目定向",
  },
  scanToTakeQuizCapital: {
    en: "SCAN TO START QUIZ",
    es: "ESCANEAR PARA EMPEZAR EL CUESTIONARIO",
    pt: "DIGITALIZAR PARA INICIAR O QUESTIONÁRIO",
    it: "SCANSIONA PER INIZIARE IL QUIZ",
    fr: "SCANNER POUR DÉMARRER LE QUIZ",
    zh_hans: "扫描开始测验",
  },
  scanToRegister: {
    en: "Scan to Register",
    es: "Escanear para Registrarse",
    pt: "Digitalizar para Registrar",
    it: "Scansiona per Registrarsi",
    fr: "Scannez pour vous enregistrer",
    zh_hans: "扫描以注册",
  },
  thankYouForCompletingThisOrientation: {
    en: "Thank you for completing this Orientation.",
    es: "Gracias por completar esta Orientación.",
    pt: "Obrigado por concluir esta Orientação.",
    it: "Grazie per aver completato questa Orientamento.",
    fr: "Merci d'avoir terminé cette Orientation.",
    zh_hans: "感谢您完成本部分的导引。",
  },
  startOrientation: {
    en: "Start Orientation",
    es: "Orientación inicial",
    pt: "Iniciar orientação",
    it: "Inizia l'orientamento",
    fr: "Scannez pour vous enregistrer",
    zh_hans: "开始方向",
  },
  scanQRCodeToRegister: {
    en: "Scan the QR code to register",
    es: "Escanear el código QR para registrarse",
    pt: "Digitalize o código QR para se registrar",
    it: "Scansiona il codice QR per registrarti",
    fr: "Scannez le code QR pour vous inscrire",
    zh_hans: "扫描二维码注册",
  },
  scanToRegisterCaptial: {
    en: "SCAN TO REGISTER",
    es: "ESCANEAR PARA REGISTRARSE",
    pt: "DIGITALIZAR PARA SE REGISTRAR",
    it: "SCANSIONA PER REGISTRARTI",
    fr: "SCANNER POUR VOUS ENREGISTRER",
    zh_hans: "扫描注册",
  },
  workerAndTradePartner: {
    en: "Worker or Trade Partner",
    es: "Trabajador o Socio Comercial",
    pt: "Trabalhador ou Parceiro Comercial",
    it: "Lavoratore o Partner Commerciale",
    fr: "Travailleur ou Partenaire Commercial",
    zh_hans: "工人或贸易伙伴",
  },
  save: {
    en: "Save",
    es: "Guardar",
    pt: "Salvar",
    it: "Salvare",
    fr: "Sauvegarder",
    zh_hans: "保存",
  },

  worker: {
    en: "Worker",
    es: "Trabajador",
    pt: "Trabalhador",
    it: "Lavoratore",
    fr: "Travailleur",
    zh_hans: "工人",
  },
  tradespersonCraftspersonTechnicianMechanic: {
    en: "Tradesperson, craftsperson, technician, mechanic",
    es: "Oficio, artesano, técnico, mecánico",
    pt: "Profissional de ofício, artesão, técnico, mecânico",
    it: "Artigiano, artigiano, tecnico, meccanico",
    fr: "Artisan, artisan, technicien, mécanicien",
    zh_hans: "行业工人，手工艺人，技术员，机械师",
  },
  whatBestDescribesWhoYouWorkFor: {
    en: "What best describes who you work for?",
    es: "¿Qué describe mejor a quién trabajas para?",
    pt: "O que melhor descreve para quem você trabalha?",
    it: "Cosa descrive meglio chi lavori per?",
    fr: "Qu'est-ce qui décrit le mieux qui vous travaillez pour ?",
    zh_hans: "什么最能描述你的工作对象？",
  },
  gcOperationsOrDesignTeam: {
    en: "GC Operations or Design Team",
    es: "Equipo de Operaciones de GC o Diseño",
    pt: "Equipe de Operações de GC ou Design",
    it: "Team di Operazioni GC o Design",
    fr: "Équipe des opérations GC ou de conception",
    zh_hans: "总承包运营或设计团队",
  },
  employedBySubcontractor: {
    en: "Employed by a Subcontractor",
    es: "Empleado por un subcontratista",
    pt: "Empregado por um Subcontratado",
    it: "Impiegato da un subappaltatore",
    fr: "Employé par un sous-traitant",
    zh_hans: "受承包商雇佣",
  },
  welcome: {
    en: "Welcome",
    es: "Bienvenido",
    pt: "Bem-vindo",
    it: "Benvenuto",
    fr: "Bienvenue",
    zh_hans: "欢迎",
  },
  language: {
    en: "Language",
    es: "Idioma",
    pt: "Língua",
    it: "Lingua",
    fr: "Langue",
    zh_hans: "语言",
  },
  added: {
    en: "Added",
    es: "Agregado",
    pt: "Adicionado",
    it: "Aggiunto",
    fr: "Ajouté",
    zh_hans: "已添加",
  },
  thankYou: {
    en: "Thank You",
    es: "Gracias",
    pt: "Obrigado",
    it: "Grazie",
    fr: "Merci",
    zh_hans: "谢谢",
  },
  pressDoneAndPassDeviceToNeighbor: {
    en: "Press 'Done' and pass the device to your neighbor",
    es: "Presiona 'Hecho' y pasa el dispositivo a tu vecino",
    pt: "Pressione 'Concluído' e passe o dispositivo para o seu vizinho",
    it: "Premi 'Fatto' e passa il dispositivo al tuo vicino",
    fr: "Appuyez sur 'Terminé' et passez le dispositif à votre voisin",
    zh_hans: "按下“完成”并将设备交给您的邻居",
  },
  stayForEntireOrientation: {
    en: `Stay for the entire orientation`,
    es: `Permanezca durante toda la orientación`,
    pt: `Permaneça durante toda a orientação`,
    it: `Resta per l'intera orientamento`,
    fr: `Restez pendant toute l'orientation`,
    zh_hans: `留下参加整个导向活动`,
  },
  youCanLeaveWhenPrompted: {
    en: `You can leave when prompted`,
    es: `Puede irse cuando se le indique`,
    pt: `Você pode sair quando for instruído`,
    it: `Puoi andare via quando ti verrà detto`,
    fr: `Vous pouvez partir lorsque vous serez invité`,
    zh_hans: `您可以在指示时离开`,
  },
  siteFormIsCommittedToYourPrivacyAndWillNotSellYourData: {
    en: "SiteForm is committed to your privacy and will NOT sell your data.",
    es: "SiteForm se compromete con tu privacidad y NO venderá tus datos.",
    pt: "O SiteForm está comprometido com a sua privacidade e NÃO venderá seus dados.",
    it: "SiteForm si impegna per la tua privacy e NON venderà i tuoi dati.",
    fr: "SiteForm s'engage pour votre vie privée et NE vendra PAS vos données.",
    zh_hans: "SiteForm承诺保护您的隐私，不会出售您的数据。",
  },
  whatisYourTitle: {
    en: "What is your Title?",
    es: "¿Cuál es tu Título?",
    pt: "Qual é o seu Título?",
    it: "Qual è il tuo Titolo?",
    fr: "Quel est votre Titre ?",
    zh_hans: "您的职称是什么？",
  },
  name: {
    en: "Name",
    es: "Nombre",
    pt: "Nome",
    it: "Nome",
    fr: "Nom",
    zh_hans: "姓名",
  },
  continue: {
    en: "Continue",
    es: "Continuar",
    pt: "Continuar",
    it: "Continua",
    fr: "Continuer",
    zh_hans: "继续",
  },
  frontImage: {
    en: "Front Image",
    es: "Imagen Frontal",
    pt: "Imagem Frontal",
    it: "Immagine Frontale",
    fr: "Image Avant",
    zh_hans: "前图像",
  },
  backImage: {
    en: "Back Image",
    es: "Imagen Trasera",
    pt: "Imagem Traseira",
    it: "Immagine Posteriore",
    fr: "Image Arrière",
    zh_hans: "后图像",
  },
  cityOfResidence: {
    en: "City of residence",
    es: "Ciudad de residencia",
    pt: "Cidade de residência",
    it: "Città di residenza",
    fr: "Ville de résidence",
    zh_hans: "居住城市",
  },
  architectEngineerOwnerConsultantGCManagement: {
    en: "Architect, Owner, Consultant, GC Management",
    es: "Arquitecto, Propietario, Consultor, Gestión de Contratistas Generales",
    pt: "Arquiteto, Proprietário, Consultor, Gerenciamento de Construção",
    it: "Architetto, Proprietario, Consulente, Gestione della Costruzione",
    fr: "Architecte, Propriétaire, Consultant, Gestion de la Construction",
    zh_hans: "建筑师，业主，顾问，总承包管理",
  },
  whatIsYourAge: {
    en: "What is your age?",
    es: "¿Cuál es tu edad?",
    pt: "Qual é a sua idade?",
    it: "Qual è la tua età?",
    fr: "Quel âge as-tu?",
    zh_hans: "你多大了？",
  },
  projectTitle: {
    en: "Project Title",
    es: "Título del Proyecto",
    pt: "Título do Projeto",
    it: "Titolo del Progetto",
    fr: "Titre du Projet",
    zh_hans: "项目标题",
  },
  generalContractor: {
    en: "General Contractor",
    es: "Contratista General",
    pt: "Empreiteiro Geral",
    it: "Appaltatore Generale",
    fr: "Entrepreneur Général",
    zh_hans: "总承包商",
  },
  certificationTitle: {
    en: "Certification Title",
    es: "Título de Certificación",
    pt: "Título de Certificação",
    it: "Titolo della Certificazione",
    fr: "Titre de Certification",
    zh_hans: "认证标题",
  },
  yearsInConstruction: {
    en: "Years in Construction",
    es: "Años en Construcción",
    pt: "Anos em Construção",
    it: "Anni di Esperienza nella Costruzione",
    fr: "Années d'Expérience dans la Construction",
    zh_hans: "施工年限",
  },
  pastCertificationsAndTrainings: {
    en: "Past Certifications and Trainings",
    es: "Certificaciones y Formaciones Pasadas",
    pt: "Certificações e Treinamentos Anteriores",
    it: "Certificazioni e Formazioni Precedenti",
    fr: "Certifications et Formations Passées",
    zh_hans: "过往证书和培训",
  },
  recentlyUploadedCerts: {
    en: "Recently uploaded certifications and trainings",
    es: "Certificaciones y formaciones recientemente cargadas",
    pt: "Certificações e treinamentos recentemente enviados",
    it: "Certificazioni e formazioni caricate di recente",
    fr: "Certifications et formations récemment téléchargées",
    zh_hans: "最近上传的证书和培训",
  },
  submitYourDrugtestPhotos: {
    en: "Submit your drugtest photos",
    es: "Envíe las fotos de su prueba de drogas",
    pt: "Envie as fotos do seu teste de drogas",
    it: "Invia le foto del tuo test antidroga",
    fr: "Envoyez vos photos de test de drogue",
    zh_hans: "提交您的药物测试照片",
  },
  begin: {
    en: "Begin",
    es: "Comenzar",
    pt: "Iniciar",
    it: "Inizia",
    fr: "Commencer",
    zh_hans: "开始",
  },

  imNotInTheList: {
    en: "I'm not in the list",
    es: "No estoy en la lista",
    pt: "Não estou na lista",
    it: "Non sono nell'elenco",
    fr: "Je ne suis pas dans la liste",
    zh_hans: "我不在名单上",
  },
  myEmployerIsNotInTheList: {
    en: "My employer is not in the list",
    es: "Mi empleador no está en la lista",
    pt: "Meu empregador não está na lista",
    it: "Il mio datore di lavoro non è nell'elenco",
    fr: "Mon employeur ne figure pas dans la liste",
    zh_hans: "我的雇主不在名单上",
  },
  hello: {
    en: "Hello!",
    es: "¡Hola!",
    pt: "Olá!",
    it: "Ciao!",
    fr: "Bonjour !",
    zh_hans: "你好！",
  },
  selectYourSelf: {
    en: "Select yourself",
    es: "Seleccionarse",
    pt: "Selecione-se",
    it: "Selezionati",
    fr: "Sélectionnez-vous",
    zh_hans: "选择自己",
  },
  pressBeginWhenYoureReady: {
    en: `Press "Begin" when you’re ready`,
    es: `Presiona "Comenzar" cuando estés listo`,
    pt: `Pressione "Iniciar" quando estiver pronto`,
    it: `Premi "Inizia" quando sei pronto`,
    fr: `Appuyez sur "Commencer" lorsque vous êtes prêt`,
    zh_hans: `准备好后点击"开始"`,
  },
  letsOnboardYouToThisProject: {
    en: "Let's onboard you to this project",
    es: "Vamos a incorporarte a este proyecto",
    pt: "Vamos te integrar a este projeto",
    it: "Ti integreremo in questo progetto",
    fr: "Laissez-nous vous intégrer à ce projet",
    zh_hans: "让我们将您纳入到这个项目中",
  },

  completePreTaskPlan: {
    en: "Complete a Pre-Task Plan",
    es: "Completar un plan previo a la tarea",
    pt: "Conclua um plano pré-tarefa",
    it: "Completa un piano pre-attività",
    fr: "Compléter un plan préalable à la tâche",
    zh_hans: "完成任务前计划",
  },

  preTaskPlan: {
    en: "Pre-Task Plan",
    es: "Plan previo a la tarea",
    pt: "Plano Pré-Tarefa",
    it: "Piano pre-attività",
    fr: "Plan préalable à la tâche",
    zh_hans: "任务前计划",
  },

  sorryWecannotIdentifyYou: {
    en: "Sorry, we cannot identify you",
    es: "Lo siento, no podemos identificarte",
    pt: "Desculpe, não podemos identificá-lo",
    it: "Spiacente, non possiamo identificarti",
    fr: "Désolé, nous ne pouvons pas vous identifier",
    zh_hans: "对不起，我们无法识别您",
  },
  pleaseGoBackAndEnterYourName: {
    en: "Please, go back and enter your name",
    es: "Por favor, regresa e ingresa tu nombre",
    pt: "Por favor, volte e insira seu nome",
    it: "Per favore, torna indietro e inserisci il tuo nome",
    fr: "Veuillez revenir en arrière et saisir votre nom",
    zh_hans: "请返回并输入您的名字",
  },
  pleaseEnterYourDateOfBirth: {
    en: "Please, enter your date of birth",
    es: "Por favor, ingresa tu fecha de nacimiento",
    pt: "Por favor, insira a sua data de nascimento",
    it: "Per favore, inserisci la tua data di nascita",
    fr: "Veuillez saisir votre date de naissance",
    zh_hans: "请填写您的出生日期",
  },
  selectYourUnionToContinue: {
    en: "Select your union to continue",
    es: "Seleccione su sindicato para continuar",
    pt: "Selecione o seu sindicato para continuar",
    it: "Seleziona il tuo sindacato per continuare",
    fr: "Sélectionnez votre syndicat pour continuer",
    zh_hans: "选择您的工会以继续",
  },
  birthDateNotFound: {
    en: "Birth date not found",
    es: "Fecha de nacimiento no encontrada",
    pt: "Data de nascimento não encontrada",
    it: "Data di nascita non trovata",
    fr: "Date de naissance non trouvée",
    zh_hans: "未找到出生日期",
  },
  nameNotFoundPleaseGoBackAndTryAgain: {
    en: "Name not found, please go back and try again",
    es: "Nombre no encontrado, por favor regrese e inténtelo de nuevo",
    pt: "Nome não encontrado, por favor volte e tente novamente",
    it: "Nome non trovato, per favore torna indietro e riprova",
    fr: "Nom non trouvé, veuillez revenir en arrière et réessayer",
    zh_hans: "姓名未找到，请返回重试",
  },
  selectYourUnion: {
    en: "Select your union",
    es: "Seleccione su sindicato",
    pt: "Selecione o seu sindicato",
    it: "Seleziona il tuo sindacato",
    fr: "Sélectionnez votre syndicat",
    zh_hans: "选择您的工会",
  },
  selectYourUnionChapterToContinue: {
    en: "Select your union chapter to continue",
    es: "Seleccione su capítulo sindical para continuar",
    pt: "Selecione o capítulo do seu sindicato para continuar",
    it: "Seleziona il capitolo del tuo sindacato per continuare",
    fr: "Sélectionnez votre section syndicale pour continuer",
    zh_hans: "选择您的工会分会以继续",
  },
  selectYourUnionChapter: {
    en: "Select your union chapter",
    es: "Seleccione su capítulo sindical",
    pt: "Selecione o capítulo do seu sindicato",
    it: "Seleziona il capitolo del tuo sindacato",
    fr: "Sélectionnez votre section syndicale",
    zh_hans: "选择您的工会分会",
  },

  projectWorkingLocation: {
    en: "Project Working Location",
    es: "Ubicación de trabajo del proyecto",
    pt: "Local de trabalho do projeto",
    it: "Posizione di lavoro del progetto",
    fr: "Lieu de travail du projet",
    zh_hans: "项目工作地点",
  },
  enter: {
    en: "Enter",
    es: "Ingresar",
    pt: "Digite",
    it: "Inserisci",
    fr: "Entrer",
    zh_hans: "输入",
  },
  enterYourUnion: {
    en: "Enter your union",
    es: "Ingrese su sindicato",
    pt: "Digite o seu sindicato",
    it: "Inserisci il tuo sindacato",
    fr: "Entrez votre syndicat",
    zh_hans: "输入您的工会",
  },
  noUserFound: {
    en: "No User found",
    es: "Usuario no encontrado",
    pt: "Nenhum usuário encontrado",
    it: "Nessun utente trovato",
    fr: "Aucun utilisateur trouvé",
    zh_hans: "未找到用户",
  },
  iUnderstand: {
    en: "I understand",
    es: "Entiendo",
    pt: "Eu entendo",
    it: "Ho capito",
    fr: "J'ai compris",
    zh_hans: "我理解",
  },
  suggested: {
    en: "Suggested",
    es: "Sugerido",
    pt: "Sugerido",
    it: "Suggerito",
    fr: "Suggéré",
    zh_hans: "建议",
  },
  birthDate: {
    en: "Birth Date",
    es: "Fecha de nacimiento",
    pt: "Data de nascimento",
    it: "Data di nascita",
    fr: "Date de naissance",
    zh_hans: "出生日期",
  },
  tradeTitle: {
    en: "Trade(Title)",
    es: "Comercio(Título)",
    pt: "Negócio(Título)",
    it: "Commercio(Titolo)",
    fr: "Métier(Titre)",
    zh_hans: "贸易(标题)",
  },
  roleLevel: {
    en: "Role(level)",
    es: "Rol(nivel)",
    pt: "Função(nível)",
    it: "Ruolo(livello)",
    fr: "Rôle(niveau)",
    zh_hans: "角色(级别)",
  },
  enterYourEmergencyContact: {
    en: "Enter Your emergency contact",
    es: "Ingrese su contacto de emergencia",
    pt: "Digite o seu contato de emergência",
    it: "Inserisci il tuo contatto di emergenza",
    fr: "Entrez votre contact d'urgence",
    zh_hans: "输入您的紧急联系人",
  },
  enterYourHardHatThatWasGivenToYou: {
    en: "Enter the Hard Hat # that was given to you",
    es: "Ingrese el número de su casco de seguridad que se le proporcionó",
    pt: "Digite o número do seu capacete de segurança que lhe foi dado",
    it: "Inserisci il numero del tuo casco che ti è stato dato",
    fr: "Entrez le numéro de votre casque de sécurité qui vous a été donné",
    zh_hans: "输入您收到的安全帽编号",
  },
  enterYourResidence: {
    en: "Enter your residence",
    es: "Ingrese su lugar de residencia",
    pt: "Digite o seu local de residência",
    it: "Inserisci il tuo indirizzo di residenza",
    fr: "Entrez votre lieu de résidence",
    zh_hans: "输入您的居住地",
  },
  selectYourState: {
    en: "Select your state",
    es: "Seleccione su estado",
    pt: "Selecione o seu estado",
    it: "Seleziona la tua regione",
    fr: "Sélectionnez votre état",
    zh_hans: "选择您的州",
  },
  selectYourZipCode: {
    en: "Select your zip code",
    es: "Seleccione su código postal",
    pt: "Selecione o seu código postal",
    it: "Seleziona il tuo codice postale",
    fr: "Sélectionnez votre code postal",
    zh_hans: "选择您的邮政编码",
  },
  selectYourCity: {
    en: "Select your city",
    es: "Seleccione su ciudad",
    pt: "Selecione a sua cidade",
    it: "Seleziona la tua città",
    fr: "Sélectionnez votre ville",
    zh_hans: "选择您的城市",
  },
  enterYourHardHatNumber: {
    en: "Enter your hard hat number",
    es: "Ingrese el número de su casco de seguridad",
    pt: "Digite o número do seu capacete de segurança",
    it: "Inserisci il numero del tuo casco",
    fr: "Entrez le numéro de votre casque de sécurité",
    zh_hans: "输入您的安全帽编号",
  },
  enterYourZipCode: {
    en: "Enter your zip code",
    es: "Ingrese su código postal",
    pt: "Digite o seu código postal",
    it: "Inserisci il tuo codice postale",
    fr: "Entrez votre code postal",
    zh_hans: "输入您的邮政编码",
  },
  union: {
    en: "Union",
    es: "Sindicato",
    pt: "Sindicato",
    it: "Sindacato",
    fr: "Syndicat",
    zh_hans: "工会",
  },
  otherTrades: {
    en: "Other Trades",
    es: "Otros Oficios",
    pt: "Outros Negócios",
    it: "Altri Commerci",
    fr: "Autres Métiers",
    zh_hans: "其他贸易",
  },
  other: {
    en: "Other",
    es: "Otro",
    pt: "Outro",
    it: "Altro",
    fr: "Autre",
    zh_hans: "其他",
  },
  youCertifyYouAreThePersonMentionedAbove: {
    en: "You certify you are the person mentioned above and agree to SiteForm's",
    es: "Certificas que eres la persona mencionada arriba y aceptas los",
    pt: "Você certifica que é a pessoa mencionada acima e concorda com os",
    it: "Certifichi di essere la persona sopra menzionata e accetti i",
    fr: "Vous certifiez être la personne mentionnée ci-dessus et acceptez les",
    zh_hans: "您确认您是上述提到的人，并同意SiteForm的",
  },
  and: {
    en: "and",
    es: "y",
    pt: "e",
    it: "e",
    fr: "et",
    zh_hans: "和",
  },
  termsOfService: {
    en: "Terms of Service",
    es: "Términos de servicio",
    pt: "Termos de serviço",
    it: "Termini di servizio",
    fr: "Conditions de service",
    zh_hans: "服务条款",
  },
  privacyPolicy: {
    en: "Privacy Policy",
    es: "Política de privacidad",
    pt: "Política de privacidade",
    it: "Informativa sulla privacy",
    fr: "Politique de confidentialité",
    zh_hans: "隐私政策",
  },
  yearsWithEmployer: {
    en: "Years With Employer",
    es: "Años con el empleador",
    pt: "Anos com o empregador",
    it: "Anni con l'azienda",
    fr: "Années avec l'employeur",
    zh_hans: "与雇主的年数",
  },
  rewind30Sec: {
    en: "Rewind 30 sec",
    es: "Rebobinar 30 segundos",
    pt: "Rebobinar 30 segundos",
    it: "Riavvolgi di 30 secondi",
    fr: "Reculer de 30 secondes",
    zh_hans: "倒退 30 秒",
  },
  howManyYearHaveYouBeenWithThisCompany: {
    en: "How many years have you been with this company?",
    es: "¿Cuántos años has estado en esta empresa?",
    pt: "Há quantos anos você está nesta empresa?",
    it: "Da quanti anni lavori in questa azienda?",
    fr: "Depuis combien d'années travaillez-vous dans cette entreprise?",
    zh_hans: "您在这家公司工作了多少年？",
  },
  thankYouForCompletingRegistration: {
    en: "Thank you for completing Registration",
    es: "Gracias por completar el registro",
    pt: "Obrigado por concluir o registro",
    it: "Grazie per aver completato la registrazione",
    fr: "Merci d'avoir terminé l'inscription",
    zh_hans: "感谢您完成注册",
  },
  uploadYourDrugtest: {
    en: "Upload Your Drugtest",
    es: "Sube tu prueba de drogas",
    pt: "Carregue o seu teste de drogas",
    it: "Carica il tuo test di droga",
    fr: "Téléchargez votre test de dépistage de drogue",
    zh_hans: "上传您的药物测试",
  },
  selectYourTitle: {
    en: "Select your Title",
    es: "Seleccione su título",
    pt: "Selecione seu título",
    it: "Seleziona il tuo titolo",
    fr: "Sélectionnez votre titre",
    zh_hans: "选择您的头衔",
  },

  selectYourDateOfBirthToContinue: {
    en: "Select your Date of Birth to continue",
    es: "Selecciona tu fecha de nacimiento para continuar",
    pt: "Selecione sua data de nascimento para continuar",
    it: "Seleziona la tua data di nascita per continuare",
    fr: "Sélectionnez votre date de naissance pour continuer",
    zh_hans: "选择您的出生日期以继续",
  },
  searchByZipCode: {
    en: "Search by zip code",
    es: "Buscar por código postal",
    pt: "Pesquisar por código postal",
    it: "Cerca per codice postale",
    fr: "Rechercher par code postal",
    zh_hans: "按邮政编码搜索",
  },
  whatIsYourZipCode: {
    en: "What is your zip code?",
    es: "¿Cuál es tu código postal?",
    pt: "Qual é o seu código postal?",
    it: "Qual è il tuo codice postale?",
    fr: "Quel est votre code postal ?",
    zh_hans: "你的邮政编码是什么？",
  },
  selectYourZipCodeToContinue: {
    en: "Select your zip code to continue",
    es: "Selecciona tu código postal para continuar",
    pt: "Selecione o seu código postal para continuar",
    it: "Seleziona il tuo codice postale per continuare",
    fr: "Sélectionnez votre code postal pour continuer",
    zh_hans: "选择您的邮政编码以继续",
  },
  enterZipCodeAssociatedWithYourAddress: {
    en: "Enter the zip code associated with your address. Only enter 5 numbers, for example 12345",
    es: "Ingresa el código postal asociado a tu dirección. Solo ingresa 5 números, por ejemplo 12345",
    pt: "Digite o código postal associado à sua morada. Apenas insira 5 números, por exemplo 12345",
    it: "Inserisci il codice postale associato al tuo indirizzo. Inserisci solo 5 numeri, ad esempio 12345",
    fr: "Entrez le code postal associé à votre adresse. Ne saisissez que 5 chiffres, par exemple 12345",
    zh_hans: "输入与您的地址相关联的邮政编码。仅输入5个数字，例如12345",
  },
  noCitiesFoundForThisZipCode: {
    en: "No cities found for selected zip code, enter a valid zip code",
    es: "No se encontraron ciudades para el código postal seleccionado. Ingresa un código postal válido",
    pt: "Nenhuma cidade encontrada para o código postal selecionado, insira um código postal válido",
    it: "Nessuna città trovata per il codice postale selezionato, inserisci un codice postale valido",
    fr: "Aucune ville trouvée pour le code postal sélectionné, veuillez saisir un code postal valide",
    zh_hans: "未找到所选邮政编码的城市，请输入有效的邮政编码",
  },
  enterYourPhoneNumber: {
    en: "Enter your Phone number to continue",
    es: "Ingresa tu número de teléfono para continuar",
    pt: "Digite o seu número de telefone para continuar",
    it: "Inserisci il tuo numero di telefono per continuare",
    fr: "Entrez votre numéro de téléphone pour continuer",
    zh_hans: "输入您的电话号码以继续",
  },
  searchByCity: {
    en: "Search by city",
    es: "Buscar por ciudad",
    pt: "Pesquisar por cidade",
    it: "Cerca per città",
    fr: "Rechercher par ville",
    zh_hans: "按城市搜索",
  },
  pleaseEnterA5DigitNumber: {
    en: "Please enter a 5 digit number",
    es: "Por favor, ingresa un número de 5 dígitos",
    pt: "Por favor, insira um número de 5 dígitos",
    it: "Inserisci un numero di 5 cifre",
    fr: "Veuillez entrer un nombre à 5 chiffres",
    zh_hans: "请输入一个5位数",
  },
  enterYourZipCodeToContinue: {
    en: "Enter your zip code to continue.",
    es: "Ingresa tu código postal para continuar.",
    pt: "Digite o seu código postal para continuar.",
    it: "Inserisci il tuo codice postale per continuare.",
    fr: "Entrez votre code postal pour continuer.",
    zh_hans: "输入您的邮政编码以继续。",
  },
  howManyYearsHaveYouWorkedInConstruction: {
    en: "How many years of experience do you have in construction?",
    es: "¿Cuántos años de experiencia tienes en construcción?",
    pt: "Quantos anos de experiência você tem em construção?",
    it: "Quanti anni di esperienza hai nel campo delle costruzioni?",
    fr: "Combien d'années d'expérience avez-vous dans la construction ?",
    zh_hans: "你在建筑行业有多少年的经验？",
  },
  ok: {
    en: "OK",
    es: "OK",
    pt: "OK",
    it: "OK",
    fr: "OK",
    zh_hans: "好",
  },
  signature: {
    en: "Signature",
    es: "Firma",
    pt: "Assinatura",
    it: "Firma",
    fr: "Signature",
    zh_hans: "签名",
  },
  enterYourEmailWeWillCheckWhetherYouNeedToCreateANewAccount: {
    en: "Enter your email, we'll check if you need to create a new account.",
    es: "Ingresa tu correo electrónico, verificaremos si necesitas crear una nueva cuenta.",
    pt: "Digite seu e-mail, vamos verificar se você precisa criar uma nova conta.",
    it: "Inserisci la tua email, verificheremo se è necessario creare un nuovo account.",
    fr: "Entrez votre adresse e-mail, nous vérifierons si vous devez créer un nouveau compte.",
    zh_hans: "输入您的电子邮件，我们将检查您是否需要创建一个新账户。",
  },
  selectValidOptionToContinue: {
    en: "Select a valid option to continue",
    es: "Selecciona una opción válida para continuar",
    pt: "Selecione uma opção válida para continuar",
    it: "Seleziona un'opzione valida per continuare",
    fr: "Sélectionnez une option valide pour continuer",
    zh_hans: "选择有效选项以继续",
  },
  enterYourFullNameFirstPlusLast: {
    en: "Enter your Full Name (First Name + Last Name)",
    es: "Ingresa tu Nombre Completo (Nombre + Apellido)",
    pt: "Digite seu Nome Completo (Primeiro Nome + Último Nome)",
    it: "Inserisci il tuo Nome Completo (Nome + Cognome)",
    fr: "Entrez votre Nom Complet (Prénom + Nom de famille)",
    zh_hans: "输入您的全名（名字 + 姓氏）",
  },

  trygoingBackToRegistrationPage: {
    es: "Intenta volver a la página de registro y regístrate de nuevo para abrir este página.",
    en: "Try going back to the registration page and register again to open this page.",
    pt: "Tente voltar para a página de registro e registre-se novamente para abrir esta página.",
    it: "Prova a tornare alla pagina di registrazione e registrati nuovamente per aprire questa pagina.",
    fr: "Essayez de revenir à la page d'inscription et de vous inscrire à nouveau pour ouvrir cette page.",
    zh_hans: "请返回注册页面并重新注册以打开此页面。",
  },
  whatIsYourGender: {
    es: "¿Cuál es su género?",
    en: "What is your Gender?",
    pt: "Qual é o seu Gênero?",
    it: "Qual è il tuo genere?",
    fr: "Quel est votre genre?",
    zh_hans: "你的性别是什么？",
  },
  selectYourGenderToContinue: {
    es: "Seleccione su género para continuar",
    en: "Select your gender to continue",
    pt: "Selecione seu gênero para continuar",
    it: "Seleziona il tuo genere per continuare",
    fr: "Sélectionnez votre genre pour continuer",
    zh_hans: "选择您的性别以继续",
  },
  whatIsYourethnicity: {
    es: "¿Cuál es tu etnia?",
    en: "What is your Ethnicity?",
    pt: "Qual é a sua Etnia?",
    it: "Qual è la tua etnia?",
    fr: "Quelle est votre ethnie?",
    zh_hans: "你的种族是什么？",
  },
  selectOneOfTheAboveOptions: {
    es: "selecciona una de las opciones de arriba",
    en: "select one of the options above",
    pt: "selecione uma das opções acima",
    it: "seleziona una delle opzioni sopra",
    fr: "sélectionnez l'une des options ci-dessus",
    zh_hans: "从上面选择一个选项",
  },
  selectFromTheList: {
    en: "Select from the list",
    es: "Seleccione de la lista",
    pt: "Selecione da lista",
    it: "Seleziona dalla lista",
    fr: "Sélectionnez dans la liste",
    zh_hans: "从列表中选择",
  },

  selectYourEthnicityToContinue: {
    es: "Seleccione su etnia para continuar",
    en: "Select your ethnicity to continue",
    pt: "Selecione sua etnia para continuar",
    it: "Seleziona la tua etnia per continuare",
    fr: "Sélectionnez votre ethnie pour continuer",
    zh_hans: "选择您的种族以继续",
  },

  howOldAreYou: {
    es: "¿Cuántos años tienes?",
    en: "How old are you?",
    pt: "Qual é a sua idade?",
    it: "Quanti anni hai?",
    fr: "Quel âge as-tu?",
    zh_hans: "你多大了？",
  },
  enterYourAgeToContinue: {
    es: "Ingresa tu edad para continuar",
    en: "Enter your age to continue",
    pt: "Digite sua idade para continuar",
    it: "Inserisci la tua età per continuare",
    fr: "Entrez votre âge pour continuer",
    zh_hans: "输入您的年龄以继续",
  },
  areYouAVeteranOrDisabledVeteran: {
    es: "¿Es usted un veterano o un veterano discapacitado?",
    en: "Are you a Veteran or Disabled Veteran?",
    pt: "Você é um veterano ou veterano com deficiência?",
    it: "Sei un veterano o un veterano disabile?",
    fr: "Êtes-vous un vétéran ou un vétéran handicapé?",
    zh_hans: "您是退伍军人还是残疾退伍军人？",
  },
  showRemainingCerts: {
    en: "show remaining certs",
    es: "mostrar los certificados restantes",
    pt: "mostrar certificados restantes",
    it: "mostra i certificati rimanenti",
    fr: "afficher les certificats restants",
    zh_hans: "显示剩余证书",
  },
  whoIsYourEmergencyContact: {
    es: "¿Quién es su contacto de emergencia?",
    en: "Who is your emergency contact?",
    pt: "Quem é o seu contato de emergência?",
    it: "Chi è il tuo contatto di emergenza?",
    fr: "Qui est votre contact d'urgence?",
    zh_hans: "谁是您的紧急联系人？",
  },
  emergencyContactPhoneNumber: {
    es: "Número de teléfono de contacto de emergencia",
    en: "Emergency contact phone number",
    pt: "Número de telefone do contato de emergência",
    it: "Numero di telefono del contatto di emergenza",
    fr: "Numéro de téléphone du contact d'urgence",
    zh_hans: "紧急联系电话",
  },
  pleaseEnterThePersonWhomToContactInEmergency: {
    es: "por favor ingrese a quién contactar en caso de emergencia",
    en: "please enter whom to contact in case of emergency",
    pt: "por favor, informe quem contatar em caso de emergência",
    it: "per favore, inserisci chi contattare in caso di emergenza",
    fr: "veuillez indiquer qui contacter en cas d'urgence",
    zh_hans: "请提供紧急情况下联系人",
  },
  pleaseEnterYourEmergencyContact: {
    en: "Please enter your emergency contact.",
    es: "Por favor ingrese su contacto de emergencia.",
    pt: "Por favor, insira o seu contato de emergência.",
    it: "Per favore, inserisci il tuo contatto di emergenza.",
    fr: "Veuillez entrer votre contact d'urgence.",
    zh_hans: "请填写您的紧急联系人。",
  },
  whichStateDoYouLiveIn: {
    es: "¿En qué estado vives?",
    en: "Which state do you live in?",
    pt: "Em qual estado você mora?",
    it: "In quale stato vivi?",
    fr: "Dans quel état habitez-vous?",
    zh_hans: "你住在哪个州？",
  },
  selectYourStateToContinue: {
    es: "seleccione su estado para continuar",
    en: "Select your state to continue",
    pt: "Selecione seu estado para continuar",
    it: "Seleziona il tuo stato per continuare",
    fr: "Sélectionnez votre état pour continuer",
    zh_hans: "选择您的州以继续",
  },
  whichCityDoYouLiveIn: {
    es: "¿En qué ciudad vives?",
    en: "Which city do you live in?",
    pt: "Em qual cidade você mora?",
    it: "In quale città vivi?",
    fr: "Dans quelle ville habitez-vous?",
    zh_hans: "你住在哪个城市？",
  },
  selectYourCityToContinue: {
    es: "Selecciona tu ciudad para continuar",
    en: "Select your city to continue",
    pt: "Selecione sua cidade para continuar",
    it: "Seleziona la tua città per continuare",
    fr: "Sélectionnez votre ville pour continuer",
    zh_hans: "选择您的城市以继续",
  },
  thisIsAgcUniversalOrientation: {
    en: "This is AGC Universal Orientation.",
    es: "Esta es la Orientación Universal AGC.",
    pt: "Esta é a Orientação Universal AGC.",
    it: "Questa è l'Orientamento Universale AGC.",
    fr: "Il s'agit de l'orientation universelle AGC.",
    zh_hans: "这是AGC通用定向。",
  },
  pause: {
    en: "Pause",
    es: "Pausa",
    pt: "Pausar",
    it: "Pausa",
    fr: "Pause",
    zh_hans: "暂停",
  },
  male: {
    en: "Male",
    es: "Masculino",
    pt: "Masculino",
    it: "Maschio",
    fr: "Homme",
    zh_hans: "男性",
  },
  female: {
    en: "Female",
    es: "Femenino",
    pt: "Feminino",
    it: "Femmina",
    fr: "Femme",
    zh_hans: "女性",
  },
  play: {
    en: "Play",
    es: "Jugar",
    pt: "Reproduzir",
    it: "Riproduci",
    fr: "Lecture",
    zh_hans: "播放",
  },
  next: {
    es: "Próximo",
    en: "Next",
    pt: "Próximo",
    it: "Avanti",
    fr: "Suivant",
    zh_hans: "下一步",
  },
  playAudioTrack: {
    en: "Play Audio Track",
    es: "Reproducir pista de audio",
    pt: "Reproduzir faixa de áudio",
    it: "Riproduci traccia audio",
    fr: "Lire la piste audio",
    zh_hans: "播放音轨",
  },
  stopAudioTrack: {
    en: "Stop Audio Track",
    es: "Detener pista de audio",
    pt: "Parar faixa de áudio",
    it: "Ferma la traccia audio",
    fr: "Arrêter la piste audio",
    zh_hans: "停止音轨",
  },
  yourBrowserDoesNotSupportAudioElement: {
    en: "Your browser does not support the audio element.",
    es: "Tu navegador no admite el elemento de audio.",
    pt: "Seu navegador não suporta o elemento de áudio.",
    it: "Il tuo browser non supporta l'elemento audio.",
    fr: "Votre navigateur ne prend pas en charge l'élément audio.",
    zh_hans: "您的浏览器不支持音频元素。",
  },
  uploadFromYourDevice: {
    es: "Cargar desde su dispositivo",
    en: "Upload from your Device",
    pt: "Carregar do seu dispositivo",
    it: "Carica dal tuo dispositivo",
    fr: "Télécharger depuis votre appareil",
    zh_hans: "从您的设备上传",
  },
  notNow: {
    es: "Ahora no",
    en: "Not Now",
    pt: "Agora não",
    it: "Non ora",
    fr: "Pas maintenant",
    zh_hans: "暂时不",
  },
  requiredCertifications: {
    en: "Required Certifications",
    es: "Certificaciones requeridas",
    pt: "Certificações exigidas",
    it: "Certificazioni richieste",
    fr: "Certifications requises",
    zh_hans: "所需认证",
  },
  youAreRequiredToHaveTheFollowingTraining: {
    en: "you are required to have the following training to work on this project",
    es: "debe tener la siguiente capacitación para trabajar en este proyecto",
    pt: "você deve ter o seguinte treinamento para trabalhar neste projeto",
    it: "è necessario possedere la seguente formazione per lavorare su questo progetto",
    fr: "vous devez avoir la formation suivante pour travailler sur ce projet",
    zh_hans: "您需要接受以下培训才能从事此项目",
  },
  certificatesUploadedForVerificationYouCanMoveToNextPag: {
    es: "Certificación cargada para verificación. Puedes pasar a la siguiente página si no tienes más certificados para cargar.",
    en: "Certification uploaded for verification. You can move to the next page if you have no more certificates to upload.",
    pt: "Certificação enviada para verificação. Você pode avançar para a próxima página se não tiver mais certificados para enviar.",
    it: "Certificazione caricata per la verifica. Puoi passare alla pagina successiva se non hai altri certificati da caricare.",
    fr: "Certification téléchargée pour vérification. Vous pouvez passer à la page suivante si vous n'avez plus de certificats à télécharger.",
    zh_hans: "证书已上传进行验证。如果没有更多证书可上传，您可以转到下一页。",
  },
  or: { es: "O", en: "OR", pt: "OU", it: "O", fr: "OU", zh_hans: "或" },
  orientationDoesNotExist: {
    en: "Orientation does not exist",
    es: "La orientación no existe.",
    pt: "Orientação não existe",
    it: "L'orientamento non esiste",
    fr: "L'orientation n'existe pas",
    zh_hans: "方向不存在",
  },
  orientationIsEmptyPleaseAddSlides: {
    en: "Orientation is empty. Please add slides",
    es: "La orientación está vacía. Por favor, agrega diapositivas",
    pt: "A orientação está vazia. Por favor, adicione slides",
    it: "L'orientamento è vuoto. Si prega di aggiungere diapositive",
    fr: "L'orientation est vide. Veuillez ajouter des diapositives",
    zh_hans: "方向为空，请添加幻灯片",
  },
  certificate: {
    en: "Certificate",
    pt: "Certificado",
    es: "Certificado",
    it: "Certificato",
    fr: "Certificat",
    zh_hans: "证书",
  },
  projectRegistration: {
    en: "Project Registration",
    es: "Registro de proyectos",
    pt: "Registro de Projeto",
    it: "Registrazione del progetto",
    fr: "Inscription au projet",
    zh_hans: "项目注册",
  },
  yourEmail: {
    en: "Your Email",
    es: "Tu correo electrónico",
    pt: "Seu Email",
    it: "La tua email",
    fr: "Votre email",
    zh_hans: "您的电子邮件",
  },
  yourName: {
    en: "Your Name",
    es: "Tu nombre",
    pt: "Seu Nome",
    it: "Il tuo nome",
    fr: "Votre nom",
    zh_hans: "您的姓名",
  },
  whatIsYOurFullName: {
    en: "What is your Full Name?",
    es: "¿Cuál es tu nombre completo?",
    pt: "Qual é o seu nome completo?",
    it: "Qual è il tuo nome completo?",
    fr: "Quel est votre nom complet?",
    zh_hans: "您的全名是什么？",
  },
  firstAndLastName: {
    en: "first and last name",
    es: "nombre y apellido",
    pt: "nome e sobrenome",
    it: "nome e cognome",
    fr: "prénom et nom de famille",
    zh_hans: "名字和姓氏",
  },
  typeYourFirstNameToContinue: {
    en: "Type First and Last Name to continue",
    es: "Escribe tu nombre y apellido para continuar",
    pt: "Digite o nome e sobrenome para continuar",
    it: "Digita il nome e cognome per continuare",
    fr: "Saisissez votre prénom et nom de famille pour continuer",
    zh_hans: "输入名字和姓氏以继续",
  },
  firstAndLastNameCapital: {
    en: "First and Last Name",
    es: "Nombre y Apellido",
    pt: "Nome e Sobrenome",
    it: "Nome e Cognome",
    fr: "Prénom et Nom de Famille",
    zh_hans: "名字和姓氏",
  },
  whoDoyYouWorkFor: {
    en: "Who do you work for?",
    es: "¿Para quién trabajas?",
    pt: "Para quem você trabalha?",
    it: "Per chi lavori?",
    fr: "Pour qui travaillez-vous?",
    zh_hans: "你为哪个公司工作？",
  },
  ifYouDoNotSeeYourCompanyContactYourOffice: {
    en: "If you don't see your company, contact your office",
    es: "Si no ves tu empresa, contacta a tu oficina",
    pt: "Se não encontrar sua empresa, entre em contato com seu escritório",
    it: "Se non vedi la tua azienda, contatta il tuo ufficio",
    fr: "Si vous ne voyez pas votre entreprise, contactez votre bureau",
    zh_hans: "如果找不到您的公司，请联系您的办公室",
  },
  ifYouDoNotSeeYourCompanyContactYourGCOrOffice: {
    en: "If you don't see your company, contact the GC or your office",
    es: "Si no ves tu empresa, contacta al GC o a tu oficina",
    pt: "Se não encontrar sua empresa, entre em contato com o GC ou seu escritório",
    it: "Se non vedi la tua azienda, contatta il GC o il tuo ufficio",
    fr: "Si vous ne voyez pas votre entreprise, contactez le GC ou votre bureau",
    zh_hans: "如果找不到您的公司，请联系GC或您的办公室",
  },
  welcomeToSiteForm: {
    en: "Welcome to SiteForm!",
    es: "¡Bienvenido a SiteForm!",
    pt: "Bem-vindo ao SiteForm!",
    it: "Benvenuto in SiteForm!",
    fr: "Bienvenue sur SiteForm!",
    zh_hans: "欢迎来到 SiteForm！",
  },
  selectYourCompanyToContinue: {
    en: "Select your Company to Continue",
    es: "Selecciona tu empresa para continuar",
    pt: "Selecione sua empresa para continuar",
    it: "Seleziona la tua azienda per continuare",
    fr: "Sélectionnez votre entreprise pour continuer",
    zh_hans: "选择您的公司以继续",
  },
  selectYourCompany: {
    en: "Select Your Company",
    es: "Selecciona tu empresa",
    pt: "Selecione sua empresa",
    it: "Seleziona la tua azienda",
    fr: "Sélectionnez votre entreprise",
    zh_hans: "选择您的公司",
  },
  whatIsYourRole: {
    en: "What is your Role?",
    es: "¿Cuál es tu Rol?",
    pt: "Qual é o seu cargo?",
    it: "Qual è il tuo ruolo?",
    fr: "Quel est votre rôle ?",
    zh_hans: "您的职位是什么？",
  },
  selectYourRoleToContinue: {
    en: "Select your Role to Continue",
    es: "Selecciona tu Rol para continuar",
    pt: "Selecione seu cargo para continuar",
    it: "Seleziona il tuo ruolo per continuare",
    fr: "Sélectionnez votre rôle pour continuer",
    zh_hans: "选择您的职位以继续",
  },
  selectYourRole: {
    en: "Select your Role",
    es: "Selecciona tu Rol",
    pt: "Selecione seu cargo",
    it: "Seleziona il tuo ruolo",
    fr: "Sélectionnez votre rôle",
    zh_hans: "选择您的职位",
  },
  whatIsYourTrade: {
    en: "What is your Trade?",
    es: "¿Cuál es tu oficio?",
    pt: "Qual é o seu ofício?",
    it: "Qual è il tuo mestiere?",
    fr: "Quel est votre métier ?",
    zh_hans: "您从事哪个行业？",
  },
  selectYourTradeToContinue: {
    en: "Select your Trade to Continue",
    es: "Selecciona tu oficio para continuar",
    pt: "Selecione seu ofício para continuar",
    it: "Seleziona il tuo mestiere per continuare",
    fr: "Sélectionnez votre métier pour continuer",
    zh_hans: "选择您的行业以继续",
  },
  selectYourTrade: {
    en: "Select your Trade",
    es: "Selecciona tu oficio",
    pt: "Selecione seu ofício",
    it: "Seleziona il tuo mestiere",
    fr: "Sélectionnez votre métier",
    zh_hans: "选择您的行业",
  },
  whatIsYourPhoneNumber: {
    en: "What is your phone number?",
    es: "¿Cuál es tu número de teléfono?",
    pt: "Qual é o seu número de telefone?",
    it: "Qual è il tuo numero di telefono?",
    fr: "Quel est votre numéro de téléphone ?",
    zh_hans: "您的电话号码是多少？",
  },
  enter10DigitPhoneNumberWithoutSpace: {
    en: "Enter a 10 digit phone number without spaces or characters",
    es: "Ingresa un número de teléfono de 10 dígitos sin espacios ni caracteres",
    pt: "Digite um número de telefone com 10 dígitos sem espaços ou caracteres",
    it: "Inserisci un numero di telefono di 10 cifre senza spazi o caratteri",
    fr: "Entrez un numéro de téléphone à 10 chiffres sans espaces ni caractères",
    zh_hans: "输入一个没有空格或字符的10位电话号码",
  },
  pleaseEnterA10DigitPhoneNumberWithNoSpacesOrCharacters: {
    en: "Please enter a 10 digit phone number with no spaces or characters",
    es: "Por favor, ingresa un número de teléfono de 10 dígitos sin espacios ni caracteres",
    pt: "Por favor, digite um número de telefone com 10 dígitos sem espaços ou caracteres",
    it: "Per favore, inserisci un numero di telefono di 10 cifre senza spazi o caratteri",
    fr: "Veuillez entrer un numéro de téléphone à 10 chiffres sans espaces ni caractères",
    zh_hans: "请输入一个没有空格或字符的10位电话号码",
  },
  submit: {
    en: "Submit",
    es: "Enviar",
    pt: "Enviar",
    it: "Invia",
    fr: "Envoyer",
    zh_hans: "提交",
  },
  iAccept: {
    es: "Acepto",
    en: "I Accept",
    pt: "Eu Aceito",
    it: "Accetto",
    fr: "J'accepte",
    zh_hans: "我接受",
  },
  agcUniversalOrientationRegistration: {
    en: "AGC Universal Orientation Registration",
    es: "Registro de Orientación Universal AGC",
    pt: "Registro de Orientação Universal AGC",
    it: "Registrazione dell'Orientamento Universale AGC",
    fr: "Inscription à l'Orientation Universelle AGC",
    zh_hans: "AGC通用定向注册",
  },
  addYourCertsAndTrainings: {
    en: "Add photos of your Certification and Training cards.",
    es: "Agrega fotos de tus certificaciones y tarjetas de entrenamiento.",
    pt: "Adicione fotos dos seus certificados e cartões de treinamento.",
    it: "Aggiungi foto dei tuoi certificati e delle carte di formazione.",
    fr: "Ajoutez des photos de vos certificats et cartes de formation.",
    zh_hans: "添加您的认证和培训卡的照片。",
  },
  yourCertsAndTrainingsAlreadyInSiteForm: {
    es: "Tus certificaciones y entrenamientos ya están en SiteForm",
    en: "Your Certs and Training already in SiteForm",
    pt: "Suas certificações e treinamentos já estão no SiteForm",
    it: "Le tue certificazioni e addestramenti sono già in SiteForm",
    fr: "Vos certifications et formations sont déjà sur SiteForm",
    zh_hans: "您的认证和培训已在 SiteForm 中",
  },
  none: {
    es: "Ninguno",
    en: "None",
    pt: "Nenhum",
    it: "Nessuno",
    fr: "Aucun",
    zh_hans: "无",
  },
  certificatesProcessing: {
    es: "Procesamiento de certificado(s)",
    en: "Certificate(s) Processing",
    pt: "Processamento de certificado(s)",
    it: "Elaborazione del(i) certificato(i)",
    fr: "Traitement du/des certificat(s)",
    zh_hans: "证书处理",
  },
  takePhotoOfYourCerts: {
    es: "Toma una foto de tus certificados",
    en: "Take a Photo of Your Certs",
    pt: "Tire uma foto dos seus certificados",
    it: "Scatta una foto dei tuoi certificati",
    fr: "Prenez une photo de vos certificats",
    zh_hans: "拍摄您的证书照片",
  },
  front: {
    es: "Delantera",
    en: "Front",
    pt: "Frente",
    it: "Fronte",
    fr: "Avant",
    zh_hans: "正面",
  },
  retake: {
    es: "Volver a tomar",
    en: "Retake",
    pt: "Refazer",
    it: "Ritirare",
    fr: "Reprendre",
    zh_hans: "重新拍摄",
  },
  addBack: {
    es: "+ Reverso",
    en: "+ Add back",
    pt: "+ Adicionar verso",
    it: "+ Aggiungi retro",
    fr: "+ Ajouter l'arrière",
    zh_hans: "+ 添加背面",
  },

  addTrainingsAndCertifications: {
    es: "Agregar entrenamientos y certificaciones",
    en: "Add Trainings and Certifications",
    pt: "Adicionar treinamentos e certificações",
    it: "Aggiungi addestramenti e certificazioni",
    fr: "Ajouter des formations et des certifications",
    zh_hans: "添加培训和认证",
  },
  scanQRCodeOrEnterCertificationCode: {
    es: "Escanee el código QR o ingrese el código de certificación",
    en: "Scan QR Code or Enter Certification Code",
    pt: "Digitalize o código QR ou insira o código de certificação",
    it: "Scansiona il codice QR o inserisci il codice di certificazione",
    fr: "Scannez le code QR ou saisissez le code de certification",
    zh_hans: "扫描QR码或输入认证代码",
  },
  searchForYourCertification: {
    es: "Busque su certificación",
    en: "Search for your certification",
    pt: "Procure sua certificação",
    it: "Cerca la tua certificazione",
    fr: "Recherchez votre certification",
    zh_hans: "搜索您的认证",
  },
  enterCertificationCode: {
    es: "Ingrese el código de certificación",
    en: "Enter Certification Code",
    pt: "Insira o código de certificação",
    it: "Inserisci il codice di certificazione",
    fr: "Entrez le code de certification",
    zh_hans: "输入认证代码",
  },
  noMatchFound: {
    es: "No se encontraron coincidencias",
    en: "No match found",
    pt: "Nenhuma correspondência encontrada",
    it: "Nessuna corrispondenza trovata",
    fr: "Aucune correspondance trouvée",
    zh_hans: "没有找到匹配项",
  },
  enterCourseName: {
    es: "Ingrese el nombre del curso",
    en: "Enter course name",
    pt: "Digite o nome do curso",
    it: "Inserisci il nome del corso",
    fr: "Entrez le nom du cours",
    zh_hans: "输入课程名称",
  },
  add: {
    es: "Agregar",
    en: "Add",
    pt: "Adicionar",
    it: "Aggiungi",
    fr: "Ajouter",
    zh_hans: "添加",
  },
  yourCertsAndTrainingsHaveBeenSubmitted: {
    es: "Tus certificaciones y entrenamientos han sido enviados",
    en: "Your Certs and Trainings have been submitted",
    pt: "Suas certificações e treinamentos foram enviados",
    it: "Le tue certificazioni e addestramenti sono stati inviati",
    fr: "Vos certifications et formations ont été soumises",
    zh_hans: "您的认证和培训已提交",
  },
  thankYouForSubmittingYourCertsAndTrainings: {
    es: "Gracias por enviar tus certificaciones y entrenamientos",
    en: "Thank you for submitting your Certs and Trainings",
    pt: "Obrigado por enviar suas certificações e treinamentos",
    it: "Grazie per aver inviato le tue certificazioni e addestramenti",
    fr: "Merci d'avoir soumis vos certifications et formations",
    zh_hans: "感谢您提交的认证和培训",
  },
  somethingWentWrongPleaseTryAgain: {
    es: "Algo salió mal, por favor inténtalo de nuevo",
    en: "Something went wrong, please try again",
    pt: "Algo deu errado, por favor tente novamente",
    it: "Si è verificato un problema, riprova",
    fr: "Quelque chose s'est mal passé, veuillez réessayer",
    zh_hans: "出了点问题，请重试",
  },
  youCanProceedToTheNextSection: {
    es: "Puedes proceder a la siguiente sección",
    en: "You can proceed to the next section",
    pt: "Você pode prosseguir para a próxima seção",
    it: "Puoi procedere alla sezione successiva",
    fr: "Vous pouvez passer à la section suivante",
    zh_hans: "您可以继续下一节",
  },
  addYourEmploymentHistory: {
    es: "Agrega tu historial de empleo",
    en: "Add your employment history",
    pt: "Adicione seu histórico de emprego",
    it: "Aggiungi la tua esperienza lavorativa",
    fr: "Ajoutez votre historique d'emploi",
    zh_hans: "添加您的就业历史",
  },
  employerName: {
    es: "Nombre del empleador",
    en: "Employer Name",
    pt: "Nome do empregador",
    it: "Nome del datore di lavoro",
    fr: "Nom de l'employeur",
    zh_hans: "雇主名称",
  },
  position: {
    es: "Posición",
    en: "Position",
    pt: "Cargo",
    it: "Posizione",
    fr: "Poste",
    zh_hans: "职位",
  },
  start: {
    es: "Inicio",
    en: "Start",
    pt: "Início",
    it: "Inizio",
    fr: "Début",
    zh_hans: "开始",
  },
  end: {
    es: "Fin",
    en: "End",
    pt: "Fim",
    it: "Fine",
    fr: "Fin",
    zh_hans: "结束",
  },
  addAnotherJob: {
    es: "Agregar otro trabajo",
    en: "Add another job",
    pt: "Adicionar outro emprego",
    it: "Aggiungi un altro lavoro",
    fr: "Ajouter un autre emploi",
    zh_hans: "添加另一份工作",
  },

  yourEmploymentHistoryHasBeenSubmitted: {
    es: "Tu historial de empleo ha sido enviado",
    en: "Your employment history has been submitted",
    pt: "Seu histórico de emprego foi enviado",
    it: "La tua esperienza lavorativa è stata inviata",
    fr: "Votre historique d'emploi a été soumis",
    zh_hans: "您的就业历史已提交",
  },
  thankYouForSubmittingYourEmploymentHistory: {
    es: "Gracias por enviar tu historial de empleo",
    en: "Thank you for submitting your employment history",
    pt: "Obrigado por enviar seu histórico de emprego",
    it: "Grazie per aver inviato la tua esperienza lavorativa",
    fr: "Merci d'avoir soumis votre historique d'emploi",
    zh_hans: "感谢您提交就业历史",
  },

  addYourEmergencyContact: {
    es: "Agrega tu contacto de emergencia",
    en: "Add your emergency contact",
    pt: "Adicione seu contato de emergência",
    it: "Aggiungi il tuo contatto di emergenza",
    fr: "Ajoutez votre contact d'urgence",
    zh_hans: "添加您的紧急联系人",
  },
  emergencyContactInfo: {
    es: "Información de contacto de emergencia",
    en: "Emergency Contact Info",
    pt: "Informações de contato de emergência",
    it: "Informazioni di contatto di emergenza",
    fr: "Informations de contact d'urgence",
    zh_hans: "紧急联系人信息",
  },
  fullName: {
    es: "Nombre completo",
    en: "Full Name",
    pt: "Nome completo",
    it: "Nome completo",
    fr: "Nom complet",
    zh_hans: "全名",
  },
  relationship: {
    es: "Parentesco",
    en: "Relationship",
    pt: "Relacionamento",
    it: "Relazione",
    fr: "Relation",
    zh_hans: "关系",
  },
  phoneNumber: {
    es: "Número de teléfono",
    en: "Phone Number",
    pt: "Número de telefone",
    it: "Numero di telefono",
    fr: "Numéro de téléphone",
    zh_hans: "电话号码",
  },

  yourEmergencyContactHasBeenSubmitted: {
    es: "Tu contacto de emergencia ha sido enviado",
    en: "Your emergency contact has been submitted",
    pt: "Seucontato de emergência foi enviado",
    it: "Il tuo contatto di emergenza è stato inviato",
    fr: "Votre contact d'urgence a été soumis",
    zh_hans: "您的紧急联系人已提交",
  },
  thankYouForSubmittingYourEmergencyContact: {
    es: "Gracias por enviar tu contacto de emergencia",
    en: "Thank you for submitting your emergency contact",
    pt: "Obrigado por enviar seu contato de emergência",
    it: "Grazie per aver inviato il tuo contatto di emergenza",
    fr: "Merci d'avoir soumis votre contact d'urgence",
    zh_hans: "感谢您提交紧急联系人",
  },

  addYourSkills: {
    es: "Agrega tus habilidades",
    en: "Add your skills",
    pt: "Adicione suas habilidades",
    it: "Aggiungi le tue competenze",
    fr: "Ajoutez vos compétences",
    zh_hans: "添加您的技能",
  },
  addSkills: {
    es: "Agregar habilidades",
    en: "Add Skills",
    pt: "Adicionar habilidades",
    it: "Aggiungi competenze",
    fr: "Ajouter des compétences",
    zh_hans: "添加技能",
  },
  yourSkills: {
    es: "Tus habilidades",
    en: "Your Skills",
    pt: "Suas habilidades",
    it: "Le tue competenze",
    fr: "Vos compétences",
    zh_hans: "您的技能",
  },
  searchForSkills: {
    es: "Busca habilidades",
    en: "Search for skills",
    pt: "Procure por habilidades",
    it: "Cerca competenze",
    fr: "Recherchez des compétences",
    zh_hans: "搜索技能",
  },

  yourSkillsHaveBeenSubmitted: {
    es: "Tus habilidades han sido enviadas",
    en: "Your skills have been submitted",
    pt: "Suas habilidades foram enviadas",
    it: "Le tue competenze sono state inviate",
    fr: "Vos compétences ont été soumises",
    zh_hans: "您的技能已提交",
  },
  thankYouForSubmittingYourSkills: {
    es: "Gracias por enviar tus habilidades",
    en: "Thank you for submitting your skills",
    pt: "Obrigado por enviar suas habilidades",
    it: "Grazie per aver inviato le tue competenze",
    fr: "Merci d'avoir soumis vos compétences",
    zh_hans: "感谢您提交技能",
  },

  addYourLanguages: {
    es: "Agrega tus idiomas",
    en: "Add your languages",
    pt: "Adicione seus idiomas",
    it: "Aggiungi le tue lingue",
    fr: "Ajoutez vos langues",
    zh_hans: "添加您的语言",
  },
  addLanguages: {
    es: "Agregar idiomas",
    en: "Add Languages",
    pt: "Adicionar idiomas",
    it: "Aggiungi lingue",
    fr: "Ajouter des langues",
    zh_hans: "添加语言",
  },
  yourLanguages: {
    es: "Tus idiomas",
    en: "Your Languages",
    pt: "Seus idiomas",
    it: "Le tue lingue",
    fr: "Vos langues",
    zh_hans: "您的语言",
  },
  searchForLanguages: {
    es: "Busca idiomas",
    en: "Search for languages",
    pt: "Procure por idiomas",
    it: "Cerca lingue",
    fr: "Recherchez des langues",
    zh_hans: "搜索语言",
  },

  yourLanguagesHaveBeenSubmitted: {
    es: "Tus idiomas han sido enviados",
    en: "Your languages have been submitted",
    pt: "Seus idiomas foram enviados",
    it: "Le tue lingue sono state inviate",
    fr: "Vos langues ont été soumises",
    zh_hans: "您的语言已提交",
  },
  thankYouForSubmittingYourLanguages: {
    es: "Gracias por enviar tus idiomas",

    en: "Thank you for submitting your languages",
    pt: "Obrigado por enviar seus idiomas",
    it: "Grazie per aver inviato le tue lingue",
    fr: "Merci d'avoir soumis vos langues",
    zh_hans: "感谢您提交语言",
  },

  addYourReferences: {
    es: "Agrega tus referencias",
    en: "Add your references",
    pt: "Adicione suas referências",
    it: "Aggiungi i tuoi riferimenti",
    fr: "Ajoutez vos références",
    zh_hans: "添加您的参考人",
  },
  addReference: {
    es: "Agregar referencia",
    en: "Add Reference",
    pt: "Adicionar referência",
    it: "Aggiungi riferimento",
    fr: "Ajouter une référence",
    zh_hans: "添加参考人",
  },
  yourReferences: {
    es: "Tus referencias",
    en: "Your References",
    pt: "Suas referências",
    it: "I tuoi riferimenti",
    fr: "Vos références",
    zh_hans: "您的参考人",
  },

  email: {
    es: "Correo electrónico",
    en: "Email",
    pt: "E-mail",
    it: "E-mail",
    fr: "E-mail",
    zh_hans: "电子邮件",
  },

  yourReferencesHaveBeenSubmitted: {
    es: "Tus referencias han sido enviadas",
    en: "Your references have been submitted",
    pt: "Suas referências foram enviadas",
    it: "I tuoi riferimenti sono stati inviati",
    fr: "Vos références ont été soumises",
    zh_hans: "您的参考人已提交",
  },
  thankYouForSubmittingYourReferences: {
    es: "Gracias por enviar tus referencias",
    en: "Thank you for submitting your references",
    pt: "Obrigado por enviar suas referências",
    it: "Grazie per aver inviato i tuoi riferimenti",
    fr: "Merci d'avoir soumis vos références",
    zh_hans: "感谢您提交参考人",
  },

  congratulations: {
    es: "¡Felicitaciones!",
    en: "Congratulations!",
    pt: "Parabéns!",
    it: "Congratulazioni!",
    fr: "Félicitations !",
    zh_hans: "恭喜！",
  },
  applicationSubmitted: {
    es: "Tu solicitud ha sido enviada",
    en: "Your application has been submitted",
    pt: "Sua inscrição foi enviada",
    it: "La tua candidatura è stata inviata",
    fr: "Votre candidature a été soumise",
    zh_hans: "您的申请已提交",
  },
  thankYouForApplying: {
    es: "Gracias por postularte",
    en: "Thank you for applying",
    pt: "Obrigado por se candidatar",
    it: "Grazie per aver presentato domanda",
    fr: "Merci d'avoir postulé",
    zh_hans: "感谢您申请",
  },
  weWillReviewYourApplication: {
    es: "Revisaremos tu solicitud y nos pondremos en contacto contigo",
    en: "We will review your application and get in touch with you",
    pt: "Vamos analisar sua inscrição e entraremos em contato com você",
    it: "Esamineremo la tua candidatura e ti contatteremo",
    fr: "Nous examinerons votre candidature et vous contacterons",
    zh_hans: "我们将审查您的申请，并与您联系",
  },

  useExistingDrugtest: {
    es: "Usar prueba de drogas existente",
    en: "Use Existing Drug Test",
    pt: "Usar Teste de Drogas Existente",
    it: "Usa un Test di Droga Esistente",
    fr: "Utiliser un Test de Drogue Existante",
    zh_hans: "使用现有的毒品测试",
  },
  ifYouDoNotWantToUploadNewDrugtest: {
    en: "If you do not want to upload a new recent drug test, press the button below",
    es: "Si no deseas cargar una nueva prueba de drogas reciente, presiona el botón a continuación",
    pt: "Se você não quiser enviar um novo teste recente de drogas, pressione o botão abaixo",
    it: "Se non desideri caricare un nuovo test di droga recente, premi il pulsante qui sotto",
    fr: "Si vous ne souhaitez pas télécharger un nouveau test récent de drogue, appuyez sur le bouton ci-dessous",
    zh_hans: "如果您不想上传新的最近毒品测试，请按下面的按钮",
  },
  youAlreadyHaveAValidDrugtest: {
    en: "You already have a valid drug test, but you can upload a more recent one if you have it",
    es: "Ya tienes una prueba de drogas válida, pero puedes cargar una más reciente si la tienes",
    pt: "Você já tem um teste de drogas válido, mas pode enviar um mais recente se tiver",
    it: "Hai già un test di droga valido, ma puoi caricarne uno più recente se lo hai",
    fr: "Vous avez déjà un test de drogue valide, mais vous pouvez en télécharger un plus récent si vous en avez un",
    zh_hans:
      "您已经拥有一张有效的毒品测试报告，但如果您有更近期的报告，可以上传它",
  },
  dateOfValidTest: {
    en: "Date of valid test:",
    es: "Fecha de prueba válida:",
    pt: "Data do teste válido:",
    it: "Data del test valido:",
    fr: "Date du test valide :",
    zh_hans: "有效测试日期：",
  },
  retakeBack: {
    es: "Volver a tomar",
    en: "Retake",
    pt: "Refazer",
    it: "Rifare",
    fr: "Refaire",
    zh_hans: "重新拍摄",
  },
  takePhoto: {
    es: "Tomar foto",
    en: "Take Photo",
    pt: "Tirar foto",
    it: "Scattare foto",
    fr: "Prendre une photo",
    zh_hans: "拍照",
  },
  deliveryBlockOutError: {
    en: `Deliveries not permitted during Block Out time`,
    es: `Entregas no permitidas durante el tiempo bloqueado`,
    pt: `Entregas não permitidas durante o período de bloqueio`,
    it: `Consegne non consentite durante il periodo di blocco`,
    fr: `Livraisons non autorisées pendant la période de blocage`,
    zh_hans: `禁止在封锁时间内进行交付`,
  },
  addAnother: {
    es: "Agregar otro",
    en: "Add Another",
    pt: "Adicionar outro",
    it: "Aggiungi un altro",
    fr: "Ajouter un autre",
    zh_hans: "添加另一个",
  },
  uploadANewerDrugtest: {
    en: "Upload a Newer Drug Test",
    es: "Subir una prueba de drogas más reciente",
    pt: "Enviar um Teste de Drogas Mais Recente",
    it: "Carica un Nuovo Test di Droga",
    fr: "Télécharger un Test de Drogue Plus Récent",
    zh_hans: "上传较新的毒品测试",
  },
  done: {
    es: "Hecho",
    en: "Done",
    pt: "Concluído",
    it: "Fatto",
    fr: "Terminé",
    zh_hans: "完成",
  },
  profilePhotoToBeUploaded: {
    es: "Foto de perfil para cargar",
    en: "Profile Photo to be Uploaded",
    pt: "Foto de Perfil a ser Enviada",
    it: "Foto del Profilo da Caricare",
    fr: "Photo de Profil à Télécharger",
    zh_hans: "要上传的个人资料照片",
  },
  imageIsRequired: {
    es: "La imagen es requerida",
    en: "Image is Required",
    pt: "A imagem é obrigatória",
    it: "L'immagine è obbligatoria",
    fr: "L'image est requise",
    zh_hans: "需要图片",
  },
  addYourPhoto: {
    es: "Agregar tu foto",
    en: "Add Your Photo",
    pt: "Adicione sua foto",
    it: "Aggiungi la tua foto",
    fr: "Ajoutez votre photo",
    zh_hans: "添加您的照片",
  },
  updateYourPhoto: {
    es: "Actualiza tu foto",
    en: "Update Your Photo",
    pt: "Atualize sua foto",
    it: "Aggiorna la tua foto",
    fr: "Mettre à jour votre photo",
    zh_hans: "更新您的照片",
  },
  cancel: {
    es: "Cancelar",
    en: "Cancel",
    pt: "Cancelar",
    it: "Annulla",
    fr: "Annuler",
    zh_hans: "取消",
  },
  letsGetStarted: {
    es: "Empecemos",
    en: "Let's get Started",
    pt: "Vamos começar",
    it: "Iniziamo",
    fr: "Commençons",
    zh_hans: "让我们开始吧",
  },
  profilePhotoUploadedSuccessfullyForVerification: {
    es: "Foto de perfil cargada correctamente para verificación",
    en: "Profile Photo uploaded successfully for verification",
    pt: "Foto de perfil enviada com sucesso para verificação",
    it: "Foto del profilo caricata con successo per la verifica",
    fr: "Photo de profil téléchargée avec succès pour vérification",
    zh_hans: "成功上传个人资料照片进行验证",
  },
  useAnEmailAddressYouWillAlwaysHaveAccessTo: {
    es: "Utilice una dirección de correo electrónico a la que siempre tendrá acceso para que siempre pueda acceder a su información.",
    en: "Use an email address you will always have access to so you can always access your information.",
    pt: "Use um endereço de e-mail ao qual você sempre terá acesso para poder acessar suas informações sempre que precisar.",
    it: "Utilizza un indirizzo email a cui avrai sempre accesso in modo da poter sempre accedere alle tue informazioni.",
    fr: "Utilisez une adresse e-mail à laquelle vous aurez toujours accès afin de pouvoir toujours accéder à vos informations.",
    zh_hans: "请使用您随时可以访问的电子邮件地址，以便您随时访问您的信息。",
  },
  enterAValidEmail: {
    es: "Introduzca un correo electrónico válido",
    en: "Enter a valid Email",
    pt: "Digite um email válido",
    it: "Inserisci un indirizzo email valido",
    fr: "Entrez une adresse e-mail valide",
    zh_hans: "请输入有效的电子邮件地址",
  },
  weDoNotSendSpamEmails: {
    es: "no enviamos spam ni correos electrónicos de marketing",
    en: "we do not send spam or marketing emails",
    pt: "não enviamos spam ou emails de marketing",
    it: "non inviamo spam o email di marketing",
    fr: "nous n'envoyons pas de spam ni d'e-mails de marketing",
    zh_hans: "我们不会发送垃圾邮件或营销邮件",
  },
  iDontHaveEmailAddress: {
    es: "no tengo una dirección de correo electrónico",
    en: "I don't have an email address",
    pt: "Não tenho um endereço de e-mail",
    it: "Non ho un indirizzo email",
    fr: "Je n'ai pas d'adresse e-mail",
    zh_hans: "我没有电子邮件地址",
  },
  enterBelowDetails: {
    es: "Ingrese los detalles a continuación",
    en: "Enter below details",
    pt: "Digite os detalhes abaixo",
    it: "Inserisci i dettagli di seguito",
    fr: "Entrez les détails ci-dessous",
    zh_hans: "在下面输入详细信息",
  },
  enterValidNameWithAFirstAndLastNameWithSpace: {
    es: "Ingrese un nombre válido con Nombre y Apellido con espacio entre ellos",
    en: "Enter valid name with a First and Last Name with spacing between them",
    pt: "Digite um nome válido com Nome e Sobrenome separados por espaço",
    it: "Inserisci un nome valido con Nome e Cognome separati da spazio",
    fr: "Entrez un nom valide avec un prénom et un nom de famille séparés par un espace",
    zh_hans: "输入有效的名字，名字和姓氏之间用空格隔开",
  },
  firstNameOfYourChildhoodFriendCannotcontainSpace: {
    es: "El nombre de tu amigo de la infancia no puede contener espacios ni caracteres especiales.",
    en: "First Name of your childhood friend cannot contain a space or any special characters.",
    pt: "O primeiro nome do seu amigo de infância não pode conter espaços nem caracteres especiais.",
    it: "Il nome del tuo amico d'infanzia non può contenere spazi o caratteri speciali.",
    fr: "Le prénom de votre ami d'enfance ne peut pas contenir d'espace ni de caractères spéciaux.",
    zh_hans: "你小时候的朋友的名字不能包含空格或任何特殊字符。",
  },
  enterYourFullName: {
    es: "Ingrese su Nombre Completo (Nombre + Apellido)",
    en: "Enter your Full Name (First Name + Last Name)",
    pt: "Digite seu nome completo (Nome + Sobrenome)",
    it: "Inserisci il tuo nome completo (Nome + Cognome)",
    fr: "Entrez votre nom complet (prénom + nom de famille)",
    zh_hans: "输入您的全名（名字+姓氏）",
  },
  pleaseEnterYourNameToContinue: {
    es: "Por favor ingrese su nombre para continuar",
    en: "Please enter your name to continue",
    pt: "Por favor, digite seu nome para continuar",
    it: "Per favore inserisci il tuo nome per continuare",
    fr: "Veuillez entrer votre nom pour continuer",
    zh_hans: "请输入您的名字以继续",
  },
  enterYourDateOfBirth: {
    es: "Introduzca su fecha de nacimiento",
    en: "Enter your Date of Birth",
    pt: "Digite sua Data de Nascimento",
    it: "Inserisci la tua data di nascita",
    fr: "Entrez votre date de naissance",
    zh_hans: "输入您的出生日期",
  },
  securityQuestion: {
    es: "Pregunta de seguridad",
    en: "Security Question",
    pt: "Pergunta de Segurança",
    it: "Domanda di sicurezza",
    fr: "Question de sécurité",
    zh_hans: "安全问题",
  },
  whatIsFirstNameOfYourChildhoodBestFriend: {
    es: "¿Cuál es el nombre de tu mejor amigo de la infancia?",
    en: "What is the first name of your childhood best friend?",
    pt: "Qual é o primeiro nome do seu melhor amigo de infância?",
    it: "Qual è il nome del tuo migliore amico d'infanzia?",
    fr: "Quel est le prénom de votre meilleur ami d'enfance ?",
    zh_hans: "您小时候最好的朋友叫什么名字？",
  },
  enterSecurityQuestionsAnswerToContinue: {
    es: "Ingrese la respuesta de la pregunta de seguridad para continuar",
    en: "Enter Security Question's Answer to continue",
    pt: "Digite a resposta da pergunta de segurança para continuar",
    it: "Inserisci la risposta alla domanda di sicurezza per continuare",
    fr: "Entrez la réponse à la question de sécurité pour continuer",
    zh_hans: "请输入安全问题的答案以继续",
  },
  back: {
    es: "Atrás",
    en: "Back",
    pt: "Voltar",
    it: "Indietro",
    fr: "Retour",
    zh_hans: "返回",
  },
  completeOrientation: {
    es: "Orientación Completa",
    en: "Complete Orientation",
    pt: "Concluir Orientação",
    it: "Completa Orientamento",
    fr: "Terminer l'orientation",
    zh_hans: "完成导引",
  },
  signBelow: {
    es: "Firme abajo",
    en: "Sign below",
    pt: "Assine abaixo",
    it: "Firma qui sotto",
    fr: "Signez ci-dessous",
    zh_hans: "在下方签名",
  },
  uploadAPhoto: {
    es: "Sube una foto",
    en: "Upload A Photo",
    pt: "Carregar uma foto",
    it: "Carica una foto",
    fr: "Télécharger une photo",
    zh_hans: "上传照片",
  },
  noteAllPhotosAreReviewedForVerification: {
    es: "NOTA: Todas las fotos son revisadas para verificación",
    en: "NOTE: All photos are reviewed for verification",
    pt: "NOTA: Todas as fotos são revisadas para verificação",
    it: "NOTA: Tutte le foto vengono controllate per la verifica",
    fr: "REMARQUE : Toutes les photos sont vérifiées pour vérification",
    zh_hans: "注意：所有照片都会进行审核以进行验证",
  },
  uploadImageFromYourDevice: {
    es: "Sube la imagen desde tu dispositivo",
    en: "Upload image from your device",
    pt: "Carregar imagem do seu dispositivo",
    it: "Carica immagine dal tuo dispositivo",
    fr: "Télécharger une image depuis votre appareil",
    zh_hans: "从您的设备上传图片",
  },
  clearSignature: {
    es: "Firma clara",
    en: "Clear signature",
    pt: "Limpar assinatura",
    it: "Cancella firma",
    fr: "Effacer la signature",
    zh_hans: "清除签名",
  },
  question: {
    es: "Pregunta",
    en: "Question",
    pt: "Pergunta",
    it: "Domanda",
    fr: "Question",
    zh_hans: "问题",
  },
  answerIsRequired: {
    es: "La respuesta es requerida",
    en: "Answer is required",
    pt: "Resposta é obrigatória",
    it: "La risposta è obbligatoria",
    fr: "La réponse est obligatoire",
    zh_hans: "答案是必填项",
  },
  incorrect: {
    es: "Incorrecto",
    en: "Incorrect",
    pt: "Incorreto",
    it: "Errato",
    fr: "Incorrect",
    zh_hans: "不正确",
  },
  correct: {
    es: "¡Correcto!",
    en: "Correct!",
    pt: "Correto!",
    it: "Corretto!",
    fr: "Correct !",
    zh_hans: "正确！",
  },
  startOver: {
    es: "Comenzar de nuevo",
    en: "Start Over",
    pt: "Começar novamente",
    it: "Ricomincia",
    fr: "Recommencer",
    zh_hans: "重新开始",
  },
  registrationCompleted: {
    es: "Registro completado",
    en: "Registration Completed",
    pt: "Registro concluído",
    it: "Registrazione completata",
    fr: "Inscription terminée",
    zh_hans: "注册完成",
  },
  orientationCompleted: {
    es: "Orientación Completada",
    en: "Orientation Completed",
    pt: "Orientação concluída",
    it: "Orientamento completato",
    fr: "Orientation terminée",
    zh_hans: "导引完成",
  },
  whenYouArriveOnProject: {
    es: "Cuando llegas al proyecto,",
    en: "When you arrive at the project,",
    pt: "Quando você chegar ao projeto,",
    it: "Quando arrivi al progetto,",
    fr: "Lorsque vous arrivez sur le projet,",
    zh_hans: "当你到达项目时,",
  },
  beforeStartingWorkCheckInWithGC: {
    es: "antes de comenzar a trabajar consulte con el contratista general",
    en: "before starting work check in with the General Contractor",
    pt: "antes de iniciar o trabalho, verifique com o Contratante Geral",
    it: "prima di iniziare il lavoro, verifica con l'Appaltatore Generale",
    fr: "avant de commencer le travail, vérifiez auprès de l'Entrepreneur Général",
    zh_hans: "开始工作之前，请与总承包商确认",
  },
  toFindOutWhenTheyWillHoldTheNextInPerson: {
    es: "para saber cuándo tendrán la próxima Orientación en persona.",
    en: "to find out when they will hold the next in-person Orientation.",
    pt: "para descobrir quando eles realizarão a próxima Orientação presencial.",
    it: "per scoprire quando terranno la prossima Orientazione in presenza.",
    fr: "pour savoir quand aura lieu la prochaine orientation en personne.",
    zh_hans: "了解他们将何时进行下一次现场导引。",
  },
  hours: {
    en: "Hours",
    es: "Horas",
    pt: "Horas",
    it: "Ore",
    fr: "Heures",
    zh_hans: "小时",
  },

  allMarkedGoodChangeResultToPassOrMarkDeficient: {
    en: "All items of the checklist are logged as GOOD. Either change the inspection to Pass or select an item(s) that is deficient.",
    es: "Todos los elementos de la lista de verificación se registran como BUENOS. Cambie la inspección a Aprobada o seleccione un elemento que esté deficiente.",
    pt: "Todos os itens da lista de verificação estão marcados como BOM. Altere a inspeção para Aprovada ou selecione um item que está deficiente.",
    it: "Tutti gli elementi della checklist sono registrati come BUONI. Cambia l'ispezione a Pass o seleziona un elemento che è carente.",
    fr: "Tous les éléments de la liste de contrôle sont enregistrés comme BONS. Modifiez l'inspection en Pass ou sélectionnez un élément qui est défectueux.",
    zh_hans:
      "检查表中的所有项目都被标记为良好。要么将检查更改为通过，要么选择一个有问题的项目。",
  },
  beginOrientationInPreferredLanguage: {
    en: "Begin orientation in preferred language",
    es: "Comience la orientación en el idioma preferido",
    pt: "Inicie a orientação no idioma preferido",
    it: "Inizia l'orientamento nella lingua preferita",
    fr: "Commencez l'orientation dans la langue de votre choix",
    zh_hans: "以首选语言开始导向",
  },
  completeThisChecklistForSelecteditems: {
    en: "Complete this checklist for all Inspections marked PASS. Any NEGATIVE findings need to be logged on the previous screen",
    es: "Complete esta lista de verificación para todas las Inspecciones marcadas como APROBADO. Cualquier hallazgo NEGATIVO debe registrarse en la pantalla anterior",
    pt: "Complete esta lista de verificação para todas as Inspeções marcadas como APROVADO. Quaisquer resultados NEGATIVOS precisam ser registrados na tela anterior",
    it: "Completa questa checklist per tutte le ispezioni contrassegnate come PASS. Eventuali riscontri NEGATIVI devono essere registrati nella schermata precedente",
    fr: "Complétez cette liste de contrôle pour toutes les inspections marquées PASS. Tout constat NÉGATIF doit être enregistré sur l'écran précédent",
    zh_hans:
      "为所有标记为通过的检查完成此清单。任何负面发现都需要记录在前一个屏幕上",
  },
  deficientItemExplainer: {
    en: "An item was logged as deficient. Confirm the appropriate action was taken and the necessary personnel were notified (GC, supervisor, office, etc.)",
    es: "Se registró un elemento como deficiente. Confirme que se tomó la acción adecuada y se notificó al personal necesario (GC, supervisor, oficina, etc.)",
    pt: "Um item foi registrado como deficiente. Confirme se a ação apropriada foi tomada e se o pessoal necessário foi notificado (GC, supervisor, escritório, etc.)",
    it: "Un elemento è stato registrato come carente. Confermare che sia stata adottata l'azione appropriata e che il personale necessario sia stato informato (GC, supervisore, ufficio, ecc.)",
    fr: "Un élément a été enregistré comme défectueux. Confirmez que la mesure appropriée a été prise et que le personnel nécessaire a été informé (GC, superviseur, bureau, etc.)",
    zh_hans:
      "某项物品被记录为有缺陷。请确认已采取适当措施，并已通知必要的人员（总承包商、主管、办公室等）。",
  },
  takePhotosOfYouAreInspecting: {
    en: "Take photos of what you are inspecting. Capture the entire object. Include the model #, serial #, gauges, and any other relevant part of what is being inspected.",
    es: "Toma fotos de lo que estás inspeccionando. Captura todo el objeto. Incluye el número de modelo, número de serie, medidores y cualquier otra parte relevante de lo que se está inspeccionando.",
    pt: "Tire fotos do que você está inspecionando. Capture o objeto inteiro. Inclua o número do modelo, número de série, medidores e qualquer outra parte relevante do que está sendo inspecionado.",
    it: "Scatta foto di ciò che stai ispezionando. Cattura l'intero oggetto. Includi il numero di modello, il numero di serie, i calibri e qualsiasi altra parte rilevante di ciò che viene ispezionato.",
    fr: "Prenez des photos de ce que vous inspectez. Capturez l'objet entier. Incluez le numéro de modèle, le numéro de série, les jauges et toute autre partie pertinente de ce qui est inspecté.",
    zh_hans:
      "拍摄您正在检查的物品的照片。拍摄整个对象。包括型号、序列号、量规以及正在检查的物品的任何其他相关部分。",
  },
  whatDidYouDoToMakeSafe: {
    en: "What did you do to 'make safe', 'safe-off', or 'correct' this item to protect yourself and others?",
    es: "¿Qué hiciste para 'hacer seguro', 'apagar de manera segura' o 'corregir' este elemento para protegerte a ti mismo y a los demás?",
    pt: "O que você fez para 'tornar seguro', 'desligar com segurança' ou 'corrigir' este item para proteger a si mesmo e aos outros?",
    it: "Cosa hai fatto per 'rendere sicuro', 'spegnerlo in sicurezza' o 'correggere' questo oggetto per proteggere te stesso e gli altri?",
    fr: "Qu'avez-vous fait pour « rendre sûr », « sécuriser » ou « corriger » cet élément afin de vous protéger, vous et les autres ?",
    zh_hans:
      "为了保护自己和他人，您采取了什么措施来“保持安全”，“关闭安全”或“纠正”这个物品？",
  },
  confirmThisIsCorrectModelNumber: {
    en: "Confirm this is the correct Location or Model/ID # listed on the item or QR Code being inspected (equipment, tool, machine, etc)",
    es: "Confirme si esta es la Ubicación o el Número de Modelo/ID correcto que aparece en el artículo o código QR que se está inspeccionando (equipo, herramienta, máquina, etc.)",
    pt: "Confirme se esta é a Localização correta ou o Número de Modelo/ID listado no item ou Código QR que está sendo inspecionado (equipamento, ferramenta, máquina, etc.)",
    it: "Conferma che questa sia la posizione corretta o il Numero di Modello/ID elencato sull'oggetto o sul codice QR che viene ispezionato (attrezzatura, utensile, macchina, ecc.)",
    fr: "Confirmez s'il s'agit de l'emplacement correct ou du numéro de modèle/ID indiqué sur l'article ou le code QR en cours d'inspection (équipement, outil, machine, etc.)",
    zh_hans:
      "确认这是否是正在检查的物品或QR码（设备、工具、机器等）上列出的正确位置或型号/ID号码。",
  },
  doesNotMatchConfirmSelectedCorrectInspectionAndInspectingCorrectEquipment: {
    en: "Does not match? Confirm you selected the correct inspection and inspecting the correct equipment",
    es: "¿No coincide? Confirme que seleccionó la inspección correcta y está inspeccionando el equipo correcto",
    pt: "Não corresponde? Confirme se selecionou a inspeção correta e está inspecionando o equipamento correto",
    it: "Non corrisponde? Conferma di aver selezionato l'ispezione corretta e stai ispezionando l'attrezzatura corretta",
    fr: "Ça ne correspond pas ? Confirmez que vous avez sélectionné la bonne inspection et que vous inspectez l'équipement correct",
    zh_hans: "不匹配？确认您选择了正确的检查并且正在检查正确的设备",
  },
  emailAddressWasNotProvidedOrDoesntExist: {
    en: "Email address was not provided or does not exist. User will not receive Orientation Confirmation by email",
    es: "No se proporcionó una dirección de correo electrónico o no existe. El usuario no recibirá la Confirmación de Orientación por correo electrónico",
    it: "L'indirizzo email non è stato fornito o non esiste. L'utente non riceverà la Conferma dell'Orientamento via email",
    pt: "O endereço de e-mail não foi fornecido ou não existe. O usuário não receberá a Confirmação de Orientação por e-mail",
    fr: "L'adresse e-mail n'a pas été fournie ou n'existe pas. L'utilisateur ne recevra pas la Confirmation d'Orientation par e-mail",
    zh_hans: "未提供电子邮件地址或不存在。用户将无法通过电子邮件收到方向确认",
  },
  projectSpecificInfoNotifcationsCheckbox: {
    en: "Project-specific information like emergency notifications and weather closures may be sent by text message. Check this box to opt-out.",
    es: "Se pueden enviar información específica del proyecto como notificaciones de emergencia y cierres del proyecto debido al clima por mensaje de texto. Marque esta casilla para optar por no participar.",
    pt: "Informações específicas do projeto, como notificações de emergência e fechamentos devido ao clima, podem ser enviadas por mensagem de texto. Marque esta caixa para optar por não participar.",
    it: "Informazioni specifiche del progetto come notifiche di emergenza e chiusure a causa delle condizioni meteorologiche possono essere inviate tramite messaggio di testo. Seleziona questa casella per non partecipare.",
    fr: "Des informations spécifiques au projet, telles que des notifications d'urgence et des fermetures en raison des conditions météorologiques, peuvent être envoyées par SMS. Cochez cette case pour ne pas participer.",
    zh_hans:
      "项目特定信息，如紧急通知和天气关闭，可能会通过短信发送。勾选此框以选择退出。",
  },
  areYouSureYouMayMissLifeSavingMsgs: {
    en: "Are you sure? You may miss life-saving messages.",
    es: "¿Estás seguro? Puedes perder mensajes que salvan vidas.",
    pt: "Tem certeza? Você pode perder mensagens que salvam vidas.",
    it: "Sei sicuro? Potresti perdere messaggi che salvano vite.",
    fr: "Êtes-vous sûr ? Vous pourriez manquer des messages qui sauvent des vies.",
    zh_hans: "你确定吗？你可能会错过拯救生命的消息。",
  },

  continueWithoutPhoneNumber: {
    en: "Continue without phone number",
    es: "Continuar sin número de teléfono",
    pt: "Continuar sem número de telefone",
    it: "Continua senza numero di telefono",
    fr: "Continuer sans numéro de téléphone",
    zh_hans: "继续没有电话号码",
  },

  fullyReviewThisTBTWithYourCrew: {
    en: "Fully review this Toolbox Talk with your crew",
    es: "Revise completamente esta Toolbox Talk con su equipo",
    pt: "Revise completamente esta Reunião Pré-Trabalho com sua equipe",
    it: "",
    fr: "",
    zh_hans: "",
  },
  topic: {
    en: "Topic",
    es: "Tema",
    pt: "Tema",
    it: "",
    fr: "",
    zh_hans: "",
  },
  toolboxTalkContent: {
    en: "Toolbox Talk Content",
    es: "Contenido de la charla de caja de herramientas",
    pt: "Conteúdo da Reunião Pré-Trabalho",
    it: "",
    fr: "",
    zh_hans: "",
  },
  byContinuingYouConfirmYouHaveProvidedAllInformationToYourCrew: {
    en: "By continuing, you confirm you have provided all information to your crew",
    es: "Al continuar, confirma que ha proporcionado toda la información a su equipo",
    pt: "Ao continuar, você confirma que forneceu todas as informações à sua equipe",
    fr: "",
    it: "",
    zh_hans: "",
  },

  whatLanguage: {
    en: "What is your preferred Language?",
    es: "¿Cuál es tu idioma preferido?",
    pt: "Qual é o seu idioma preferido?",
    it: "Qual è la tua lingua preferita?",
    fr: "Quelle est votre langue préférée?",
    zh_hans: "您的首选语言是什么？",
  },
  youHaveNeverCompletedThisOrientationOrExpired: {
    en: `You have never completed this orientation or it has expired. Please stay and watch it now.`,
    es: `Nunca has completado esta orientación o ha expirado. Por favor, quédate y mírala ahora.`,
    pt: `Você nunca concluiu esta orientação ou ela expirou. Por favor, fique e assista agora.`,
    it: `Non hai mai completato questa orientamento o è scaduto. Ti preghiamo di rimanere e guardarlo ora.`,
    fr: `Vous n'avez jamais terminé cette orientation ou elle a expiré. Veuillez rester et la regarder maintenant.`,
    zh_hans: `您从未完成过这个导向活动，或者它已经过期了。请留下来现在观看。`,
  },
  orientationCompletionThankYou: {
    en: "Thank you for completing this portion of the Orientation.",
    es: "Gracias por completar esta parte de la Orientación.",
    pt: "Obrigado por concluir esta parte da Orientação.",
    it: "Grazie per aver completato questa parte dell'Orientamento.",
    fr: "Merci d'avoir terminé cette partie de l'Orientation.",
    zh_hans: "感谢您完成本部分的导引。",
  },
  SiteFormIncIsCommitedToYourPrivacy: {
    en: "SiteForm, Inc. is committed to your privacy. We will not send spam emails or sell your information.",
    es: "SiteForm, Inc. se compromete con tu privacidad. No enviaremos correos electrónicos de spam ni venderemos tu información.",
    pt: "A SiteForm, Inc. está comprometida com a sua privacidade. Não enviaremos emails de spam nem venderemos suas informações.",
    it: "SiteForm, Inc. è impegnata per la tua privacy. Non invieremo email di spam né venderemo le tue informazioni.",
    fr: "SiteForm, Inc. s'engage à protéger votre vie privée. Nous n'enverrons pas d'emails indésirables (spam) ni ne vendrons vos informations.",
    zh_hans:
      "SiteForm，Inc. 致力于保护您的隐私。我们不会发送垃圾邮件，也不会出售您的信息.",
  },
  thisProjectRequiresYouToAddAPhotoOfYourself: {
    es: "Este proyecto requiere que agregues una foto tuya. Seleccione una opción a continuación para tomar una selfie o cargar una foto.",
    en: "This project requires you to add a photo of yourself. Select an option below to take a selfie or upload a photo.",
    pt: "Este projeto requer que você adicione uma foto sua. Selecione uma opção abaixo para tirar uma selfie ou fazer o upload de uma foto.",
    it: "Questo progetto richiede di aggiungere una tua foto. Seleziona un'opzione di seguito per scattare un selfie o caricare una foto.",
    fr: "Ce projet nécessite que vous ajoutiez une photo de vous-même. Sélectionnez une option ci-dessous pour prendre un selfie ou télécharger une photo.",
    zh_hans: "此项目要求您添加自己的照片。请选择以下选项以自拍或上传照片。",
  },
  tryGoingBackAndRegisterAgain: {
    es: "Intenta volver a la página de registro y regístrate de nuevo para abrir esta página.",
    en: "Try going back to the registration page and register again to open this page.",
    pt: "Tente voltar para a página de registro e se registrar novamente para abrir esta página.",
    it: "Prova a tornare alla pagina di registrazione e registrati di nuovo per aprire questa pagina.",
    fr: "Essayez de revenir à la page d'inscription et de vous inscrire à nouveau pour ouvrir cette page.",
    zh_hans: "尝试返回注册页面并重新注册以打开此页面。",
  },
  agcUniversalOrientation: {
    en: "AGC Universal Orientation",
    es: "Orientación universal AGC",
    pt: "Orientação Universal AGC",
    it: "Orientamento universale AGC",
    fr: "Orientation universelle AGC",
    zh_hans: "AGC通用方向",
  },
  yourPhoneNumberMayBeUsed: {
    en: "Your phone number may be used to provide project-specific information such as emergency notifications and weather impacts.",
    es: "Su número de teléfono puede ser utilizado para proporcionar información específica del proyecto, como notificaciones de emergencia e impactos climáticos.",
    pt: "Seu número de telefone pode ser usado para fornecer informações específicas do projeto, como notificações de emergência e impactos climáticos.",
    it: "Il tuo numero di telefono potrebbe essere utilizzato per fornire informazioni specifiche del progetto come notifiche di emergenza e impatti meteorologici.",
    fr: "Votre numéro de téléphone peut être utilisé pour fournir des informations spécifiques au projet, telles que les notifications d'urgence et les impacts météorologiques.",
    zh_hans: "您的电话号码可能会被用于提供项目特定信息，如紧急通知和天气影响。",
  },
  pleaseAcceptTermsOfService: {
    es: "Acepte los Términos de servicio y la Política de privacidad para continuar",
    en: "Please accept the Terms of Service and Privacy Policy to continue",
    pt: "Por favor, aceite os Termos de Serviço e a Política de Privacidade para continuar",
    it: "Si prega di accettare i Termini di Servizio e l'Informativa sulla Privacy per continuare",
    fr: "Veuillez accepter les conditions d'utilisation et la politique de confidentialité pour continuer",
    zh_hans: "请接受使用条款和隐私政策以继续",
  },
  warningOnlyAddPhotosOfActualCerts: {
    en: "Warning - ONLY ADD PHOTOS OF ACTUAL CERTS. INCLUDE FRONT AND BACK. RETAKE PICTURE IF IMAGE IS BLURRY",
    es: "Advertencia: SOLO AGREGUE FOTOS DE CERTIFICADOS REALES. INCLUYA LA PARTE FRONTAL Y POSTERIOR. VUELVA A TOMAR LA FOTO SI LA IMAGEN ESTÁ BORROSA",
    pt: "Aviso - ADICIONE APENAS FOTOS DE CERTIFICADOS REAIS. INCLUA A FRENTE E O VERSO. REPITA A FOTO SE A IMAGEM ESTIVER BORRADA",
    it: "Avviso: AGGIUNGERE SOLO FOTO DI CERTIFICATI REALI. INCLUDERE FRONTE E RETRO. RIPRENDERE LA FOTO SE L'IMMAGINE È SFUOCATA",
    fr: "Avertissement - AJOUTEZ UNIQUEMENT DES PHOTOS DE CERTIFICATS RÉELS. INCLURE LE RECTO ET LE VERSO. REPRENDRE LA PHOTO SI L'IMAGE EST FLUO",
    zh_hans:
      "警告- 仅添加真实证书的照片。包括正面和背面。如果图像模糊，请重新拍照",
  },
  EnterYOurEmailAndWeWillCheck: {
    en: "Enter your email, we'll check if you need to create a new account.",
    es: "Ingresa tu correo electrónico, verificaremos si necesitas crear una cuenta nueva.",
    pt: "Digite seu e-mail, verificaremos se você precisa criar uma nova conta.",
    it: "Inserisci la tua email, verificheremo se è necessario creare un nuovo account.",
    fr: "Entrez votre adresse e-mail, nous vérifierons si vous devez créer un nouveau compte.",
    zh_hans: "输入您的电子邮件，我们将检查是否需要创建新账户。",
  },

  selectLocations: {
    en: "Select Location(s)",
    es: "Seleccionar ubicación(es)",
    pt: "Selecionar local(is)",
    it: "Seleziona posizione(i)",
    fr: "Sélectionner emplacement(s)",
    zh_hans: "选择位置",
  },

  takePictureOfHuddleBoard: {
    en: `Take a picture of your PTP Huddle Board`,
    es: `Toma una foto de tu tablero de reuniones PTP`,
    pt: `Tire uma foto do seu quadro de reuniões PTP`,
    it: `Fai una foto del tuo tabellone delle riunioni PTP`,
    fr: `Prenez une photo de votre tableau de réunion PTP`,
    zh_hans: `拍下你的PTP讨论板`,
  },

  takePhotoOfYourCrew: {
    en: "Take a photo of your crew",
    es: "Toma una foto de tu equipo",
    pt: "Tire uma foto da sua equipe",
    it: "Fai una foto della tua squadra",
    fr: "Prenez une photo de votre équipe",
    zh_hans: "拍下你的团队照片",
  },

  addImage: {
    en: "Add Image",
    es: "Agregar imagen",
    pt: "Adicionar imagem",
    it: "Aggiungi immagine",
    fr: "Ajouter une image",
    zh_hans: "添加图片",
  },

  wasAnyoneInjuredLastTimeYouWorked: {
    en: "Was anyone injured the last time you worked?",
    es: "¿Alguien resultó herido la última vez que trabajaste?",
    pt: "Alguém se machucou da última vez que você trabalhou?",
    it: "Qualcuno si è infortunato l'ultima volta che hai lavorato?",
    fr: "Quelqu'un a-t-il été blessé la dernière fois que vous avez travaillé ?",
    zh_hans: "上次你工作时有人受伤了吗？",
  },

  selectSubcontractor: {
    en: "Select Subcontractor",
    es: "Seleccionar subcontratista",
    pt: "Selecionar subcontratado",
    it: "Seleziona subappaltatore",
    fr: "Sélectionner un sous-traitant",
    zh_hans: "选择分包商",
  },

  yourCompany: {
    en: "Your company",
    es: "Tu empresa",
    pt: "Sua empresa",
    it: "La tua azienda",
    fr: "Votre entreprise",
    zh_hans: "你的公司",
  },

  othersOnThisProject: {
    en: "Others on this project",
    es: "Otros en este proyecto",
    pt: "Outros neste projeto",
    it: "Altri in questo progetto",
    fr: "D'autres sur ce projet",
    zh_hans: "该项目中的其他人",
  },

  searchForUsers: {
    en: "Search for Users",
    es: "Buscar usuarios",
    pt: "Buscar usuários",
    it: "Cerca utenti",
    fr: "Rechercher des utilisateurs",
    zh_hans: "搜索用户",
  },

  yourCrew: {
    en: "(Your Crew)",
    es: "(Tu tripulación)",
    pt: "(Sua tripulação)",
    it: "(Il tuo equipaggio)",
    fr: "(Votre équipage)",
    zh_hans: "（你的船员",
  },

  crewLead: {
    en: "Crew Lead",
    es: "Líder de equipo",
    pt: "Líder da equipe",
    it: "Capo squadra",
    fr: "Responsable d'équipe",
    zh_hans: "团队负责人",
  },

  howDoYouWantToDocumentWorkers: {
    en: "How do you want to document your workers?",
    es: "¿Cómo quieres documentar a tus trabajadores?",
    pt: "Como você deseja documentar seus trabalhadores?",
    it: "Come vuoi documentare i tuoi lavoratori?",
    fr: "Comment souhaitez-vous documenter vos travailleurs ?",
    zh_hans: "您想如何记录您的工人？",
  },

  niceWork: {
    en: "Nice Work!",
    es: "¡Buen trabajo!",
    pt: "Bom trabalho!",
    it: "Bel lavoro!",
    fr: "Bon travail !",
    zh_hans: "干得好！",
  },

  ptpSubmitted: {
    en: "PTP Submitted",
    es: "PTP enviado",
    pt: "PTP enviado",
    it: "PTP inviato",
    fr: "PTP soumis",
    zh_hans: "PTP已提交",
  },

  selectTasksYouArePerforming: {
    en: "Select the tasks you are performing",
    es: "Selecciona las tareas que estás realizando",
    pt: "Selecione as tarefas que você está realizando",
    it: "Seleziona le attività che stai svolgendo",
    fr: "Sélectionnez les tâches que vous effectuez",
    zh_hans: "选择您正在执行的任务",
  },

  signYourName: {
    en: "Sign your name",
    es: "Firma tu nombre",
    pt: "Assine seu nome",
    it: "Firma il tuo nome",
    fr: "Signez votre nom",
    zh_hans: "签名",
  },

  bySigningYouAgreeToSiteform: {
    en: "By signing and submitting this PTP, you agree to SiteForm’s",
    es: "Al firmar y enviar este PTP, aceptas los",
    pt: "Ao assinar e enviar este PTP, você concorda com os",
    it: "Firmando e inviando questo PTP, accetti i",
    fr: "En signant et en soumettant ce PTP, vous acceptez les",
    zh_hans: "通过签署并提交此PTP，您同意SiteForm的",
  },

  termsOfSevice: {
    en: "Terms of Service",
    es: "Términos de servicio",
    pt: "Termos de serviço",
    it: "Termini di servizio",
    fr: "Conditions d'utilisation",
    zh_hans: "服务条款",
  },

  reportAnyUnsafeConditionsToSupervisorOrGc: {
    en: "and you will report any unsafe or hazardous conditions to your supervisor or the General Contractor instead of documenting them here.",
    es: "y reportarás cualquier condición insegura o peligrosa a tu supervisor o al contratista general en lugar de documentarlas aquí.",
    pt: "e você deverá relatar qualquer condição insegura ou perigosa ao seu supervisor ou ao empreiteiro geral em vez de documentá-las aqui.",
    it: "e riporterai eventuali condizioni pericolose o insicure al tuo supervisore o al contratto generale invece di documentarle qui.",
    fr: "et vous signalerez toute condition dangereuse ou dangereuse à votre superviseur ou à l'entrepreneur général au lieu de les documenter ici.",
    zh_hans:
      "您将向您的主管或总承包商报告任何不安全或危险的情况，而不是在这里记录。",
  },

  selectRole: {
    en: "Select Role",
    es: "Seleccionar rol",
    pt: "Selecionar função",
    it: "Seleziona ruolo",
    fr: "Sélectionner un rôle",
    zh_hans: "选择角色",
  },

  selectTrade: {
    en: "Select Trade",
    es: "Seleccionar oficio",
    pt: "Selecionar comércio",
    it: "Seleziona mestiere",
    fr: "Sélectionner un métier",
    zh_hans: "选择行业",
  },

  selectWorkersAtPtp: {
    en: "Select Workers at PTP",
    es: "Seleccionar trabajadores en PTP",
    pt: "Selecionar trabalhadores no PTP",
    it: "Seleziona lavoratori al PTP",
    fr: "Sélectionner les travailleurs au PTP",
    zh_hans: "选择PTP上的工人",
  },

  yourRole: {
    en: "Your Role",
    es: "Tu rol",
    pt: "Sua função",
    it: "Il tuo ruolo",
    fr: "Votre rôle",
    zh_hans: "你的角色",
  },

  yourTrade: {
    en: "Your Trade",
    es: "Tu oficio",
    pt: "Seu comércio",
    it: "Il tuo mestiere",
    fr: "Votre métier",
    zh_hans: "你的行业",
  },

  addNewWorker: {
    en: "Add new Worker",
    es: "Agregar nuevo trabajador",
    pt: "Adicionar novo trabalhador",
    it: "Aggiungi nuovo lavoratore",
    fr: "Ajouter un nouveau travailleur",
    zh_hans: "添加新工人",
  },

  submitting: {
    en: "Submitting",
    es: "Enviando",
    pt: "Enviando",
    it: "Inviando",
    fr: "En soumission",
    zh_hans: "提交中",
  },

  submittingQMark: {
    en: "Submitting?",
    es: "¿Enviando?",
    pt: "Enviando?",
    it: "Inviando?",
    fr: "En soumission ?",
    zh_hans: "提交中吗？",
  },

  reportReq: {
    en: "Report Reqd.",
    es: "Informe requerido.",
    pt: "Relatório necessário.",
    it: "Report richiesto.",
    fr: "Rapport requis.",
    zh_hans: "需要报告。",
  },

  whatWillYouDoTodayAndWhere: {
    en: "What will you do today and where?",
    es: "¿Qué harás hoy y dónde?",
    pt: "O que você fará hoje e onde?",
    it: "Cosa farai oggi e dove?",
    fr: "Que ferez-vous aujourd'hui et où ?",
    zh_hans: "你今天会做什么，在哪里？",
  },

  hoursPerWorker: {
    en: "Hours per worker",
    es: "Horas por trabajador",
    pt: "Horas por trabalhador",
    it: "Ore per lavoratore",
    fr: "Heures par travailleur",
    zh_hans: "每位工人的工作时间",
  },

  byPhoto: {
    en: "By Photo",
    es: "Por foto",
    pt: "Por foto",
    it: "Con foto",
    fr: "Par photo",
    zh_hans: "通过照片",
  },

  bySigning: {
    en: "By Signing",
    es: "Por firma",
    pt: "Por assinatura",
    it: "Con firma",
    fr: "Par signature",
    zh_hans: "通过签名",
  },

  deliveryOverlapWarning: (
    calendars_name: string,
    delivery_name: string,
    start_time: string,
    end_time: string,
  ) => ({
    en: `WARNING: This time slot is already booked on ${calendars_name} calendars for ${delivery_name} from ${start_time} to ${end_time}. Coordinate your delivery appropriately.`,
    es: `ADVERTENCIA: Este intervalo de tiempo ya está reservado en los calendarios de ${calendars_name} para ${delivery_name} desde ${start_time} hasta ${end_time}. Coordina tu entrega adecuadamente.`,
    pt: `AVISO: este horário já está reservado nas agendas de ${calendars_name} para ${delivery_name} de ${start_time} a ${end_time}. Coordene sua entrega adequadamente.`,
    it: `AVVISO: questa fascia oraria è già prenotata sui calendari di ${calendars_name} per ${delivery_name} dalle ${start_time} alle ${end_time}. Coordina la tua consegna di conseguenza.`,
    fr: `ATTENTION : Ce créneau horaire est déjà réservé sur les calendriers de ${calendars_name} pour ${delivery_name} de ${start_time} à ${end_time}. Coordonnez votre livraison en conséquence.`,
    zh_hans: `警告: 这个时间段已经在${calendars_name}的日历上为${delivery_name}从${start_time}到${end_time}预订了。请适当安排您的交付时间。`,
  }),

  signBelowToCompleteInspection: (workerName: string) => ({
    es: workerName + ", firma abajo para completar la inspección",
    en: workerName + ", sign below to complete the inspection.",
    pt: workerName + ", assine abaixo para completar a inspeção.",
    it: workerName + ", firma qui sotto per completare l'ispezione.",
    fr: workerName + ", signez ci-dessous pour terminer l'inspection.",
    zh_hans: workerName + "，请在下方签字以完成检查。",
  }),
  signBelowToCompleteOrientation: (workerName: string) => ({
    es: workerName + ", firma abajo para completar la orientación",
    en: workerName + ", sign below to complete orientation",
    pt: workerName + ", assine abaixo para completar a orientação",
    it: workerName + ", firma sotto per completare l'orientamento",
    fr: workerName + ", signez ci-dessous pour terminer l'orientation",
    zh_hans: workerName + "，请在下方签字以完成培训",
  }),
  uploadProfilePhotoFor: (workerName: string) => ({
    es: "Subir foto de perfil para " + workerName,
    en: "Upload Profile Photo for " + workerName,
    pt: "Enviar foto de perfil para " + workerName,
    it: "Carica foto del profilo per " + workerName,
    fr: "Télécharger une photo de profil pour " + workerName,
    zh_hans: "上传" + workerName + "的个人照片",
  }),

  notNameClickHere: (name: string) => ({
    en: `Not ${name}? Click Here`,
    es: `¿No ${name}? Haz clic aquí`,
    pt: `Não ${name}? Clique aqui`,
    it: `Non ${name}? Clicca qui`,
    fr: `Pas ${name} ? Cliquez ici`,
    zh_hans: `不是${name}？点击这里`,
  }),

  hiReadyToSubmitPtp: (name: string) => ({
    en: `Hi, ${name}! Ready to submit your Pre-Task Plan`,
    es: `¡Hola, ${name}! ¿Listo para enviar tu Plan de Pre-Tarea?`,
    pt: `Oi, ${name}! Pronto para enviar seu Plano de Pré-Tarefa?`,
    it: `Ciao, ${name}! Pronto a inviare il tuo Piano Pre-Lavoro?`,
    fr: `Salut, ${name} ! Prêt à soumettre votre Plan de Pré-Tâche ?`,
    zh_hans: `嗨，${name}！准备提交你的任务前计划了吗？`,
  }),

  warningDoNotUploadAnyThingThatcontainsPersonalInformation: {
    en: "WARNING - Do not upload any results or documentation that includes any personal or private medical information. SiteForm does not meet HIPAA data storage requirements. Only add simple results that document a passed or failed result. If you do not have this, see the GC",
    es: "ADVERTENCIA: No cargue ningún resultado o documentación que incluya información médica personal o privada. SiteForm no cumple con los requisitos de almacenamiento de datos de HIPAA. Solo agregue resultados simples que documenten un resultado aprobado o fallido. Si no tiene esto, consulte con el contratista general.",
    pt: "ATENÇÃO - Não faça upload de resultados ou documentação que incluam informações médicas pessoais ou privadas. O SiteForm não atende aos requisitos de armazenamento de dados da HIPAA. Apenas adicione resultados simples que documentem um resultado aprovado ou reprovado. Se você não tiver isso, consulte o GC.",
    it: "ATTENZIONE: Non caricare risultati o documentazione che includa informazioni personali o sanitarie private. SiteForm non rispetta i requisiti di archiviazione dati dell'HIPAA. Aggiungi solo risultati semplici che documentano un risultato positivo o negativo. Se non hai questo, consulta l'appaltatore generale.",
    fr: "ATTENTION - Ne téléchargez aucun résultat ou document comprenant des informations médicales personnelles ou confidentielles. SiteForm ne répond pas aux exigences de stockage des données HIPAA. Ajoutez uniquement des résultats simples qui documentent un résultat positif ou négatif. Si vous ne disposez pas de cela, veuillez consulter l'Entrepreneur Général.",
    zh_hans:
      "警告 - 请勿上传包含任何个人或私人医疗信息的结果或文档。SiteForm不符合HIPAA数据存储要求。只添加简单的结果，记录通过或失败的结果。如果您没有此项，请咨询总承包商。",
  },
  beforeStartingWorkContactGC: {
    es: "Antes de comenzar a trabajar, consulte con el contratista general para completar cualquier incorporación adicional y recibir una calcomanía de casco.",
    en: "Before starting work, check in with the General Contractor to complete any additional onboarding and receive a Hard Hat sticker.",
    pt: "Antes de iniciar o trabalho, entre em contato com o Contratante Geral para concluir qualquer integração adicional e receber um adesivo de capacete.",
    it: "Prima di iniziare a lavorare, contatta l'Appaltatore Generale per completare qualsiasi ulteriore onboarding e ricevere un adesivo per il casco.",
    fr: "Avant de commencer le travail, veuillez contacter l'Entrepreneur Général pour effectuer toute intégration supplémentaire et recevoir un autocollant de casque de sécurité.",
    zh_hans:
      "开始工作前，请与总承包商联系，完成任何额外的入职手续，并领取安全帽贴纸。",
  },
  orientationSignatureDisclaimer: (isProject: boolean) => ({
    en: `Great, you have completed our ${
      isProject ? "project" : ""
    } Orientation. By signing, you certify that you are the person that was registered and you fully completed this orientation; you provided the requested information truthfully to the best of your knowledge; you understand the information provided during orientation; ${
      isProject
        ? " you agree to the terms, rules, procedures, and policies of this project;"
        : ""
    }`,
    es: `Genial, has completado nuestra Orientación ${
      isProject ? "del proyecto" : ""
    }. Al firmar, certificas que eres la persona que se registró y completaste completamente esta orientación; proporcionaste la información solicitada de manera veraz según tu mejor conocimiento; comprendes la información proporcionada durante la orientación; ${
      isProject
        ? " aceptas los términos, reglas, procedimientos y políticas de este proyecto;"
        : ""
    }`,
    pt: `Ótimo, você concluiu nossa Orientação ${
      isProject ? "do projeto" : ""
    }. Ao assinar, você certifica que é a pessoa que foi registrada e que concluiu completamente esta orientação; você forneceu as informações solicitadas de forma verdadeira da melhor maneira que você sabe; você compreende as informações fornecidas durante a orientação; ${
      isProject
        ? " concorda com os termos, regras, procedimentos e políticas deste projeto;"
        : ""
    }`,
    it: `Ottimo, hai completato la nostra Orientamento ${
      isProject ? "del progetto" : ""
    }. Firmando, certifichi che tu sia la persona registrata e che hai completato completamente questa orientamento; hai fornito le informazioni richieste in modo veritiero secondo le tue migliori conoscenze; comprendi le informazioni fornite durante l'orientamento; ${
      isProject
        ? " accetti i termini, le regole, le procedure e le politiche di questo progetto;"
        : ""
    }`,
    fr: `Super, vous avez terminé notre Orientation ${
      isProject ? "du projet" : ""
    }. En signant, vous certifiez que vous êtes la personne qui a été enregistrée et que vous avez entièrement terminé cette orientation; vous avez fourni les informations demandées en toute sincérité au mieux de vos connaissances; vous comprenez les informations fournies pendant l'orientation; ${
      isProject
        ? " vous acceptez les termes, règles, procédures et politiques de ce projet;"
        : ""
    }`,
    zh_hans: `很好，您已经完成了我们的${
      isProject ? "项目" : ""
    }导向。签字时，您证明您是已注册的人，并且您已完全完成了这次导向；您根据您的最佳知识提供了所需的信息；您理解了导向期间提供的信息；${
      isProject ? "您同意本项目的条款、规则、程序和政策；" : ""
    }`,
  }),
  andYouAgreeToSiteForm: {
    en: "and you agree to SiteForm",
    es: "y aceptas SiteForm",
    pt: "e concordas com o SiteForm",
    it: "e accetti SiteForm",
    fr: "et vous acceptez SiteForm",
    zh_hans: "并且您同意 SiteForm",
  },
  dragAndDropCertFor: (workerName: string) => ({
    en: ` Simply drag-and-drop to upload a Certification or Training for ${workerName}.\n
      No need to log the type or expiration date...SiteForm will
      take care of that for you!`,

    es: `Simplemente arrastra y suelta para cargar una Certificación o Capacitación para ${workerName}.\n
  No es necesario registrar el tipo ni la fecha de vencimiento... ¡SiteForm se encargará de eso por ti!`,

    fr: `Il vous suffit de faire glisser-déposer pour télécharger une certification ou une formation pour ${workerName}.\n
  Pas besoin de noter le type ni la date d'expiration... SiteForm s'en occupe pour vous !`,

    pt: `Basta arrastar e soltar para enviar um Certificado ou Treinamento para ${workerName}.\n
  Não é necessário registrar o tipo ou a data de validade... o SiteForm cuidará disso para você!`,

    it: `Trascina semplicemente il file per caricare una Certificazione o una Formazione per ${workerName}.\n
  Nessuna necessità di annotare il tipo o la data di scadenza... SiteForm si occuperà di tutto per te!`,

    zh_hans: `只需拖放即可上传${workerName}的证书或培训。\n
  无需记录类型或到期日期... SiteForm会为您处理！`,
  }),
  hoursYouArePlanningToStay: {
    en: "How many hours do you plan on staying?",
    es: "¿Cuántas horas piensas quedarte?",
    pt: "Quantas horas você pretende ficar?",
    it: "Quante ore pensi di restare?",
    fr: "Combien d'heures comptez-vous rester ?",
    zh_hans: "您计划停留多少小时？",
  },

  selectAnOrientationLanguage: {
    en: "Select an orientation language",
    es: "Seleccione un idioma de orientación",
    pt: "Selecione um idioma de orientação",
    it: "Seleziona una lingua di orientamento",
    fr: "Sélectionnez une langue d'orientation",
    zh_hans: "选择一种方向语言",
  },
  welcomeToProject: (projectName) => ({
    en: `Welcome to ${projectName}`,
    es: `Bienvenido a ${projectName}`,
    pt: `Bem-vindo ao ${projectName}`,
    it: `Benvenuto a ${projectName}`,
    fr: `Bienvenue à ${projectName}`,
    zh_hans: `欢迎来到${projectName}`,
  }),
  youHaveCompletedTheGivenOrientationInPast: (hasUniversal?: boolean) => ({
    en: `You have completed the ${
      hasUniversal ? "CARE" : "Company"
    } Orientation in the past. It is valid and you don't have to do it again.`,
    es: `Has completado la Orientación de ${
      hasUniversal ? "CARE" : "la Compañía"
    } en el pasado. Es válida y no tienes que hacerla de nuevo.`,
    pt: `Você concluiu a Orientação ${
      hasUniversal ? "CARE" : "da Empresa"
    } no passado. Ela é válida e você não precisa fazê-la novamente.`,
    it: `Hai completato l'Orientamento ${
      hasUniversal ? "CARE" : "dell'Azienda"
    } in passato. È valido e non è necessario rifarlo.`,
    fr: `Vous avez terminé l'Orientation ${
      hasUniversal ? "CARE" : "de l'entreprise"
    } dans le passé. Elle est valide, et vous n'avez pas besoin de la refaire.`,
    zh_hans: `您在过去已完成了${
      hasUniversal ? "CARE" : "公司"
    } 导向活动。它是有效的，您不需要再次完成。`,
  }),
  rewindSeconds: (timeSec: number) => ({
    en: `Rewind ${timeSec} seconds`,
    es: `Retroceder ${timeSec} segundos`,
    pt: `Retroceder ${timeSec} segundos`,
    it: `Riavvolgere ${timeSec} secondi`,
    fr: `Revenir en arrière de ${timeSec} secondes`,
    zh_hans: `倒带${timeSec}秒`,
  }),
  skipSeconds: (timeSec: number) => ({
    en: `Skip ${timeSec} seconds`,
    es: `Saltar ${timeSec} segundos`,
    pt: `Pular ${timeSec} segundos`,
    it: `Salta ${timeSec} secondi`,
    fr: `Passer ${timeSec} secondes`,
    zh_hans: `跳过${timeSec}秒`,
  }),
} satisfies {
  [key: string]: TranslationValue | ((...args: any[]) => TranslationValue);
};

export const translationStringOptions = {
  //WARNING: please do not delete or modify current strings in below keys
  workerRoles: {
    //worker role options start
    foreman: {
      en: "Foreman",
      es: "Capataz",
      pt: "Mestre de Obras",
      it: "Caposquadra",
      fr: "Contremaître",
      zh_hans: "工头",
    },
    journeyman: {
      en: "Journeyman",
      es: "Oficial",
      pt: "Jornaleiro",
      it: "Artigiano",
      fr: "Compagnon",
      zh_hans: "熟练工",
    },
    apprentice: {
      en: "Apprentice",
      es: "Aprendiz",
      pt: "Aprendiz",
      it: "Apprendista",
      fr: "Apprenti",
      zh_hans: "学徒",
    },
    //worker role options end
  },
  workerTrades: {
    //worker trade options start
    "0ba23231-a7b7-45f2-927e-ac2abe871681": {
      en: "Asbestos Worker",
      es: "Trabajador del amianto",
      pt: "Trabalhador do Amianto",
      it: "Lavoratore dell'amianto",
      fr: "Travailleur de l'amiante",
      zh_hans: "石棉工人",
    },
    "197232c2-1fc8-441b-ba24-db14bd52d5e7": {
      en: "Boilermaker",
      es: "Calderero",
      pt: "Caldeireiro",
      it: "Caldaista",
      fr: "Chaudronnier",
      zh_hans: "锅炉制造工人",
    },
    "2fced864-4e27-49f0-9866-3973ff396153": {
      en: "Bricklayer",
      es: "Albañil",
      pt: "Pedreiro",
      it: "Muratore",
      fr: "Maçon",
      zh_hans: "砖匠",
    },
    "e8deae29-35b3-4a84-85ea-904e195604c4": {
      en: "Carpenter",
      es: "Carpintero",
      pt: "Carpinteiro",
      it: "Carpentiere",
      fr: "Charpentier",
      zh_hans: "木匠",
    },
    "ee2d348b-574f-400e-ab10-4e6694aba660": {
      en: "Ceiling Installer",
      es: "Instalador de Techo",
      pt: "Mecânico de Teto",
      it: "Meccanico di soffitti",
      fr: "Mécanicien de plafond",
      zh_hans: "天花板安装工",
    },
    "6e34027b-2fd0-44ea-be6b-5657853f4624": {
      en: "Concrete Finisher",
      es: "terminador de concreto",
      pt: "Finalizador de concreto",
      it: "Finitore di calcestruzzo",
      fr: "Finir le Béton",
      zh_hans: "混凝土面工",
    },
    "7818a694-51ce-44f6-a58c-aea735a97c6f": {
      en: "Electrician",
      es: "Electricista",
      pt: "Eletricista",
      it: "Elettricista",
      fr: "Électricien",
      zh_hans: "电工",
    },
    "0c882521-3bda-444c-8467-03add11d675e": {
      en: "Electrician - Low Voltage",
      es: "Electricista - Baja Tensión",
      pt: "Eletricista - Baixa Tensão",
      it: "Elettricista - Bassa tensione",
      fr: "Électricien - Basse tension",
      zh_hans: "低压电工",
    },
    "8d3dfb3c-c7ae-43bf-bd9d-9abe334e4c4d": {
      en: "Elevator Mechanic",
      es: "Mecánico de ascensores",
      pt: "Mecânico de elevadores",
      it: "Meccanico di ascensori",
      fr: "Mécanicien d'ascenseur",
      zh_hans: "电梯技师",
    },
    "87f956dc-2f59-4c45-aa12-514bb1ce2904": {
      en: "Equipment Operator",
      es: "Operador de equipos",
      pt: "Operador de Equipamentos",
      it: "Operatore di attrezzature",
      fr: "Opérateur d'équipement",
      zh_hans: "设备操作员",
    },
    "42e2abc6-33bb-49f6-92a0-b6ff8d8de041": {
      en: "Field Technician",
      es: "Técnico de campo",
      pt: "Técnico de Campo",
      it: "Tecnico di campo",
      fr: "Technicien de terrain",
      zh_hans: "现场技术员",
    },
    "cb993d9b-aa6e-4421-a5fd-1ee68b168d30": {
      en: "Fireproofer",
      es: "Instalador de Retardantes de Fuego",
      pt: "Instalador Retardador de Fogo",
      it: "Installatore Ignifugo",
      fr: "Installateur Ignifuge",
      zh_hans: "阻燃安装工",
    },
    "9779d933-6b1b-46c9-af1e-aafa9ff84cee": {
      en: "Floor-Mechanic (Non-Tile/Stone)",
      es: "Instalador de Pisos (no teja/piedra)",
      pt: "Instalador de Piso (Não-Ladrilho/Pedra)",
      it: "Installatore di Pavimenti (non piastrelle/pedra)",
      fr: "Installateur de Plancher (hors tuile/pierre)",
      zh_hans: "地板安装工（非瓷砖/石头）",
    },
    "0c9478f5-084c-49b5-aca7-5cad490a5ab9": {
      en: "Glazier",
      es: "Glazier",
      pt: "Vidraceiro",
      it: "Vetraio",
      fr: "Vitrier",
      zh_hans: "玻璃工",
    },
    "65964fdf-7772-49ec-be92-9c71732168e6": {
      en: "HVAC Mechanic",
      es: "Mecánico de Climatización",
      pt: "Mecânico de HVAC",
      it: "Meccanico HVAC",
      fr: "Mécanicien HVAC",
      zh_hans: "暖通空调技工",
    },
    "fb912896-353f-492c-8be4-e6e6e0e2fc16": {
      en: "Heavy Equipment Operator",
      es: "Operador de equipos pesados",
      pt: "Operador de Equipamentos Pesados",
      it: "Operatore di attrezzature pesanti",
      fr: "Opérateur d'équipement lourd",
      zh_hans: "重型设备操作员",
    },
    "cb3f0554-4d0f-431f-a439-89241b1a5f68": {
      en: "Insulator",
      es: "Aislador",
      pt: "Isolador",
      it: "Isolante",
      fr: "Isolant",
      zh_hans: "隔热工",
    },
    "07b6237f-3049-4ca1-bc8a-31116384bbb4": {
      en: "Ironworker",
      es: "Instalador de Acero",
      pt: "Instalador de Aço",
      it: "Installatore di Acciaio",
      fr: "Installateur d'acier",
      zh_hans: "钢铁工人",
    },
    "c2079fe5-3027-46b6-9ae8-3b82e2c9dd05": {
      en: "Laborer",
      es: "Trabajador",
      pt: "Trabalhador",
      it: "Lavoratore",
      fr: "Ouvrier",
      zh_hans: "劳工",
    },
    "eda39605-7dfb-437e-9fbf-0b197c9afcaf": {
      en: "Landscaper",
      es: "Paisajista",
      pt: "Paisagista",
      it: "Giardiniere",
      fr: "Paysagiste",
      zh_hans: "园艺师",
    },
    "11c2c5aa-de1c-44b4-a76a-b209c1f1fc57": {
      en: "Lather",
      es: "Lather",
      pt: "Lather",
      it: "Lather",
      fr: "Lather",
      zh_hans: "Lather",
    },
    "df96c93a-914c-4251-8b2a-ed3743d20b20": {
      en: "Management",
      es: "Administración",
      pt: "Gestão",
      it: "Gestione",
      fr: "Gestion",
      zh_hans: "管理",
    },
    "e01c008b-8ffc-419a-9be4-1e1e5f59cd6c": {
      en: "Mason",
      es: "Mason",
      pt: "Mason",
      it: "Muratore",
      fr: "Maçon",
      zh_hans: "泥瓦匠",
    },
    "6e364cc7-3db8-438b-87f7-c50c69cca50f": {
      en: "Painter",
      es: "Pintor",
      pt: "Pintor",
      it: "Pittore",
      fr: "Peintre",
      zh_hans: "画家",
    },
    "a5e6c539-e937-447b-895d-1f3f24986868": {
      en: "Pile Driver",
      es: "Conductor de pila",
      pt: "Bate-estacas",
      it: "Battipalo",
      fr: "Battage de pieux",
      zh_hans: "打桩机手",
    },
    "6e52ed82-2254-453b-95d5-4edf26224896": {
      en: "Pipefitter",
      es: "Instalador de Tuberías",
      pt: "Encaixe de Tubulação",
      it: "Tubista",
      fr: "Monteur de tuyauterie",
      zh_hans: "管工",
    },
    "b3706bc0-8984-4123-b78f-387a4b56fa3e": {
      en: "Plasterer",
      es: "Yesero",
      pt: "Estucador",
      it: "Intonacatore",
      fr: "Plâtrier",
      zh_hans: "泥水匠",
    },
    "f8e5f908-fd44-4f51-b7d1-f1e3b0b06469": {
      en: "Plumber",
      es: "Fontanero",
      pt: "Encanador",
      it: "Idraulico",
      fr: "Plombier",
      zh_hans: "管道工",
    },
    "4668cd40-343a-4437-8d5d-cd651f44f1ed": {
      en: "Roofer",
      es: "Techador",
      pt: "Roofer",
      it: "Coperturista",
      fr: "Couvreur",
      zh_hans: "屋顶工",
    },
    "79b6faa7-5c0f-4256-a694-5ed739b77ebe": {
      en: "Scaffold Erector",
      es: "Erector de andamio",
      pt: "Ereto de andaime",
      it: "Montatore di impalcature",
      fr: "Monteur d'échafaudage",
      zh_hans: "脚手架安装工",
    },
    "8fdc3ec2-0dfd-4d9d-a2be-70a40ae59942": {
      en: "Sheet Metal",
      es: "Instalador de Conductos",
      pt: "instalador de dutos",
      it: "installatore di canali",
      fr: "installateur de conduits",
      zh_hans: "管道安装工",
    },
    "41a11e0c-2fc1-497c-a986-27406b6a02ed": {
      en: "Signage Installer",
      es: "Instalador de señalización",
      pt: "Instalador de sinalização",
      it: "Installatore di segnaletica",
      fr: "Installateur de signalisation",
      zh_hans: "标志安装师",
    },
    "4d073f42-7578-4247-8cda-8b348ffd4187": {
      en: "Solar Technician",
      es: "Técnico solar",
      pt: "Técnico Solar",
      it: "Tecnico solare",
      fr: "Technicien solaire",
      zh_hans: "太阳能技术员",
    },
    "0b8752bf-52ba-4708-8d1b-3dc7680b13ac": {
      en: "Sprinkler Fitter",
      es: "Aspersor Fitter",
      pt: "Montador de aspersão",
      it: "Installatore di impianti di irrigazione",
      fr: "Installateur d'arroseur",
      zh_hans: "洒水装配工",
    },
    "4b97b417-a044-4bde-8e72-61abebd64674": {
      en: "Steam Fitter",
      es: "Steam Fitter",
      pt: "Montador a vapor",
      it: "Montatore a vapore",
      fr: "Monteur de vapeur",
      zh_hans: "蒸汽装配工",
    },
    "b68a7eb3-53e1-4a38-aaff-acaff0446289": {
      en: "Surveyor",
      es: "Topógrafo",
      pt: "Agrimensor",
      it: "Tecnico di rilevamento",
      fr: "Géomètre",
      zh_hans: "测量员",
    },
    "be25f7e3-7322-4b7e-8065-263b1ed941f9": {
      en: "Taper & Finisher",
      es: "Taper & Finisher",
      pt: "Taper & Finisher",
      it: "Taper e finitore",
      fr: "Jointeur & Finition",
      zh_hans: "干墙工和精饰工",
    },
    "8f67486e-d3f0-41a9-acfb-476813611def": {
      en: "Tile Setter",
      es: "Tile Setter",
      pt: "Setter de ladrilhos",
      it: "Posatore di piastrelle",
      fr: "Poseur de carrelage",
      zh_hans: "铺砖工",
    },
    "94d6d6ad-c786-4f8e-9b5c-7278ea8e2cc7": {
      en: "Truck Driver",
      es: "Camionero",
      pt: "Motorista de caminhão",
      it: "Autista di camion",
      fr: "Camionneur",
      zh_hans: "卡车司机",
    },
    "95e6da78-2f11-4dcd-bc9d-3040fcae6053": {
      en: "Utility Installer",
      es: "Instalador de utilidades",
      pt: "Instalador de utilidades",
      it: "Installatore di utilità",
      fr: "Installateur d'utilitaires",
      zh_hans: "公用事业安装工",
    },
    "7e7d5bf3-3e2b-4edb-a71c-2d7f32e8d469": {
      en: "Waterproofer",
      es: "Impermeabilizante",
      pt: "Impermeabilização",
      it: "Impermeabilizzazione",
      fr: "Imperméabilisation",
      zh_hans: "防水工",
    },
    "16757970-9178-409e-9098-d018f095a9b1": {
      en: "Welder",
      es: "Soldador",
      pt: "Soldador",
      it: "Saldatore",
      fr: "Soudeur",
      zh_hans: "焊工",
    },
    "0bd03920-6960-4d95-b5bc-497b7fc0ddb5": {
      en: "Window Tinting",
      es: "Tinte de ventana",
      pt: "Estanho de janela",
      it: "Tinteggiatura finestre",
      fr: "Teinte de fenêtre",
      zh_hans: "窗户着色",
    },
    // worker trade options end
  },
  laborUnions: {
    //worker Union options start
    "e416a8ea-9525-4730-a0c5-96294047f9da": {
      en: "Asbestos Workers",
      es: "Trabajadores de Asbesto",
      pt: "Trabalhadores de Amianto",
      it: "Lavoratori dell'Amianto",
      fr: "Travailleurs de l'amiante",
      zh_hans: "石棉工人",
    },
    "c8d3c4aa-0064-4de2-b4f9-ccbddca615fa": {
      en: "Boilermakers",
      es: "Caldereros",
      pt: "Caldeireiros",
      it: "Caldaisti",
      fr: "Chaudronniers",
      zh_hans: "锅炉工",
    },
    "0d49e16f-1723-4dde-b25c-b0eaa03bf2ec": {
      en: "Carpenters",
      es: "Carpinteros",
      pt: "Carpinteiros",
      it: "Carpentieri",
      fr: "Charpentiers",
      zh_hans: "木匠",
    },
    "3674c965-9346-4f02-a07d-1983e2f3aafa": {
      en: "Floorcoverers",
      es: "Instaladores de Revestimientos de Suelo",
      pt: "Instaladores de Revestimentos de Piso",
      it: "Posatori di Rivestimenti per Pavimenti",
      fr: "Poseurs de Revêtements de Sol",
      zh_hans: "地板安装工人",
    },
    "bacdfcd1-eacd-4620-b5da-ef9767b77ea1": {
      en: "IBEW International Brotherhood of Electrical Workers",
      es: "Hermandad Internacional de Electricistas",
      pt: "Irmãos Internacionais dos Eletricistas",
      it: "Fraternità Internazionale dei Lavoratori Elettricisti",
      fr: "Fraternité Internationale des Ouvriers Électriciens",
      zh_hans: "国际电工兄弟会",
    },
    "4880fc61-d901-4cac-8936-010945f6e022": {
      en: "IUOE International Union of Operating Engineers",
      es: "Unión Internacional de Operadores de Equipos Pesados",
      pt: "União Internacional dos Operadores de Equipamentos Pesados",
      it: "Unione Internazionale degli Operatori di Macchine Movimento Terra",
      fr: "Union Internationale des Opérateurs d'Engins de Chantier",
      zh_hans: "国际工程机械运营工会",
    },
    "583d62d6-56f3-4bc6-adec-271e98cda6fd": {
      en: "International Association of Iron Workers",
      es: "Asociación Internacional de Trabajadores del Hierro",
      pt: "Associação Internacional dos Trabalhadores do Ferro",
      it: "Associazione Internazionale dei Lavoratori del Ferro",
      fr: "Association Internationale des Ouvriers du Fer",
      zh_hans: "国际钢铁工人协会",
    },
    "f7724527-6348-42a9-9b00-33eb868a8352": {
      en: "International Union Elevator Constructors",
      es: "Unión Internacional de Constructores de Ascensores",
      pt: "União Internacional dos Construtores de Elevadores",
      it: "Unione Internazionale dei Costruttori di Ascensori",
      fr: "Union Internationale des Constructeurs d'Ascenseurs",
      zh_hans: "国际电梯工人工会",
    },
    "70615c6d-2faa-4d66-9b18-df48c8b3e293": {
      en: "International Union of Bricklayers and Allied Craftworkers",
      es: "Unión Internacional de Albañiles y Trabajadores de Oficios Afines",
      pt: "União Internacional dos Pedreiros e Trabalhadores de Ofícios Afins",
      it: "Unione Internazionale dei Muratori e Lavoratori Artigiani Affini",
      fr: "Union Internationale des Maçons et Ouvriers d'Artisanat Connexe",
      zh_hans: "国际砖瓦工和相关工种工人工会",
    },
    "873c96ae-a23d-432b-ade3-8cc711d64349": {
      en: "Laborers",
      es: "Trabajadores",
      pt: "Trabalhadores",
      it: "Lavoratori",
      fr: "Ouvriers",
      zh_hans: "劳工",
    },
    "cfdcba03-3d7c-4f76-b8ed-37ec12e00d95": {
      en: "Laborers - Tunnel Workers",
      es: "Trabajadores de Túneles",
      pt: "Trabalhadores de Túneis",
      it: "Lavoratori - Lavoratori di Tunnel",
      fr: "Ouvriers - Travaux de Tunnel",
      zh_hans: "劳工 - 隧道工人",
    },
    "8e4933ec-bb92-4d2c-8ea6-d01c8ecfd052": {
      en: "Laborers - Wreckers",
      es: "Trabajadores de Demolición",
      pt: "Trabalhadores de Demolição",
      it: "Lavoratori - Demolitori",
      fr: "Ouvriers - Démolisseurs",
      zh_hans: "劳工 - 拆迁工人",
    },
    "f265901b-6cf2-4bb6-941d-daf5fb32c877": {
      en: "Painters and Allied Trades District Council",
      es: "Consejo del Distrito de Pintores y Oficios Afines",
      pt: "Conselho do Distrito dos Pintores e Trabalhadores Afiliados",
      it: "Consiglio Distrettuale dei Pittori e Lavoratori Affiliati",
      fr: "Conseil du District des Peintres et Artisans Connexes",
      zh_hans: "画家和相关行业工会地区委员会",
    },
    "60838b0e-6740-4d59-b492-16f88a6b58e9": {
      en: "Piledrivers",
      es: "Conductor de Pila",
      pt: "Bate-estacas",
      it: "Battipalo",
      fr: "Conducteur de Pieux",
      zh_hans: "打桩机",
    },
    "e0432986-d5fd-4998-8e4b-867777613a59": {
      en: "Pipe Fitters",
      es: "Instaladores de Tuberías",
      pt: "Instaladores de Tubulações",
      it: "Montatori di Tubazioni",
      fr: "Monteurs de Tuyaux",
      zh_hans: "管道安装工人",
    },
    "64a06b90-70cc-4862-b4aa-d3d09cc36c46": {
      en: "Plasterers and Cement Masons",
      es: "Enlucidores y Albañiles de Cemento",
      pt: "Estucadores e Pedreiros de Cimento",
      it: "Stuccatori e Muratori di Cemento",
      fr: "Plâtriers et Maçons de Ciment",
      zh_hans: "石膏匠和水泥匠",
    },
    "d626b240-c08a-4bc0-913c-363a4994bd6e": {
      en: "Plumbers & Gasfitters",
      es: "Plomeros y Gasistas",
      pt: "Encanadores e Gasfitters",
      it: "Idraulici e Installatori di Gas",
      fr: "Plombiers et Monteurs de Gaz",
      zh_hans: "水管工和燃气安装工",
    },
    "2d20538a-50d5-4c4a-9b3a-6da063554f14": {
      en: "Plumbers & Pipe Fitters",
      es: "Fontaneros e Instaladores de Tuberías",
      pt: "Encanadores e Instaladores de Tubulações",
      it: "Idraulici e Montatori di Tubazioni",
      fr: "Plombiers et Monteurs de Tuyaux",
      zh_hans: "管道工和管道安装工人",
    },
    "35a336fd-eaf3-48f1-ac21-3dac993d9722": {
      en: "Roofers",
      es: "Techadores",
      pt: "Telhadistas",
      it: "Coperturisti",
      fr: "Couvreur",
      zh_hans: "屋顶工人",
    },
    "cc97e982-5553-4ceb-acc6-59d43902cb55": {
      en: "Sheet Metal Workers",
      es: "Trabajadores de Chapa y Metal",
      pt: "Trabalhadores de Chapa e Metal",
      it: "Lavoratori della Lamiera",
      fr: "Ouvriers en Tôlerie",
      zh_hans: "钣金工人",
    },
    "88fc8e73-43f6-4244-b496-af4aead5c274": {
      en: "Shop & Mill Cabinet",
      es: "Taller y Carpintería de Molino",
      pt: "Oficina e Carpintaria de Moinho",
      it: "Cabentry Officina e Falegnameria",
      fr: "Atelier et Menuiserie de Moulin",
      zh_hans: "车间和磨坊木匠",
    },
    "29af5ca5-7dd0-49f8-a710-b7167acae488": {
      en: "Sprinklerfitters",
      es: "Instaladores de Rociadores",
      pt: "Instaladores de Sprinklers",
      it: "Montatori di Sprinkler",
      fr: "Installateurs de Gicleurs D'incendie",
      zh_hans: "喷水工",
    },
    "5df2b9f1-2fac-412d-94fa-024ea66fafa4": {
      en: "Teamsters",
      es: "Conductores de Camiones",
      pt: "Motoristas de Caminhão",
      it: "Camionisti",
      fr: "Chauffeurs Routiers",
      zh_hans: "卡车司机工会成员",
    },
    "629f25a3-9948-4a5f-9a88-c816612a2925": {
      en: "Woodframe",
      es: "Constructores de Estructuras de Madera",
      pt: "Construtores de Estruturas de Madeira",
      it: "Costruttori di Telaio in Legno",
      fr: "Charpentiers de Bois",
      zh_hans: "木结构工人",
    },
    //worker union options end
  },
  veterranTypes: {
    //veteran type options start
    veteran: {
      es: "Veterano",
      en: "Veteran",
      pt: "Veterano",
      it: "Veterano",
      fr: "Vétéran",
      zh_hans: "退伍军人",
    },
    disabledVeteran: {
      es: "Veterano discapacitado",
      en: "Disabled Veteran",
      pt: "Veterano com deficiência",
      it: "Veterano disabile",
      fr: "Vétéran handicapé",
      zh_hans: "残疾退伍军人",
    },
    nonVeteran: {
      en: "Non Veteran",
      es: "No Veterano",
      pt: "Não Veterano",
      it: "Non Veterano",
      fr: "Non vétéran",
      zh_hans: "非退伍军人",
    },
    //veteran ttype options
  },
  genderOptions: {
    //gender Options start
    M_Gender: {
      en: "Male",
      es: "Masculino",
      pt: "Masculino",
      it: "Maschio",
      fr: "Homme",
      zh_hans: "男性",
    },
    F_Gender: {
      en: "Female",
      es: "Femenino",
      pt: "Feminino",
      it: "Femmina",
      fr: "Femme",
      zh_hans: "女性",
    },

    O_Gender: {
      en: "Other",
      es: "Otro",
      pt: "Outro",
      it: "Altro",
      fr: "Autre",
      zh_hans: "其他",
    },
    //gender options end
  },
  ethnicityOptions: {
    //ethnicityty options start
    "Native American Indian": {
      en: "Native American Indian",
      es: "Indio nativo americano",
      pt: "Índio nativo americano",
      it: "Indiano nativo americano",
      fr: "Indien natif américain",
      zh_hans: "美洲原住民",
    },
    Asian: {
      en: "Asian",
      es: "Asiático",
      pt: "Asiático",
      it: "Asiatico",
      fr: "Asiatique",
      zh_hans: "亚洲人",
    },
    "Black/African American": {
      en: "Black/African American",
      es: "Negro/Afroamericano",
      pt: "Negro/Afro-americano",
      it: "Nero/Afroamericano",
      fr: "Noir/Afro-américain",
      zh_hans: "黑人/非洲裔美国人",
    },
    "Hispanic/Latino": {
      en: "Hispanic/Latino",
      es: "Hispano/Latino",
      pt: "Hispano/Latino",
      it: "Ispanico/Latino",
      fr: "Hispanique/Latino",
      zh_hans: "西班牙裔/拉丁裔",
    },
    "White/Caucasian/non-Hispanic": {
      en: "White/Caucasian/non-Hispanic",
      es: "Blanco/Caucásico/no Hispano",
      pt: "Branco/Caucasiano/não-hispânico",
      it: "Bianco/Caucasico/non-ispanico",
      fr: "Blanc/Caucasien/non hispanique",
      zh_hans: "白人/高加索人/非西班牙裔",
    },
    "Cape Verdean": {
      en: "Cape Verdean",
      es: "caboverdiano",
      pt: "Cabo-verdiano",
      it: "Capoverdiano",
      fr: "Cap-verdien",
      zh_hans: "佛得角人",
    },
    Other: {
      en: "Other",
      es: "Otro",
      pt: "Outro",
      it: "Altro",
      fr: "Autre",
      zh_hans: "其他",
    },
    //ethnicity options end
  },
} satisfies { [key: string]: { [key: string]: TranslationValue } };
