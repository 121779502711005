import { Button } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ProjectSubcontractorTasksTable from "src/common/components/tables/ProjectSubcontractorTasksTable";
import { SubProjectMobilizationStepJhaQuery } from "src/common/types/generated/relay/SubProjectMobilizationStepJhaQuery.graphql";
import UploadTaskToSiteFormModal from "src/domain-features/sitesafety/job-hazard-analysis/components/modals/UploadTaskToSiteformModal";
import { ColumnKeys } from "src/domain-features/sitesafety/job-hazard-analysis/components/tables/TasksTable";

export interface SubProjectMobilizationStepJhaProps {
  projectId: string;
  subcontractorId: string;
  onPrev: () => void;
  onNext: (isUploaded: boolean) => void;
}

const SubProjectMobilizationStepJha: React.FC<
  SubProjectMobilizationStepJhaProps
> = (props) => {
  const query = graphql`
    query SubProjectMobilizationStepJhaQuery(
      $projectId: uuid!
      $subcontractorId: uuid!
    ) {
      ppe_type_connection(order_by: { name: { en: asc } }) {
        edges {
          node {
            id
            pk: id @__clientField(handle: "pk")
            name {
              en
            }
          }
        }
      }
      project_connection(where: { id: { _eq: $projectId } }) {
        edges {
          node {
            name
            general_contractor {
              id
              pk: id @__clientField(handle: "pk")
              name
            }
          }
        }
      }
      permit_type_connection(order_by: { name: { en: asc } }) {
        edges {
          node {
            id
            pk: id @__clientField(handle: "pk")
            name {
              en
            }
          }
        }
      }
      project_subcontractor_connection(
        where: { subcontractor_id: { _eq: $subcontractorId } }
        order_by: { project: { name: asc } }
      ) {
        edges {
          node {
            project {
              name
              id
              pk: id @__clientField(handle: "pk")
            }
          }
        }
      }
      task_connection(
        where: {
          project_id: { _eq: $projectId }
          subcontractor_id: { _eq: $subcontractorId }
          deleted_at: { _is_null: true }
          report_id: { _is_null: true }
        }
      ) {
        edges {
          node {
            id
            pk: id @__clientField(handle: "pk")
            task_edit(order_by: { edited_at: desc }) {
              task_patch
              edited_at
            }
            task_signatures(
              where: { is_active: { _eq: true } }
              order_by: { created_at: desc }
            ) {
              created_at
              signature_image_id
            }
          }
        }
      }
    }
  `;
  const [isOpenUploadModal, setIsOpenUploadModal] = useState(false);
  const projectId = props.projectId;
  const subcontractorId = props.subcontractorId;
  const [fetchKey, setFetchKey] = useState(0);
  const data = useLazyLoadQuery<SubProjectMobilizationStepJhaQuery>(
    query,
    {
      projectId: projectId,
      subcontractorId,
    },
    { fetchKey },
  );
  const refresh = () => {
    setFetchKey((i) => i + 1);
    props.onNext(true);
  };
  const excludedColumns: ColumnKeys[] = [
    "subcontractor",
    "addToProject",
    "addToMulipleProjects",
    "delete",
    "internalReview",
    "ppe",
    "permit",
    "status",
    "subcontractorApproval",
    "workersSigned",
    "lastUsed",
    "firstUsed",
    "lastEdit",
    "actionButtons",
  ];
  const projectData = data.project_connection.edges[0].node;
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const isNotTurnersProject = projectData.general_contractor.pk !== turnerGCId;
  const projectsOfSub = data.project_subcontractor_connection.edges.map(
    (proj) => ({ id: proj.node.project.pk, name: proj.node.project.name }),
  );
  return (
    <div>
      <UploadTaskToSiteFormModal
        visible={isOpenUploadModal}
        subcontractorId={subcontractorId}
        onClose={() => setIsOpenUploadModal(false)}
        onSubmit={() => {
          setIsOpenUploadModal(false);
          props.onNext(true);
        }}
        projectId={projectId}
      />
      <div className="mt-1">
        <Button
          onClick={() => {
            props.onPrev();
          }}
        >
          Back
        </Button>
        <Button
          className="ml-1"
          onClick={() => {
            props.onNext(false);
          }}
        >
          Next
        </Button>
      </div>
      <BasicWrapper scrollable>
        <ProjectSubcontractorTasksTable
          {...{
            projectId,
            refresh,
            subcontractorId,
            projectsOfSub,
            isNotTurnersProject,
            taskEdges: data.task_connection.edges,
            ppeOptions: data.ppe_type_connection.edges,
            permitOptions: data.permit_type_connection.edges,
            excludedColumns,
          }}
        />
      </BasicWrapper>
    </div>
  );
};

export default SubProjectMobilizationStepJha;
