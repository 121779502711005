import VideoPlayer from "../../../../../common/components/VideoPlayer/VideoPlayer";
import { useState } from "react";
import { Button, Card, Input, Space, Typography } from "antd";
import Separator from "../../../../../common/components/general/Separator";
import getVideoType from "../../../../../common/components/VideoPlayer/utils/getVideoType";

const videoUrlExamples = {
  youtube: "https://www.youtube.com/watch?v=u31qwQUeGuM",
  vimeo: "https://vimeo.com/76979871",
};

const VideoPlayerDemo: React.FC = () => {
  const [videoUrl, setVideoUrl] = useState<string>("");

  return (
    <div className={`grid grid-cols-2 gap-1`}>
      <Card title={"Inputs"}>
        <Space className={`w-full`} direction={"vertical"}>
          <Input
            value={videoUrl}
            onChange={(e) => setVideoUrl(e.target.value)}
            placeholder="Enter video URL"
            allowClear
          />
          <Button
            onClick={() => {
              setVideoUrl(videoUrlExamples.youtube);
            }}
          >
            Paste YouTube URL
          </Button>
          <Button
            onClick={() => {
              setVideoUrl(videoUrlExamples.vimeo);
            }}
          >
            Paste Vimeo URL
          </Button>
          <Separator />
          <Typography>
            <Typography.Text>{`Video Type: `}</Typography.Text>
            <Typography.Text code>
              {"" + getVideoType(videoUrl)}
            </Typography.Text>
          </Typography>
        </Space>
      </Card>
      <Card title={"VideoPlayer"}>
        <VideoPlayer videoUrl={videoUrl} />
      </Card>
    </div>
  );
};

export default VideoPlayerDemo;
