import ProjectTable from "src/root/routes/views/general-contractor/hierarchy/component/ProjectTable";
import { useRef, useState } from "react";
import GCAddProject from "../../component/GCAddProject";
import { IconPlus } from "@tabler/icons";
import { message } from "antd";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import { useParams } from "react-router-dom";
import {
  GetGcOfficesDocument,
  GetGcOfficesQuery,
  GetGcOfficesQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";

const GCOfficeProjects: React.FC<OfficeProps> = ({ officeId }) => {
  const { userData } = useUserData();
  const [open, setOpen] = useState(false);
  const { data } = useSuspenseQuery<
    GetGcOfficesQuery,
    GetGcOfficesQueryVariables
  >(GetGcOfficesDocument, {
    variables: {
      includeTableData: false,
      officeWhere: { id: { _eq: officeId } },
      buWhere: { id: { _is_null: true } },
      divisionWhere: { id: { _is_null: true } },
    },
    fetchPolicy: "cache-first",
  });
  const office = data?.gc_office[0];
  console.log(office?.id, officeId);
  if (!office) throw new Error("No Office found for given officeId");
  if (!userData.employee) throw new Error("userData not found");
  const ref = useRef<DataScrollTableRef>(null);
  return (
    <div className={`w-full relative h-full p-2 bg-white rounded-2`}>
      {open && (
        <GCAddProject
          toInsertWith={{
            gc_office_id: officeId,
            gc_business_unit_id: office.gc_business_unit_id,
            gc_division_id: office.gc_division_id,
          }}
          onCancel={() => setOpen(false)}
          onCreate={() => {
            message.success("Project Created Successfully");
            setOpen(false);
          }}
        />
      )}
      <ProjectTable
        ref={ref}
        showLevels={[]}
        topBarButtons={[
          {
            label: "Add New Project",
            icon: IconPlus,
            onClick: () => setOpen(true),
          },
        ]}
        excludedKeys={["gc"]}
        where={{
          general_contractors: {
            general_contractor_id: {
              _eq: userData.employee.general_contractor.id,
            },
          },
          gc_office_id: { _eq: officeId },
        }}
      />
    </div>
  );
};
export default withOfficeId(GCOfficeProjects);
