import {
  useDeleteDocumentByPkMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  GetIncidentByPkDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import { DeletedDocument } from "../components/basic/IncidentDocViewer";
import createIncidentPatch from "./createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import { getIncidentFieldValue } from "./useUploadIncidentDocuments";

type IncidentFieldKey =
  | "incident_detail"
  | "injury_detail"
  | "insurance_detail"
  | "incident_type"
  | "injured_user"
  | "root_cause_analysis"
  | "witness_detail";

type IncidentType =
  | "environmental"
  | "equipment"
  | "near_miss"
  | "property_damage"
  | "vehicle"
  | "utility"
  | "theft";

interface DeleteDocumentParams {
  doc: DeletedDocument;
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>;
  incidentFieldKey: IncidentFieldKey;
  incidentUserId?: string;
  type?: IncidentType;
}

const getUpdatedIncidentForDelete = ({
  doc,
  incidentFieldKey,
  incident,
  incidentUserId,
  type,
}: DeleteDocumentParams) => {
  switch (incidentFieldKey) {
    case "witness_detail":
      return {
        ...incident,
        witnesses: incident.witnesses.map((witness) =>
          witness.id === incidentUserId
            ? {
                ...witness,
                attached_files: witness.attached_files.filter(
                  (file) => file.id !== doc.id,
                ),
              }
            : witness,
        ),
      };

    case "injury_detail":
      return {
        ...incident,
        injured_users: incident.injured_users.map((injuredUser) =>
          injuredUser.id === incidentUserId && injuredUser.injury_detail
            ? {
                ...injuredUser,
                injury_detail: {
                  ...injuredUser.injury_detail,
                  attached_files:
                    injuredUser.injury_detail.attached_files.filter(
                      (file) => file.id !== doc.id,
                    ),
                },
              }
            : injuredUser,
        ),
      };

    case "insurance_detail":
      return {
        ...incident,
        injured_users: incident.injured_users.map((injuredUser) =>
          injuredUser.id === incidentUserId &&
          injuredUser.injury_detail?.insurance_claim
            ? {
                ...injuredUser,
                injury_detail: {
                  ...injuredUser.injury_detail,
                  insurance_claim: {
                    ...injuredUser.injury_detail.insurance_claim,
                    attached_files:
                      injuredUser.injury_detail.insurance_claim.attached_files.filter(
                        (file) => file.id !== doc.id,
                      ),
                  },
                },
              }
            : injuredUser,
        ),
      };

    case "incident_detail":
      return {
        ...incident,
        attached_files: incident.attached_files.filter(
          (file) => file.id !== doc.id,
        ),
      };

    case "root_cause_analysis":
      return {
        ...incident,
        root_cause_analysis: incident.root_cause_analysis
          ? {
              ...incident.root_cause_analysis,
              document: incident.root_cause_analysis.document.filter(
                (file) => file.id !== doc.id,
              ),
            }
          : incident.root_cause_analysis,
      };

    case "incident_type":
      return {
        ...incident,
        incident_types: incident.incident_types.map((incidentType) =>
          incidentType.type_value === type
            ? {
                ...incidentType,
                documents: incidentType.documents.filter(
                  (document) => document.id !== doc.id,
                ),
              }
            : incidentType,
        ),
      };

    default:
      return incident;
  }
};

const useDeleteIncidentDocument = () => {
  const [deleteDocumentMutation] = useDeleteDocumentByPkMutation();
  const authUser = useAuthUser();

  const deleteDocument = async ({
    doc,
    incidentFieldKey,
    incident,
    incidentUserId,
    type,
  }: DeleteDocumentParams) => {
    const updatedIncident = getUpdatedIncidentForDelete({
      doc,
      incidentFieldKey,
      incident,
      incidentUserId,
      type,
    });

    const patch = createIncidentPatch(updatedIncident, incident);
    const incidentFieldValue = getIncidentFieldValue(
      incident,
      incidentFieldKey,
      incidentUserId,
      type,
    );
    const comment = `Deleted document for ${incidentFieldValue}`;

    await deleteDocumentMutation({
      variables: {
        id: doc.id,
        editObjects: {
          comment: comment,
          edited_by_uid: authUser.uid,
          edit_type: "document-delete",
          document_edit: [
            {
              action: "deleted",
              field: incidentFieldValue,
              documents: [{ url: doc.url, name: doc.name }],
            },
          ],
          patch: patch,
          incident_id: incident.id,
        },
      },
      update(cache, result) {
        const docId = result.data?.delete_document_by_pk?.id;
        if (!docId) return;

        const updatedIncident = getUpdatedIncidentForDelete({
          doc,
          incidentFieldKey,
          incident,
          incidentUserId,
          type,
        });

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          data: {
            incident_by_pk: updatedIncident,
          },
          query: GetIncidentByPkDocument,
        });
      },
    });
  };

  return [deleteDocument] as const;
};

export default useDeleteIncidentDocument;
