import React from "react";
import { Select } from "antd";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { useGetWorkerTitlesQuery } from "src/common/types/generated/apollo/graphQLTypes";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface PtpQrWorkerTradeProps
  extends Omit<SiteOrientationStepProps, "onNextButtonClick"> {
  defaultSub?: string;
}

const PtpQrWorkerTrade: React.FC<PtpQrWorkerTradeProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const { ptpQrUser, setPtpQrUser } = usePtpQr();
  const getWorkerTitles = useGetWorkerTitlesQuery();
  const tradeOptions =
    getWorkerTitles.data?.worker_title.map((title) => ({
      label: title.translation.en,
      value: title.id,
    })) || [];
  const userInfo = ptpQrUser.userInfo;

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={props.onNext}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={!userInfo?.title}
    >
      <SiteFeatureStepsInputLayout headline={langStrings.strings.yourTrade}>
        <div className="text-grey text-1">
          {langStrings.strings.selectTrade}
        </div>

        <Select
          value={userInfo?.title}
          options={tradeOptions}
          onChange={(val) =>
            setPtpQrUser((prev) => ({
              ...prev,
              userInfo: { ...prev.userInfo, title: val },
            }))
          }
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrWorkerTrade;
