import React from "react";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteOrientationStepsUIFullName from "src/domain-features/siteorientation/components/stepsUI/fullName/SiteOrientationStepsUIFullName";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";
import usePtpQr from "../../../utils/ptpQrHook";

export interface PtpQrCodeUserFullNameProps extends SiteOrientationStepProps {}

const PtpQrCodeUserFullName: React.FC<PtpQrCodeUserFullNameProps> = (props) => {
  const { ptpQrUser, setPtpQrUser } = usePtpQr();

  const { userInfo } = ptpQrUser;
  const langStrings = useCurrentLangStrings();

  const handleNameSubmit = (newFullName: string) => {
    setPtpQrUser((prevState) => ({
      ...prevState,
      userInfo: {
        ...prevState.userInfo,
        name: newFullName,
      },
    }));

    props.onNext();
  };

  return (
    <SiteOrientationStepsUIFullName
      onNameSubmit={handleNameSubmit}
      langStrings={langStrings}
      defaultName={userInfo?.name}
      onBack={props.onBack}
      onNext={props.onNext}
    />
  );
};

export default PtpQrCodeUserFullName;
