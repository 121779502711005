/**
 * @generated SignedSource<<3f2e191c72dfb91e60cac28f95427608>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { worker_certification_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type WorkerCertificationTableDeleteCertMutation$variables = {
  where: worker_certification_bool_exp;
};
export type WorkerCertificationTableDeleteCertMutation$data = {
  readonly delete_worker_certification: {
    readonly returning: ReadonlyArray<{
      readonly id: string;
      readonly pk: string;
    }>;
  } | null | undefined;
};
export type WorkerCertificationTableDeleteCertMutation = {
  response: WorkerCertificationTableDeleteCertMutation$data;
  variables: WorkerCertificationTableDeleteCertMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "WorkerCertificationTableDeleteCertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "worker_certification_mutation_response",
        "kind": "LinkedField",
        "name": "delete_worker_certification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "__id_pk",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "WorkerCertificationTableDeleteCertMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "worker_certification_mutation_response",
        "kind": "LinkedField",
        "name": "delete_worker_certification",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "worker_certification",
            "kind": "LinkedField",
            "name": "returning",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": "pk",
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "alias": "pk",
                "args": null,
                "filters": null,
                "handle": "pk",
                "key": "",
                "kind": "ScalarHandle",
                "name": "id"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "f8f9fcd1f2c8a5cbf0c7a8196bc6dc32",
    "id": null,
    "metadata": {},
    "name": "WorkerCertificationTableDeleteCertMutation",
    "operationKind": "mutation",
    "text": "mutation WorkerCertificationTableDeleteCertMutation(\n  $where: worker_certification_bool_exp!\n) {\n  delete_worker_certification(where: $where) {\n    returning {\n      id\n      pk: id\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "e779b3ce0a53067b6ed65ff66da08c71";

export default node;
