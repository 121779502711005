import React from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { Select } from "antd";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface PtpQrCodeLocationsProps
  extends Omit<SiteOrientationStepProps, "onNextButtonClick"> {}

const PtpQrCodeLocations: React.FC<PtpQrCodeLocationsProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const ptpQrContext = usePtpQr();
  const { ptpQrUser, setPtpQrUser } = ptpQrContext;

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={props.onNext}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={
        ptpQrUser.selectedLocationIds.length === 0 &&
        ptpQrContext.locationOptions.length > 0
      }
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.selectLocations}
      >
        <div className="text-grey text-1">
          {langStrings.strings.selectLocations}
        </div>

        <Select
          value={ptpQrUser.selectedLocationIds}
          mode="multiple"
          options={ptpQrContext.locationOptions}
          onChange={(val) => {
            setPtpQrUser((prev) => ({
              ...prev,
              selectedLocationIds: val,
            }));
          }}
        />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrCodeLocations;
