/**
 * @generated SignedSource<<7e7d377486479daf197ac1ea9db0371c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { add_subcontractor_request_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCAddNewSubModal_Mutation$variables = {
  object: add_subcontractor_request_insert_input;
};
export type GCAddNewSubModal_Mutation$data = {
  readonly insert_add_subcontractor_request_one: {
    readonly id: string;
  } | null | undefined;
};
export type GCAddNewSubModal_Mutation = {
  response: GCAddNewSubModal_Mutation$data;
  variables: GCAddNewSubModal_Mutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      }
    ],
    "concreteType": "add_subcontractor_request",
    "kind": "LinkedField",
    "name": "insert_add_subcontractor_request_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCAddNewSubModal_Mutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCAddNewSubModal_Mutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "d2e613922fe0ebc000abed5588f32794",
    "id": null,
    "metadata": {},
    "name": "GCAddNewSubModal_Mutation",
    "operationKind": "mutation",
    "text": "mutation GCAddNewSubModal_Mutation(\n  $object: add_subcontractor_request_insert_input!\n) {\n  insert_add_subcontractor_request_one(object: $object) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "c8064146b10ee1d670a9b4032b890b03";

export default node;
