import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import React, { useState } from "react";
import { Card, Flex, Typography } from "antd";
import { useLocation } from "react-router-dom";
import tabsDemoNavigationItems from "./tabsDemoNavigationItems";
import CustomTabsRouted from "../../../../../common/components/layouts/tabs/CustomTabsRouted";

const tabsArray: CustomTabsProps["tabs"] = tabsDemoNavigationItems.map(
  (item) => ({
    id: item.pathName,
    label: item.title,
    icon: item.icon,
  }),
);

const TabsComponent1: React.FC = () => {
  const [selectedTabId, setSelectedTabId] = useState<string>();

  return (
    <CustomTabs
      tabs={tabsArray.map((tab) => ({
        ...tab,
        content: <Card>{tab.label}</Card>,
      }))}
      selectedTabId={selectedTabId}
      onTabSelect={setSelectedTabId}
    />
  );
};

const TabsComponent2: React.FC = () => {
  const basePath = "/development-playground/tabs";
  return (
    <CustomTabsRouted
      tabs={tabsArray.map((tab) => {
        const { id: path, ...rest } = tab;
        return { path, ...rest };
      })}
      basePath={basePath}
    />
  );
};

const RoutingInfo: React.FC = () => {
  const location = useLocation();
  return (
    <Typography>
      <Typography.Text code>{`location:`}</Typography.Text>
      <Typography.Paragraph>
        <pre>{JSON.stringify(location, null, 2)}</pre>
      </Typography.Paragraph>
    </Typography>
  );
};

const TabsDemo: React.FC = () => {
  const location = useLocation();
  console.group(`Tabs Demo`);
  console.log(location);
  console.groupEnd();
  return (
    <Flex vertical className={`w-full`} gap={"middle"}>
      <Card rootClassName={`shadow sticky top-1 z-10`} title={`Routing`}>
        <RoutingInfo />
      </Card>

      <Card title={`Default Tabs`}>
        <Typography>
          <Typography.Paragraph code>{`CustomTabs`}</Typography.Paragraph>
          <Typography.Paragraph>
            You defined tabs with ids and pass the selected tab id.
          </Typography.Paragraph>
        </Typography>
        <TabsComponent1 />
      </Card>
      <Card title={"Routed Tabs"}>
        <Typography>
          <Typography.Paragraph code>{`CustomTabsRouted`}</Typography.Paragraph>
          <Typography.Paragraph>
            You defined tabs with relative paths and pass the base path. The
            component defines the active tab automatically.
          </Typography.Paragraph>
        </Typography>
        <TabsComponent2 />
      </Card>
    </Flex>
  );
};

export default TabsDemo;
