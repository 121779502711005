import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import {
  Checkbox,
  Form,
  Modal,
  Select,
  Upload,
  message,
  notification,
  Popover,
} from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { FC, useState } from "react";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { uploadFilesWithPath } from "src/common/functions/upload-utility/uploadFiles";
import { useEmailUserForJhaCreationRequestMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { UploadTaskToSiteformModalMutation } from "src/common/types/generated/relay/UploadTaskToSiteformModalMutation.graphql";
import { GCTasksQuery$data } from "src/common/types/generated/relay/GCTasksQuery.graphql";
import NotifyUserException from "src/utility-features/error-handling/NotifyUserException";

interface UploadTaskToSiteFormModalProps {
  visible: boolean;
  subcontractorId: string | null;
  subcontractorOptions?: GCTasksQuery$data["project_subcontractor_connection"]["edges"];
  onClose: () => void;
  subAdmins?: Array<{ name: string; id: string }>;
  onSubmit: () => void;
  projectId?: string;
  workers?: Array<{ name: string | undefined; id: string }>;
}
type FormValues = {
  numberOfJHAs: number;
  acceptance: [boolean];
  subcontractor?: string;
  subAdmins?: Array<string>;
  workers?: Array<string>;
  uploads: { fileList: Array<{ originFileObj: File }> };
};
const UploadTaskToSiteFormModal: FC<UploadTaskToSiteFormModalProps> = ({
  visible,
  subcontractorId,
  subcontractorOptions,
  subAdmins,
  onClose,
  onSubmit,
  projectId,
  workers,
}) => {
  const [subAdminOptions, setSubAdminOptions] = useState(subAdmins);
  const [workerOptions, setWorkerOptions] = useState(workers);
  const subAdminWorkerMap: {
    workers: { [key: string]: Array<{ name: string; id: string }> };
    subAdmins: { [key: string]: Array<{ name: string; id: string }> };
  } = { workers: {}, subAdmins: {} };

  subcontractorOptions?.forEach((sub) => {
    subAdminWorkerMap.subAdmins[sub.node.subcontractor_id] =
      sub.node.subcontractor.subcontractor_employees.map((val) => ({
        id: val.user_id,
        name: val.user.name,
      }));

    subAdminWorkerMap.workers[sub.node.subcontractor_id] =
      sub.node.subcontractor.subcontractor_workers.map((val) => ({
        id: val.worker_id,
        name: val.worker.user.name,
      }));
  });

  const [loading, setLoading] = useState(false);
  const [uploadTask, isUploading] =
    useAsyncMutation<UploadTaskToSiteformModalMutation>(
      graphql`
        mutation UploadTaskToSiteformModalMutation(
          $object: add_task_request_insert_input!
        ) {
          insert_add_task_request_one(object: $object) {
            id
            pk: id @__clientField(handle: "pk")
          }
        }
      `,
    );
  const [emailUserForJHACreationRequest] =
    useEmailUserForJhaCreationRequestMutation();
  const [form] = Form.useForm();
  const arr = [];
  for (let i = 1; i <= 50; i++) arr.push(i);

  return (
    <Modal
      open={visible}
      confirmLoading={loading || isUploading}
      title={
        <div>
          JHA Upload
          <br />
        </div>
      }
      okText="Submit"
      onCancel={() => onClose()}
      onOk={async () => {
        setLoading(true);
        await form
          .validateFields()
          .then(async (v: FormValues) => {
            const [frontFiles] = await uploadFilesWithPath(
              v.uploads.fileList.map((o) => ({
                file: o.originFileObj,
                directoryName: "jha-creation-req",
              })),
            );
            if (v.acceptance[0]) {
              const task_res = await uploadTask({
                variables: {
                  object: {
                    project_id: projectId,
                    requested_by_uid: auth.currentUser?.uid,
                    subcontractor_id: subcontractorId ?? v.subcontractor,
                    agree_to_not_hold_siteform_responsible: v.acceptance[0],
                    number_of_task_requested: v.numberOfJHAs,
                    attached_files: {
                      data: frontFiles.map((o) => ({
                        url: o.url,
                        name: o.name,
                        document_type: o.type,
                        type: "jha_creation_request",
                        author_id: auth.currentUser?.uid,
                        description: "JHA Request's Attached file",
                      })),
                    },
                  },
                },
              });
              const requestId = task_res.insert_add_task_request_one?.pk;
              if (!requestId) {
                throw new NotifyUserException("Task is not created");
              }
              message.success("Uploaded To SiteForm");
              emailUserForJHACreationRequest({
                variables: {
                  input: { requestId },
                },
              }).catch((e) =>
                notification.error({
                  message: "Couldn't send Email.",
                  description: e.message,
                }),
              );
            }
            form.resetFields();
            onSubmit();
          })
          .catch((e) => {
            notification.error({
              message: "Upload error",
              description: e.message,
              duration: null,
            });
            console.log(e);
          });
        setLoading(false);
      }}
    >
      <Form
        form={form}
        layout="vertical"
        initialValues={{ numberOfJHAs: 1 }}
        title="Upload Form"
      >
        <span>
          Upload JHA(s) in current format
          <Popover
            trigger={"hover"}
            content={
              <div className="w-32">
                JHAs are processed in their current format. Save time by
                uploading original and readable documents. Try to avoid images
                and photocopies which take longer.
                <div className="font-accent">
                  FOR INSTANT UPLOAD, EXIT AND SELECT "Upload with Excel
                  (instant)
                </div>
              </div>
            }
          >
            ℹ️
          </Popover>
        </span>
        <Form.Item
          className="mt-2"
          label="How many JHAs are you uploading?"
          name="numberOfJHAs"
        >
          <Select placeholder="Select Total no. of JHAs to be uploaded">
            {arr.map((i) => (
              <Select.Option value={i} key={i}>
                {i}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          className="mt-2"
          label={"Add Files"}
          name="uploads"
          rules={[{ required: true, message: "Upload at least 1 file." }]}
        >
          <Upload.Dragger
            multiple={true}
            iconRender={() => <AntCloudOutlined />}
            customRequest={() => true}
            maxCount={form.getFieldValue("numberOfJHA")}
          >
            <p className="ant-upload-drag-icon">
              <InboxOutlined />
            </p>
            <p>Click or drag files here to upload</p>
          </Upload.Dragger>
        </Form.Item>

        {subcontractorOptions && !subcontractorId && (
          <Form.Item
            name="subcontractor"
            label="Select Company"
            rules={[
              {
                required: true,
                message:
                  "Select Subcontractor/Company you want to upload JHA to",
              },
            ]}
          >
            <Select
              options={subcontractorOptions.map((option) => ({
                key: option.node.subcontractor_id,
                value: option.node.subcontractor_id,
                label: option.node.subcontractor.name,
              }))}
              onChange={(e) => {
                setSubAdminOptions(subAdminWorkerMap.subAdmins[e.toString()]);
                setWorkerOptions(subAdminWorkerMap.workers[e.toString()]);
                form.setFieldsValue({ subAdmins: undefined });
              }}
            >
              {" "}
            </Select>
          </Form.Item>
        )}

        {(subAdminOptions || workerOptions) && (
          <Form.Item
            name="subAdmins"
            label="Select Subcontractor Administrators and/or Workers to send processed JHAs for Approval"
          >
            <Select mode="multiple">
              {subAdminOptions && (
                <Select.OptGroup key={1} label={"Sub Admins"}>
                  {subAdminOptions.map((s) => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              )}
              {workerOptions && (
                <Select.OptGroup key={1} label={"Workers"}>
                  {workerOptions.map((s) => (
                    <Select.Option key={s.id} value={s.id}>
                      {s.name}
                    </Select.Option>
                  ))}
                </Select.OptGroup>
              )}
            </Select>
          </Form.Item>
        )}
        <Form.Item
          className="mt-2"
          name="acceptance"
          label={
            "I agree to hold SiteForm harmless from any liability or responsibility due to or from a result of the content of my JHA(s) or the content of the JHA(s) entered into SiteForm."
          }
          rules={[
            {
              required: true,
              message: "Please accept above condition to upload the JHAs",
            },
          ]}
        >
          <Checkbox.Group>
            <span>
              <Checkbox value={true} />
              <span className="mt-2"> I Accept</span>
            </span>
          </Checkbox.Group>
        </Form.Item>
        <div className="mt-2 text-grey">
          You will receive an email asking to review and accept the content once
          the JHA(s) are processed Email support if you have any questions:
          <a
            href="mailto:support@siteform.io"
            className="text-semantic-pending"
          >
            support@siteform.io
          </a>
        </div>
      </Form>
    </Modal>
  );
};
export default UploadTaskToSiteFormModal;
