import { DeleteOutlined, PlusOutlined, WarningFilled } from "@ant-design/icons";
import { Checkbox, message, notification, Button } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { forwardRef, useState } from "react";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import CounterLabel from "src/common/components/general/CounterLabel";
import DataScrollTable, {
  DataScrollTableImplementorProps,
  DataScrollTableRef,
} from "src/common/components/tables/basic/DataScrollTable";
import { InspectionTemplateTableQuery } from "src/common/types/generated/relay/InspectionTemplateTableQuery.graphql";
import { InspectionTemplateTable_inspection_templates$data } from "src/common/types/generated/relay/InspectionTemplateTable_inspection_templates.graphql";
import Tag from "src/common/components/general/Tag";
import useUpdateInspectionTemplate from "../../utils/useUpdateInspectionTemplate";
import useCreateInspectionInstanceFromTemplate from "../../utils/useCreateInspectionInstanceFromTemplate";
import { OptionType } from "../../utils/siteInspectionTypes";
import { IconProps } from "src/common/components/general/Icon";

type ColumnKeys =
  | "action"
  | "date"
  | "name"
  | "model"
  | "created_by"
  | "used"
  | "check"
  | "labels";
const CONNECTION_NAME = "inspection_template_connection";

type DConnection =
  InspectionTemplateTable_inspection_templates$data[typeof CONNECTION_NAME];
export type InspectionTemplateType = DConnection["edges"][number]["node"];
type Props = DataScrollTableImplementorProps<
  DConnection,
  ColumnKeys,
  InspectionTemplateTableQuery,
  string
> & {
  projectId: string;
  titleIcon?: IconProps;
  labelOptions: Array<OptionType>;
  headerComponent?: React.ReactElement;
  showDateRangeFilter?: boolean;
  OnRowItemClick: (insp: InspectionInstanceType) => void;
};
export type InspectionInstanceType =
  InspectionTemplateTable_inspection_templates$data["inspection_template_connection"]["edges"][number]["node"];

const InspectionTemplateTable = forwardRef<DataScrollTableRef, Props>(
  (
    { title = "Inspections", onRowClick, showDateRangeFilter, ...props },
    ref,
  ) => {
    const [loadingRows, setLoadingRows] = useState<{ [key: string]: boolean }>(
      {},
    );
    const [insertInspection, isInserting] =
      useCreateInspectionInstanceFromTemplate(props.projectId);
    const [updateInspection, isUpdating] = useUpdateInspectionTemplate();
    return (
      <DataScrollTable<DConnection, ColumnKeys, InspectionTemplateTableQuery>
        {...props}
        newCustomTableLook
        ref={ref}
        titleIcon={props.titleIcon}
        title={title}
        defaultTableSort={{ key: "date", order: "desc" }}
        datePickerDataIndex={showDateRangeFilter ? ["created_at"] : undefined}
        onRowClick={(insp) => {
          props.OnRowItemClick && props.OnRowItemClick(insp);
        }}
        connectionName={CONNECTION_NAME}
        totalCountConnectionName={"allInstancesConnection"}
        topBarButtons={[...(props.topBarButtons || [])]}
        columns={[
          {
            title: "Add to Project",
            key: "check",
            dataIndex: ["pk"],
            size: "icon",
            render: (pk, row) => {
              return (
                <BPopconfirm
                  title={
                    <div>
                      Are you sure you want to add this <br />
                      Inspection to this project?
                    </div>
                  }
                  onConfirm={async (e) => {
                    e?.stopPropagation();
                    setLoadingRows((prev) => ({
                      ...prev,
                      [pk]: true,
                    }));
                    try {
                      await insertInspection(row);
                      message.success(
                        `Successfully added ${row.name.en} to your Library`,
                      );
                    } catch (err) {
                      console.log(err);
                      message.error(
                        `Error: Couldn't add ${row.name.en} to your Library`,
                      );
                    }
                    setLoadingRows((prev) => ({
                      ...prev,
                      [pk]: false,
                    }));
                  }}
                >
                  <Button
                    type="primary"
                    ghost
                    onClick={(e) => e.stopPropagation()}
                    shape="circle"
                    icon={<PlusOutlined />}
                    loading={!!loadingRows[pk]}
                  />
                </BPopconfirm>
              );
            },
          },
          {
            title: "Name",
            key: "name",
            size: "md",
            dataIndex: ["name", "en"],
            searchDataIndex: ["name", "en"],
            contentType: { type: "row-title" },
          },
          {
            title: "Model #",
            key: "model",
            size: "sm",
            sortable: true,
            searchDataIndex: ["model_number"],
            dataIndex: ["model_number"],
            render: (val) => val ?? "",
          },
          {
            title: "Used Times",
            key: "used",
            size: "sm",
            sortable: true,
            dataIndex: ["inspection_instances_aggregate", "aggregate", "count"],
            render: (_, row) => {
              return row.inspection_instances.length ? (
                <CounterLabel
                  count={row.inspection_instances.length}
                  label="Used Times"
                />
              ) : (
                ""
              );
            },
          },
          {
            title: "Labels",
            key: "labels",
            size: "sm",
            sortable: true,
            dataIndex: ["inspection_template_qr_codes"],
            // ...(props.labelOptions
            //   ? {
            //       filters: {
            //         type: "checklist",
            //         dataIndex: ["inspection_instance_labels"],
            //         options: props.labelOptions.map((r) => ({
            //           text: r.label,
            //           value: r.value,
            //         })),
            //       },
            //     }
            //   : {}),
            render: (_, row) => {
              return row.inspection_labels.length ? (
                row.inspection_labels.length === 1 ? (
                  <Tag status="inactive">
                    {row.inspection_labels[0].label.label_name}
                  </Tag>
                ) : (
                  <CounterLabel
                    count={row.inspection_labels.length}
                    label="Labels"
                    popover={{
                      trigger: "hover",
                      placement: "bottom",
                      content: (
                        <div>
                          {row.inspection_labels.map((qr) => (
                            <div className="mt-0.5" key={qr.label_id}>
                              {qr.label.label_name}
                            </div>
                          ))}
                        </div>
                      ),
                    }}
                  />
                )
              ) : (
                ""
              );
            },
          },
          {
            title: "Created On",
            key: "date",
            sortable: true,
            contentType: {
              type: "date",
              renderOptions: () => ({ format: "full", withTime: true }),
            },
            size: "sm",
            defaultSortOrder: "desc",
            dataIndex: ["created_at"],
          },
          {
            title: "Created By",
            key: "created_by",
            sortable: true,
            size: "md",
            defaultSortOrder: "asc",
            searchDataIndex: ["created_by_user", "name"],
            dataIndex: ["created_by_user", "name"],
          },
          {
            title: "Action",
            key: "action",
            size: "icon",
            dataIndex: ["pk"],
            render: (pk, row) => (
              <BPopconfirm
                cancelText={"No"}
                icon={<WarningFilled color="red" />}
                onConfirm={async () => {
                  //delete worker
                  if (pk) {
                    setLoadingRows((prev) => ({
                      ...prev,
                      [pk]: true,
                    }));
                    try {
                      await updateInspection({
                        variables: {
                          id: pk,
                          _set: { deleted_at: dayjs().toISOString() },
                        },
                      });
                      message.success("Deleted Inspection Successfully");
                    } catch (e) {
                      console.log(e);
                      notification.error({
                        message: "Couldn't delete QR: " + row.name.en,
                        description:
                          e instanceof Error ? e.message : JSON.stringify(e),
                      });
                    }
                    setLoadingRows((prev) => ({
                      ...prev,
                      [pk]: false,
                    }));
                  }
                }}
                placement="topRight"
                title={
                  <div className="w-24">
                    <div className="text-semantic-negative font-accent">
                      Delete this Inspection
                    </div>
                    <div>
                      Do you want to delete this Inspection? deleting this means
                      you agree to remove this Inspection from all Inspection
                      QRs present on your project. <br />
                      Are you sure?
                    </div>
                  </div>
                }
              >
                <Button
                  danger
                  onClick={(e) => e.stopPropagation()}
                  type="primary"
                  shape="circle"
                  loading={!!loadingRows[pk]}
                  icon={<DeleteOutlined />}
                />
              </BPopconfirm>
            ),
          },
        ]}
        queryNode={graphql`
          query InspectionTemplateTableQuery(
            $first: Int!
            $after: String
            $where: inspection_template_bool_exp!
            $order_by: [inspection_template_order_by!]!
          ) {
            ...InspectionTemplateTable_inspection_templates
              @arguments(
                first: $first
                after: $after
                where: $where
                order_by: $order_by
              )
            ...InspectionTemplateTable_total @arguments(where: $where)
          }
        `}
        totalCountNode={graphql`
          fragment InspectionTemplateTable_total on query_root
          @argumentDefinitions(where: { type: "inspection_template_bool_exp!" })
          @refetchable(
            queryName: "InspectionTemplateTableTotalRefetchableQuery"
          ) {
            allInstancesConnection: inspection_template_connection(
              where: $where
            ) {
              edges {
                node {
                  id
                }
              }
            }
          }
        `}
        paginationNode={graphql`
          fragment InspectionTemplateTable_inspection_templates on query_root
          @argumentDefinitions(
            first: { type: "Int!" }
            after: { type: "String" }
            where: { type: "inspection_template_bool_exp!" }
            order_by: { type: "[inspection_template_order_by!]!" }
          )
          @refetchable(queryName: "InspectionTemplateTableRefetchableQuery") {
            inspection_template_connection(
              first: $first
              after: $after
              where: $where
              order_by: $order_by
            )
              @connection(
                key: "InspectionTemplateTable_inspection_template_connection"
                filters: []
              ) {
              edges {
                node {
                  ...InspectionTemplateFrag @relay(mask: false)
                }
              }
            }
          }
        `}
      />
    );
  },
);

export default InspectionTemplateTable;
