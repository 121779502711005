import NestedRoute from "../../../../../common/types/manual/NestedRoute";
import TabsDemo from "./TabsDemo";
import getRouteChildrenByNavigationItems from "../utils/getRouteChildrenByNavigationItems";
import tabsDemoNavigationItems from "./tabsDemoNavigationItems";

const tabsDemoRoute: NestedRoute = {
  element: <TabsDemo />,
  children: getRouteChildrenByNavigationItems(tabsDemoNavigationItems),
};
export default tabsDemoRoute;
