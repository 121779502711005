import React from "react";
import GCReportsType from "src/common/types/manual/GCReportsType";
import GCProjectReportsPTPsTable from "./GCProjectReportsPTPsTable";

interface GCProjectReportsPTPsProps extends GCReportsType {}

const GCProjectReportsPTPs: React.FunctionComponent<
  GCProjectReportsPTPsProps
> = (props) => {
  return (
    <GCProjectReportsPTPsTable
      projectId={props.projectId}
      openRow={props.openRow}
    />
  );
};

export default GCProjectReportsPTPs;
