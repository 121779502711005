import { ButtonProps } from "src/common/components/general/Button";
import * as React from "react";
import { IconDotsVertical } from "@tabler/icons";
import { UploadOutlined, DownloadOutlined } from "@ant-design/icons";

interface topBarButtonProps {
  onAddJhaClick?: () => void;
  onDownloadJhaLogClick?: () => void;
  onDownloadJhasClick?: () => void;
  onArchiveJhasClick?: () => void;
  onDeleteJhasClick?: () => void;
  onAddJHAFromCompanyLibraryClick?: () => void;
  isSubAdminCompanyJHA?: boolean;
}

const topBarButtons = ({
  onAddJhaClick,
  onDownloadJhaLogClick,
  onDownloadJhasClick,
  onArchiveJhasClick,
  onDeleteJhasClick,
  onAddJHAFromCompanyLibraryClick,
}: topBarButtonProps) => {
  const buttons: Array<ButtonProps> = [
    {
      fake: true,
      icon: IconDotsVertical,
      secondary: true,
      hint: (
        <div>
          {onAddJhaClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onAddJhaClick();
              }}
            >
              <UploadOutlined /> Add
            </div>
          )}

          {onDownloadJhaLogClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDownloadJhaLogClick();
              }}
            >
              <DownloadOutlined /> Download JHA Log
            </div>
          )}
          {onAddJHAFromCompanyLibraryClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={5}
              onClick={(e) => {
                e.stopPropagation();
                onAddJHAFromCompanyLibraryClick();
              }}
            >
              <UploadOutlined /> Add JHA from Company Library
            </div>
          )}

          {onDownloadJhasClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDownloadJhasClick();
              }}
            >
              <DownloadOutlined /> Download JHA(s)
            </div>
          )}

          {onArchiveJhasClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onArchiveJhasClick();
              }}
            >
              🗓️ Archive
            </div>
          )}

          {onDeleteJhasClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              onClick={(e) => {
                e.stopPropagation();
                onDeleteJhasClick();
              }}
            >
              🗑️ Delete
            </div>
          )}
        </div>
      ),
    },
  ];

  return buttons;
};

export default topBarButtons;
