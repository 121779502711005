import React from "react";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import Button from "src/common/components/general/Button";
import usePtpQr from "../../../utils/ptpQrHook";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";

export interface PtpSubmissionTypeProps extends SiteOrientationStepProps {}

const PtpSubmissionType: React.FC<PtpSubmissionTypeProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const { ptpQrUser, setPtpQrUser } = usePtpQr();

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={() => {
        props.onNext();
      }}
      onBackButtonClick={props.onBack}
      nextButtonDisabled={ptpQrUser.signInType === null}
    >
      <SiteFeatureStepsInputLayout>
        <div className="flex justify-center text-1.25">
          {langStrings.strings.howDoYouWantToDocumentWorkers}
        </div>

        <div className="mt-2 flex justify-center flex-row space-x-1 ">
          <div className="inline-flex">
            <Button
              fullWidth
              label={langStrings.strings.byPhoto}
              onClick={() => {
                setPtpQrUser((prev) => ({ ...prev, signInType: "quick" }));
                props.onNext();
              }}
            />
          </div>
          <div className="inline-flex">
            <Button
              fullWidth
              label={langStrings.strings.bySigning}
              onClick={() => {
                setPtpQrUser((prev) => ({ ...prev, signInType: "signature" }));
                props.onNext();
              }}
            />
          </div>
        </div>
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpSubmissionType;
