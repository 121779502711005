import { FC, useMemo } from "react";
import { Button, Card, message } from "antd";
import {
  useGetIncidentOptionsQuery,
  useUpdateStatementDetailMutation,
  Statement_Detail_Set_Input,
  useInsertImageMutation,
  useGenerateIncidentWitnessPdfMutation,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import IncidentTextField from "../basic/IncidentTextField";
import { IconDownload } from "@tabler/icons";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useSearchParams } from "react-router-dom";
import IncidentDatepicker from "../basic/IncidentDatepicker";
import IncidentSelectField from "../basic/IncidentSelectField";
import IncidentUser from "../incident-users/IncidentUser";
import getNextPage from "../../utils/getNextPage";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentTypeProps from "../../utils/IncidentTypeProps";
import SignatureCard from "src/domain-features/incident-management/components/basic/SignatureCard";
import useIncidentUserMutations from "../../utils/useIncidentUserMutations";
import IncidentDocViewAndUpload from "../basic/IncidentDocViewAndUpload";
import useUploadIncidentDocuments from "../../utils/useUploadIncidentDocuments";
import useDeleteIncidentDocument from "../../utils/useDeleteIncidentDocument";
import { DeletedDocument } from "../basic/IncidentDocViewer";
import dayjs from "dayjs";

const WitnessDetail: FC<IncidentTypeProps> = ({ incident, refetch }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const witnessUserId = searchParams.get("id");
  const authUser = useAuthUser();
  const { insertIncidentUser } = useIncidentUserMutations(
    "witness",
    incident,
    refetch,
  );
  const [insertImage] = useInsertImageMutation();
  const [updateStatementDetail] = useUpdateStatementDetailMutation();
  const [generateIncidentWitnessPdf, { loading: generating }] =
    useGenerateIncidentWitnessPdfMutation();
  const [uploadIncidentDocuments] = useUploadIncidentDocuments();
  const [deleteIncidentDocument] = useDeleteIncidentDocument();

  const witnessData = useMemo(() => {
    const witnessData = incident.witnesses.find(
      (witness) => witness.id === witnessUserId,
    );
    return witnessData;
  }, [incident.witnesses, witnessUserId]);

  const uploadSign = async (
    imgUrl: string,
    type: "statement_provider_signature_id" | "statement_taken_by_signature_id",
  ) => {
    await insertImage({ variables: { object: { url: imgUrl } } }).then(
      (res) => {
        const imageId = res.data?.insert_image_one?.id;
        if (imageId) {
          updateStatement(
            type,
            imageId,
            `Signature added by ${
              type === "statement_provider_signature_id"
                ? `person providing witness statement`
                : `person taking witness statement`
            }`,
          );
        }
      },
    );
  };

  const deleteDocument = async (doc: DeletedDocument) => {
    await deleteIncidentDocument({
      doc,
      incident,
      incidentUserId: witnessUserId ?? undefined,
      incidentFieldKey: "witness_detail",
    });
  };

  const uploadDocument = async (objects: Document_Insert_Input[]) => {
    await uploadIncidentDocuments({
      incidentFieldKey: "witness_detail",
      incidentUserId: witnessUserId ?? undefined,
      incident: incident,
      objects: objects,
    });
  };

  const { data: incidentOptions } = useGetIncidentOptionsQuery({
    variables: { projectId: incident.project_id },
    fetchPolicy: "cache-first",
  });
  const projectEmployeeOptions = useMemo(() => {
    const employeeOptions = incidentOptions?.project_employee.map((pe) => ({
      label: pe.employee.user.name,
      value: pe.employee.user.id,
    }));
    return employeeOptions;
  }, [incidentOptions?.project_employee]);

  const statementTypes = [
    { label: "Injured Person", value: "injured" },
    { label: "Foreman/Supervisor", value: "foreman" },
    { label: "Employee", value: "employee" },
    { label: "Witness", value: "witness" },
  ];

  const updateStatement = async (
    key: keyof Statement_Detail_Set_Input,
    value: number | string | boolean,
    comment: string,
  ) => {
    if (witnessData?.statement_detail) {
      const updatedWitnesses = incident.witnesses.map((witness) => {
        if (witness.id === witnessUserId) {
          return { ...witness, [key]: value };
        } else {
          return witness;
        }
      });
      const updatedIncident = { ...incident, witnesses: updatedWitnesses };
      const patch = createIncidentPatch(updatedIncident, incident);
      await updateStatementDetail({
        variables: {
          id: witnessData.statement_detail.id,
          _set: { [key]: value },
          objects: {
            patch: patch,
            comment: comment,
            edited_by_uid: authUser.uid,
            edit_type: "witness-statement-edit",
            incident_id: incident.id,
          },
        },
        optimisticResponse: {
          update_statement_detail_by_pk: {
            ...witnessData.statement_detail,
            id: witnessData.statement_detail.id,
            [key]: value,
          },
          insert_incident_edit: {
            affected_rows: 1,
          },
        },
      });
    }
  };

  const witnessUserName =
    incident.witnesses.find((witness) => witness.id === witnessUserId)?.user
      .name ?? "";

  return (
    <>
      <div className="absolute left-24 top-2 text-2">Witness Details</div>
      <div className="w-full mt-6 pl-4 table-fixed overflow-y-auto">
        <IncidentUser
          title="Witness"
          incident={incident}
          incidentUserId={witnessUserId ?? undefined}
          onUpdateUser={async (
            id: string,
            name: string,
            subcontractorId?: string,
          ) => {
            insertIncidentUser(id, name, subcontractorId);
          }}
          userType="witness"
        />

        {witnessData?.statement_detail && witnessData && witnessUserId && (
          <>
            <Card className="w-4/5">
              <IncidentSelectField
                title="Select who is providing the Statement"
                onChange={(option) => {
                  updateStatement(
                    "type",
                    option.value,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "Select who is providing the Statement" to ${option.label}`,
                  );
                }}
                options={statementTypes}
                value={witnessData.statement_detail.type ?? undefined}
              />
            </Card>
            <Card className="w-4/5">
              <IncidentDatepicker
                label="Date of statement"
                onChange={(date) => {
                  updateStatement(
                    "statement_date",
                    date,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "Date of statement" to ${dayjs(
                      date,
                    ).format("DD MMM, YYYY")}`,
                  );
                }}
                value={witnessData.statement_detail.statement_date ?? undefined}
              />
            </Card>
            <Card className="w-4/5">
              <IncidentSelectField
                title="Employee Collecting the statement"
                onChange={(option) => {
                  updateStatement(
                    "employee_collecting_statement_user_id",
                    option.value,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "Employee Collecting the statement" to ${option.label}`,
                  );
                }}
                value={
                  witnessData.statement_detail.employee_collecting_statement
                    ?.id ?? undefined
                }
                options={projectEmployeeOptions ?? []}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentTextField
                label={"In your own words, explain what happened"}
                text={witnessData?.statement_description.en}
                textId={witnessData?.statement_description.id}
                fieldTypeKey={"witness_detail"}
                field={"statement_description"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
              <div className="mt-1">
                Consider the following details as you provide your statement
                <div>
                  - What happened? Tell a story answering who, what, where,
                  when, how and why
                </div>
                <div> - Where were you when the incident took place? </div>
                <div>
                  - What activity was being performed prior to the event?
                </div>
                <div> - What do you believe happened? </div>
                <div> - Who else was there? </div>
              </div>
            </Card>

            <Card className="w-4/5">
              <IncidentTextField
                label="Do you recall anything unusual or unexpected that happened?"
                text={witnessData.statement_detail.unusual_happenings.en}
                textId={witnessData.statement_detail.unusual_happenings.id}
                fieldTypeKey={"witness_detail"}
                field={"unusual_happenings"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentTextField
                label="How would you prevent this incident from happening in the future?"
                text={witnessData.statement_detail.future_prevention.en}
                textId={witnessData.statement_detail.future_prevention.id}
                fieldTypeKey={"witness_detail"}
                field={"future_prevention"}
                incidentUserId={witnessUserId}
                incident={incident}
              />
            </Card>

            <Card className="w-4/5">
              <IncidentSelectField
                title="If you were injured, have you ever injured this body part
                  before?"
                onChange={(option) => {
                  updateStatement(
                    "body_part_injured_before",
                    option.value,
                    `Updated Insurance Detail for Injured User ${witnessUserName} - "If you were injured, have you ever injured this body part
                  before?" to ${option.label}`,
                  );
                }}
                value={
                  witnessData.statement_detail.body_part_injured_before ??
                  undefined
                }
                options={[
                  { value: false, label: "No" },
                  { value: true, label: "Yes" },
                ]}
              />

              {witnessData.statement_detail.body_part_injured_before && (
                <IncidentTextField
                  label="Explain"
                  text={witnessData.statement_detail.prior_injury_detail.en}
                  textId={witnessData.statement_detail.prior_injury_detail.id}
                  fieldTypeKey={"witness_detail"}
                  field={"prior_injury_detail"}
                  incidentUserId={witnessUserId}
                  incident={incident}
                />
              )}
            </Card>

            <SignatureCard
              label="Signature of person providing statement"
              signatureUrl={
                witnessData.statement_detail.statement_provider_signature?.url
              }
              insertSignImage={async (signImageId) =>
                await uploadSign(signImageId, "statement_provider_signature_id")
              }
            />

            <SignatureCard
              label="Signature of person taking statement"
              signatureUrl={
                witnessData.statement_detail.statement_taken_by_signature?.url
              }
              insertSignImage={async (signImageId) =>
                await uploadSign(signImageId, "statement_taken_by_signature_id")
              }
            />

            <IncidentDocViewAndUpload
              deleteDocument={deleteDocument}
              documents={witnessData.attached_files}
              groupId={witnessData.id}
              uploadDocument={uploadDocument}
              type="incident"
            />

            <div className="mt-2 inline-flex">
              <Button
                type="primary"
                htmlType="submit"
                onClick={() => {
                  const index =
                    incident.witnesses.findIndex(
                      (witnessUser) => witnessUser.id === witnessUserId,
                    ) ?? 0;
                  if (index + 1 < incident.witnesses.length) {
                    const nextWitnessId = incident.witnesses[index + 1].id;
                    if (nextWitnessId) {
                      setSearchParams({
                        subview: "witness",
                        id: nextWitnessId,
                      });
                    }
                  } else {
                    const next = getNextPage(incident, "witness");
                    setSearchParams({ subview: next });
                  }
                }}
              >
                Save
              </Button>

              <div className="ml-2">
                <Button
                  type="primary"
                  loading={generating}
                  icon={<IconDownload />}
                  onClick={async () => {
                    const { data } = await generateIncidentWitnessPdf({
                      variables: {
                        input: {
                          incidentId: incident.id,
                          userId: witnessData.user.id,
                        },
                      },
                    });
                    if (data?.generateIncidentWitnessPdf) {
                      downloadFromUrl(data.generateIncidentWitnessPdf);
                    }
                    message.success("Download complete");
                  }}
                >
                  Download This Witness Statement
                </Button>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default WitnessDetail;
