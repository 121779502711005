import React, { useState } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import dayjs from "dayjs";
import { message } from "antd";
import { auth } from "src/common/functions/firebase";
import { usePtpQrCodeUserSignInThroughSecurityQuestionsMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { roleVar } from "src/common/api/apollo/cache";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import BirthDatePicker from "../../../../../../../../../common/components/birth-date-picker/BirthDatePicker";
import { useCurrentLangStrings } from "../../../../../../../../../utility-features/i18n/context/languageHooks";
import { signInWithCustomToken } from "firebase/auth";
import usePtpQr from "../../../utils/ptpQrHook";
import hasApolloErrorCode from "src/utility-features/error-handling/hasApolloErrorCode";

export interface PtpQrUserBirthDateProps extends SiteOrientationStepProps {
  onLoginSuccess: (userId: string) => Promise<void>;
}

const PtpQrUserBirthDate: React.FC<PtpQrUserBirthDateProps> = (props) => {
  const ptpQrContext = usePtpQr();
  const userInfo = ptpQrContext.ptpQrUser.userInfo;
  const [birthDate, setBirthDate] = useState<dayjs.Dayjs | null>(
    userInfo?.birthDate ? dayjs(userInfo?.birthDate) : null,
  );
  const [loading, setLoading] = useState(false);
  const [ptpQrCodeUserSignIn] =
    usePtpQrCodeUserSignInThroughSecurityQuestionsMutation();
  const langStrings = useCurrentLangStrings();

  const goNext = () => {
    props.onNext();
  };

  return (
    <SiteFeatureStepLayout
      onNextButtonClick={async () => {
        if (
          userInfo?.birthDate &&
          birthDate &&
          userInfo.birthDate === birthDate.format("YYYY-MM-DD")
        ) {
          goNext();
        } else {
          if (birthDate) {
            ptpQrContext.setPtpQrUser((prev) => ({
              ...prev,
              userInfo: {
                ...userInfo,
                birthDate: birthDate.format("YYYY-MM-DD"),
              },
            }));
          }
          const workerName = userInfo?.name;

          if (!birthDate) {
            message.error(langStrings.strings.pleaseEnterYourDateOfBirth);
            return;
          }
          if (!workerName) {
            message.error(langStrings.strings.pleaseGoBackAndEnterYourName);
            return;
          }
          if (!userInfo?.id) {
            setLoading(true);
            try {
              const { data } = await ptpQrCodeUserSignIn({
                variables: {
                  input: {
                    workerDob: birthDate.format("YYYY-MM-DD"),
                    projectId: ptpQrContext.projectId,
                    name: workerName,
                  },
                },
              });

              if (data) {
                const { workerId, token, userRole } =
                  data.ptpQrCodeUserSignInThroughSecurityQuestions;
                if (token) {
                  roleVar("none");
                  await auth.signOut();
                  await signInWithCustomToken(auth, token);
                }
                if (userRole === "employee") {
                  throw new Error("As an Gc user you cannot submit a ptp");
                }
                props.onLoginSuccess && (await props.onLoginSuccess(workerId));
                setLoading(false);
                goNext();
              } else
                throw new Error(
                  "data not found. please try reloading or submitting again",
                );
            } catch (err) {
              setLoading(false);
              if (hasApolloErrorCode(err, "unique-user-not-found")) {
                ptpQrContext.setPtpQrUser((prev) => ({
                  ...prev,
                  userInfo: {
                    ...prev.userInfo,
                    birthDate: birthDate.format("YYYY-MM-DD"),
                  },
                }));

                goNext();
              } else {
                message.info(
                  "We could not find a matching profile. Please provide additional information",
                  0,
                );
                props.onNext();
              }
            }
          } else {
            ptpQrContext.setPtpQrUser((prev) => ({
              ...prev,
              userInfo: {
                ...prev.userInfo,
                birthDate: birthDate.format("YYYY-MM-DD"),
              },
            }));
            goNext();
          }
        }
      }}
      nextButtonDisabled={!birthDate}
      loading={loading}
      onBackButtonClick={props.onBack}
    >
      <SiteFeatureStepsInputLayout
        headline={langStrings.strings.enterYourDateOfBirth}
      >
        <BirthDatePicker value={birthDate} onValueChange={setBirthDate} />
      </SiteFeatureStepsInputLayout>
    </SiteFeatureStepLayout>
  );
};

export default PtpQrUserBirthDate;
