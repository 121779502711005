import { useSuspenseQuery } from "@apollo/client";
import { useNavigate } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";

import {
  GetCsrProjectDataDocument,
  GetCsrProjectDataQuery,
  GetCsrProjectDataQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import CsrProjectWorkers from "./CsrProjectWorkers";
import CsrProjectEmployees from "./CsrProjectEmployees";
import CsrProjectSubs from "./CsrProjectSubs";
import CsrProjectOrientations from "./CsrProjectOrientations";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";

const CsrProject: React.FunctionComponent<{
  projectId: string;
  subview: string;
}> = ({ projectId, subview }) => {
  const { data } = useSuspenseQuery<
    GetCsrProjectDataQuery,
    GetCsrProjectDataQueryVariables
  >(GetCsrProjectDataDocument, { variables: { projectId } });
  const navigate = useNavigate();
  const project = data.project_by_pk;

  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  if (!project) throw new Error("Project not found");
  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "employees",
      label: "Employees",
      content: (
        <CsrProjectEmployees
          projectId={projectId}
          tradeOptions={data.employee_title.map((t) => ({
            label: t.name.en,
            value: t.id,
          }))}
        />
      ),
    },
    {
      id: "workers",
      label: "Workers",
      content: (
        <CsrProjectWorkers
          projectData={project}
          roleOptions={data.worker_role.map((t) => ({
            label: t.translation.en,
            value: t.value,
          }))}
          subTitles={data.subcontractor_employee_title.map((t) => ({
            label: t.name.en,
            value: t.id,
          }))}
          titleOptions={data.worker_title.map((t) => ({
            label: t.translation.en,
            value: t.id,
          }))}
        />
      ),
    },
    {
      id: "subs",
      label: "Subcontractors",
      content: (
        <CsrProjectSubs
          projectId={projectId}
          tradeOptions={data.subcontractor_trade.map((t) => ({
            label: t.name,
            value: t.id,
          }))}
        />
      ),
    },
    {
      id: "orientations",
      label: "Orientations",
      content: (
        <CsrProjectOrientations projectId={projectId} projectData={project} />
      ),
    },
  ];

  return (
    <div>
      <BasicWrapper>
        Project: {project.name}, {project.general_contractor.name}
        <br />
        <CustomTabs
          onTabSelect={handleReportsTabClick}
          tabs={tabs}
          selectedTabId={subview}
        />
      </BasicWrapper>
    </div>
  );
};
export default CsrProject;
