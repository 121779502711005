/**
 * @generated SignedSource<<06fb0c2c28f6f3f2173c67a8277b7dd8>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { calendar_bool_exp, subcontractor_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SitedeliveryTimeBlockModalQuery$variables = {
  calendarWhere: calendar_bool_exp;
  projectId: string;
  subcontractorWhere: subcontractor_bool_exp;
};
export type SitedeliveryTimeBlockModalQuery$data = {
  readonly calendar_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  };
  readonly project_delivery_sub_block_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly calendars: ReadonlyArray<{
          readonly calendar: {
            readonly color_hex: string;
            readonly id: string;
            readonly name: {
              readonly en: string;
            };
            readonly pk: string;
          };
        }>;
        readonly end_time: string;
        readonly id: string;
        readonly pk: string;
        readonly start_time: string;
        readonly subcontractors: ReadonlyArray<{
          readonly subcontractor: {
            readonly id: string;
            readonly name: string;
            readonly pk: string;
          };
        }>;
        readonly weekday: number;
      };
    }>;
  };
  readonly subcontractor_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
};
export type SitedeliveryTimeBlockModalQuery = {
  response: SitedeliveryTimeBlockModalQuery$data;
  variables: SitedeliveryTimeBlockModalQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "calendarWhere"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "projectId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "subcontractorWhere"
},
v3 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name": "asc"
    }
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "subcontractorWhere"
  }
],
v4 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "calendarWhere"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v8/*: any*/)
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "start_time",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "end_time",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "weekday",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "color_hex",
  "storageKey": null
},
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cursor",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "concreteType": "PageInfo",
  "kind": "LinkedField",
  "name": "pageInfo",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "endCursor",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "hasNextPage",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v18 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v19 = {
  "alias": null,
  "args": null,
  "concreteType": "text_translation",
  "kind": "LinkedField",
  "name": "name",
  "plural": false,
  "selections": [
    (v8/*: any*/),
    (v7/*: any*/)
  ],
  "storageKey": null
},
v20 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 10000
  },
  {
    "fields": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "projectId"
          }
        ],
        "kind": "ObjectValue",
        "name": "project_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SitedeliveryTimeBlockModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "subcontractorConnection",
        "kind": "LinkedField",
        "name": "subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v4/*: any*/),
                  (v5/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "calendarConnection",
        "kind": "LinkedField",
        "name": "calendar_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "calendarEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v9/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": "project_delivery_sub_block_connection",
        "args": null,
        "concreteType": "project_delivery_sub_blockConnection",
        "kind": "LinkedField",
        "name": "__SitedeliveryTimeBlockModal_project_delivery_sub_block_connection_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_delivery_sub_blockEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_delivery_sub_block",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v4/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sub_block_calendar",
                    "kind": "LinkedField",
                    "name": "calendars",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "calendar",
                        "kind": "LinkedField",
                        "name": "calendar",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v4/*: any*/),
                          (v9/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sub_block_subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractors",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v4/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v2/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SitedeliveryTimeBlockModalQuery",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "subcontractorConnection",
        "kind": "LinkedField",
        "name": "subcontractor_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "subcontractorEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "subcontractor",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v5/*: any*/),
                  (v7/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "calendarConnection",
        "kind": "LinkedField",
        "name": "calendar_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "calendarEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "calendar",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v19/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v20/*: any*/),
        "concreteType": "project_delivery_sub_blockConnection",
        "kind": "LinkedField",
        "name": "project_delivery_sub_block_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "project_delivery_sub_blockEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "project_delivery_sub_block",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v7/*: any*/),
                  (v17/*: any*/),
                  (v18/*: any*/),
                  (v10/*: any*/),
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sub_block_calendar",
                    "kind": "LinkedField",
                    "name": "calendars",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "calendar",
                        "kind": "LinkedField",
                        "name": "calendar",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v19/*: any*/),
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "sub_block_subcontractor",
                    "kind": "LinkedField",
                    "name": "subcontractors",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "subcontractor",
                        "kind": "LinkedField",
                        "name": "subcontractor",
                        "plural": false,
                        "selections": [
                          (v7/*: any*/),
                          (v17/*: any*/),
                          (v18/*: any*/),
                          (v5/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v7/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v14/*: any*/)
                ],
                "storageKey": null
              },
              (v15/*: any*/)
            ],
            "storageKey": null
          },
          (v16/*: any*/)
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v20/*: any*/),
        "filters": [],
        "handle": "connection",
        "key": "SitedeliveryTimeBlockModal_project_delivery_sub_block_connection",
        "kind": "LinkedHandle",
        "name": "project_delivery_sub_block_connection"
      }
    ]
  },
  "params": {
    "cacheID": "981781cb85440d0286bbdb81ec2219c5",
    "id": null,
    "metadata": {
      "connection": [
        {
          "count": null,
          "cursor": null,
          "direction": "forward",
          "path": [
            "project_delivery_sub_block_connection"
          ]
        }
      ]
    },
    "name": "SitedeliveryTimeBlockModalQuery",
    "operationKind": "query",
    "text": "query SitedeliveryTimeBlockModalQuery(\n  $calendarWhere: calendar_bool_exp!\n  $subcontractorWhere: subcontractor_bool_exp!\n  $projectId: uuid!\n) {\n  subcontractor_connection(where: $subcontractorWhere, order_by: {name: asc}) {\n    edges {\n      node {\n        pk: id\n        name\n        id\n      }\n    }\n  }\n  calendar_connection(where: $calendarWhere) {\n    edges {\n      node {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n      }\n    }\n  }\n  project_delivery_sub_block_connection(where: {project_id: {_eq: $projectId}}, first: 10000) {\n    edges {\n      node {\n        id\n        pk: id\n        start_time\n        end_time\n        weekday\n        calendars {\n          calendar {\n            id\n            pk: id\n            name {\n              en\n              id\n            }\n            color_hex\n          }\n          id\n        }\n        subcontractors {\n          subcontractor {\n            id\n            pk: id\n            name\n          }\n          id\n        }\n        __typename\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "2fc8be0d4386661e6aaa580741790f26";

export default node;
