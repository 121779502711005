import TaskType from "src/common/types/manual/TaskType";
import updateTaskMutation from "src/common/api/relay/mutations/UpdateTask";
import { UpdateTaskMutation } from "src/common/types/generated/relay/UpdateTaskMutation.graphql";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import dayjs from "dayjs";
import { auth } from "src/common/functions/firebase";
import { compareTwoJHAs } from "src/common/components/CompareTwoJHAs";
import handleUpdateTaskUpdater from "./handleUpdateTaskUpdater";

const useOnSaveTaskAfterEdit = () => {
  const [updateTask] = useAsyncMutation<UpdateTaskMutation>(updateTaskMutation);

  const OnSaveTaskAfterEdit = async (
    currentTask: TaskType,
    previousTask: TaskType,
    taskId: string,
    descriptionId: string,
    stepsOrderChanged: boolean,
    removeSigns: boolean,
  ) => {
    let hazardsEdited = 0,
      stepsEdited = 0,
      controlsEdited = 0,
      controlsDeleted = 0,
      controlsAdded = 0,
      ppeAdded = 0,
      ppeDeleted = 0,
      permitAdded = 0,
      permitDeleted = 0,
      ecmAdded = 0,
      ecmDeleted = 0,
      hazardsAdded = 0,
      hazardsDeleted = 0,
      stepsAdded = 0,
      stepsDeleted = 0;
    let descriptionEdited = false;

    const currentJha: {
      steps: { [key: string]: string };
      hazards: { [key: string]: string };
      controls: { [key: string]: string };
      ecms: Array<string>;
    } = {
      steps: {},
      hazards: {},
      controls: {},
      ecms: [],
    };
    const initialJha: {
      steps: { [key: string]: string };
      hazards: { [key: string]: string };
      controls: { [key: string]: string };
      ecms: Array<string>;
    } = {
      steps: {},
      hazards: {},
      controls: {},
      ecms: [],
    };
    currentTask.steps.forEach((step) => {
      currentJha.steps[step.id] = step.description;
      step.hazards.forEach((h) => {
        currentJha.hazards[h.id] = h.description;
        currentJha.controls[h.id] = h.control;
        h.ecms?.forEach((e) => {
          currentJha.ecms.push(e.id + h.id);
        });
      });
    });
    const toBeDeletedEcms: Array<string> = [];
    previousTask.steps.forEach((step) => {
      initialJha.steps[step.id] = step.description;
      if (!currentJha.steps[step.id]) {
        stepsDeleted++;
      } else if (currentJha.steps[step.id] !== step.description) {
        stepsEdited++;
      }
      step.hazards.forEach((h) => {
        if (!currentJha.hazards[h.id]) {
          hazardsDeleted++;
        } else if (currentJha.hazards[h.id] !== h.description) {
          hazardsEdited++;
        }
        if (!currentJha.controls[h.id]) {
          controlsDeleted++;
        } else if (currentJha.controls[h.id] !== h.control) {
          controlsEdited++;
        }
        initialJha.hazards[h.id] = h.description;
        initialJha.controls[h.id] = h.control;
        toBeDeletedEcms.push(h.id);
        h.ecms?.forEach((e) => {
          initialJha.ecms.push(e.id + h.id);
          if (!currentJha.ecms.includes(e.id + h.id)) {
            ecmDeleted++;
          }
        });
      });
    });
    currentTask.steps.forEach((step) => {
      if (!initialJha.steps[step.id]) {
        stepsAdded++;
      }
      step.hazards.forEach((h) => {
        if (!initialJha.hazards[h.id]) {
          hazardsAdded++;
        }
        if (!initialJha.controls[h.id]) {
          controlsAdded++;
        }
        h.ecms?.forEach((e) => {
          if (!initialJha.ecms.includes(e.id + h.id)) {
            ecmAdded++;
          }
        });
      });
    });
    currentTask.ppes.forEach((cur) => {
      if (previousTask.ppes.findIndex((prev) => cur.id === prev.id) === -1)
        ppeAdded++;
    });
    previousTask.ppes.forEach((prev) => {
      if (currentTask.ppes.findIndex((cur) => cur.id === prev.id) === -1)
        ppeDeleted++;
    });
    currentTask.permits.forEach((cur) => {
      if (previousTask.permits.findIndex((prev) => prev.id === cur.id) === -1)
        permitAdded++;
    });
    previousTask.permits.forEach((prev) => {
      if (currentTask.permits.findIndex((cur) => prev.id === cur.id) === -1)
        permitDeleted++;
    });
    if (previousTask.description !== currentTask.description) {
      descriptionEdited = true;
    }
    const jhaPatch = compareTwoJHAs(currentTask, previousTask);
    const editType = `${
      (descriptionEdited ? ` JHA Description Edited ` : ``) +
      (stepsAdded ? stepsAdded + ` step(s) added ` : ``) +
      (stepsEdited ? stepsEdited + ` step(s) edited ` : ``) +
      (stepsDeleted ? stepsDeleted + ` step(s) deleted ` : ``) +
      (stepsOrderChanged ? `JHA Steps order changed ` : ``) +
      (hazardsAdded ? hazardsAdded + ` hazard(s) added ` : ``) +
      (hazardsEdited ? hazardsEdited + ` hazard(s) edited ` : ``) +
      (hazardsDeleted ? hazardsDeleted + ` hazard(s) deleted ` : ``) +
      (controlsAdded ? controlsAdded + ` control(s) added ` : ``) +
      (controlsEdited ? controlsEdited + ` control(s) edited ` : ``) +
      (controlsDeleted ? controlsDeleted + ` control(s) deleted ` : ``) +
      (ecmAdded ? ecmAdded + ` ecms(s) added ` : ``) +
      (ecmDeleted ? ecmDeleted + `ecm(s) deleted ` : ``) +
      (ppeAdded ? ppeAdded + ` PPE(s) added ` : ``) +
      (ppeDeleted ? ppeDeleted + ` PPE(s) deleted ` : ``) +
      (permitAdded ? permitAdded + ` Permit(s) added ` : ``) +
      (permitDeleted ? permitDeleted + ` Permit(s) deleted ` : ``)
    }`;
    await updateTask({
      variables: {
        taskId: taskId,
        taskPpeTypeObjects: currentTask.ppes.map((ppe) => ({
          ppe_type_id: ppe.id,
          task_id: taskId,
        })),
        ecmTypeWhere: {
          task_hazard_id: { _in: toBeDeletedEcms },
        },
        taskPermitTypeObjects: currentTask.permits.map((permit) => ({
          permit_type_id: permit.id,
          task_id: taskId,
        })),
        taskSignWhere: {
          is_active: { _eq: true },
          task_id: !!removeSigns ? { _eq: taskId } : { _is_null: true },
        },
        taskStepObjects: currentTask.steps.map((step, si) => ({
          id: step.id,
          task_id: taskId,
          sort_index: si,
          description: {
            data: {
              original: step.description,
              es: step.description,
              en: step.description,
            },
          },
          task_step_hazards: {
            data: step.hazards.map((h, hi) => ({
              id: h.id,
              description: {
                data: {
                  original: h.description,
                  es: h.description,
                  en: h.description,
                },
              },
              control: {
                data: {
                  original: h.control,
                  es: h.control,
                  en: h.control,
                },
              },
              task_hazard_ecm_types: {
                data: h.ecms
                  ? h.ecms.map((ecm) => ({
                      ecm_type_id: ecm.id,
                    }))
                  : [],
              },
              sort_index: hi,
            })),
          },
        })),
        task_set: {
          reviewed_at: null,
          reviewed_by_uid: null,
          updated_at: dayjs().format(),
          updated_by_uid: auth.currentUser?.uid,
        },
        taskEditobjects: [
          {
            task_id: taskId,
            task_patch: jhaPatch,
            edit_type: editType,
            edited_by_uid: auth.currentUser?.uid,
            edited_at: dayjs().format(),
          },
        ],
        _set: {
          en: currentTask.description,
          original: currentTask.description,
          es: currentTask.description,
          pt: currentTask.description,
        },
        text_pk_columns: {
          id: descriptionId,
        },
      },
      updater: handleUpdateTaskUpdater,
    });
  };

  return [OnSaveTaskAfterEdit] as const;
};

export default useOnSaveTaskAfterEdit;
