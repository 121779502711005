import {
  useInsertIncidentUserMutation,
  GetIncidentByPkQuery,
  GetIncidentByPkDocument,
  useUpdateIncidentUserMutation,
  useUpdateIncidentEditByPkMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import useAuthUser from "src/common/hooks/useAuthUser";
import { useSearchParams } from "react-router-dom";
import * as uuid from "uuid";
import createIncidentPatch from "./createIncidentPatch";
import { message } from "antd";

const useIncidentUserMutations = (
  userType: "witness" | "injured",
  incident: NonNullable<GetIncidentByPkQuery["incident_by_pk"]>,
  refetch: () => void,
) => {
  const authUser = useAuthUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const [updateIncidentUser] = useUpdateIncidentUserMutation();
  const [updateIncidentEdit] = useUpdateIncidentEditByPkMutation();
  const userKey = userType === "witness" ? "witnesses" : "injured_users";

  const [insertIncidentUserMutation] = useInsertIncidentUserMutation();

  const insertIncidentUser = async (
    id: string,
    name: string,
    subcontractorId?: string,
  ) => {
    const user = incident[userKey].find((user) => user.user.id === id);
    if (user && !user.deleted_at) {
      message.info(`The ${userType} user has already been added`);
      return;
    }

    if (user && user.deleted_at) {
      await restoreDeletedUser(user.id, name);
      return;
    }

    if (user) {
      await updateExistingUser(user.id, id, name);
    } else {
      await addNewUser(id, name, subcontractorId);
    }
  };

  const restoreDeletedUser = async (userId: string, name: string) => {
    const updatedIncident = {
      ...incident,
      [userKey]: incident[userKey].map((user) =>
        user.id === userId ? { ...user, deleted_at: null } : user,
      ),
    };
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Restored ${userType} user "${name}"`;

    await updateIncidentUser({
      variables: {
        id: userId,
        set: { deleted_at: null },
        objects: {
          patch,
          comment,
          edit_type: `${userType}-edit`,
          incident_id: incident.id,
        },
      },
    });
    refetch();
    setSearchParams({ ...searchParams, id: userId });
  };

  const updateExistingUser = async (
    userId: string,
    newUserId: string,
    name: string,
  ) => {
    const prevName = incident[userKey].find((user) => user.id === userId)?.user
      .name;
    const comment = `Updated ${userType} user from ${prevName} to ${name}`;

    const { data: updatedIncidentUser } = await updateIncidentUser({
      variables: {
        id: userId,
        set: { user_id: newUserId },
        objects: {
          comment,
          edit_type: `${userType}-update`,
          incident_id: incident.id,
          edited_by_uid: authUser.uid,
        },
      },
    });

    const updatedIncident = {
      ...incident,
      [userKey]: incident[userKey].map((user) => {
        if (
          user.user.id === userId &&
          updatedIncidentUser?.update_incident_user_by_pk
        ) {
          return updatedIncidentUser.update_incident_user_by_pk;
        }
        return user;
      }),
    };

    const patch = createIncidentPatch(updatedIncident, incident);
    const incidentEditId =
      updatedIncidentUser?.insert_incident_edit?.returning.at(0)?.id;

    if (incidentEditId) {
      await updateIncidentEdit({
        variables: { id: incidentEditId, _set: { patch } },
      });
    }
    refetch();
  };

  const addNewUser = async (
    id: string,
    name: string,
    subcontractorId?: string,
  ) => {
    const newUserId = uuid.v4();
    const comment = `Added new ${userType} user "${name}"`;
    let updatedIncident = incident;

    const { data: insertedUserData } = await insertIncidentUserMutation({
      variables: {
        object: {
          id: newUserId,
          user_id: id,
          subcontractor_id: subcontractorId,
          project_id: incident.project_id,
          type: userType,
          incident_id: incident.id,
          injury_detail:
            userType === "injured"
              ? { data: { insurance_claim: { data: {} } } }
              : undefined,
          statement_detail: userType === "witness" ? { data: {} } : undefined,
        },
        editObjects: {
          comment,
          incident_id: incident.id,
          edited_by_uid: authUser.uid,
          edit_type: `${userType}-inserted`,
          patch: [],
        },
      },
      update(cache, result) {
        const addedUser = result.data?.insert_incident_user_one;
        updatedIncident = {
          ...incident,
          [userKey]: [...incident[userKey], addedUser],
        };

        cache.writeQuery({
          data: { __typename: "query_root", incident_by_pk: updatedIncident },
          query: GetIncidentByPkDocument,
        });
      },
    });

    const insertedUserId = insertedUserData?.insert_incident_user_one?.id;
    if (insertedUserId) {
      setSearchParams({
        subview: userType === "injured" ? "injured_user" : userType,
        id: insertedUserId,
      });
    }

    const patch = createIncidentPatch(updatedIncident, incident);
    const incidentEditId =
      insertedUserData?.insert_incident_edit?.returning.at(0)?.id;

    if (incidentEditId) {
      await updateIncidentEdit({
        variables: { id: incidentEditId, _set: { patch } },
      });
    }
  };

  return { insertIncidentUser };
};

export default useIncidentUserMutations;
