import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCAdminIncidents from "../../component/incident/GCAdminIncidents";
import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";

const GCBusinessUnitIncidents: React.FC<BusinessUnitProps> = ({
  businessUnitId,
}) => {
  const { userData } = useUserData();
  const projWhere = { gc_business_unit_id: { _eq: businessUnitId } };
  return (
    <GCAdminIncidents
      optionsQueryVars={{
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
        gcOfficeWhere: userData.employee?.general_contractor
          .hierarchy_office_name
          ? { gc_business_unit_id: { _eq: businessUnitId } }
          : { id: { _is_null: true } },
      }}
      organizationLevel="business_unit"
      organizationId={businessUnitId}
      projWhere={projWhere}
      severityUserFilter={{ business_unit_id: { _eq: businessUnitId } }}
      toInsertWith={{
        gc_id: userData.employee?.general_contractor.id,
        business_unit_id: businessUnitId,
      }}
      gcEmployeeWhere={{
        gc_business_units: { gc_business_unit_id: { _eq: businessUnitId } },
      }}
    />
  );
};
export default withBusinessUnitId(GCBusinessUnitIncidents);
