/**
 * @generated SignedSource<<fc3c75daef23c2dacd6ec9e290f8647f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { certificates_to_verify_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type VerifyCertsQuery$variables = {
  where: certificates_to_verify_bool_exp;
};
export type VerifyCertsQuery$data = {
  readonly certificates_to_verify_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly back_image: {
          readonly blurhash: string | null | undefined;
          readonly lg_url: string | null | undefined;
          readonly md_url: string | null | undefined;
          readonly sm_url: string | null | undefined;
          readonly url: string;
        } | null | undefined;
        readonly back_image_id: string | null | undefined;
        readonly front_image: {
          readonly blurhash: string | null | undefined;
          readonly lg_url: string | null | undefined;
          readonly md_url: string | null | undefined;
          readonly sm_url: string | null | undefined;
          readonly url: string;
        } | null | undefined;
        readonly front_image_id: string;
        readonly pk: string;
        readonly project_id: string | null | undefined;
        readonly worker: {
          readonly user: {
            readonly name: string;
          };
        };
        readonly worker_id: string;
      };
    }>;
  };
  readonly certification_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: string;
        readonly pk: string;
      };
    }>;
  };
  readonly reason_to_reject_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly reason: string;
        readonly type: string;
      };
    }>;
  };
};
export type VerifyCertsQuery = {
  response: VerifyCertsQuery$data;
  variables: VerifyCertsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "created_at": "desc"
    }
  },
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
],
v2 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "front_image_id",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "md_url",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "lg_url",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "sm_url",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "blurhash",
  "storageKey": null
},
v9 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/)
],
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "back_image_id",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "worker_id",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "project_id",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v14 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "reason": "asc"
    }
  }
],
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v16 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "reason",
  "storageKey": null
},
v17 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "type",
  "storageKey": null
},
v18 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name": "asc"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "is_custom": {
        "_eq": false
      }
    }
  }
],
v19 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v20 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v21 = [
  (v4/*: any*/),
  (v5/*: any*/),
  (v6/*: any*/),
  (v7/*: any*/),
  (v8/*: any*/),
  (v15/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VerifyCertsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "certificates_to_verifyConnection",
        "kind": "LinkedField",
        "name": "certificates_to_verify_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificates_to_verifyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certificates_to_verify",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "front_image",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "back_image",
                    "plural": false,
                    "selections": (v9/*: any*/),
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "reason_to_rejectConnection",
        "kind": "LinkedField",
        "name": "reason_to_reject_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reason_to_rejectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "reason_to_reject",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v2/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "reason_to_reject_connection(order_by:{\"reason\":\"asc\"})"
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "certificationConnection",
        "kind": "LinkedField",
        "name": "certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v2/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "certification_connection(order_by:{\"name\":\"asc\"},where:{\"is_custom\":{\"_eq\":false}})"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VerifyCertsQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "certificates_to_verifyConnection",
        "kind": "LinkedField",
        "name": "certificates_to_verify_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificates_to_verifyEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certificates_to_verify",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v3/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "front_image",
                    "plural": false,
                    "selections": (v21/*: any*/),
                    "storageKey": null
                  },
                  (v10/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "image",
                    "kind": "LinkedField",
                    "name": "back_image",
                    "plural": false,
                    "selections": (v21/*: any*/),
                    "storageKey": null
                  },
                  (v11/*: any*/),
                  (v12/*: any*/),
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "worker",
                    "kind": "LinkedField",
                    "name": "worker",
                    "plural": false,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "user",
                        "kind": "LinkedField",
                        "name": "user",
                        "plural": false,
                        "selections": [
                          (v13/*: any*/),
                          (v15/*: any*/)
                        ],
                        "storageKey": null
                      },
                      (v15/*: any*/)
                    ],
                    "storageKey": null
                  },
                  (v15/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v14/*: any*/),
        "concreteType": "reason_to_rejectConnection",
        "kind": "LinkedField",
        "name": "reason_to_reject_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "reason_to_rejectEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "reason_to_reject",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "reason_to_reject_connection(order_by:{\"reason\":\"asc\"})"
      },
      {
        "alias": null,
        "args": (v18/*: any*/),
        "concreteType": "certificationConnection",
        "kind": "LinkedField",
        "name": "certification_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "certificationEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "certification",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v15/*: any*/),
                  (v19/*: any*/),
                  (v20/*: any*/),
                  (v13/*: any*/)
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "certification_connection(order_by:{\"name\":\"asc\"},where:{\"is_custom\":{\"_eq\":false}})"
      }
    ]
  },
  "params": {
    "cacheID": "54368ab29970420d58f9d6b6ae26736e",
    "id": null,
    "metadata": {},
    "name": "VerifyCertsQuery",
    "operationKind": "query",
    "text": "query VerifyCertsQuery(\n  $where: certificates_to_verify_bool_exp!\n) {\n  certificates_to_verify_connection(where: $where, order_by: {created_at: desc}) {\n    edges {\n      node {\n        pk: id\n        front_image_id\n        front_image {\n          url\n          md_url\n          lg_url\n          sm_url\n          blurhash\n          id\n        }\n        back_image_id\n        back_image {\n          url\n          md_url\n          lg_url\n          sm_url\n          blurhash\n          id\n        }\n        worker_id\n        project_id\n        worker {\n          user {\n            name\n            id\n          }\n          id\n        }\n        id\n      }\n    }\n  }\n  reason_to_reject_connection(order_by: {reason: asc}) {\n    edges {\n      node {\n        id\n        pk: id\n        reason\n        type\n      }\n    }\n  }\n  certification_connection(where: {is_custom: {_eq: false}}, order_by: {name: asc}) {\n    edges {\n      node {\n        id\n        pk: id\n        name\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "3a85e955f913891837dd09977bb732ea";

export default node;
