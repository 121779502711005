import React, { useState } from "react";
import { Checkbox, Button } from "antd";
import SiteOrientationInPersonCollapse from "src/domain-features/siteorientation/entryRoutes/inPerson/components/SiteOrientationInPersonCollapse";
import usePtpQr from "../../../utils/ptpQrHook";

interface RollCallViewUser {
  name: string;
  title?: string;
  companyName?: string;
  selected: boolean;
  project_worker_id: string;
  project_crew?: {
    lead_foreman_project_worker_id: string | null;
    name: string;
    id: string;
  };
}

export interface RollCallViewProps {
  hiddenByDefault?: boolean;
  searchPrompt?: string;
  list: RollCallViewUser[];
  onSelectToggle?: (user: RollCallViewUser) => void;
  someWorkersSelected?: boolean;
  sectionChecked?: boolean;
  requiredCrew?: boolean;
  crewId?: string;
  header: React.ReactNode;
}

const RollCallView: React.FC<RollCallViewProps> = (props) => {
  const { ptpQrUser } = usePtpQr();
  const [visible, setVisible] = useState(props.hiddenByDefault === undefined);
  const filteredList = !props.searchPrompt
    ? props.list
    : props.list.filter(
        (worker) =>
          !props.searchPrompt ||
          worker.name.toLowerCase().includes(props.searchPrompt.toLowerCase()),
      );
  filteredList.sort((a, b) => a.name.localeCompare(b.name));
  const workerSignMap = ptpQrUser.workerSignMap;
  const workersPresent = ptpQrUser.workersInfo.workersPresent;

  return (
    <SiteOrientationInPersonCollapse
      headText={props.header}
      hide={() => setVisible(false)}
      expand={() => setVisible(true)}
      expanded={visible}
    >
      {!!filteredList.length && (
        <div className="flex flex-col gap-0.25">
          {filteredList.map((item) => (
            <div
              key={item.project_worker_id}
              className="relative flex flex-row items-center gap-0.5 px-1 py-0.5 border border-interactive-secondary hover:border-interactive-primary cursor-pointer rounded-0.5"
              onClick={() => {
                props.onSelectToggle?.(item);
              }}
            >
              <Checkbox checked={item.selected} />
              <div className="flex flex-row flex-1 items-center gap-0.5 justify-between">
                <div className="relative z-10 flex flex-col gap-0">
                  {item.name && (
                    <p className="text-1 font-accent">{item.name}</p>
                  )}
                  {item.title && (
                    <p className="text-1 font-normal">{item.title}</p>
                  )}
                  {item.companyName && (
                    <p className="text-static-secondary text-0.75">
                      {item.companyName}
                    </p>
                  )}
                </div>
              </div>
              {workerSignMap[item.project_worker_id] &&
                workersPresent.has(item.project_worker_id) && (
                  <div>
                    <img
                      className="max-h-8 max-w-8"
                      src={workerSignMap[item.project_worker_id]}
                      alt="Worker Sign"
                    />
                  </div>
                )}
            </div>
          ))}
        </div>
      )}
    </SiteOrientationInPersonCollapse>
  );
};

export default RollCallView;
