import React, { useState, useMemo, useRef } from "react";
import SiteFeatureStepsInputLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepsInputLayout";
import { SiteOrientationStepProps } from "src/domain-features/siteorientation/utils/siteOrientationTypes.d";
import SiteFeatureStepLayout from "src/common/components/layouts/steps-navigation-layout/SiteFeatureStepLayout";
import PtpQrCodeTaskSign from "../task-sign/PtpQrTaskSign";
import CustomButton from "src/common/components/general/Button";
import GCCreateWorkerModal, {
  AddWorkerModalRef,
} from "src/root/routes/views/general-contractor/projects/workers/GCCreateWorkerModal";
import useInsertPtp from "../../../utils/useInsertPtp";
import RollCallView from "../roll-call-view/RollCallView";
import { Input, Button } from "antd";
import { useSuspenseQuery } from "@apollo/client";
import {
  GetCrewGroupedWorkerListQuery,
  GetCrewGroupedWorkerListQueryVariables,
  GetCrewGroupedWorkerListDocument,
} from "src/common/types/generated/apollo/graphQLTypes";
import usePtpQr from "../../../utils/ptpQrHook";
import { useGetCompletionAuditV3Query } from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import { useCurrentLangStrings } from "src/utility-features/i18n/context/languageHooks";
import useCrewSections from "../../../utils/useCrewSections";

export interface CrewGroupedWorkerListProps extends SiteOrientationStepProps {
  includeCrew?: boolean;
}

const CrewGroupedWorkerList: React.FC<CrewGroupedWorkerListProps> = (props) => {
  const langStrings = useCurrentLangStrings();
  const ptpQrContext = usePtpQr();
  const userInfo = ptpQrContext.ptpQrUser.userInfo;
  const projectWorkerId = userInfo?.projectWorkerId;
  const workersInfo = ptpQrContext.ptpQrUser.workersInfo;
  if (!userInfo) throw new Error("UserInfo not found");
  if (!userInfo.id) throw new Error("User Id not found");
  const { data } = useSuspenseQuery<
    GetCrewGroupedWorkerListQuery,
    GetCrewGroupedWorkerListQueryVariables
  >(GetCrewGroupedWorkerListDocument, {
    variables: {
      projectId: ptpQrContext.projectId,
      userId: userInfo.id,
    },
  });

  const createWorker = useRef<AddWorkerModalRef>(null);

  const roleOptions = useMemo(
    () =>
      data.worker_role.map((role) => ({
        label: role.translation.en,
        value: role.value,
      })),
    [data.worker_role],
  );
  const titleOptions = useMemo(
    () =>
      data.worker_title.map((title) => ({
        label: title.translation.en,
        value: title.id,
      })),
    [data.worker_title],
  );

  const [workerSignView, setWorkerSignView] = useState<string | undefined>(
    undefined,
  );

  const crewId = data?.crewInfo?.at(0)?.project_crew?.id;
  const { crewSections, projWorkersCrewIdMap } = useCrewSections(
    data.project_worker,
    crewId,
  );

  const timezone = ptpQrContext.ptpQrUser.timezone;

  const { data: completionAudit } = useGetCompletionAuditV3Query({
    variables: {
      input: {
        startDate: dayjs().startOf("d").tz(timezone).startOf("d").toISOString(),
        endDate: dayjs().endOf("d").tz(timezone).endOf("d").toISOString(),
        projectId: ptpQrContext.projectId,
        subcontractorId: ptpQrContext.ptpQrUser.userInfo?.company,
        timezone: timezone,
      },
    },
  });

  const areAllWorkersPresentExceptLead = (
    workers: { id: string }[],
    workersPresent: Set<string>,
    leadForemanId?: string,
  ): boolean => {
    return workers
      .filter((pw) => pw.id !== leadForemanId)
      .every((pw) => workersPresent.has(pw.id));
  };

  const toggleSectionCheck = (crewId: string) => {
    ptpQrContext.setPtpQrUser((prev) => {
      const newObj = { ...prev };
      newObj.selectedProjectCrews.has(crewId)
        ? newObj.selectedProjectCrews.delete(crewId)
        : newObj.selectedProjectCrews.add(crewId);
      return newObj;
    });
  };

  const updateSelectedCrews = (
    projectWorkerId: string,
    workersPresent: Set<string>,
  ) => {
    const projectCrewId = projWorkersCrewIdMap.get(projectWorkerId);
    const section = crewSections.find(
      (section) => section.crew?.id === projectCrewId,
    );
    const selectedItemCrew = section?.crew;

    if (selectedItemCrew) {
      if (
        workersPresent.has(projectWorkerId) &&
        (selectedItemCrew.id === crewId ||
          section.data.length === 1 ||
          areAllWorkersPresentExceptLead(
            section.data,
            workersPresent,
            selectedItemCrew.lead_foreman_project_worker_id ?? undefined,
          ))
      ) {
        ptpQrContext.setPtpQrUser((prev) => {
          const newObj = { ...prev };
          newObj.selectedProjectCrews.add(selectedItemCrew.id);
          return newObj;
        });
      } else if (section.data.every((pw) => !workersPresent.has(pw.id))) {
        ptpQrContext.setPtpQrUser((prev) => {
          const newObj = { ...prev };
          newObj.selectedProjectCrews.delete(selectedItemCrew.id);
          return newObj;
        });
      }
    }
  };

  const crewData = completionAudit?.getCompletionAuditV3.at(0)?.crewData;
  const requiredCrews =
    crewData && crewData.length > 1
      ? crewData
          .filter(
            (crew) =>
              crew.completion[0].onsite_required &&
              crew.completion[0].safety_reports_required &&
              !crew.completion[0].safety_reports_completion_count,
          )
          .map((crew) => crew.id)
      : [];

  if (!data) throw new Error("Crew Workers Data Not Found");
  const [searchPrompt, setSearchPrompt] = useState<string>("");
  const [insertPtp, inserting] = useInsertPtp();

  return !!workerSignView ? (
    <PtpQrCodeTaskSign
      onNext={() => setWorkerSignView(undefined)}
      workerId={workerSignView}
      onBack={() => setWorkerSignView(undefined)}
      updateSelectedCrews={updateSelectedCrews}
    />
  ) : (
    <>
      <SiteFeatureStepLayout
        onNextButtonClick={async () => {
          if (ptpQrContext.ptpQrUser.signInType === "signature") {
            await insertPtp();
          }
          props.onNext();
        }}
        onBackButtonClick={props.onBack}
        nextButtonDisabled={
          workersInfo.workersPresent.size === 0 || !!workerSignView
        }
        nextButtonText={langStrings.strings.submit}
        loading={inserting}
      >
        <SiteFeatureStepsInputLayout
          headline={langStrings.strings.selectWorkersAtPtp}
        >
          <GCCreateWorkerModal
            projectId={ptpQrContext.projectId}
            subcontractorId={userInfo.company}
            workersData={data}
            roleOptions={roleOptions}
            titleOptions={titleOptions}
            ref={createWorker}
          />

          <CustomButton
            label={langStrings.strings.addNewWorker}
            tiny
            onClick={() => createWorker.current?.open()}
          />

          <Input
            placeholder={langStrings.strings.searchForUsers}
            value={searchPrompt}
            onChange={(e) => setSearchPrompt(e.target.value)}
          />
          {crewSections.map((section, index) => {
            const someWorkersSelected = !!section.data.find((pw) =>
              workersInfo.workersPresent.has(pw.id),
            );

            const requiredCrew = !!(
              section.crew?.id &&
              requiredCrews.find((c) => c === section.crew?.id)
            );

            const isChecked =
              section.crew?.id &&
              ptpQrContext.ptpQrUser.selectedProjectCrews.has(section.crew.id);

            return (
              <RollCallView
                key={section.key}
                header={
                  <>
                    <p
                      className={`text-1.2 ${
                        isChecked
                          ? "text-semantic-positive-green"
                          : "text-semantic-negative-dark"
                      }    `}
                    >
                      {section.title +
                        (!!crewId && section.crew?.id === crewId
                          ? langStrings.strings.yourCrew
                          : "")}
                    </p>

                    <div className="flex items-center">
                      {section.crew?.id === crewId ? null : (
                        <div className="flex flex-row items-center">
                          <div className="mr-0.5 flex items-center justify-center">
                            {someWorkersSelected && (
                              <p
                                className={`text-center ${
                                  isChecked
                                    ? "text-semantic-positive-green"
                                    : "text-interactive-primary"
                                }`}
                              >
                                {isChecked
                                  ? langStrings.strings.submitting
                                  : langStrings.strings.submittingQMark}
                              </p>
                            )}
                          </div>

                          {someWorkersSelected && (
                            <Button
                              size="small"
                              style={{
                                backgroundColor: isChecked ? "green" : "blue",
                                color: "white",
                                borderRadius: "50%",
                                padding: "5px",
                              }}
                              onClick={(e) => {
                                e.preventDefault();
                                e.stopPropagation();
                                if (
                                  areAllWorkersPresentExceptLead(
                                    section.data,
                                    ptpQrContext.ptpQrUser.workersInfo
                                      .workersPresent,
                                    section.crew
                                      ?.lead_foreman_project_worker_id ??
                                      undefined,
                                  )
                                ) {
                                  return;
                                }

                                section.crew?.id &&
                                  toggleSectionCheck(section.crew?.id);

                                ptpQrContext.setPtpQrUser((prev) => {
                                  const newObj = { ...prev };
                                  if (section.crew?.id) {
                                    if (isChecked) {
                                      newObj.selectedProjectCrews.delete(
                                        section.crew?.id,
                                      );
                                    } else {
                                      newObj.selectedProjectCrews.add(
                                        section.crew?.id,
                                      );
                                    }
                                  }
                                  return newObj;
                                });
                              }}
                            >
                              <span className="text-white">&#10003;</span>
                            </Button>
                          )}

                          {requiredCrew && (
                            <p
                              className={`text-center ${
                                isChecked
                                  ? "text-semantic-positive-green"
                                  : "text-lightgrey"
                              }`}
                            >
                              <span> *</span> {langStrings.strings.reportReq}
                            </p>
                          )}
                        </div>
                      )}
                    </div>
                  </>
                }
                list={section.data
                  .filter((w) => w.id !== projectWorkerId)
                  .map((pw) => ({
                    project_worker_id: pw.id,
                    name: pw.user?.name ?? "",
                    selected: workersInfo.workersPresent.has(pw.id),
                    companyName: pw.hard_hat_number
                      ? "#" + pw.hard_hat_number
                      : "" +
                        (pw.leading_project_crews.length > 0
                          ? `, ${langStrings.strings.crewLead}`
                          : ""),

                    title: pw.title?.translation.en,
                    project_crew: pw.project_crew
                      ? {
                          id: pw.project_crew.id,
                          lead_foreman_project_worker_id:
                            pw.project_crew.lead_foreman_project_worker_id ??
                            null,
                          name: pw.project_crew.name,
                        }
                      : undefined,
                  }))}
                onSelectToggle={(user) => {
                  const updatedWorkersPresent = new Set(
                    workersInfo.workersPresent,
                  );

                  if (workersInfo.workersPresent.has(user.project_worker_id)) {
                    updatedWorkersPresent.delete(user.project_worker_id);
                  } else if (
                    ptpQrContext.ptpQrUser.signInType === "signature"
                  ) {
                    setWorkerSignView(user.project_worker_id);
                    return;
                  } else {
                    updatedWorkersPresent.add(user.project_worker_id);
                  }

                  ptpQrContext.setPtpQrUser((prev) => ({
                    ...prev,
                    workersInfo: {
                      ...prev.workersInfo,
                      workersPresent: updatedWorkersPresent,
                    },
                  }));

                  updateSelectedCrews(
                    user.project_worker_id,
                    updatedWorkersPresent,
                  );
                }}
                someWorkersSelected={someWorkersSelected}
                crewId={crewId}
                sectionChecked={!!isChecked}
                requiredCrew={requiredCrew}
                searchPrompt={searchPrompt}
              />
            );
          })}
        </SiteFeatureStepsInputLayout>
      </SiteFeatureStepLayout>
    </>
  );
};

export default CrewGroupedWorkerList;
