import { graphql } from "babel-plugin-relay/macro";
import { FC, useState } from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import ProjectSubcontractorTasksTable from "src/common/components/tables/ProjectSubcontractorTasksTable";
import { SCChildSubJHAsQuery } from "src/common/types/generated/relay/SCChildSubJHAsQuery.graphql";
import useAuthUser from "src/common/hooks/useAuthUser";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";

interface SCChildSubJHAsProps {
  projectId: string;
  subcontractorId: string;
}
const SCChildSubJHAs: FC<SCChildSubJHAsProps> = ({
  projectId,
  subcontractorId,
}) => {
  const [fetchKey, setFetchKey] = useState(0);

  const data = useLazyLoadQuery<SCChildSubJHAsQuery>(
    graphql`
      query SCChildSubJHAsQuery($projectId: uuid!, $subcontractorId: uuid!) {
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              general_contractor_id
              id
            }
          }
        }
        ppe_type_connection(order_by: { name: { en: asc } }) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
            }
          }
        }
        permit_type_connection(order_by: { name: { en: asc } }) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name {
                en
              }
            }
          }
        }
        task_connection(
          where: {
            project_id: { _eq: $projectId }
            subcontractor_id: { _eq: $subcontractorId }
            deleted_at: { _is_null: true }
            report_id: { _is_null: true }
          }
        ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              task_edit(order_by: { edited_at: desc }) {
                task_patch
                edited_at
              }
              task_signatures(
                where: { user: { role: { _neq: "employee" } } }
                order_by: { created_at: desc }
              ) {
                created_at
                signature_image_id
              }
            }
          }
        }
      }
    `,
    { projectId, subcontractorId },
    { fetchKey, fetchPolicy: "network-only" },
  );
  const authUser = useAuthUser();
  const projectData = data.project_connection.edges[0].node;
  const turnerGCId = "96916668-c816-4c2a-9008-73a6116d4c00";
  const isNotTurnersProject = projectData.general_contractor_id !== turnerGCId;

  const refresh = () => setFetchKey((i) => i + 1);

  return (
    <ProjectSubcontractorTasksTable
      {...{
        projectId,
        refresh,
        viewOnly: true,
        subcontractorId,
        isNotTurnersProject,
        taskEdges: data.task_connection.edges,
        ppeOptions: data.ppe_type_connection.edges,
        permitOptions: data.permit_type_connection.edges,
      }}
    />
  );
};
export default withCustomSuspense(SCChildSubJHAs);
