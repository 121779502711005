import { Typography } from "antd";
import { Link } from "react-router-dom";

const TabsDemoNestedTabLink: React.FC = () => {
  return (
    <Link to={`nested-tab`}>
      <Typography.Link>.../nested-tab</Typography.Link>
    </Link>
  );
};

export default TabsDemoNestedTabLink;
