import React, { useContext, useState } from "react";
import { Checkbox } from "antd";
import { IconChevronDown } from "@tabler/icons";
import SiteOrientationInPersonCollapse from "src/domain-features/siteorientation/entryRoutes/inPerson/components/SiteOrientationInPersonCollapse";
import ptpQrCodeUserContext from "src/domain-features/sitesafety/entryRoutes/qrProjectReports/routes/ptp/utils/ptpQrUserContext";
import Icon from "src/common/components/general/Icon";
export interface TaskSelectionViewForPtpSubmissionProps {
  hiddenByDefault?: boolean;
  searchPrompt?: string;
  listTitle: string;
  list: {
    name: string;
    stepsCount: number;
    hazardsCount: number;
    ppeCount: number;
    permitCount: number;
    id: string;
  }[];
  onSelectToggle: (id: string) => void;
  onSelectTaskForEdit: (id: string) => void;
}

const TaskSelectionViewForPtpSubmission: React.FC<
  TaskSelectionViewForPtpSubmissionProps
> = (props) => {
  const [visible, setVisible] = useState(props.hiddenByDefault === undefined);
  const workerContext = useContext(ptpQrCodeUserContext);
  if (!workerContext) throw new Error("No context found to be used");

  const { ptpQrUser, setPtpQrUser } = workerContext;
  const filteredList = !props.searchPrompt
    ? props.list
    : props.list.filter(
        (worker) =>
          !props.searchPrompt ||
          worker.name.toLowerCase().includes(props.searchPrompt.toLowerCase()),
      );
  filteredList.sort((a, b) => a.name.localeCompare(b.name));

  return (
    <SiteOrientationInPersonCollapse
      headText={`${props.listTitle} (${filteredList.length})`}
      hide={() => setVisible(false)}
      expand={() => setVisible(true)}
      expanded={visible}
    >
      {!!filteredList.length && (
        <div className={`flex flex-col gap-0.25`}>
          {filteredList.map((item) => (
            <div
              key={item.id}
              className={`relative flex flex-row items-center gap-0.5 px-1 py-0.5 border border-interactive-secondary hover:border-interactive-primary cursor-pointer rounded-0.5`}
              onClick={(e) => {
                props.onSelectToggle(item.id);
              }}
            >
              <Checkbox checked={!!ptpQrUser.selectedTasks[item.id]} />
              <div
                className={`flex flex-row flex-1 items-center gap-0.5 justify-between`}
              >
                <div className={`relative z-10 flex flex-col gap-0`}>
                  {item.name && (
                    <p className={`text-1 font-accent`}>{item.name}</p>
                  )}

                  <div className="mt-0.5 flex flex-wrap">
                    <div className="w-6">
                      <p className={`text-1 font-normal`}>
                        <span className="text-lightgrey">Steps </span>{" "}
                        {item.stepsCount}
                      </p>

                      <p className={`text-1 font-normal`}>
                        <span className="text-lightgrey">PPE </span>
                        <span className="ml-0.75"> {item.ppeCount}</span>
                      </p>
                    </div>
                    <div className="w-6">
                      <p className={`text-1 font-normal`}>
                        <span className="text-lightgrey">Hazards </span>{" "}
                        {item.hazardsCount}
                      </p>
                      <p className={`text-1 font-normal`}>
                        <span className="text-lightgrey">Permits </span>
                        <span className="ml-0.25">{item.permitCount}</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <Icon
                icon={IconChevronDown}
                color="suplementary-3"
                onClick={() => props.onSelectTaskForEdit(item.id)}
              />
            </div>
          ))}
        </div>
      )}
    </SiteOrientationInPersonCollapse>
  );
};

export default TaskSelectionViewForPtpSubmission;
