import { IconCirclePlus } from "@tabler/icons";
import { Button, message, notification, Space } from "antd";
import { FC, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import DataDetailDescriptions, {
  DataDetailItems,
} from "src/common/components/layouts/DataDetailDescriptions";
import StyledContent from "src/common/components/layouts/StyledContent";
import CustomTable from "src/common/components/tables/basic/CustomTable";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import getAddressText from "src/common/functions/getAddressText";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import { ProjectSubcontractorEmployeeFrag$data } from "src/common/types/generated/relay/ProjectSubcontractorEmployeeFrag.graphql";
import AddSubcontractorProjectTeamModal, {
  insertProjectSubcontractorEmployeeMutation,
} from "src/domain-features/sitesafety/siteSafetyPlan/components/AddSubcontractorProjectTeamModal";
import ProjectSubcontractorEmployeeTable from "src/domain-features/sitesafety/siteSafetyPlan/components/ProjectSubcontractorEmployeeTable";
import GCSendInviteModal, {
  GCSendInviteModalRef,
} from "src/utility-features/invitations/GCSendInviteModal";
import sendInvite from "src/utility-features/invitations/sendInvite";
import { PlusOutlined } from "@ant-design/icons";
import {
  AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation,
  AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$data,
} from "src/common/types/generated/relay/AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation.graphql";
import * as uuid from "uuid";
import GetFullID from "src/common/functions/GetFullId";
import ProjectSubWorkerTitleViewAndEdit from "./components/ProjectSubWorkerTitleViewAndEdit";
import ProjectSubChildSubs from "./components/ProjectSubChildSubs";

export const handleInsertProjSubEmpForGcSubcontractorPage = (
  store: RecordSourceSelectorProxy<AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation$data>,
  subcontractorId: string,
  projectId: string,
) => {
  const insertProjectSubcontractorEmployees = store.getRootField(
    "insert_project_subcontractor_employee",
  );
  const projSUbEmpRecs =
    insertProjectSubcontractorEmployees.getLinkedRecords("returning") || [];
  const conn1 = ConnectionHandler.getConnection(
    store.getRoot(),
    "GCSubcontractorQuery_subcontractor_connection",
  );
  console.log(conn1);
  if (conn1) {
    console.log(conn1.getLinkedRecords("edges"), "edges");
    const edge = (conn1.getLinkedRecords("edges") || []).find((edge) => {
      console.log(edge.getLinkedRecord("node"), "node");
      return (
        edge.getLinkedRecord("node")?.getDataID() ===
        GetFullID("subcontractor", subcontractorId)
      );
    });
    console.log(edge, "EDGE");
    if (edge) {
      const node = edge.getLinkedRecord("node");
      if (!node) return;
      const subEmps =
        node.getLinkedRecords("subcontractor_employees", {
          order_by: { user: { name: "asc" } },
        }) || [];
      console.log(subEmps, "SUBEMPS");
      subEmps.forEach((subEmp) => {
        console.log(subEmp, "SUBEMP111");
        const projSubEmprecord = projSUbEmpRecs.find((r) => {
          console.log(
            r.getValue("subcontractor_employee_id"),
            subEmp.getValue("user_id"),
          );
          return (
            r.getValue("subcontractor_employee_id") ===
            subEmp.getValue("user_id")
          );
        });
        console.log(projSubEmprecord);
        if (projSubEmprecord)
          subEmp.setLinkedRecords(
            [projSubEmprecord],
            "project_subcontractor_employees",
            { where: { project_id: { _eq: projectId } } },
          );
      });
    }
  }
  const conn2 = ConnectionHandler.getConnection(
    store.getRoot(),
    "ProjectSubcontractorEmployeeTable_project_subcontractor_employee_connection",
  );
  if (conn2) {
    projSUbEmpRecs.forEach((projsubEmp) => {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(projsubEmp, "node");
      ConnectionHandler.insertEdgeBefore(conn2, edge);
    });
  }
};

const GCSubcontractorDetail: FC<{
  data: GCSubcontractorQuery$data;
  subcontractorId: string;
  projectId: string;
  refetchData: () => void;
}> = ({ data, subcontractorId, projectId, refetchData }) => {
  const navigate = useNavigate();
  const subcontractorData = data.subcontractor_connection.edges[0].node;

  const sendInviteRef = useRef<GCSendInviteModalRef>(null);
  const subcontractorItems: DataDetailItems = [
    {
      label: "Name",
      value: subcontractorData.name,
      span: 2,
    },
    {
      label: "Company Trade",
      value: subcontractorData.trade?.name ?? "",
    },
    {
      label: "Address",
      value: getAddressText(subcontractorData.address),
    },
  ];

  const projectSubcontractorEmployeeTableRef = useRef<DataScrollTableRef>(null);
  const [
    openAddSubcontractorProjectTeamModal,
    setOpenAddSubcontractorProjectTeamModal,
  ] = useState(false);

  const resendInvite = async (
    u:
      | SubEmployeeType
      | ProjectSubcontractorEmployeeFrag$data["subcontractor_employee"],
  ) => {
    console.log(u);

    const sentFromName = data.user_connection.edges[0].node.name;
    const sentFromCompanyName =
      data.user_connection.edges[0].node.employee?.general_contractor.name;
    const projectName =
      data.user_connection.edges[0].node.employee?.employee_projects[0]?.project
        ?.name;
    const joinGeneralContractorId =
      data.user_connection.edges[0].node.employee?.general_contractor.pk;
    if (u.user.email) {
      const { success, error } = await sendInvite({
        sendTo: u.user.email,
        joinSubcontractorId: subcontractorId,
        sentFromCompanyName,
        sentFromName,
        projectName,
        joinGeneralContractorId,
        joinProjectId: projectId,
        joinRole: "sub-admin",
      });
      if (success == false) {
        notification.error({
          message: "Error: ",
          description: error,
          duration: null,
        });
      } else {
        message.success("Invite sent");
      }
    }
  };

  type SubEmployeeType =
    GCSubcontractorQuery$data["subcontractor_connection"]["edges"][0]["node"]["subcontractor_employees"][number];

  const [insertProjectSubcontractorEmployee, inserting] =
    useAsyncMutation<AddSubcontractorProjectTeamModal_insertProjectSubcontractorEmployee_Mutation>(
      insertProjectSubcontractorEmployeeMutation,
    );

  const SubEmpAddToProjectButton: React.FC<{ row: SubEmployeeType }> = ({
    row,
  }) => (
    <Button
      loading={inserting}
      icon={<PlusOutlined />}
      type="link"
      onClick={async () => {
        await insertProjectSubcontractorEmployee({
          variables: {
            objects: [
              {
                subcontractor_employee_id: row.user_id,
                project_id: projectId,
                subcontractor_id: subcontractorId,
              },
            ],
          },
          updater: (store) =>
            handleInsertProjSubEmpForGcSubcontractorPage(
              store,
              subcontractorId,
              projectId,
            ),
        });
      }}
    >
      Add to Project
    </Button>
  );

  return (
    <StyledContent padding backgroundColor="white">
      <div className="w-full">
        <GCSendInviteModal
          ref={sendInviteRef}
          projectId={projectId}
          subcontractorId={subcontractorId}
        />
        <div className="flex w-full">
          <div className="w-1/3">
            <DataDetailDescriptions
              title="Subcontractor Info"
              items={subcontractorItems}
            />
          </div>
          <div className="w-1/3 mt-1.5">
            <ProjectSubWorkerTitleViewAndEdit
              {...{
                projectId,
                subcontractorId,
                currentWorkerTitles:
                  subcontractorData.subcontractor_project_worker_titles,
                workerTitleOptions: data.worker_title_connection.edges.map(
                  (wt) => ({
                    value: wt.node.pk,
                    label: wt.node.translation.en,
                    disabled:
                      (subcontractorData.subcontractor_project_worker_titles.find(
                        (p) => p.worker_title_id === wt.node.pk,
                      )?.project_workers_aggregate?.aggregate?.count ?? 0) > 0,
                  }),
                ),
              }}
            />
          </div>
          <div className="w-1/3 mt-1.5">
            <ProjectSubChildSubs
              {...{
                projectId,
                subcontractorId,
                currentChildSubs:
                  data.parent_child_project_subcontractor_connection.edges,
                subSelectOptions: data.childSubOptions.edges.map(
                  ({ node }) => ({
                    label: node.subcontractor.name,
                    value: node.subcontractor.pk,
                  }),
                ),
              }}
            />
          </div>
        </div>

        <Space>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/projects/${projectId}/reports/pretaskplans?subId=${subcontractorId}`,
              )
            }
          >
            Safety Reports
          </Button>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/projects/${projectId}/reports/daily?subId=${subcontractorId}`,
              )
            }
          >
            Daily Reports
          </Button>
          <Button
            type="link"
            onClick={() =>
              navigate(
                `/gce/projects/${projectId}/reports/toolboxtalks?subId=${subcontractorId}`,
              )
            }
          >
            Toolbox Talks
          </Button>
        </Space>

        <AddSubcontractorProjectTeamModal
          modalClose={() => {
            setOpenAddSubcontractorProjectTeamModal(false);
          }}
          refetchTables={() => {
            projectSubcontractorEmployeeTableRef.current?.refetch();
            refetchData();
          }}
          modalVisible={openAddSubcontractorProjectTeamModal}
          subcontractorId={subcontractorId}
          projectId={projectId}
          onSubmit={() => {
            setOpenAddSubcontractorProjectTeamModal(false);
            // projectSubcontractorEmployeeTableRef.current?.refetch();
          }}
        />
        <div className="mt-2">
          <ProjectSubcontractorEmployeeTable
            ref={projectSubcontractorEmployeeTableRef}
            showFullName
            resendInvite={resendInvite}
            headerComponent={
              <>
                <br />
                Add your project’s Point of Contacts (POC)
              </>
            }
            where={{
              project_id: { _eq: projectId },
              subcontractor_employee: {
                subcontractor_id: { _eq: subcontractorId },
              },
            }}
            loading={false}
            topBarButtons={[
              {
                onClick: () => {
                  setOpenAddSubcontractorProjectTeamModal(true);
                },
                label: "+ Add",
              },
            ]}
          />
        </div>
        {/* TODO COnvert this to DataScrollTable */}
        <div className="mt-2">
          <StyledContent padding backgroundColor="white">
            <CustomTable
              title={"Employees and Staff"}
              dataSource={[
                ...data.subcontractor_connection.edges[0].node
                  .subcontractor_employees,
              ]}
              titleIcon={{
                icon: IconCirclePlus,
                color: "interactive",
                onClick: () => {
                  sendInviteRef.current && sendInviteRef.current.open();
                },
              }}
              columns={[
                {
                  dataIndex: ["user", "name"],
                  title: "Name",
                },
                {
                  dataIndex: ["employee_title", "name_text"],
                  title: "Title",
                },
                {
                  dataIndex: ["user", "email"],
                  title: "Email",
                  size: "xl",
                },
                {
                  dataIndex: ["user", "phone_number"],
                  title: "Phone #",
                },

                {
                  title: "",
                  dataIndex: ["user"],
                  render: (_, row) => {
                    return row.project_subcontractor_employees[0] ? null : (
                      <SubEmpAddToProjectButton row={row} />
                    );
                  },
                },
              ]}
            />
          </StyledContent>
        </div>
      </div>
    </StyledContent>
  );
};

export default GCSubcontractorDetail;
