import dayjs from "dayjs";
import React, { useState } from "react";
import GCDashAppReportsTable, { SubOnsiteData } from "./GCDashAppReportsTable";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";

interface GCDashAppReportsProps {
  projectId: string;
  projectTimezone: string;
  dashboardStart: dayjs.Dayjs;
  onsiteSwitcher?: boolean;
  subOnsiteData: SubOnsiteData[];
  crewLeads: { [id: string]: string };
}

const GCDashAppReports: React.FunctionComponent<GCDashAppReportsProps> = ({
  projectId,
  dashboardStart,
  projectTimezone,
  onsiteSwitcher,
  subOnsiteData,
  crewLeads,
}) => {
  const [selectedReportsTab, setSelectedReoprtsTab] =
    useState<string>("pretaskplans");

  const handleTabClick = (newTabId: string) => {
    setSelectedReoprtsTab(newTabId);
  };

  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "pretaskplans",
      label: "Pretask plans",
    },
    {
      id: "daily",
      label: "Daily reports",
    },
  ];

  let reportsComponent = null;

  switch (selectedReportsTab) {
    case "daily":
      reportsComponent = (
        <GCDashAppReportsTable
          crewLeads={crewLeads}
          projectTimezone={projectTimezone}
          projectId={projectId}
          start={dashboardStart}
          reportType="dailyReport"
          subOnsiteData={subOnsiteData}
          onsiteSwitcher={onsiteSwitcher}
        />
      );
      break;
    case "pretaskplans":
      reportsComponent = (
        <GCDashAppReportsTable
          crewLeads={crewLeads}
          projectId={projectId}
          subOnsiteData={subOnsiteData}
          projectTimezone={projectTimezone}
          start={dashboardStart}
          reportType="ptpReport"
          onsiteSwitcher={onsiteSwitcher}
        />
      );
      break;
    default:
      break;
  }

  return (
    <div className="relative flex flex-col">
      <CustomTabs
        onTabSelect={handleTabClick}
        tabs={tabs}
        selectedTabId={selectedReportsTab}
      />
      <div className="w-full pt-1 pr-0.5">{reportsComponent}</div>
    </div>
  );
};

export default GCDashAppReports;
