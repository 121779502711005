type UrlVideoType = "youtube" | "vimeo" | null;

const isYoutubeVideo = (url: string) => {
  const youtubeRegex = new RegExp(
    "^(https?://)?(www.)?(youtube.com|youtu.?be)/.+$",
  );
  return youtubeRegex.test(url);
};

const isVimeoVideo = (url: string) => {
  const vimeoRegex = new RegExp(
    /(?:https?:\/\/(?:www\.)?)?vimeo.com\/(?:channels\/|groups\/([^/]*)\/videos\/|album\/(\d+)\/video\/|)(\d+)(?:$|\/|\?)/,
  );
  return vimeoRegex.test(url);
};

const getVideoType: (url: string) => UrlVideoType = (url: string) => {
  if (isYoutubeVideo(url)) {
    return "youtube";
  }
  if (isVimeoVideo(url)) {
    return "vimeo";
  }
  return null;
};

export default getVideoType;
