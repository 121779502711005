import { InboxOutlined } from "@ant-design/icons";
import { Form, Upload, Button } from "antd";
import { useState } from "react";
import normalizeUploadFile from "src/common/functions/normalizeUploadFile";
import useAuthUser from "src/common/hooks/useAuthUser";
import {
  Document_Use_Type,
  Document_Insert_Input,
} from "src/common/types/generated/apollo/graphQLTypes";
import * as uuid from "uuid";
import useUploadImageOrDoc from "src/common/functions/upload-utility/useUploadImagesOrDocs";

const IncidentDocUploader: React.FunctionComponent<{
  docType: "image" | "other";
  acceptedFiles: string;
  groupId: string;
  type: Document_Use_Type["value"];
  title?: string;
  insertDoc: (objects: Document_Insert_Input[]) => Promise<void>;
}> = ({ docType, acceptedFiles, groupId, type, title, insertDoc }) => {
  const authUser = useAuthUser();
  const [form] = Form.useForm();
  const [currentFilesCount, setCurrentFilesCount] = useState(0);
  const { uploadImagesOrDocsFromFiles, uploadingDoc } =
    useUploadImageOrDoc(docType);
  return (
    <Form
      className="mt-2"
      layout="vertical"
      form={form}
      onFinish={async (v) => {
        if (!v || !v[docType]) return;
        const res = await uploadImagesOrDocsFromFiles(
          v[docType],
          "incident-doc",
        );
        const objectsToInsert = res.map(
          (doc): Document_Insert_Input => ({
            group_id: groupId,
            author_id: authUser.uid,
            document_type: doc.type,
            type: type,
            name: doc.name,
            id: doc.is_image ? uuid.v4() : doc.id,
            url: doc.url,
            image: doc.is_image
              ? { data: { id: doc.id, ...doc.imageResult } }
              : null,
          }),
        );
        await insertDoc(objectsToInsert);
        form.resetFields();
        setCurrentFilesCount(0);
      }}
    >
      <Form.Item
        label={
          !!title
            ? title
            : docType === "image"
            ? "Upload images"
            : "Upload any related documents "
        }
        name={docType}
        getValueFromEvent={normalizeUploadFile}
        key={docType}
      >
        <Upload.Dragger
          accept={acceptedFiles}
          onChange={(t) => setCurrentFilesCount(t.fileList.length)}
          disabled={uploadingDoc}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p>
            Click or drag any
            {docType === "image"
              ? " Image(s)"
              : " Document, Video, or Audio files"}{" "}
            here to upload
            {docType === "image" ? ` (.jpg, .jpeg, .png)` : ``}
          </p>
        </Upload.Dragger>
      </Form.Item>
      {currentFilesCount > 0 && (
        <Button type="primary" htmlType="submit" loading={uploadingDoc}>
          {docType === "image" ? "Save Images" : "Save"}
        </Button>
      )}
    </Form>
  );
};
export default IncidentDocUploader;
