import React, { FunctionComponent } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

import { useLazyLoadQuery } from "react-relay/hooks";
import { graphql } from "babel-plugin-relay/macro";
import ToolboxTalkTable from "src/domain-features/sitesafety/components/toolbox-talks/ToolboxTalkTable";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import GCProjectReportsTBTsDetail from "../gcProjectReports/routes/toolboxtalks/components/GCProjectReportsTBTsDetail";
import { GCProjectToolboxTalksQuery } from "src/common/types/generated/relay/GCProjectToolboxTalksQuery.graphql";
import ManageToolboxTalks from "src/domain-features/sitesafety/components/toolbox-talks/ManageToolboxTalks";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";

const GCProjectToolboxTalks: FunctionComponent = () => {
  const { subview, projectId } = useParams();
  if (!projectId) throw new Error("Project Id not found");
  const data = useLazyLoadQuery<GCProjectToolboxTalksQuery>(
    graphql`
      query GCProjectToolboxTalksQuery($projectId: uuid!) {
        project_connection(where: { id: { _eq: $projectId } }) {
          edges {
            node {
              id
              general_contractor_id
              project_subcontractors(
                order_by: { subcontractor: { name: asc } }
              ) {
                subcontractor_id
                subcontractor {
                  name
                }
              }
            }
          }
        }
      }
    `,
    { projectId },
  ).project_connection.edges[0].node;
  const navigate = useNavigate();
  const handleReportsTabClick = (tabId: string) => {
    navigate("../" + tabId);
  };
  const [searchParams, setSearchParams] = useSearchParams();
  const toolboxTalkId = searchParams.get("toolboxTalkId");

  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "manage",
      label: "Manage TBTs",
      content: (
        <ManageToolboxTalks
          projectId={projectId}
          gcId={data.general_contractor_id}
          subsOption={data.project_subcontractors.map((ps) => ({
            id: ps.subcontractor_id,
            name: ps.subcontractor.name,
          }))}
        />
      ),
    },
    {
      id: "completed",
      label: "Completed TBTs",
      content: (
        <ToolboxTalkTable
          showDateRangeFilter={true}
          excludedKeys={["project"]}
          where={{
            project_id: { _eq: projectId },
          }}
          onRowClick={(pk) => setSearchParams({ toolboxTalkId: pk })}
        />
      ),
    },
  ];

  return toolboxTalkId ? (
    <GCProjectReportsTBTsDetail {...{ toolboxTalkId, projectId }} />
  ) : (
    <BasicWrapper scrollable>
      <CustomTabs
        onTabSelect={handleReportsTabClick}
        tabs={tabs}
        selectedTabId={subview || "completed"}
      />
    </BasicWrapper>
  );
};
export default GCProjectToolboxTalks;
