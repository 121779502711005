import { NavigateFunction } from "react-router-dom";
import { NavigationMenuItemProps } from "src/common/components/navigation-menu/NavigationMenuItem";
import { UserContextDataType } from "src/utility-features/authorization/UserDataProvider";

export const getLevelsCommonNavigationItems = (
  navigate: NavigateFunction,
  gc: NonNullable<UserContextDataType["employee"]>["general_contractor"],
): NavigationMenuItemProps[] => [
  {
    label: "All Projects",
    rootPath: "projects",
    onClick: () => {
      navigate("projects");
    },
  },

  {
    label: "Workers",
    rootPath: "workers",
    onClick: () => {
      navigate("workers");
    },
  },
  ...(gc.incident_enabled
    ? [
        {
          label: "Incidents",
          rootPath: "incidents",
          onClick: () => {
            navigate("incidents");
          },
        },
      ]
    : []),
  ...(gc.observation_enabled
    ? [
        {
          label: "Observations",
          rootPath: "observations",
          onClick: () => {
            navigate("observations");
          },
        },
      ]
    : []),
  {
    label: "Admins",
    rootPath: "teams",
    onClick: () => {
      navigate("teams");
    },
  },
  {
    label: "Settings",
    rootPath: "settings",
    onClick: () => {
      navigate("settings");
    },
  },
];
