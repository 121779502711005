import { forwardRef, useImperativeHandle, useRef } from "react";
import FModal, { FModalRef } from "./FModal";
import { UploadOutlined, PlusOutlined } from "@ant-design/icons";

interface ModalValues {
  subs: string[];
}

interface JHAUploadOptionsModalProps {
  onCancel: () => void;
  onInstantTaskUploadClick?: () => void;
  onNonInstantTaskUploadClick?: () => void;
  onRequestJHAFromSubClick?: () => void;
  onAddJHAFromCompanyLibraryClick?: () => void;
  onAddJHAFromOtherProjectClick?: () => void;
  onAddNewJHAClick?: () => void;
  isSubAdminCompanyJHA?: boolean;
}

export type JHAUploadOptionsModalRef = FModalRef<ModalValues> | null;
const JHAUploadOptionsModal = forwardRef<
  JHAUploadOptionsModalRef,
  JHAUploadOptionsModalProps
>(
  (
    {
      onCancel,
      onInstantTaskUploadClick,
      onNonInstantTaskUploadClick,
      onRequestJHAFromSubClick,
      onAddJHAFromCompanyLibraryClick,
      onAddJHAFromOtherProjectClick,
      onAddNewJHAClick,
      isSubAdminCompanyJHA,
    },
    ref,
  ) => {
    const modal = useRef<FModalRef<ModalValues>>(null);

    const handleCancel = () => {
      onCancel();
    };

    useImperativeHandle<JHAUploadOptionsModalRef, JHAUploadOptionsModalRef>(
      ref,
      () => modal.current,
    );
    return (
      <FModal
        ref={modal}
        okText="Download"
        cancelText="Cancel"
        okButtonProps={{ style: { display: "none" } }}
        cancelButtonProps={{ style: { display: "none" } }}
        onCancel={handleCancel}
      >
        <div>
          {onNonInstantTaskUploadClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={3}
              onClick={(e) => {
                e.stopPropagation();
                onNonInstantTaskUploadClick();
              }}
            >
              <UploadOutlined /> Upload As-is (not instant)
            </div>
          )}

          {onInstantTaskUploadClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={2}
              onClick={(e) => {
                e.stopPropagation();
                onInstantTaskUploadClick();
              }}
            >
              <UploadOutlined /> Upload with Excel (instant)
            </div>
          )}

          {onAddJHAFromCompanyLibraryClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={5}
              onClick={(e) => {
                e.stopPropagation();
                onAddJHAFromCompanyLibraryClick();
              }}
            >
              <UploadOutlined /> Add JHA from Company Library
            </div>
          )}

          {onAddJHAFromOtherProjectClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={6}
              onClick={(e) => {
                e.stopPropagation();
                onAddJHAFromOtherProjectClick();
              }}
            >
              {isSubAdminCompanyJHA ? (
                <>
                  <UploadOutlined /> Add JHA from Project
                </>
              ) : (
                <>
                  <UploadOutlined /> Add JHA from Another Project
                </>
              )}
            </div>
          )}

          {onAddNewJHAClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={1}
              onClick={(e) => {
                e.stopPropagation();
                onAddNewJHAClick();
              }}
            >
              <PlusOutlined /> Enter Manually
            </div>
          )}

          {onRequestJHAFromSubClick && (
            <div
              className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
              key={4}
              onClick={(e) => {
                e.stopPropagation();
                onRequestJHAFromSubClick();
              }}
            >
              📨 Request from Subcontractor
            </div>
          )}
        </div>
      </FModal>
    );
  },
);

export default JHAUploadOptionsModal;
