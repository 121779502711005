/**
 * @generated SignedSource<<bdd4c91ff42f57b6fb9e4222a84d3691>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { checklist_item_config_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCProjectReportsDailySettingsInsertConfigMutation$variables = {
  object: checklist_item_config_insert_input;
};
export type GCProjectReportsDailySettingsInsertConfigMutation$data = {
  readonly insert_checklist_item_config_one: {
    readonly id: string;
  } | null | undefined;
};
export type GCProjectReportsDailySettingsInsertConfigMutation = {
  response: GCProjectReportsDailySettingsInsertConfigMutation$data;
  variables: GCProjectReportsDailySettingsInsertConfigMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "object"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "object",
        "variableName": "object"
      }
    ],
    "concreteType": "checklist_item_config",
    "kind": "LinkedField",
    "name": "insert_checklist_item_config_one",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "id",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCProjectReportsDailySettingsInsertConfigMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCProjectReportsDailySettingsInsertConfigMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "48df93761b5c66564ddaa83ceb93f125",
    "id": null,
    "metadata": {},
    "name": "GCProjectReportsDailySettingsInsertConfigMutation",
    "operationKind": "mutation",
    "text": "mutation GCProjectReportsDailySettingsInsertConfigMutation(\n  $object: checklist_item_config_insert_input!\n) {\n  insert_checklist_item_config_one(object: $object) {\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "45f2cd658fa79847ab336022dcdfa491";

export default node;
