import dayjs from "dayjs";
import React, { useEffect } from "react";
import { graphql } from "babel-plugin-relay/macro";
import { useLazyLoadQuery } from "react-relay/hooks";
import { QuickLookWorkerQuery } from "src/common/types/generated/relay/QuickLookWorkerQuery.graphql";
import { auth } from "src/common/functions/firebase";
import { browserSessionPersistence } from "firebase/auth";

const query = graphql`
  query QuickLookWorkerQuery($reference: String!) {
    user_connection(where: { reference_number: { _eq: $reference } }) {
      edges {
        node {
          name
          universal_orientations(
            limit: 1
            order_by: { universal_orientated_at: desc }
          ) {
            universal_orientated_at
          }
        }
      }
    }
  }
`;

type QuickLookWorkerProps = {
  reference: string;
};
const QuickLookWorker: React.FC<QuickLookWorkerProps> = ({ reference }) => {
  const data = useLazyLoadQuery<QuickLookWorkerQuery>(query, { reference });

  useEffect(() => {
    auth.setPersistence(browserSessionPersistence);
  }, []);

  return data.user_connection.edges[0].node?.universal_orientations[0]
    ?.universal_orientated_at ? (
    <div className="bg-semantic-positive-green h-screen text-1.25 gap-1 text-white flex flex-col justify-center text-center items-center">
      <div>Name: {data.user_connection.edges[0].node.name}</div>
      AGC Universal Orientation:{" "}
      {dayjs(
        data.user_connection.edges[0].node?.universal_orientations[0]
          .universal_orientated_at,
      ).format("LL")}
    </div>
  ) : (
    <div className="bg-semantic-negative h-screen text-1.25 gap-1 text-white flex flex-col justify-center text-center items-center">
      {" "}
      <div>Name: {data.user_connection.edges[0].node.name}</div>
      AGC Universal Orientation: not complete
    </div>
  );
};

export default QuickLookWorker;
