import { FC, useMemo } from "react";
import { Button, Menu, Popover, message, Spin } from "antd";
import { useParams, useNavigate, useSearchParams } from "react-router-dom";
import {
  useUpdateIncidentMutation,
  useUpdateIncidentUserMutation,
  useGenerateIncidentPdfMutation,
  useEmailIncidentPdfMutation,
  GetIncidentByPkDocument,
  GetIncidentByPkQuery,
  GetIncidentByPkQueryVariables,
  useGetMonetarySeverityLevelsQuery,
  useGetIncidentOptionsQuery,
} from "src/common/types/generated/apollo/graphQLTypes";
import getMenuItemLabel from "../../utils/getMenuItemLabel";
import getMenuItemIcon from "../../utils/getMenuItemIcon";
import { IconSend, IconDownload } from "@tabler/icons";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { useSuspenseQuery } from "@apollo/client";
import { DownOutlined } from "@ant-design/icons";
import capitalize from "src/common/functions/capitalize";
import dayjs from "dayjs";
import BPopconfirm from "src/common/components/dialogs/BPopconfirm";
import { IconTrash } from "@tabler/icons";
import Icon from "src/common/components/general/Icon";
import { InfoCircleOutlined } from "@ant-design/icons";
import createIncidentPatch from "../../utils/createIncidentPatch";
import useAuthUser from "src/common/hooks/useAuthUser";
import IncidentComponentRenderer from "../../components/IncidentComponentRenderer";

const GcIncidentManagement: FC = () => {
  const { projectId, incidentId } = useParams();
  const navigate = useNavigate();
  const authUser = useAuthUser();
  const [searchParams, setSearchParams] = useSearchParams();
  const subview = searchParams.get("subview");
  const incidentUserId = searchParams.get("id");

  if (!incidentId) {
    throw new Error("IncidentId not found");
  }
  if (!projectId) throw new Error("ProjectId not found");
  const [emailIncidentPdf, { loading: emailing }] =
    useEmailIncidentPdfMutation();
  const [generateIncidentPdf, { loading: generating }] =
    useGenerateIncidentPdfMutation();
  const [updateIncident] = useUpdateIncidentMutation();
  const [updateIncidentUser] = useUpdateIncidentUserMutation();
  const { loading: loadingOptions, error: optionsError } =
    useGetIncidentOptionsQuery({
      variables: { projectId },
    }); //used in child component
  const { loading: monetaryLoading, error: monetaryError } =
    useGetMonetarySeverityLevelsQuery(); // used in child component queries
  const { data: incidentData, refetch } = useSuspenseQuery<
    GetIncidentByPkQuery,
    GetIncidentByPkQueryVariables
  >(GetIncidentByPkDocument, {
    variables: {
      incidentId: incidentId,
    },
  });

  const incident = incidentData.incident_by_pk;

  if (!incident) throw new Error("Incident not found for given incident id");

  const allIncidentTypes = [
    "environmental",
    "equipment",
    "near_miss",
    "property_damage",
    "vehicle",
    "utility",
    "theft",
  ];
  // Has not included injury_illness to these types as it is located at a fixed position in the Menu Item.

  const { selectedIncidentTypes, nonSelectedIncidentTypes } = useMemo(() => {
    const selectedIncidentTypes: Array<string> = [];
    const nonSelectedIncidentTypes: Array<string> = [];
    for (const type of allIncidentTypes) {
      if (
        incident.incident_types.findIndex(
          (incidentType) => incidentType.type_value === type,
        ) !== -1
      ) {
        selectedIncidentTypes.push(type);
      } else {
        nonSelectedIncidentTypes.push(type);
      }
    }
    return { selectedIncidentTypes, nonSelectedIncidentTypes };
  }, [incident.incident_types]);

  const deleteIncidentUser = async (
    id: string,
    type: "witness" | "injured_user",
  ) => {
    const incidentUsers =
      type === "witness" ? incident.witnesses : incident.injured_users;

    const userName = incidentUsers.find(
      (incidentUser) => incidentUser.id === id,
    )?.user.name;
    const filteredIncidetUsers = incidentUsers.filter(
      (incidentUser) => incidentUser.id !== id,
    );

    const updatedIncident =
      type === "injured_user"
        ? {
            ...incident,
            injured_users: filteredIncidetUsers,
          }
        : { ...incident, witnesses: filteredIncidetUsers };

    const patch = createIncidentPatch(updatedIncident, incident);

    const comment = `Deleted the ${
      type === "injured_user" ? "injured user" : "witness"
    } ${userName}`;

    await updateIncidentUser({
      variables: {
        id: id,
        set: { deleted_at: dayjs().format() },
        objects: {
          patch: patch,
          comment: comment,
          edit_type: "deleted-incident-user",
          edited_by_uid: authUser.uid,
          incident_id: incident.id,
        },
      },
      update(cache, result) {
        const incidentUserId = result.data?.update_incident_user_by_pk?.id;
        if (!incidentUserId) return;

        const incidentUsers =
          type === "witness" ? incident.witnesses : incident.injured_users;

        const filteredIncidetUsers = incidentUsers.filter(
          (incidentUser) => incidentUser.id !== incidentUserId,
        );
        const updatedIncident =
          type === "injured_user"
            ? {
                ...incident,
                injured_users: filteredIncidetUsers,
              }
            : { ...incident, witnesses: filteredIncidetUsers };

        cache.writeQuery<GetIncidentByPkQuery, GetIncidentByPkQueryVariables>({
          query: GetIncidentByPkDocument,
          data: {
            __typename: "query_root",
            incident_by_pk: updatedIncident,
          },
        });
      },
    });

    const incidentUserId = searchParams.get("id");

    if (incidentUserId === id) {
      setSearchParams({ subview: "incident_detail" });
    }
  };

  const selectedItem = incidentUserId
    ? `${subview}&&${incidentUserId}`
    : subview;

  const incidentUsersData: Array<{
    key: string;
    label: JSX.Element;
  }> = [];

  const witnessUsers: Array<{
    key: string;
    label: JSX.Element;
  }> = [];

  if (!!incident?.injured_users && Array.isArray(incident.injured_users)) {
    for (const user of incident.injured_users) {
      incidentUsersData.push({
        key: `injured_user&&${user.id}`,
        label: (
          <div className="flex flex-row">
            <div className="min-w-10 max-w-10"> {user.user.name} </div>
            <div
              className="mt-0.5 ml-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <BPopconfirm
                title="Are you sure you want to remove this person?"
                onConfirm={() => deleteIncidentUser(user.id, "injured_user")}
                okText="Yes"
                cancelText="No"
              >
                <Icon icon={IconTrash} color="negative" />
              </BPopconfirm>
            </div>
          </div>
        ),
      });
    }
  }

  if (!!incident.witnesses && Array.isArray(incident.witnesses)) {
    for (const user of incident.witnesses) {
      witnessUsers.push({
        key: `witness&&${user.id}`,
        label: (
          <div className="flex flex-row">
            <div className="min-w-10 max-w-10"> {user.user.name} </div>
            <div
              className="mt-0.5 ml-2"
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
            >
              <BPopconfirm
                title="Are you sure you want to remove this person?"
                onConfirm={() => deleteIncidentUser(user.id, "witness")}
                okText="Yes"
                cancelText="No"
              >
                <Icon icon={IconTrash} color="negative" />
              </BPopconfirm>
            </div>
          </div>
        ),
      });
    }
  }

  incidentUsersData.push({
    key: "injured_user",
    label: <span>+ Add Person</span>,
  });
  witnessUsers.push({ key: "witness", label: <span> + Add Person </span> });

  const menuItems = [
    {
      label: getMenuItemLabel(incident, "table"),
      key: "table",
      icon: getMenuItemIcon("table"),
    },
    {
      label: getMenuItemLabel(incident, "project"),
      key: "project_info",
      icon: getMenuItemIcon("project"),
    },
    {
      label: getMenuItemLabel(incident, "incident_detail"),
      key: "incident_detail",
      icon: getMenuItemIcon("incident_detail"),
    },
    {
      label: getMenuItemLabel(incident, "injury_illness"),
      key: "injury_detail",
      icon: getMenuItemIcon("injury_illness"),
      children: incidentUsersData,
    },
    {
      label: getMenuItemLabel(incident, "root_cause"),
      key: "root_cause",
      icon: getMenuItemIcon("root_cause"),
    },
    {
      label: getMenuItemLabel(incident, "witness"),
      key: "witness_detail",
      icon: getMenuItemIcon("witness"),
      children: witnessUsers,
    },
  ];

  for (const type of selectedIncidentTypes) {
    menuItems.push({
      label: getMenuItemLabel(incident, type),
      key: type,
      icon: getMenuItemIcon(type),
    });
  }

  for (const type of nonSelectedIncidentTypes) {
    menuItems.push({
      label: getMenuItemLabel(incident, type),
      key: type,
      icon: getMenuItemIcon(type, "grey"),
    });
  }

  menuItems.push({
    label: getMenuItemLabel(incident, "notes"),
    key: "notes",
    icon: getMenuItemIcon("notes"),
  });

  menuItems.push({
    label: getMenuItemLabel(incident, "logs"),
    key: "logs",
    icon: getMenuItemIcon("logs"),
  });

  const updateIncidentStatus = async (status: string) => {
    const updatedIncident = { ...incident, status: status };
    const patch = createIncidentPatch(updatedIncident, incident);
    const comment = `Updated "Incident status" to ${status}`;
    await updateIncident({
      variables: {
        incidentId: incidentId,
        _set: { status: status },
        objects: {
          patch: patch,
          comment: comment,
          edited_by_uid: authUser.uid,
          incident_id: incidentId,
          edit_type: "updated-incident-status",
        },
      },
      optimisticResponse: {
        update_incident_by_pk: {
          ...incident,
          status: status,
          id: incidentId,
        },
        insert_incident_edit: {
          affected_rows: 1,
        },
      },
    });
  };

  if (loadingOptions) return <Spin />;
  if (optionsError) throw optionsError;
  if (monetaryLoading) return <Spin />;
  if (monetaryError) throw monetaryError;
  return (
    <div className="flex h-screen gap-0.5 p-2 h-full w-full bg-white rounded-2">
      <div className="absolute top-2 right-4 flex gap-1 z-20">
        <div>
          <Button
            type="primary"
            loading={emailing}
            icon={<IconSend />}
            onClick={async () => {
              await emailIncidentPdf({
                variables: { input: { incidentId, projectId } },
              });
              message.success("Email sent");
            }}
          >
            Report Incident
          </Button>
        </div>
        <div>
          <Button
            type="primary"
            loading={generating}
            icon={<IconDownload />}
            onClick={async () => {
              const { data } = await generateIncidentPdf({
                variables: { input: { incidentId } },
              });
              if (data?.generateIncidentPdf) {
                downloadFromUrl(data.generateIncidentPdf);
              }
              message.success("Download complete");
            }}
          >
            Download PDF
          </Button>
        </div>
        <Popover
          trigger={"click"}
          placement="bottomLeft"
          title={"Mark the Incident Status as"}
          content={
            <div className="flex flex-col gap-0.5">
              {incident.status !== "open" && (
                <div
                  className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                  onClick={() => {
                    updateIncidentStatus("open");
                  }}
                >
                  Open
                </div>
              )}

              {incident.status !== "closed" && (
                <div
                  className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                  onClick={() => {
                    updateIncidentStatus("closed");
                  }}
                >
                  Closed
                </div>
              )}

              {incident.status !== "pending" && (
                <div
                  className="hover:text-interactive-primary  py-0.25 px-0.75 text-1 cursor-pointer"
                  onClick={() => {
                    updateIncidentStatus("pending");
                  }}
                >
                  In-progress
                </div>
              )}
            </div>
          }
        >
          <Button
            type="text"
            block={true}
            icon={<DownOutlined />}
            className="border-grey"
          >
            {capitalize(
              incident.status === "pending"
                ? "In-progress"
                : incident.status || "",
            )}
          </Button>
        </Popover>

        <Popover
          trigger={"hover"}
          placement="bottomLeft"
          title={"Incident Status Definitions"}
          content={
            <div className={"flex flex-col w-24 space-y-1"}>
              <div>
                <strong>Open:</strong> Additional details or information
                required
              </div>
              <div>
                <strong>In-progress:</strong> All general details or information
                have been provided. In-Progress incidents are following cases
                that have not been closed, like a worker that has not returned
                to work
              </div>
              <div>
                <strong>Closed:</strong> All information is collected and the
                incident is considered 100% complete
              </div>
            </div>
          }
        >
          <InfoCircleOutlined />
        </Popover>
      </div>
      <div>
        <Menu
          className="w-20 flex-col max-h-screen overflow-y-auto"
          mode="inline"
          selectedKeys={[selectedItem ?? ""]}
          items={menuItems}
          onClick={(e) => {
            if (e.key === "table") {
              navigate(`/gce/projects/${projectId}/incidents`);
            } else {
              const [type, id] = e.key.split("&&");
              setSearchParams(
                id ? { subview: type, id: id } : { subview: type },
              );
            }
          }}
        />
      </div>

      <IncidentComponentRenderer
        selectedIncidentUserKey={incidentUserId}
        subview={subview}
        typeProps={{ incident, refetch }}
      />
    </div>
  );
};

export default GcIncidentManagement;
