import { IconInfoSquare } from "@tabler/icons";
import { Button, Form, Select, Tag } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import { useMemo, useState } from "react";
import { ConnectionHandler } from "relay-runtime";
import DataDetailDescriptions from "src/common/components/layouts/DataDetailDescriptions";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation } from "src/common/types/generated/relay/ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation.graphql";
import { GCSubcontractorQuery$data } from "src/common/types/generated/relay/GCSubcontractorQuery.graphql";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import compareTwoLists from "src/common/components/ComparingTwoLists";

const updateProjectSubWorkrerTitleMutation = graphql`
  mutation ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation(
    $toDeleteWhere: project_subcontractor_worker_title_bool_exp!
    $objects: [project_subcontractor_worker_title_insert_input!]!
  ) {
    delete_project_subcontractor_worker_title(where: $toDeleteWhere) {
      affected_rows
    }
    insert_project_subcontractor_worker_title(objects: $objects) {
      returning {
        id
        project_workers_aggregate(
          where: { is_last: { _eq: true }, deleted_at: { _is_null: true } }
        ) {
          aggregate {
            count
          }
        }
        worker_title {
          id
          translation {
            en
            id
          }
        }
        worker_title_id
      }
    }
  }
`;
const ProjectSubWorkerTitleViewAndEdit: React.FC<{
  projectId: string;
  subcontractorId: string;
  currentWorkerTitles: GCSubcontractorQuery$data["subcontractor_connection"]["edges"][number]["node"]["subcontractor_project_worker_titles"];
  workerTitleOptions: { label: string; value: string; disabled: boolean }[];
}> = ({
  projectId,
  subcontractorId,
  currentWorkerTitles,
  workerTitleOptions,
}) => {
  const [editTrade, setEditTrade] = useState(false);
  const currWorkerTradeIds = useMemo(
    () => currentWorkerTitles.map((c) => c.worker_title_id),
    [currentWorkerTitles],
  );
  const [changeProjectSubWorkerTitle, loading] =
    useAsyncMutation<ProjectSubWorkerTitleViewAndEdit_ChangeProjectSubWorkerTitle_Mutation>(
      updateProjectSubWorkrerTitleMutation,
    );
  const addProjSubWorkerTitles = async (values: {
    newTrades: Array<string>;
  }) => {
    const [toInsert, toDelete] = compareTwoLists(
      values.newTrades,
      currWorkerTradeIds,
    );
    if (!toInsert.length && !toDelete.length) {
      setEditTrade(false);
      return;
    }
    try {
      changeProjectSubWorkerTitle({
        variables: {
          objects: toInsert.map((t) => ({
            project_id: projectId,
            subcontractor_id: subcontractorId,
            worker_title_id: t,
          })),
          toDeleteWhere: {
            project_id: { _eq: projectId },
            subcontractor_id: { _eq: subcontractorId },
            worker_title_id: { _in: toDelete },
          },
        },
        updater: (store) => {
          const results = store.getRootField(
            "insert_project_subcontractor_worker_title",
          );
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCSubcontractorQuery_subcontractor_connection",
          );
          if (conn && results) {
            const subEdge = conn.getLinkedRecords("edges");
            if (subEdge?.length) {
              const node = subEdge[0].getLinkedRecord("node");
              if (node)
                node.setLinkedRecords(
                  results.getLinkedRecords("returning"),
                  "subcontractor_project_worker_titles",
                  {
                    where: {
                      project_id: { _eq: projectId },
                    },
                    order_by: {
                      worker_title: {
                        translation: {
                          en: Order_By.Asc,
                        },
                      },
                    },
                  },
                );
            }
          }
        },
      });
    } finally {
      setEditTrade(false);
    }
  };

  return (
    <DataDetailDescriptions
      title={" "}
      items={[
        {
          label: "Worker’s Trade(s)",
          iconProps: {
            icon: IconInfoSquare,
            color: "interactive",
            hoverContent: {
              content: (
                <div>
                  Add Pre-Selected trades for this subcontractor. Instead
                  <br />
                  of searching the entire list of Trades during
                  <br />
                  Orientation registration, workers see these trades
                  <br />
                  first. Workers can still select from the entire list.
                  <br />
                  <br />
                  Any trades that workers select are also shown here.
                </div>
              ),
              title: "HELP EXPEDITE THE SPEED WORKERS REGISTER",
            },
          },
          value: editTrade ? (
            <Form
              onFinish={async (values) => await addProjSubWorkerTitles(values)}
              initialValues={{
                newTrades: currentWorkerTitles.map((p) => p.worker_title_id),
              }}
            >
              <Form.Item name="newTrades">
                <Select
                  mode="multiple"
                  showSearch
                  filterOption={getNormalSelectOptionsFilter}
                  options={workerTitleOptions}
                />
              </Form.Item>
              <Button
                htmlType="submit"
                type="primary"
                size="small"
                loading={loading}
                className="rounded-2 font-accent"
              >
                Done
              </Button>
            </Form>
          ) : (
            <>
              {currentWorkerTitles.map((p) => (
                <Tag
                  key={p.worker_title_id}
                  className="bg-suplementary-3 text-semantic-pending-dark rounded-2"
                >
                  {p.worker_title.translation.en}
                </Tag>
              ))}
              <Button
                onClick={async () => setEditTrade(true)}
                type="primary"
                size="small"
                className="rounded-2 font-accent"
              >
                + Trades
              </Button>
            </>
          ),
        },
      ]}
    />
  );
};
export default ProjectSubWorkerTitleViewAndEdit;
