/**
 * @generated SignedSource<<118cc90e0fc62cf8cb1051285bd5702c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
export type SCChildSubJHAsQuery$variables = {
  projectId: string;
  subcontractorId: string;
};
export type SCChildSubJHAsQuery$data = {
  readonly permit_type_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  };
  readonly ppe_type_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly name: {
          readonly en: string;
        };
        readonly pk: string;
      };
    }>;
  };
  readonly project_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly general_contractor_id: string;
        readonly id: string;
      };
    }>;
  };
  readonly task_connection: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly pk: string;
        readonly task_edit: ReadonlyArray<{
          readonly edited_at: string;
          readonly task_patch: any | null | undefined;
        }>;
        readonly task_signatures: ReadonlyArray<{
          readonly created_at: string;
          readonly signature_image_id: string | null | undefined;
        }>;
      };
    }>;
  };
};
export type SCChildSubJHAsQuery = {
  response: SCChildSubJHAsQuery$data;
  variables: SCChildSubJHAsQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "projectId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "subcontractorId"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "_eq",
    "variableName": "projectId"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": [
    {
      "fields": [
        {
          "fields": (v1/*: any*/),
          "kind": "ObjectValue",
          "name": "id"
        }
      ],
      "kind": "ObjectValue",
      "name": "where"
    }
  ],
  "concreteType": "projectConnection",
  "kind": "LinkedField",
  "name": "project_connection",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "projectEdge",
      "kind": "LinkedField",
      "name": "edges",
      "plural": true,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "project",
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "general_contractor_id",
              "storageKey": null
            },
            (v2/*: any*/)
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
},
v4 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "name": {
        "en": "asc"
      }
    }
  }
],
v5 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "__id_pk",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "en",
  "storageKey": null
},
v7 = [
  (v2/*: any*/),
  (v5/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v6/*: any*/)
    ],
    "storageKey": null
  }
],
v8 = {
  "_is_null": true
},
v9 = [
  {
    "fields": [
      {
        "kind": "Literal",
        "name": "deleted_at",
        "value": (v8/*: any*/)
      },
      {
        "fields": (v1/*: any*/),
        "kind": "ObjectValue",
        "name": "project_id"
      },
      {
        "kind": "Literal",
        "name": "report_id",
        "value": (v8/*: any*/)
      },
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "_eq",
            "variableName": "subcontractorId"
          }
        ],
        "kind": "ObjectValue",
        "name": "subcontractor_id"
      }
    ],
    "kind": "ObjectValue",
    "name": "where"
  }
],
v10 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "edited_at": "desc"
    }
  }
],
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "task_patch",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "edited_at",
  "storageKey": null
},
v13 = [
  {
    "kind": "Literal",
    "name": "order_by",
    "value": {
      "created_at": "desc"
    }
  },
  {
    "kind": "Literal",
    "name": "where",
    "value": {
      "user": {
        "role": {
          "_neq": "employee"
        }
      }
    }
  }
],
v14 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "created_at",
  "storageKey": null
},
v15 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "signature_image_id",
  "storageKey": null
},
v16 = {
  "alias": "pk",
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v17 = {
  "alias": "pk",
  "args": null,
  "filters": null,
  "handle": "pk",
  "key": "",
  "kind": "ScalarHandle",
  "name": "id"
},
v18 = [
  (v2/*: any*/),
  (v16/*: any*/),
  (v17/*: any*/),
  {
    "alias": null,
    "args": null,
    "concreteType": "text_translation",
    "kind": "LinkedField",
    "name": "name",
    "plural": false,
    "selections": [
      (v6/*: any*/),
      (v2/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SCChildSubJHAsQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ppe_typeConnection",
        "kind": "LinkedField",
        "name": "ppe_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ppe_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ppe_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "ppe_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "permit_typeConnection",
        "kind": "LinkedField",
        "name": "permit_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "permit_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "permit_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v7/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "permit_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v5/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "task_edit",
                    "kind": "LinkedField",
                    "name": "task_edit",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/)
                    ],
                    "storageKey": "task_edit(order_by:{\"edited_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "concreteType": "task_signature",
                    "kind": "LinkedField",
                    "name": "task_signatures",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/)
                    ],
                    "storageKey": "task_signatures(order_by:{\"created_at\":\"desc\"},where:{\"user\":{\"role\":{\"_neq\":\"employee\"}}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SCChildSubJHAsQuery",
    "selections": [
      (v3/*: any*/),
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "ppe_typeConnection",
        "kind": "LinkedField",
        "name": "ppe_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ppe_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ppe_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "ppe_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "permit_typeConnection",
        "kind": "LinkedField",
        "name": "permit_type_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "permit_typeEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "permit_type",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": (v18/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": "permit_type_connection(order_by:{\"name\":{\"en\":\"asc\"}})"
      },
      {
        "alias": null,
        "args": (v9/*: any*/),
        "concreteType": "taskConnection",
        "kind": "LinkedField",
        "name": "task_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "taskEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "task",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  (v2/*: any*/),
                  (v16/*: any*/),
                  (v17/*: any*/),
                  {
                    "alias": null,
                    "args": (v10/*: any*/),
                    "concreteType": "task_edit",
                    "kind": "LinkedField",
                    "name": "task_edit",
                    "plural": true,
                    "selections": [
                      (v11/*: any*/),
                      (v12/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": "task_edit(order_by:{\"edited_at\":\"desc\"})"
                  },
                  {
                    "alias": null,
                    "args": (v13/*: any*/),
                    "concreteType": "task_signature",
                    "kind": "LinkedField",
                    "name": "task_signatures",
                    "plural": true,
                    "selections": [
                      (v14/*: any*/),
                      (v15/*: any*/),
                      (v2/*: any*/)
                    ],
                    "storageKey": "task_signatures(order_by:{\"created_at\":\"desc\"},where:{\"user\":{\"role\":{\"_neq\":\"employee\"}}})"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "ca6742be4c849d11778bfd9d65b7997e",
    "id": null,
    "metadata": {},
    "name": "SCChildSubJHAsQuery",
    "operationKind": "query",
    "text": "query SCChildSubJHAsQuery(\n  $projectId: uuid!\n  $subcontractorId: uuid!\n) {\n  project_connection(where: {id: {_eq: $projectId}}) {\n    edges {\n      node {\n        general_contractor_id\n        id\n      }\n    }\n  }\n  ppe_type_connection(order_by: {name: {en: asc}}) {\n    edges {\n      node {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n      }\n    }\n  }\n  permit_type_connection(order_by: {name: {en: asc}}) {\n    edges {\n      node {\n        id\n        pk: id\n        name {\n          en\n          id\n        }\n      }\n    }\n  }\n  task_connection(where: {project_id: {_eq: $projectId}, subcontractor_id: {_eq: $subcontractorId}, deleted_at: {_is_null: true}, report_id: {_is_null: true}}) {\n    edges {\n      node {\n        id\n        pk: id\n        task_edit(order_by: {edited_at: desc}) {\n          task_patch\n          edited_at\n          id\n        }\n        task_signatures(where: {user: {role: {_neq: \"employee\"}}}, order_by: {created_at: desc}) {\n          created_at\n          signature_image_id\n          id\n        }\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "ccd5536399a175dedbcca3209672e709";

export default node;
