import Icon from "src/common/components/general/Icon";
import { IconEye, IconEyeOff } from "@tabler/icons";
import clsx from "clsx";
import React, { useState } from "react";
import hexToRgba from "src/common/functions/hexToRgba";
import { CalendarBaseType } from "../utilities/sitedeliveryTypes";
// import { CalendarBaseType } from "../sitedeliveryTypes";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Popover } from "antd";
import { GCProjectCalendarSitedeliveryDeliveriesQuery$data } from "src/common/types/generated/relay/GCProjectCalendarSitedeliveryDeliveriesQuery.graphql";
import PdfModal from "src/common/components/modals/PdfModal";

export interface GCProjectCalendarSitedeliveryDeliveriesCalendarsFilterProps {
  logisticPlan: GCProjectCalendarSitedeliveryDeliveriesQuery$data["project_connection"]["edges"][number]["node"]["logistic_plans"];
  calendars: (CalendarBaseType & {
    visible: boolean;
  })[];
  toggleCalendarVisible: (
    calendar: CalendarBaseType,
    newValue: boolean,
  ) => void;
  hideLogistic?: boolean;
}

const GCProjectCalendarSitedeliveryDeliveriesCalendarsFilter: React.FC<
  GCProjectCalendarSitedeliveryDeliveriesCalendarsFilterProps
> = (props) => {
  const [pdfUrl, setPdfUrl] = useState("");
  const [openPopover, setOpenPopover] = useState(false);
  return (
    <div className="flex justify-between gap-1">
      <div className="flex flex-row w-full gap-1">
        <div className="font-accent flex ml-1">Available Calendars:</div>
        <div className="flex-1 w-0">
          <div className="w-full overflow-x-auto">
            <div className="flex flex-row gap-0.25">
              {props.calendars.map((calendar) => (
                <div
                  className={
                    "flex flex-row items-center px-0.5 whitespace-nowrap rounded-1 gap-0.25 text-0.75 border cursor-pointer select-none transition-colors group"
                  }
                  key={calendar.id}
                  onClick={() => {
                    props.toggleCalendarVisible(calendar, !calendar.visible);
                  }}
                  style={
                    calendar.visible
                      ? {
                          backgroundColor: hexToRgba(calendar.colorHex, 0.4),
                          borderColor: "transparent",
                        }
                      : {
                          borderColor: hexToRgba(calendar.colorHex, 0.4),
                          backgroundColor: "transparent",
                        }
                  }
                >
                  <div
                    className={
                      calendar.visible
                        ? "text-static-primary font-accent text-1.2"
                        : "text-static-secondary"
                    }
                  >
                    {calendar.title}
                  </div>
                  <div
                    className={
                      "opacity-0 group-hover:opacity-100 transition-opacity"
                    }
                  >
                    <Icon
                      icon={calendar.visible ? IconEyeOff : IconEye}
                      size="small"
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
      <PdfModal
        destroyOnClose
        visible={!!pdfUrl}
        onClose={() => {
          setPdfUrl("");
        }}
        pdfUrl={pdfUrl}
      />

      {props.hideLogistic ? (
        <></>
      ) : props.logisticPlan &&
        props.logisticPlan.at(0) &&
        props.logisticPlan[0].documents.length !== 0 ? (
        <Popover
          open={openPopover}
          onOpenChange={(v) => setOpenPopover(v)}
          content={
            props.logisticPlan
              ? props.logisticPlan[0].documents.map((doc) => (
                  <Button
                    key={doc.id}
                    className="flex text-interactive-primary"
                    type="text"
                    onClick={() => {
                      setOpenPopover(false);
                      setPdfUrl(doc.url);
                    }}
                  >
                    {doc.name}
                  </Button>
                ))
              : []
          }
        >
          <Button
            className="flex text-interactive-primary"
            type="text"
            onClick={() => {}}
          >
            Logistics Plan
          </Button>
        </Popover>
      ) : (
        <Button className="flex" type="text" onClick={() => {}}>
          No Logistics Plan
        </Button>
      )}
    </div>
  );
};

export default GCProjectCalendarSitedeliveryDeliveriesCalendarsFilter;
