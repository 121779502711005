import { DevelopmentPlaygroundNavigationItem } from "../utils/developmentPlaygroundTypes";
import { UserOutlined } from "@ant-design/icons";
import React from "react";
import { Card } from "antd";
import { Outlet } from "react-router-dom";
import TabsDemoNestedTabLink from "./TabsDemoNestedTabLink";

const tabsNumber = 5;

const tabsDemoNavigationItems: DevelopmentPlaygroundNavigationItem[] = [];
for (let i = tabsDemoNavigationItems.length; i < tabsNumber; i++) {
  tabsDemoNavigationItems.push({
    pathName: `tab-${i}`,
    icon: <UserOutlined />,
    title: `Tab ${i}`,

    routeElement: {
      element: (
        <Card>
          <div>Tab {i}</div>
          <Outlet />
        </Card>
      ),
      children: [
        {
          path: "",
          element: <TabsDemoNestedTabLink />,
        },
        {
          path: `nested-tab`,
          element: <Card>Nested Tab Content</Card>,
        },
      ],
    },
  });
}

export default tabsDemoNavigationItems;
