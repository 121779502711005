import ProjectTable from "src/root/routes/views/general-contractor/hierarchy/component/ProjectTable";

import { useRef, useState } from "react";
import GCAddProject from "../../component/GCAddProject";
import { IconPlus } from "@tabler/icons";
import { message } from "antd";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import { useParams } from "react-router-dom";
import {
  GetGcBusinessUnitsDocument,
  GetGcBusinessUnitsQuery,
  GetGcBusinessUnitsQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useSuspenseQuery } from "@apollo/client";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import withBusinessUnitId, {
  BusinessUnitProps,
} from "../../utils/withBusinessUnitId";

const GCBusinessProjects: React.FC<BusinessUnitProps> = ({
  businessUnitId,
}) => {
  const { userData } = useUserData();
  const [open, setOpen] = useState(false);
  const { data } = useSuspenseQuery<
    GetGcBusinessUnitsQuery,
    GetGcBusinessUnitsQueryVariables
  >(GetGcBusinessUnitsDocument, {
    variables: {
      includeTableData: false,
      buWhere: { id: { _eq: businessUnitId } },
      divisionWhere: { id: { _is_null: true } },
    },
  });
  const bu = data?.gc_business_unit[0];
  if (!bu) throw new Error("No Business Unit found for given Id");
  if (!userData.employee) throw new Error("userData not found");
  console.log({
    general_contractor_id: {
      _eq: userData.employee.general_contractor.id,
    },
    gc_business_unit_id: { _eq: businessUnitId },
  });
  const ref = useRef<DataScrollTableRef>(null);
  return (
    <div className={`w-full relative h-full p-2 bg-white rounded-2`}>
      {open && (
        <GCAddProject
          onCancel={() => setOpen(false)}
          toInsertWith={{
            gc_business_unit_id: bu.id,
            gc_division_id: bu.gc_division_id,
          }}
          onCreate={() => {
            message.success("Project Created Successfully");
            setOpen(false);
          }}
        />
      )}
      <ProjectTable
        ref={ref}
        showLevels={["office"]}
        topBarButtons={[
          {
            label: "Add New Project",
            icon: IconPlus,
            onClick: () => setOpen(true),
          },
        ]}
        excludedKeys={["gc"]}
        where={{
          general_contractors: {
            general_contractor_id: {
              _eq: userData.employee.general_contractor.id,
            },
          },
          gc_business_unit_id: { _eq: businessUnitId },
        }}
      />
    </div>
  );
};
export default withBusinessUnitId(GCBusinessProjects);
