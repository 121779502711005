import { FC, useRef, useState } from "react";
import IncidentTable from "../../components/table/IncidentTable";
import { useInsertIncidentMutation } from "src/common/types/generated/apollo/graphQLTypes";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import AddIncidentModal from "../../components/modals/AddIncidentModal";
import useAuthUser from "src/common/hooks/useAuthUser";
import { IconPlus } from "@tabler/icons";
import CustomTabs, {
  CustomTabsProps,
} from "src/common/components/layouts/tabs/CustomTabs";
import GCProjectIncidentDashboard from "../GCProjectIncidentDashboard";

const GCProjectIncidents: FC = () => {
  const { projectId } = useParams();
  if (!projectId) throw new Error("Project id not found");
  const navigate = useNavigate();
  const [addNewIncident, setAddNewIncident] = useState(false);
  const [insertIncident, { loading }] = useInsertIncidentMutation();
  const incidentTableRef = useRef<DataScrollTableRef>(null);
  const authUser = useAuthUser();

  const [searchParams, setSearchParams] = useSearchParams();
  const addIncident = async (values: Array<string>) => {
    const comment = `Incident Report created`;
    const res = await insertIncident({
      variables: {
        object: {
          project_id: projectId,
          incident_types: { data: values.map((val) => ({ type_value: val })) },
          submitted_by_uid: authUser.uid,
          equipment_incident: { data: {} },
          theft_incident: { data: {} },
          near_miss_incident: { data: {} },
          environmental_incident: { data: {} },
          vehicle_incident: { data: {} },
          property_damage_incident: { data: {} },
          utility_incident: { data: {} },
          root_cause_analysis: { data: {} },
          status: "open",
          incident_edits: {
            data: [
              {
                comment: comment,
                edited_by_uid: authUser.uid,
                patch: [],
                edit_type: "log",
              },
            ],
          },
        },
      },
    });
    const updatedIncident = res.data?.insert_incident_one;
    if (updatedIncident?.id) {
      navigate(`${updatedIncident?.id}?subview=project_info`);
    }
  };
  const subview = searchParams.get("subview") || "incidents";
  const handleReportsTabClick = (tabId: string) => {
    setSearchParams({ subview: tabId });
  };
  const tabs: CustomTabsProps["tabs"] = [
    {
      id: "charts",
      label: "Overview",
    },
    {
      id: "incidents",
      label: "Incidents",
    },
  ];
  return (
    <BasicWrapper scrollable>
      <CustomTabs
        onTabSelect={handleReportsTabClick}
        tabs={tabs}
        selectedTabId={subview}
      />
      <AddIncidentModal
        onCancel={() => setAddNewIncident(false)}
        onCreate={async (values) => {
          addIncident(values.types);
          setAddNewIncident(false);
        }}
        visible={addNewIncident}
      />
      <div className={`mt-1`}>
        {subview === "charts" ? (
          <GCProjectIncidentDashboard projectId={projectId} />
        ) : (
          <IncidentTable
            topBarButtons={[
              {
                label: "New Incident",
                onClick: () => setAddNewIncident(true),
                icon: IconPlus,
                loading,
              },
            ]}
            ref={incidentTableRef}
            onRowClick={(pk) => navigate(`${pk}?subview=project_info`)}
            where={{
              project_id: { _eq: projectId },
              deleted_at: { _is_null: true },
            }}
          />
        )}
      </div>
    </BasicWrapper>
  );
};

export default GCProjectIncidents;
