import { message } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import React, {
  forwardRef,
  useContext,
  useImperativeHandle,
  useRef,
  useState,
} from "react";
import { useLazyLoadQuery } from "react-relay/hooks";
import { ConnectionHandler, RecordSourceSelectorProxy } from "relay-runtime";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import uploadFiles from "src/common/functions/upload-utility/uploadFiles";
import {
  AddSafetyReportModalInsertSafetyReportMutation,
  AddSafetyReportModalInsertSafetyReportMutation$data,
} from "src/common/types/generated/relay/AddSafetyReportModalInsertSafetyReportMutation.graphql";
import * as uuid from "uuid";
import FModal, { FModalRef } from "./FModal";
import dayjs from "dayjs";
import useGCProjectDailyWorkLogContext from "src/domain-features/sitesafety/entryRoutes/gcProjectReports/routes/daily/utils/useGCProjectDailyWorkLogContext";

type FormValues = {
  comments: string;
  subcontractorId: string;
  tagIds: string[];
  images: Array<{ originFileObj: File }>;
};

const insertSafetyReportMutation = graphql`
  mutation AddSafetyReportModalInsertSafetyReportMutation(
    $object: daily_safety_report_insert_input!
  ) {
    insert_daily_safety_report_one(object: $object) {
      id
      pk: id @__clientField(handle: "pk")
      created_at
      created_by_user {
        id
        name
        pk: id @__clientField(handle: "pk")
      }
      subcontractor {
        name
        pk: id @__clientField(handle: "pk")
      }
      images(order_by: { sort_index: asc }) {
        ...ImageFrag @relay(mask: false)
      }
      daily_safety_report_tags {
        id
        pk: id @__clientField(handle: "pk")
        tag {
          ...TagFrag @relay(mask: false)
        }
      }
      daily_safety_report_comments {
        id
        pk: id @__clientField(handle: "pk")
        text_translation {
          en
        }
      }
    }
  }
`;

export type AddSafetyReportModalDataProps = {
  projectId: string;
  day: dayjs.Dayjs;
  lang?: string;
};

export type AddSafetyReportModalProps = {
  onInserted: () => void;
} & AddSafetyReportModalDataProps;

export type AddSafetyReportModalRef = FModalRef<FormValues> | null;
const AddSafetyReportModal = forwardRef<
  AddSafetyReportModalRef,
  AddSafetyReportModalProps
>(({ projectId, day, onInserted, lang = "en" }, ref) => {
  const modal = useRef<FModalRef<FormValues>>(null);
  const [inserting, setInserting] = useState(false);

  const [insertSafetyReport, insertingSafetyReport] =
    useAsyncMutation<AddSafetyReportModalInsertSafetyReportMutation>(
      insertSafetyReportMutation,
    );

  useImperativeHandle<AddSafetyReportModalRef, AddSafetyReportModalRef>(
    ref,
    () => modal.current,
  );
  const drContext = useGCProjectDailyWorkLogContext();
  let subcontractorOptions: { key: string; value: string; label: string }[] =
    [];
  let tagOptions: {
    key: string;
    value: string;
    label: string;
    color_hex: string;
    name_id: string;
  }[] = [];
  const FormContent = withCustomSuspense(() => {
    subcontractorOptions =
      drContext.data.project_subcontractor_connection.edges.map(
        ({ node: projSub }) => {
          return {
            key: projSub.subcontractor_id,
            value: projSub.subcontractor_id,
            label: projSub.subcontractor.name,
          };
        },
      );

    drContext.data.tag_connection.edges.forEach((tag) => {
      tagOptions.push({
        key: tag.node.pk,
        value: tag.node.pk,
        label: tag.node.name.en,
        color_hex: tag.node.color_hex,
        name_id: tag.node.name.pk,
      });
    });
    return (
      <>
        <FModal.TextArea
          name="comments"
          props={{
            placeholder: "Enter Details",
            style: {
              minHeight: 200,
            },
          }}
          rules={[{ required: true, message: "Enter Details" }]}
        />
        <FModal.Select
          name="subcontractorId"
          props={{
            placeholder: "Select Subcontractors (optional)",
            options: subcontractorOptions,
            style: {
              width: 400,
            },
          }}
        />
        <FModal.Select
          name="tagIds"
          props={{
            mode: "multiple",
            placeholder: "Select Tags (optional)",
            options: tagOptions,
            style: {
              width: 400,
            },
          }}
        />
        <FModal.ImgDragUpload
          label="Upload Image(s)"
          required={false}
          name="images"
        />
      </>
    );
  });

  const handleInsertSafetyReportUpdater = (
    store: RecordSourceSelectorProxy<AddSafetyReportModalInsertSafetyReportMutation$data>,
  ) => {
    const insertSafetyReport = store.getRootField(
      "insert_daily_safety_report_one",
    );
    const conn1 = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectReportsDailyDayBody_daily_safety_report_connection",
    );
    const conn2 = ConnectionHandler.getConnection(
      store.getRoot(),
      "GCProjectReportsDaily_daily_safety_report_connection",
    );
    if (conn1 && insertSafetyReport) {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(insertSafetyReport, "node");
      ConnectionHandler.insertEdgeAfter(conn1, edge);
    }
    if (conn2 && insertSafetyReport) {
      const edge = store.create(uuid.v4(), "edge");
      edge.setLinkedRecord(insertSafetyReport, "node");
      ConnectionHandler.insertEdgeAfter(conn2, edge);
    }
  };

  const reset = () => {
    setInserting(false);
    modal.current?.form.resetFields();
    modal.current?.close();
  };

  return (
    <FModal
      ref={modal}
      title={"Add a Safety Report"}
      okText={"Submit"}
      destroyOnClose={false}
      confirmLoading={inserting}
      form={{ initialValues: { doesExpire: false } }}
      onOk={() => {
        const form = modal.current?.form;
        if (!form) return;
        form
          .validateFields()
          .then(async (v) => {
            setInserting(true);
            const imageFiles =
              v.images && v.images.length > 0
                ? (await uploadFiles(v.images.map((o) => o.originFileObj)))[0]
                : [];

            const safetyReportId = uuid.v4();
            const safetyReportCommentId = uuid.v4();
            const safetyReportCommentTextTranslationId = uuid.v4();

            await insertSafetyReport({
              variables: {
                object: {
                  id: safetyReportId,
                  project_id: projectId,
                  created_by_uid: auth.currentUser?.uid,
                  subcontractor_id: v.subcontractorId,
                  date: day.format("yyyy-MM-DD"),
                  images: imageFiles
                    ? {
                        data: imageFiles.map((image, index) => {
                          return {
                            id: uuid.v4(),
                            url: image.url,
                            description: "Safety Report Images",
                            created_by_user_id: auth.currentUser?.uid,
                          };
                        }),
                      }
                    : null,
                  daily_safety_report_comments: {
                    data: [
                      {
                        id: safetyReportCommentId,
                        created_by_uid: auth.currentUser?.uid,
                        text_translation: {
                          data: {
                            id: safetyReportCommentTextTranslationId,
                            lang: lang,
                            original: v.comments,
                            en: v.comments,
                          },
                        },
                      },
                    ],
                  },
                  daily_safety_report_tags: {
                    data: v.tagIds
                      ? v.tagIds.map((tagId) => ({
                          id: uuid.v4(),
                          tag_id: tagId,
                        }))
                      : [],
                  },
                },
              },
              updater: handleInsertSafetyReportUpdater,
            })
              .then(async (d) => {
                reset();
                modal.current?.close();
                message.success("Safety Report Created");
                setInserting(false);
              })
              .catch((error) => {
                message.error("Failed to create safety report");
                setInserting(false);
              });
          })
          .catch((info) => {
            console.log("Validate Failed:", info);
          });
      }}
    >
      <FormContent />
    </FModal>
  );
});

export default AddSafetyReportModal;
