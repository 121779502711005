import React from "react";
import GCReportsType from "src/common/types/manual/GCReportsType";
import ToolboxTalkDetail from "./GCProjectReportsTBTsDetail";
import ToolboxTalkTable from "src/domain-features/sitesafety/components/toolbox-talks/ToolboxTalkTable";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { Drawer } from "antd";
import { useSearchParams, useNavigate } from "react-router-dom";

interface GCProjectReportsTBTsProps extends GCReportsType {}

const GCProjectReportsTBTs: React.FunctionComponent<
  GCProjectReportsTBTsProps
> = (props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const reportId = searchParams.get("reportId");
  const navigate = useNavigate();

  return (
    <BasicWrapper>
      {reportId && (
        <Drawer
          width={"85%"}
          open={!!reportId}
          placement="right"
          onClose={() => navigate(-1)}
        >
          <ToolboxTalkDetail
            projectId={props.projectId}
            toolboxTalkId={reportId}
            key={reportId}
          />
        </Drawer>
      )}

      <ToolboxTalkTable
        excludedKeys={["project"]}
        where={{
          project_id: {
            _eq: props.projectId,
          },
        }}
        onRowClick={(reportId) => {
          setSearchParams({ reportId: reportId });
        }}
      />
    </BasicWrapper>
  );
};

export default GCProjectReportsTBTs;
