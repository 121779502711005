import { useParams } from "react-router-dom";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import CustomTabsRouted, {
  CustomTabsRoutedProps,
} from "../../../../common/components/layouts/tabs/CustomTabsRouted";

const GCObservationNavigation: React.FunctionComponent = () => {
  const tabs: CustomTabsRoutedProps["tabs"] = [
    {
      path: "charts",
      label: "Overview",
    },
    {
      path: "observations",
      label: "Observations",
    },
    {
      path: "audits",
      label: "Audits",
    },
  ];
  const projectId = useParams().projectId;

  const basePath = `/gce/projects/${projectId}/observations`;

  return (
    <BasicWrapper scrollable>
      <CustomTabsRouted tabs={tabs} basePath={basePath} />
    </BasicWrapper>
  );
};
export default GCObservationNavigation;
