import { useUserData } from "src/utility-features/authorization/UserDataProvider";
import GCAdminIncidents from "../../component/incident/GCAdminIncidents";
import withOfficeId, { OfficeProps } from "../../utils/withOfficeId";

const GCOfficeIncidents: React.FC<OfficeProps> = ({ officeId }) => {
  const { userData } = useUserData();
  const projWhere = { gc_office_id: { _eq: officeId } };
  return (
    <GCAdminIncidents
      organizationLevel="office"
      organizationId={officeId}
      projWhere={projWhere}
      optionsQueryVars={{
        projWhere,
        gcBuWhere: { id: { _is_null: true } },
        gcOfficeWhere: { id: { _is_null: true } },
        gcDivisionWhere: { id: { _is_null: true } },
      }}
      severityUserFilter={{ office_id: { _eq: officeId } }}
      toInsertWith={{
        gc_id: userData.employee?.general_contractor.id,
        office_id: officeId,
      }}
      gcEmployeeWhere={{ gc_offices: { gc_office_id: { _eq: officeId } } }}
    />
  );
};
export default withOfficeId(GCOfficeIncidents);
