import { Button, Space } from "antd";
import React, { useState } from "react";
import { useLazyLoadQuery } from "react-relay";
import CreateNewOrientationModal from "src/common/components/dialogs/CreateNewOrientationModal";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { GCCorporateOrientationsQuery } from "src/common/types/generated/relay/GCCorporateOrientationsQuery.graphql";
import OrientationTable from "src/domain-features/siteorientation/entryRoutes/gcDashboard/routes/settings/components/OrientationTable";
import { graphql } from "babel-plugin-relay/macro";
import { useNavigate } from "react-router-dom";
import withCorporateAdminWrapper, {
  CorporateAdminWrapperProps,
} from "../withCorporateAdminWrapper";

const GCCorporateOrientations: React.FC<CorporateAdminWrapperProps> = ({
  employee,
}) => {
  const data = useLazyLoadQuery<GCCorporateOrientationsQuery>(
    graphql`
      query GCCorporateOrientationsQuery($gcId: uuid!) {
        general_contractor_connection(where: { id: { _eq: $gcId } }) {
          edges {
            node {
              gc_projects {
                project {
                  pk: id @__clientField(handle: "pk")
                  name
                }
              }
            }
          }
        }
        orientation_connection(
          where: {
            deleted_at: { _is_null: true }
            general_contractor_id: { _eq: $gcId }
            project_id: { _is_null: true }
          }
          order_by: [{ order: asc }, { name: asc }]
          first: 10000
        )
          @connection(
            key: "GCCorporateOrientationsQuery_orientation_connection"
            filters: []
          ) {
          edges {
            node {
              id
              pk: id @__clientField(handle: "pk")
              name
              type
              created_at
              project_id
              user {
                name
                employee {
                  general_contractor {
                    name
                  }
                }
              }
            }
          }
        }
      }
    `,
    { gcId: employee.general_contractor.id },
  );
  const [showCreateModal, setShowCreateModal] = useState(false);
  const navigate = useNavigate();
  console.log(data);

  return (
    <BasicWrapper scrollable>
      <CreateNewOrientationModal
        visible={showCreateModal}
        gcProjectIds={data.general_contractor_connection.edges[0]?.node.gc_projects.map(
          (p) => p.project.pk,
        )}
        type="corporate"
        gcId={employee.general_contractor.id}
        onSuccess={(orientationId) => {
          setShowCreateModal(false);
          navigate(`/gce/corporate/settings/orientations/${orientationId}`);
        }}
        onCancel={() => setShowCreateModal(false)}
      />
      <div className="p-1" id="table">
        <OrientationTable
          isHigherLevelLogin
          title={() => (
            <Space direction="vertical" className="text-1.25">
              <h3>Orientations</h3>
              <Button
                type="primary"
                className="rounded-2 font-accent"
                onClick={() => {
                  setShowCreateModal(true);
                }}
              >
                Add Orientation
              </Button>
            </Space>
          )}
          excludeColumns={["inperson", "require", "registration", "tv"]}
          dataSource={data.orientation_connection.edges.map((o) => ({
            ...o.node,
            project_orientations: [],
          }))}
          onRow={(item: any) => {
            const orientationId = item.pk;
            navigate(`/gce/corporate/settings/orientations/${orientationId}`);
          }}
        />
      </div>
    </BasicWrapper>
  );
};

export default withCustomSuspense(
  withCorporateAdminWrapper(GCCorporateOrientations),
);
