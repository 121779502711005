import { GetAgcDashboardDataQuery } from "src/common/types/generated/apollo/graphQLTypes";
import {
  DashboardDataMapType,
  StateMapDataType,
  ZipMapDataType,
  setDemographicDataMaps,
} from "src/domain-features/siteorientation/utils/setDemographicDataMaps";

const getDashboardDataMaps = (data: GetAgcDashboardDataQuery | undefined) => {
  const veteranTypeMap: DashboardDataMapType = new Map();
  const ethnicityMap: DashboardDataMapType = new Map();
  const genderMap: DashboardDataMapType = new Map();
  const roleMap: DashboardDataMapType = new Map();
  const tradeMap: DashboardDataMapType = new Map();
  const cityMap: DashboardDataMapType = new Map();
  const yearsInConstructionMap: DashboardDataMapType = new Map();
  const yearsWithEmployerMap: DashboardDataMapType = new Map();
  const subsSet = new Set<string>();
  const zipMap: Map<string, ZipMapDataType> = new Map();
  const stateMap: Map<string, StateMapDataType> = new Map();
  let totalWorkers = 0;
  for (const user of data?.user || []) {
    const worker = user.worker;
    if (!worker) continue;
    totalWorkers += 1;
    const currRole = roleMap.get(worker.current_worker_role);
    if (currRole) {
      currRole.count += 1;
    } else {
      const role = data?.worker_role.find(
        (r) => r.value === worker.current_worker_role,
      );
      if (role)
        roleMap.set(role.value, {
          name: role.translation.en,
          count: 1,
        });
    }
    if (worker.subcontractor_id && !subsSet.has(worker.subcontractor_id)) {
      subsSet.add(worker.subcontractor_id);
    }
    if (worker.worker_title_id) {
      const currTrade = tradeMap.get(worker.worker_title_id);
      if (currTrade) {
        currTrade.count += 1;
      } else {
        const title = data?.worker_title.find(
          (r) => r.id === worker.worker_title_id,
        );
        if (title)
          tradeMap.set(title.id, {
            name: title.translation.en,
            count: 1,
          });
      }
    }
    setDemographicDataMaps({
      worker: worker,
      cityMap,
      ethnicityMap,
      genderMap,
      stateMap,
      veteranTypeMap,
      yearsInConstructionMap,
      yearsWithEmployerMap,
      zipMap,
    });
  }
  console.log(tradeMap);
  return {
    roleMap,
    veteranTypeMap,
    ethnicityMap,
    genderMap,
    cityMap,
    zipMap,
    stateMap,
    tradeMap,
    totalWorkers,
    subsCount: subsSet.size,
    yearsInConstructionMap,
    yearsWithEmployerMap,
  };
};
export default getDashboardDataMaps;
