/**
 * @generated SignedSource<<80a1388fa0606fe1569a97e222cc934f>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { safety_plan_bool_exp, safety_plan_set_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type UploadSafetyPlanModal_UpdateMutation$variables = {
  _set?: safety_plan_set_input | null | undefined;
  where: safety_plan_bool_exp;
};
export type UploadSafetyPlanModal_UpdateMutation$data = {
  readonly update_safety_plan: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type UploadSafetyPlanModal_UpdateMutation = {
  response: UploadSafetyPlanModal_UpdateMutation$data;
  variables: UploadSafetyPlanModal_UpdateMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "_set"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "_set",
        "variableName": "_set"
      },
      {
        "kind": "Variable",
        "name": "where",
        "variableName": "where"
      }
    ],
    "concreteType": "safety_plan_mutation_response",
    "kind": "LinkedField",
    "name": "update_safety_plan",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "UploadSafetyPlanModal_UpdateMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "UploadSafetyPlanModal_UpdateMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "b02c1e56f1349cb4677d6a87033b71ec",
    "id": null,
    "metadata": {},
    "name": "UploadSafetyPlanModal_UpdateMutation",
    "operationKind": "mutation",
    "text": "mutation UploadSafetyPlanModal_UpdateMutation(\n  $_set: safety_plan_set_input\n  $where: safety_plan_bool_exp!\n) {\n  update_safety_plan(_set: $_set, where: $where) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "1e061403a310f8c7dc46c7ba4be410a5";

export default node;
