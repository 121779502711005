/**
 * @generated SignedSource<<5161d015f7ca0f2c50d49307728e37ab>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { user_notification_insert_input } from './types';
export * from './types';
import { ConcreteRequest, Mutation } from 'relay-runtime';
export type GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation$variables = {
  objects: ReadonlyArray<user_notification_insert_input>;
};
export type GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation$data = {
  readonly insert_user_notification: {
    readonly affected_rows: number;
  } | null | undefined;
};
export type GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation = {
  response: GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation$data;
  variables: GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "objects"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "objects",
        "variableName": "objects"
      }
    ],
    "concreteType": "user_notification_mutation_response",
    "kind": "LinkedField",
    "name": "insert_user_notification",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "affected_rows",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation",
    "selections": (v1/*: any*/),
    "type": "mutation_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "86151894d722c8354b742808bd80c264",
    "id": null,
    "metadata": {},
    "name": "GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation",
    "operationKind": "mutation",
    "text": "mutation GCUsersToBeNotifiedForRejectedCerts_InsertUsersNotificationMutation(\n  $objects: [user_notification_insert_input!]!\n) {\n  insert_user_notification(objects: $objects) {\n    affected_rows\n  }\n}\n"
  }
};
})();

(node as any).hash = "3274938cb15af873094c56b8a760fc9e";

export default node;
