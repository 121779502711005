import { AntCloudOutlined, InboxOutlined } from "@ant-design/icons";
import Button from "src/common/components/general/Button";
import { Form, Upload, Button as AntdButton, UploadFile, message } from "antd";
import React, { FC } from "react";

import * as xlsx from "xlsx";
import * as uuid from "uuid";
import downloadFromUrl from "src/common/functions/downloadFromUrl";
import { VerifyAndAddJHAsQuery$data } from "src/common/types/generated/relay/VerifyAndAddJHAsQuery.graphql";
import {
  task_insert_input,
  task_step_insert_input,
} from "src/common/types/generated/relay/VerifyAndAddJHAs_Insert_Task_Mutation.graphql";
import { UploadChangeParam } from "antd/lib/upload";
import readAsArrayBuffer from "src/common/functions/readAsArrayBuffer";

const charToECMId: { [key: string]: string } = {
  e: "39ab97a5-47ac-4e5d-b287-21c2cb016c16",
  c: "05660c6a-c370-4c82-86a9-e558772d7d56",
  m: "57774dd6-3e26-45aa-a7cd-c3cb518a75f9",
};

const ecmStringToIds = (ecm: any) => {
  if (typeof ecm !== "string") return [];
  return ecm
    .split("/")
    .map((value) => charToECMId[value.toLowerCase()])
    .filter((v) => !!v);
};

interface ExcelJHAStep {
  name: string;
  hazards: Array<string>;
  controls: Array<string>;
  ecms: Array<string[]>;
}

interface ExcelJHA {
  name: string;
  steps: Array<ExcelJHAStep>;
  ppe: Array<string>;
  permits: Array<string>;
  warnings: Array<string>;
}

const addIfNonEmpty = (
  array: Set<string>,
  sheet: xlsx.WorkSheet,
  cell: string,
) => {
  const value = sheet[cell]?.w;

  if (typeof value === "string" && value.length > 0) array.add(value);
};

const readDataFromSheet = (
  sheet: xlsx.WorkSheet,
  isTurnerProject: boolean,
): ExcelJHA => {
  const data: ExcelJHA = {
    name: sheet.D2?.w ?? "Unnamed",
    steps: [],
    ppe: [],
    permits: [],
    warnings: [],
  };

  const firstRow = isTurnerProject ? 25 : 17;
  const controlCol = isTurnerProject ? "F" : "E";

  for (let row = firstRow; row < 65535; row++) {
    const stepName = sheet["C" + row]?.w;
    if (stepName) {
      if (
        data.steps.length === 0 ||
        data.steps[data.steps.length - 1].name !== stepName
      )
        data.steps.push({
          name: stepName,
          hazards: [],
          controls: [],
          ecms: [],
        });
    }

    const hazard = sheet["D" + row]?.w;
    const control = sheet[controlCol + row]?.w;
    const ecm = isTurnerProject ? sheet["E" + row]?.w : "";

    if (!stepName && !hazard && !control) {
      break;
    }
    if (hazard && control) {
      const step = data.steps[data.steps.length - 1];
      step.hazards.push(hazard);
      step.controls.push(control);
      step.ecms.push(ecmStringToIds(ecm));
    }
  }

  const ppe = new Set<string>();
  addIfNonEmpty(ppe, sheet, "E4");
  for (let row = 5; row <= 10; row++) {
    addIfNonEmpty(ppe, sheet, "C" + row);
    addIfNonEmpty(ppe, sheet, "D" + row);
    addIfNonEmpty(ppe, sheet, "E" + row);
  }

  data.ppe = [...ppe];

  const permits = new Set<string>();
  addIfNonEmpty(permits, sheet, "E11");
  for (let row = 12; row <= 14; row++) {
    addIfNonEmpty(permits, sheet, "C" + row);
    addIfNonEmpty(permits, sheet, "D" + row);
    addIfNonEmpty(permits, sheet, "E" + row);
  }
  data.permits = [...permits];
  return data;
};

const sheetNamesToIngnore = [
  "example jha for reference only",
  "example jha nonturner",
  "data",
];

const readDataFromWorkBook = (
  book: xlsx.WorkBook,
  single: boolean,
  isTurnerProject: boolean,
) => {
  const res: ExcelJHA[] = [];
  for (const sheetName of Object.keys(book.Sheets)) {
    if (!sheetNamesToIngnore.includes(sheetName.toLowerCase()))
      res.push(readDataFromSheet(book.Sheets[sheetName], isTurnerProject));
    if (single) break;
  }
  return res;
};

export interface JHAData {
  id: string;
  name: string;
  taskInsertInput: task_insert_input;
  valuesPPE: Array<{ id: string; name: string }>;
  valuesPermit: Array<{ id: string; name: string }>;
}

interface AddJHAExcelUploadFormProps {
  isTurnerProject: boolean;
  requestId: string;
  loading: boolean;
  single?: boolean;
  ppeData: VerifyAndAddJHAsQuery$data["ppe_type_connection"]["edges"];
  permitData: VerifyAndAddJHAsQuery$data["permit_type_connection"]["edges"];
  fileId?: string;
  setJHAList: React.Dispatch<React.SetStateAction<Array<JHAData>>>;
}

const AddJHAExcelUploadForm: FC<AddJHAExcelUploadFormProps> = ({
  isTurnerProject,
  requestId,
  loading,
  ppeData,
  single,
  permitData,
  fileId,
  setJHAList,
}) => {
  const [form] = Form.useForm<{
    uploadFile: UploadChangeParam<UploadFile<any>>;
  }>();
  return (
    <Form
      form={form}
      onFinish={async (formFields) => {
        if (!formFields.uploadFile) {
          message.error("Please select file");
          return;
        }
        const currentFiles = formFields.uploadFile.fileList;
        if (!currentFiles || !currentFiles[0].originFileObj)
          throw new Error("File is not selected");

        let jhaList: JHAData[] = [];
        for (const file of currentFiles) {
          if (!file || !file.originFileObj) throw new Error("File not found");

          const strData = await readAsArrayBuffer(file.originFileObj);
          const workbook = xlsx.read(strData, { type: "array" });

          const res = readDataFromWorkBook(workbook, !!single, isTurnerProject);

          const jha = res.map((data) => {
            const taskId = uuid.v4();
            const stepData: task_step_insert_input[] = data.steps.map(
              (step, step_index) => ({
                description: {
                  data: {
                    en: step.name,
                    original: step.name,
                  },
                },
                sort_index: 10000 * step_index,
                task_step_hazards: {
                  data: step.hazards.map((hazard, index) => ({
                    description: {
                      data: {
                        en: hazard,
                        original: hazard,
                      },
                    },
                    sort_index: 10000 * index,
                    control: {
                      data: {
                        en: step.controls[index],
                        original: step.controls[index],
                      },
                    },
                    task_hazard_ecm_types: {
                      data: step.ecms[index].map((val) => ({
                        ecm_type_id: val,
                      })),
                    },
                  })),
                },
              }),
            );
            // console.log(stepData);
            const taskInsertInput: task_insert_input = {
              id: taskId,
              description: {
                data: {
                  en: data.name,
                  original: data.name,
                },
              },
              creation_request_id: requestId,
              document_id: fileId,
              task_steps: {
                data: stepData,
              },
            };

            const valuesPPE: Array<{
              name: string;
              id: string;
            }> = [];

            for (let i = 0; i < data.ppe.length; i++) {
              for (let i1 = 0; i1 < ppeData.length; i1++) {
                // console.log(data.ppe[i]);
                if (
                  data.ppe[i].toLowerCase() ==
                    ppeData[i1].node.name_text.toLowerCase() ||
                  ((data.ppe[i].toLowerCase() == "hy-vis vest" ||
                    data.ppe[i].toLowerCase() == "reflective") &&
                    ppeData[i1].node.name_text == "Reflective/Hy-Vis Vest")
                ) {
                  valuesPPE.push({
                    id: ppeData[i1].node.pk,
                    name: ppeData[i1].node.name_text,
                  });
                }
              }
            }
            // console.log(ppeData);
            // console.log(valuesPPE);
            //TODO: insert PPEs
            // //permits

            const valuesPermit: Array<{
              name: string;
              id: string;
            }> = [];
            for (let i = 0; i < data.permits.length; i++) {
              for (let i1 = 0; i1 < permitData.length; i1++) {
                if (
                  data.permits[i].toLowerCase() ==
                  permitData[i1].node.name_text.toLowerCase()
                ) {
                  valuesPermit.push({
                    id: permitData[i1].node.pk,
                    name: permitData[i1].node.name_text,
                  });
                }
              }
            }

            return {
              id: uuid.v4(),
              name: data.name,
              taskInsertInput,
              valuesPPE,
              valuesPermit,
            };
          });

          jhaList = jhaList.concat(jha);
        }
        setJHAList(jhaList);
      }}
    >
      <div className="mb-0.5 mt-2 flex justify-center">
        <Button
          loading={loading}
          label={
            isTurnerProject
              ? "Download Turner's blank JHA template"
              : "Download SiteForm blank JHA Template"
          }
          onClick={() => {
            downloadFromUrl(
              isTurnerProject
                ? "https://firebasestorage.googleapis.com/v0/b/siteform-3170b.appspot.com/o/assets%2FSiteForm%20JHA%20-%20Blank%20for%20Upload%20TURNER%20ECM.xlsx?alt=media&token=b085e8f9-d3ad-4a9e-8c63-5c1587d77b46"
                : "https://firebasestorage.googleapis.com/v0/b/siteform-3170b.appspot.com/o/assets%2FSiteForm%20JHA%20-%20Blank%20for%20Upload.xlsx?alt=media&token=2733e8ed-0927-423f-9472-f2d613c1da94",
            );
          }}
        />
      </div>
      <p className="mb-2">
        Download the spreadsheet template, fill it out with JHA information, and
        then upload it in the box below.
      </p>
      <p />
      <Form.Item name="uploadFile">
        <Upload.Dragger
          maxCount={5}
          customRequest={() => true}
          accept=".xlsx"
          iconRender={() => <AntCloudOutlined />}
          name="files"
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p>
            After you input the information from the customer provided JHA to
            the SiteForm JHA excel file, click here to upload or drag and drop
            the file here.
            <br />
            Note - if there are multiple JHAs in the file provided by our
            customer, add them on separate sheets of the excel file.
          </p>
        </Upload.Dragger>
      </Form.Item>
      <Form.Item name="submit">
        <AntdButton
          loading={loading}
          className="mt-1 rounded-2 font-accent"
          type="primary"
          htmlType="submit"
        >
          {single ? "Upload the JHA" : "Upload the JHAs"}
        </AntdButton>
      </Form.Item>
    </Form>
  );
};
export default AddJHAExcelUploadForm;
