import React, { FC, useState, useRef } from "react";
import BasicWrapper from "src/common/components/layouts/BasicWrapper";
import { DataScrollTableRef } from "src/common/components/tables/basic/DataScrollTable";
import { auth } from "src/common/functions/firebase";
import useAuthUser from "src/common/hooks/useAuthUser";
import { SCProjectQuery$data } from "src/common/types/generated/relay/SCProjectQuery.graphql";
import AddSubcontractorProjectTeamModal from "src/domain-features/sitesafety/siteSafetyPlan/components/AddSubcontractorProjectTeamModal";
import ProjectSubcontractorEmployeeTable from "src/domain-features/sitesafety/siteSafetyPlan/components/ProjectSubcontractorEmployeeTable";

interface SCProjectPocsProps {
  projectId: string;
  subcontractorId: string;
}
const SCProjectPocs: FC<SCProjectPocsProps> = ({
  projectId,
  subcontractorId,
}) => {
  const projectSubcontractorEmployeeTableRef = useRef<DataScrollTableRef>(null);
  const [
    openAddSubcontractorProjectTeamModal,
    setOpenAddSubcontractorProjectTeamModal,
  ] = useState(false);
  return (
    <BasicWrapper scrollable>
      <AddSubcontractorProjectTeamModal
        modalClose={() => {
          setOpenAddSubcontractorProjectTeamModal(false);
        }}
        modalVisible={openAddSubcontractorProjectTeamModal}
        subcontractorId={subcontractorId}
        projectId={projectId}
        onSubmit={() => {
          setOpenAddSubcontractorProjectTeamModal(false);
          // projectSubcontractorEmployeeTableRef.current?.refetch();
        }}
      />
      <div className="mt-2">
        <ProjectSubcontractorEmployeeTable
          ref={projectSubcontractorEmployeeTableRef}
          where={{
            project_id: { _eq: projectId },
            subcontractor_employee: {
              subcontractor_id: { _eq: subcontractorId },
            },
          }}
          loading={false}
          topBarButtons={[
            {
              onClick: () => {
                setOpenAddSubcontractorProjectTeamModal(true);
              },
              label: "+ Add",
            },
          ]}
        />
      </div>
    </BasicWrapper>
  );
};
export default SCProjectPocs;
