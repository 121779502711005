/**
 * @generated SignedSource<<e36cfe546a75e997f56b382affd17783>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import type { permit_bool_exp } from './types';
export * from './types';
import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type PermitTableTotalRefetchableQuery$variables = {
  where: permit_bool_exp;
};
export type PermitTableTotalRefetchableQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"PermitTable_total">;
};
export type PermitTableTotalRefetchableQuery = {
  response: PermitTableTotalRefetchableQuery$data;
  variables: PermitTableTotalRefetchableQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "where"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "where",
    "variableName": "where"
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "PermitTableTotalRefetchableQuery",
    "selections": [
      {
        "args": (v1/*: any*/),
        "kind": "FragmentSpread",
        "name": "PermitTable_total"
      }
    ],
    "type": "query_root",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "PermitTableTotalRefetchableQuery",
    "selections": [
      {
        "alias": "allPermitsConnection",
        "args": (v1/*: any*/),
        "concreteType": "permitConnection",
        "kind": "LinkedField",
        "name": "permit_connection",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "permitEdge",
            "kind": "LinkedField",
            "name": "edges",
            "plural": true,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "permit",
                "kind": "LinkedField",
                "name": "node",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "id",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "66d968d6874114a2fd54a8e3d67f5124",
    "id": null,
    "metadata": {},
    "name": "PermitTableTotalRefetchableQuery",
    "operationKind": "query",
    "text": "query PermitTableTotalRefetchableQuery(\n  $where: permit_bool_exp!\n) {\n  ...PermitTable_total_3FC4Qo\n}\n\nfragment PermitTable_total_3FC4Qo on query_root {\n  allPermitsConnection: permit_connection(where: $where) {\n    edges {\n      node {\n        id\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "7b26f241d33b167f95fe0b3470272736";

export default node;
