import { useSuspenseQuery } from "@apollo/client";
import { useParams } from "react-router-dom";
import AnonProjectWorkerTable, {
  ColumnKeys,
} from "src/common/components/tables/AnonProjectWorkerTable";
import {
  GetGcAdminWorkersDocument,
  GetGcAdminWorkersQuery,
  GetGcAdminWorkersQueryVariables,
} from "src/common/types/generated/apollo/graphQLTypes";
import { useUserData } from "src/utility-features/authorization/UserDataProvider";

const GCCorporateWorkers = () => {
  const { userData } = useUserData();
  if (!userData.employee) throw new Error("employee not found");
  const { data } = useSuspenseQuery<
    GetGcAdminWorkersQuery,
    GetGcAdminWorkersQueryVariables
  >(GetGcAdminWorkersDocument, {
    variables: {
      gcId: userData.employee.general_contractor.id,
      projWhere: {
        general_contractors: {
          general_contractor_id: {
            _eq: userData.employee.general_contractor.id,
          },
        },
      },
    },
  });
  const excludedKeys: ColumnKeys[] = ["crew"];
  if (!data.orientation.length) excludedKeys.push("corporateDate");
  return (
    <div className="bg-white h-full w-full">
      <AnonProjectWorkerTable
        excludedKeys={excludedKeys}
        gcId={userData.employee.general_contractor.id}
        projectsOptions={data.project}
        where={{
          project: {
            general_contractor_id: {
              _eq: userData.employee.general_contractor.id,
            },
          },
          is_last: { _eq: true },
        }}
      />
    </div>
  );
};
export default GCCorporateWorkers;
