import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useState } from "react";
import { ConnectionHandler } from "relay-runtime";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { SubBlockEditModalMutation } from "src/common/types/generated/relay/SubBlockEditModalMutation.graphql";
import { SubBlockEditModal_deleteSubBlockMutation } from "src/common/types/generated/relay/SubBlockEditModal_deleteSubBlockMutation.graphql";
import { SubBlockType } from "../utilities/sitedeliveryTypes";
import SitedeliveryTimeBlockModal, {
  SitedeliveryTimeBlockModalValues,
} from "./SitedeliveryTimeBlockModal";

const updateSubBlockMutation = graphql`
  mutation SubBlockEditModalMutation(
    $sub_block_id: uuid!
    $updateSubBlockSet: project_delivery_sub_block_set_input!
    $insertSubBlockSubObjects: [sub_block_subcontractor_insert_input!]!
    $insertSubBlockCalendarObjects: [sub_block_calendar_insert_input!]!
    $deleteSubBlockCalendarWhere: sub_block_calendar_bool_exp!
    $deleteSubBlockSubWhere: sub_block_subcontractor_bool_exp!
  ) {
    update_project_delivery_sub_block_by_pk(
      pk_columns: { id: $sub_block_id }
      _set: $updateSubBlockSet
    ) {
      id
      pk: id @__clientField(handle: "pk")
      start_time
      end_time
      weekday
    }
    insert_sub_block_subcontractor(objects: $insertSubBlockSubObjects) {
      returning {
        subcontractor {
          id
          pk: id @__clientField(handle: "pk")
          name
        }
      }
    }
    insert_sub_block_calendar(objects: $insertSubBlockCalendarObjects) {
      returning {
        calendar {
          id
          pk: id @__clientField(handle: "pk")
          name {
            en
          }
          color_hex
        }
      }
    }
    delete_sub_block_calendar(where: $deleteSubBlockCalendarWhere) {
      returning {
        id
      }
    }
    delete_sub_block_subcontractor(where: $deleteSubBlockSubWhere) {
      returning {
        id
      }
    }
  }
`;

const deleteSubBlockMutation = graphql`
  mutation SubBlockEditModal_deleteSubBlockMutation($id: uuid!) {
    delete_sub_block_subcontractor(where: { sub_block_id: { _eq: $id } }) {
      returning {
        id
      }
    }
    delete_sub_block_calendar(where: { sub_block_id: { _eq: $id } }) {
      returning {
        id
      }
    }

    delete_project_delivery_sub_block(where: { id: { _eq: $id } }) {
      returning {
        id
      }
    }
  }
`;

interface SubBlockEditModalProps {
  subBlock: SubBlockType;
  modalClose: () => void;
  modalVisible: boolean;
}

const SubBlockEditModal: React.FC<SubBlockEditModalProps> = (props) => {
  // const subBlockId = props.subBlockId;
  // const data = useLazyLoadQuery<SubBlockEditModalQuery>(query, {
  //   subBlockId,
  // });

  // const subBlockData = data.project_delivery_sub_block_connection.edges[0].node;

  const [loading, setLoading] = useState(false);
  const subBlockData = props.subBlock;

  const [updateSubBlock] = useAsyncMutation<SubBlockEditModalMutation>(
    updateSubBlockMutation,
  );

  const onSubmit = async (values: SitedeliveryTimeBlockModalValues) => {
    setLoading(true);

    try {
      const insertSubBlockCalendarIds = values.calendarIds.filter(
        (c) => !subBlockData.calendarIds.find((sbc) => sbc === c),
      );
      const insertSubBlockSubIds = values.subcontractorIds.filter(
        (s) => !(subBlockData.subcontractorExpected.id === s),
      );
      const deleteSubBlockCalendarIds = subBlockData.calendarIds.filter(
        (sbd) => !values.calendarIds.find((c) => c === sbd),
      );
      const deleteSubBlockSubIds = [subBlockData.subcontractorExpected]
        .filter(
          (sbd) =>
            !values.subcontractorIds.find((s) => {
              return s === sbd.id;
            }),
        )
        .map((sbd) => sbd.id);

      await updateSubBlock({
        variables: {
          sub_block_id: subBlockData.id,
          updateSubBlockSet: {
            weekday: values.weekday,
            start_time: dayjs(values.startTime).format("HH:mm"),
            end_time: dayjs(values.endTime).format("HH:mm"),
          },
          deleteSubBlockCalendarWhere: {
            sub_block_id: { _eq: subBlockData.id },
            calendar_id: { _in: deleteSubBlockCalendarIds },
          },
          deleteSubBlockSubWhere: {
            sub_block_id: { _eq: subBlockData.id },
            subcontractor_id: { _in: deleteSubBlockSubIds },
          },
          insertSubBlockCalendarObjects: insertSubBlockCalendarIds.map(
            (ic) => ({
              calendar_id: ic,
              sub_block_id: subBlockData.id,
            }),
          ),
          insertSubBlockSubObjects: insertSubBlockSubIds.map((is) => ({
            subcontractor_id: is,
            sub_block_id: subBlockData.id,
          })),
        },
        updater: (store, data) => {
          const block = store.getRootField(
            "update_project_delivery_sub_block_by_pk",
          );
          if (!block) {
            // record was deleted on server
            // TODO: delete it from connection in cache
            return;
          }

          // update calendars
          const calendars = block.getLinkedRecords("calendars") || [];
          let newCalendars = calendars;
          // use data to simplify access (we can do the same logic useing object from cache)
          const removedCalendars = data?.delete_sub_block_calendar?.returning;
          if (removedCalendars) {
            newCalendars = calendars.filter((block_cal) => {
              const calId = block_cal.getDataID();
              return !removedCalendars.find((c) => c.id === calId);
            });
          }
          const insertSubBlockCalendar = store
            .getRootField("insert_sub_block_calendar")
            ?.getLinkedRecords("returning");
          if (insertSubBlockCalendar) {
            newCalendars = [...newCalendars, ...insertSubBlockCalendar];
          }
          if (newCalendars !== calendars) {
            block.setLinkedRecords(newCalendars, "calendars");
          }

          // update subcontractors
          const subcontractors = block.getLinkedRecords("subcontractors") || [];
          let newSubs = subcontractors;
          const removedSubs = data?.delete_sub_block_subcontractor?.returning;
          if (removedSubs) {
            newSubs = newSubs.filter((block_sub) => {
              const id = block_sub.getDataID();
              return !removedSubs.find((rem) => rem.id === id);
            });
          }
          const insertSubBlockSubs =
            store
              .getRootField("insert_sub_block_subcontractor")
              ?.getLinkedRecords("returning") || [];
          if (insertSubBlockSubs) {
            newSubs = [...newSubs, ...insertSubBlockSubs];
          }
          if (newSubs !== subcontractors) {
            block.setLinkedRecords(newSubs, "subcontractors");
          }
        },
      });
    } finally {
      setLoading(false);
    }
  };

  const [deleteSubBlock] =
    useAsyncMutation<SubBlockEditModal_deleteSubBlockMutation>(
      deleteSubBlockMutation,
    );
  const onDelete = async (subBlockId: string) => {
    setLoading(true);
    try {
      await deleteSubBlock({
        variables: { id: subBlockId },
        updater: (store) => {
          const deleteSubBlock = store.getRootField(
            "delete_project_delivery_sub_block",
          );
          const conn = ConnectionHandler.getConnection(
            store.getRoot(),
            "GCProjectCalendarSitedeliverySubBlocks_project_delivery_sub_block_connection",
          );
          if (conn) {
            deleteSubBlock.getLinkedRecords("returning").forEach((s) => {
              ConnectionHandler.deleteNode(conn, s.getDataID());
            });
          }
        },
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <SitedeliveryTimeBlockModal
      loading={loading}
      modalClose={props.modalClose}
      modalVisible={props.modalVisible}
      onSubmit={onSubmit}
      onDelete={onDelete}
      timeBlock={{
        subBlockId: subBlockData.id,
        calendarsIds: subBlockData.calendarIds,
        subcontractorIds: [subBlockData.subcontractorExpected.id],
        weekdayMask: subBlockData.weekday,
        time: {
          start: dayjs(subBlockData.from),
          end: dayjs(subBlockData.to),
        },
      }}
    />
  );
};

export default SubBlockEditModal;
