import React, { PropsWithChildren, useState } from "react";
import CustomSuspense from "../general/CustomSuspense";
import NavigationMenu, {
  NavigationMenuItems,
  UtilityMenuItems,
} from "../navigation-menu/NavigationMenu";
import NavigationPlaceholder from "../NavigationPlaceholder";
import clsx from "clsx";

export type NavigationMenuProps = {
  navigationItems: NavigationMenuItems;
  utilityItems?: UtilityMenuItems;
};

interface NavigationWrapperProps {
  menu: NavigationMenuProps;
}

const NavigationWrapper: React.FunctionComponent<
  PropsWithChildren<NavigationWrapperProps>
> = (props) => {
  const [menuHovered, setMenuHovered] = useState(false);

  const handleMenuMouseEnter = () => {
    setMenuHovered(true);
  };
  const handleMenuMouseLeave = () => {
    setMenuHovered(false);
  };

  return (
    <div
      className={`flex flex-row items-center w-full h-full bg-suplementary-1`}
    >
      <NavigationMenu
        {...{
          navigationItems: props.menu.navigationItems,
          utilityItems: props.menu.utilityItems,
          onMouseEnter: handleMenuMouseEnter,
          onMouseLeave: handleMenuMouseLeave,
          expanded: menuHovered,
        }}
      />

      <div className={`h-full w-full flex-1 overflow-hidden relative`}>
        <div
          className={clsx(
            `h-full transition-all outline outline-semantic-negative flex items-start absolute left-0 top-0`,
            menuHovered ? "w-[calc(100%+13rem)]" : "w-full",
          )}
        >
          <CustomSuspense>
            {props.children ?? <NavigationPlaceholder />}
          </CustomSuspense>
        </div>
      </div>
    </div>
  );
};

export default NavigationWrapper;
