import { message, notification } from "antd";
import { graphql } from "babel-plugin-relay/macro";
import dayjs from "dayjs";
import React, { useState, useMemo } from "react";
import withCustomSuspense from "src/common/components/general/withCustomSuspense";
import { deleteWorkerCertMutation } from "src/common/components/tables/WorkerCertificationTable";
import useAsyncMutation from "src/common/hooks/useAsyncMutation";
import { auth } from "src/common/functions/firebase";
import { Order_By } from "src/common/types/generated/apollo/graphQLTypes";
import { WorkerCertificationTableDeleteCertMutation } from "src/common/types/generated/relay/WorkerCertificationTableDeleteCertMutation.graphql";
import {
  WorkerProfileMutation,
  user_set_input,
  worker_set_input,
} from "src/common/types/generated/relay/WorkerProfileMutation.graphql";
import { WorkerProfileQuery } from "src/common/types/generated/relay/WorkerProfileQuery.graphql";
import { WorkerProfileUpsertImageMutation } from "src/common/types/generated/relay/WorkerProfileUpsertImageMutation.graphql";
import { WorkerProfile_Update_Project_Worker_Mutation } from "src/common/types/generated/relay/WorkerProfile_Update_Project_Worker_Mutation.graphql";
import * as uuid from "uuid";
import useAuthUser from "src/common/hooks/useAuthUser";
import WorkerProfileUI, {
  WorkerProfileUIProps,
} from "../../../../WorkerProfileUI";
import useUpsertAndDeletePreviousSub from "src/common/api/relay/mutationHooks/useUpsertAndDeletePreviousSub";
import { useLazyLoadQuery, useRelayEnvironment } from "react-relay/hooks";
import { commitMutation } from "relay-runtime";
import { useNavigate, useParams } from "react-router-dom";
import { UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation } from "src/common/types/generated/relay/UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation.graphql";
import updateProjectWorkersMutation from "src/common/api/relay/mutations/UpdateProjectWorkersForWorkerRegistrationWithHardHat";
import { WorkerProfileUpdateProjectWorkerMutation } from "src/common/types/generated/relay/WorkerProfileUpdateProjectWorkerMutation.graphql";
import { AddWorkerDrugTestModalDataProps } from "src/common/components/dialogs/AddWorkerDrugTestModal";

const workerProfileQueryNode = graphql`
  query WorkerProfileQuery(
    $id: uuid!
    $order: order_by!
    $workerDrugtestWhere: worker_drug_test_bool_exp!
    $workerProjectsWhere: project_worker_bool_exp!
  ) {
    worker_title_connection(order_by: { translation: { en: asc } }) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          translation {
            en
            es
            pt
          }
        }
      }
    }
    worker_role_connection(order_by: { translation: { en: asc } }) {
      edges {
        node {
          value
          translation {
            en
            es
            pt
          }
        }
      }
    }
    orientation_connection(
      where: {
        deleted_at: { _is_null: true }
        type: { _eq: "corporate" }
        project_orientations: { required_by_all_workers: { _eq: true } }
        orientation_results: { user_id: { _eq: $id } }
      }
    ) {
      edges {
        node {
          general_contractor_id
          general_contractor {
            name
          }
          project_orientations(
            where: { required_by_all_workers: { _eq: true } }
          ) {
            project_id
          }
          orientation_results(
            where: { user_id: { _eq: $id }, status: { _eq: "completed" } }
            order_by: [{ completed_at: desc }, { created_at: desc }]
          ) {
            id
            completed_at
            expired_at
          }
        }
      }
    }
    worker_connection(
      where: { uid: { _eq: $id } }
      order_by: { user: { name: $order } }
    ) {
      edges {
        node {
          id
          pk: id @__clientField(handle: "pk")
          current_worker_role
          worker_title_id
          hire_date
          user {
            name
            email
            created_password
            allow_mobile_login
            invites(
              where: {
                claiming_account: { _eq: true }
                accepted_at: { _is_null: true }
              }
              order_by: { created_at: desc }
              limit: 1
            ) {
              id
              pk: id @__clientField(handle: "pk")
              accepted_at
            }
            birth_date
            phone_number
            phone_number_privacy_setting {
              employer
            }
            email_privacy_setting {
              employer
            }
            project_privacy_setting {
              employer
            }
            profile_picture_id
            universal_orientations(
              limit: 1
              order_by: { universal_orientated_at: desc }
            ) {
              universal_orientated_at
            }
            profile_picture {
              md_url
              url
            }
            task_signatures(
              distinct_on: task_id
              order_by: { task_id: asc, created_at: desc }
            ) {
              task {
                description {
                  en
                }
                id
                pk: id @__clientField(handle: "pk")
              }
            }
          }
          subcontractor_id
          worker_role {
            value
          }
          worker_certifications {
            id
            pk: id @__clientField(handle: "pk")
            certification {
              name
            }
            images {
              sm_url
              url
            }
            expires_on
          }
          processing_cert_count: certificates_to_verify(
            where: {
              verified_at: { _is_null: true }
              document: { _eq: "certificate" }
              status: { _eq: "submitted" }
            }
            order_by: { id: $order }
          ) {
            id
          }
          worker_drug_tests(
            order_by: { drug_test_date: desc }
            where: $workerDrugtestWhere
          ) {
            name
            status
            entered_through_user {
              name
            }
            drug_test_date
          }

          worker_projects(where: $workerProjectsWhere) {
            id
            pk: id @__clientField(handle: "pk")
            archived_at
            deleted_at
            title_id
            worker_role
            hard_hat_number
            permits_aggregate(
              distinct_on: permit_id
              where: { permit: { current_status: { _neq: "pending" } } }
            ) {
              aggregate {
                count
              }
            }

            daily_work_log_workers(
              where: { daily_work_log: { submitted_at: { _is_null: false } } }
              order_by: { daily_work_log: { date: desc } }
            ) {
              hours
              daily_work_log {
                date
              }
            }

            toolbox_talks(order_by: { created_at: desc }) {
              id
              created_at
              toolbox_talk {
                description {
                  en
                }
              }
            }

            reports(order_by: { created_at: desc }) {
              id
              created_at
            }

            archived_by_user {
              name
            }
            hard_hat_number
            user_orientation {
              orientated_at
              in_person_orientated_at
              completed_at
            }
            project {
              name
              pk: id @__clientField(handle: "pk")
              timezone
              in_person_orientation
              orientation_project_id
              orientation_project {
                # it's a project specific orientation so there will be only one entry for it in project_orientation table
                orientations(
                  where: {
                    project_orientations: {
                      required_by_all_workers: { _eq: true }
                    }
                  }
                ) {
                  id
                }
              }
              general_contractor_id
              general_contractor {
                gc_orientations(
                  where: {
                    project_id: { _is_null: true }
                    project_orientations: {
                      required_by_all_workers: { _eq: true }
                    }
                  }
                ) {
                  id
                  project_orientations(
                    where: { required_by_all_workers: { _eq: true } }
                  ) {
                    project_id
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

type WorkerProfileProps = {
  workerId: string;
  subcontractorId: string;
  onCrossClick?: () => void;
  refetchWorkersList: () => void;
} & (
  | {
      type: "gc";
      projWorkerId: string;
      onSubChangeSuccess: (newSubId: string) => void;
      drugtestGCProps: AddWorkerDrugTestModalDataProps["gcProps"];
      projectSubcontractors: Array<{
        key: string;
        value: string;
        label: string;
      }>;
      gcName: string;
      projectId: string;
      onUpdateWorkerName: (newName: string) => void;
    }
  | {
      type: "sub";
      projects: { id: string; name: string }[];
    }
);

//only set is_last:false for those projects which had a proj-worker-sub trio for this sub( i.e. only for those projects, worker had worked under this sub before)
const updateMulptipleProjectsSingleWorkerMutation = graphql`
  mutation WorkerProfile_Update_Project_Worker_Mutation(
    $projectIds: [uuid!]
    $workerId: uuid!
    $subId: uuid!
  ) {
    u1: update_project_worker(
      where: {
        worker_id: { _eq: $workerId }
        project_id: { _in: $projectIds }
        subcontractor_id: { _neq: $subId }
      }
      _set: { is_last: false }
    ) {
      affected_rows
    }
    u2: update_project_worker(
      where: {
        project_id: { _in: $projectIds }
        worker_id: { _eq: $workerId }
        subcontractor_id: { _eq: $subId }
      }
      _set: { is_last: true }
    ) {
      affected_rows
    }
  }
`;

const updateProjectWorkerMutation = graphql`
  mutation WorkerProfileUpdateProjectWorkerMutation(
    $set: project_worker_set_input!
    $id: uuid!
  ) {
    update_project_worker_by_pk(_set: $set, pk_columns: { id: $id }) {
      id
      title_id
      worker_role
      title {
        translation {
          en
        }
      }
    }
  }
`;

export const upsertProfileImageMutation = graphql`
  mutation WorkerProfileUpsertImageMutation($object: image_insert_input!) {
    insert_image_one(
      object: $object
      on_conflict: {
        constraint: image_pkey
        update_columns: [url, description, created_by_user_id]
      }
    ) {
      id
      pk: id @__clientField(handle: "pk")
    }
  }
`;
const WorkerProfile: React.FC<WorkerProfileProps> = ({
  workerId,
  subcontractorId,
  onCrossClick,
  refetchWorkersList,
  ...props
}) => {
  type ProjectWorker =
    (typeof data)["worker_connection"]["edges"][number]["node"]["worker_projects"][number];

  const authUser = useAuthUser();
  const navigate = useNavigate();
  const [fetchKey, setFetchKey] = useState(0);
  const data = useLazyLoadQuery<WorkerProfileQuery>(
    workerProfileQueryNode,
    {
      id: workerId,
      order: Order_By.Asc,
      workerDrugtestWhere:
        props.type === "gc"
          ? {
              status: { _is_null: false },
              project_id: { _eq: props.projectId },
            }
          : {},
      workerProjectsWhere:
        props.type == "gc"
          ? {
              is_last: { _eq: true },
              project: {
                project_employees: {
                  employee: { uid: { _eq: authUser.uid } },
                },
              },
            }
          : { subcontractor_id: { _eq: subcontractorId } },
    },
    { fetchPolicy: "network-only", fetchKey },
  );
  const [updateProjectWorker, isProjectWorkerUpdating] =
    useAsyncMutation<WorkerProfileUpdateProjectWorkerMutation>(
      updateProjectWorkerMutation,
    );
  const workerProfileData = data.worker_connection.edges[0].node;

  const projWorker = workerProfileData.worker_projects.find(
    (pw) => props.type === "gc" && pw.pk === props.projWorkerId,
  );

  const projTimezone = projWorker?.project.timezone ?? "America/New_York";

  const getWorkersAttendance = (
    dailyWorkLogs: ProjectWorker["daily_work_log_workers"],
    toolboxTalks: ProjectWorker["toolbox_talks"],
    preTaskPlans: ProjectWorker["reports"],
  ) => {
    const distinctDays = new Set<string>();
    let hoursWorked = 0;
    const lastDayOnProj = [
      dailyWorkLogs.at(0)?.daily_work_log.date,
      toolboxTalks.at(0)?.created_at
        ? dayjs(toolboxTalks.at(0)?.created_at)
            .tz(projTimezone)
            .format("YYYY-MM-DD")
        : null,
      preTaskPlans.at(0)?.created_at
        ? dayjs(preTaskPlans.at(0)?.created_at)
            .tz(projTimezone)
            .format("YYYY-MM-DD")
        : null,
    ]
      .filter(Boolean)
      .sort((a, b) => dayjs(b).diff(dayjs(a)))
      .at(0);

    dailyWorkLogs.forEach((log) => {
      const date = log.daily_work_log.date;
      if (!distinctDays.has(date)) {
        distinctDays.add(date);
        hoursWorked += log.hours;
      }
    });

    toolboxTalks.forEach((tbt) => {
      const date = dayjs(tbt.created_at).tz(projTimezone).format("YYYY-MM-DD");
      if (!distinctDays.has(date)) {
        distinctDays.add(date);
      }
    });

    preTaskPlans.forEach((ptp) => {
      const date = dayjs(ptp.created_at).tz(projTimezone).format("YYYY-MM-DD");
      if (!distinctDays.has(date)) {
        distinctDays.add(date);
      }
    });

    return {
      days: distinctDays.size,
      hours: hoursWorked,
      lastDayOnProject: lastDayOnProj ?? null,
    };
  };

  const workerRoles = data.worker_role_connection.edges;
  const workerTitles = data.worker_title_connection.edges;
  const environment = useRelayEnvironment();
  const workerData = useMemo(() => {
    const gcMap: {
      [gcId: string]: { name: string; date: dayjs.Dayjs | undefined };
    } = {};
    workerProfileData.worker_projects.forEach((p) => {
      if (p.user_orientation?.orientated_at || p.user_orientation?.completed_at)
        gcMap[p.project.general_contractor_id] = {
          name: "test", //??? why test?
          date: undefined,
        };
    });
    data.orientation_connection.edges.forEach((o) => {
      if (!o.node.general_contractor_id)
        return;
      const gcItem = gcMap[o.node.general_contractor_id];
      if (
        o.node.general_contractor &&
        gcItem
      ) {
        const completionDates: dayjs.Dayjs[] = [];
        o.node.orientation_results.forEach((p) => {
          if (p.completed_at) {
            completionDates.push(dayjs(p.completed_at));
          }
        });
        if (gcItem.date) {
          completionDates.push(dayjs(gcItem.date));
        }
        gcItem.name = o.node.general_contractor.name;
        const latestCompletionDate = dayjs.max(completionDates); 
        if (latestCompletionDate)
          gcItem.date = latestCompletionDate;
      }
    });
    const orientations = Object.entries(gcMap).map(([key, val]) => val);
    const universal_orientation = workerProfileData.user.universal_orientations[0]; 
    if (universal_orientation?.universal_orientated_at) {
      orientations.push({
        name: "AGC Universal",
        date: dayjs(universal_orientation.universal_orientated_at)
      });
    }
    const hasInPerson = projWorker?.hard_hat_number;
    const gcOrientation =
      projWorker?.project?.general_contractor.gc_orientations.filter((p) =>
        p.project_orientations.filter(
          (po) => po.project_id === projWorker.project.orientation_project_id,
        ),
      );
    const hasSlides =
      (projWorker?.project.orientation_project &&
        projWorker.project.orientation_project.orientations.length > 0) ||
      (gcOrientation && gcOrientation.length > 0);

    let orientationCompleted = !!projWorker?.user_orientation?.completed_at;
    if (hasSlides && hasInPerson) {
      orientationCompleted = !!(
        orientationCompleted ||
        (projWorker?.user_orientation?.in_person_orientated_at &&
          projWorker.user_orientation.orientated_at)
      );
    } else if (hasSlides) {
      orientationCompleted = !!(
        orientationCompleted || projWorker?.user_orientation?.orientated_at
      );
    } else if (hasInPerson) {
      orientationCompleted = !!(
        orientationCompleted ||
        projWorker?.user_orientation?.in_person_orientated_at
      );
    } else {
      orientationCompleted = true;
    }

    const workerData: WorkerProfileUIProps["workerData"] = {
      id: workerProfileData.pk,
      projectWorkerId: props.type === "gc" ? props.projWorkerId : undefined,
      orientationCompleted: orientationCompleted,
      hardHatNumber: projWorker?.hard_hat_number,
      birthDate:
        typeof workerProfileData.user.birth_date === "string"
          ? new Date(workerProfileData.user.birth_date)
          : undefined,
      hireDate:
        typeof workerProfileData.hire_date === "string"
          ? new Date(workerProfileData.hire_date)
          : undefined,
      processingCerts: workerProfileData.processing_cert_count.length ?? 0,

      certificates: workerProfileData.worker_certifications.map(
        (certificate) => {
          const image = certificate.images.at(0);
          return {
            expirationDate:
              typeof certificate.expires_on === "string"
                ? new Date(certificate.expires_on)
                : undefined,
            imageUrl: image?.sm_url
              ? new URL(image.sm_url)
              : image?.url
              ? new URL(image.url)
              : undefined,
            name: certificate.certification.name,
            id: certificate.pk,
          };
        },
      ),

      orientations: orientations,
      isTerminated: workerProfileData.subcontractor_id !== subcontractorId,
      roleId: (props.type === "gc"
        ? projWorker?.worker_role
        : workerProfileData.current_worker_role) as string,

      titleId: (props.type === "gc"
        ? projWorker?.title_id
        : workerProfileData.worker_title_id) as string,

      imageUrl: workerProfileData.user.profile_picture
        ? workerProfileData.user.profile_picture.md_url ??
          workerProfileData.user.profile_picture.url
        : undefined,
      name: workerProfileData.user.name,
      projects: workerProfileData.worker_projects.map((worker_project) => {
        const orientationDates = worker_project.user_orientation;
        return {
          id: worker_project.project.pk,
          name: worker_project.project.name,
          hasInPerson: worker_project.project.in_person_orientation,
          hasSlides:
            (worker_project.project.orientation_project &&
              worker_project.project.orientation_project.orientations.length >
                0) ||
            worker_project.project.general_contractor.gc_orientations.filter(
              (p) =>
                p.project_orientations.filter(
                  (po) =>
                    po.project_id ===
                    worker_project.project.orientation_project_id,
                ).length,
            ).length > 0,
          refetch: () => refetch(),
          worker: {
            name: workerProfileData.user.name,
            email: workerProfileData.user.email,
            id: workerProfileData.pk,
            phone_number: workerProfileData.user.phone_number,
          },
          archivedAt:
            worker_project.archived_at || worker_project.deleted_at
              ? dayjs(
                  worker_project.archived_at || worker_project.deleted_at,
                ).format("LL")
              : undefined,
          archivedBy: worker_project.archived_by_user?.name ?? "",
          startDate: undefined,
          endDate: undefined,
          toolBoxTalksCount: worker_project.toolbox_talks.length,
          toolBoxTalks: worker_project.toolbox_talks.map((t) => ({
            description: t.toolbox_talk.description.en,
          })),
          hardHat: {
            date: undefined,
            number: worker_project.hard_hat_number
              ? worker_project.hard_hat_number
              : undefined,
          },
          orientationDates: orientationDates ?? undefined,
        };
      }),
      drugTests: workerProfileData.worker_drug_tests.map((dt) => {
        return {
          status: dt.status ?? "",
          enteredBy: dt.entered_through_user?.name || dt.name || "",
          fromDate: new Date(dt.drug_test_date as string),
        };
      }),

      email: workerProfileData.user.email
        ? workerProfileData.user.email
        : undefined,
      subcontractorId: workerProfileData.subcontractor_id
        ? workerProfileData.subcontractor_id
        : undefined,
      projectPresence: getWorkersAttendance(
        projWorker?.daily_work_log_workers || [],
        projWorker?.toolbox_talks || [],
        projWorker?.reports || [],
      ),
      permitsCount: projWorker?.permits_aggregate.aggregate?.count,
      reportsCount: projWorker?.reports.length,
      allowMobileLogin: workerProfileData.user.allow_mobile_login,
      createdPassword: workerProfileData.user.created_password,
      invites: workerProfileData.user.invites.map((invite) => ({
        id: invite.pk,
        acceptedAt: invite.accepted_at ?? null,
      })),
      showEmail: !!workerProfileData.user.email_privacy_setting?.employer,
      showPhoneNumber:
        !!workerProfileData.user.phone_number_privacy_setting?.employer,
      phoneNumber: workerProfileData.user.phone_number
        ? workerProfileData.user.phone_number
        : undefined,
      showProjects:
        workerProfileData.user.project_privacy_setting?.employer ?? false,
      workerOfAnotherSub:
        workerProfileData.subcontractor_id !== null &&
        workerProfileData.subcontractor_id !== subcontractorId,
    };
    return workerData;
  }, [data]);

  const [updateWorkerData, isUpdating] =
    useAsyncMutation<WorkerProfileMutation>(graphql`
      mutation WorkerProfileMutation(
        $workerId: uuid!
        $workerSet: worker_set_input
        $userSet: user_set_input
      ) {
        update_worker_by_pk(pk_columns: { uid: $workerId }, _set: $workerSet) {
          id
          hire_date
          worker_title_id
          current_worker_role
          subcontractor_id
        }
        update_user_by_pk(pk_columns: { id: $workerId }, _set: $userSet) {
          email
          name
          username
          phone_number
          birth_date
          profile_picture_id
        }
      }
    `);
  const [upsertProfileImageUrl, isUpsertingImage] =
    useAsyncMutation<WorkerProfileUpsertImageMutation>(
      upsertProfileImageMutation,
    );

  const refetch = () => setFetchKey((i) => i + 1);
  const [loading, setLoading] = useState(false);
  const upsertPreviousSubs = useUpsertAndDeletePreviousSub();
  const [deleteWorkerCerts, deletingCerts] =
    useAsyncMutation<WorkerCertificationTableDeleteCertMutation>(
      deleteWorkerCertMutation,
    );
  const [upsertProjectWorkers, isUpserting] =
    useAsyncMutation<WorkerProfile_Update_Project_Worker_Mutation>(
      updateMulptipleProjectsSingleWorkerMutation,
    );
  const terminateWorker = async (workerId: string) => {
    setLoading(true);
    try {
      await updateWorkerData({
        variables: {
          workerId,
          userSet: {},
          workerSet: {
            subcontractor_id: null,
          },
        },
      });

      upsertPreviousSubs({
        variables: {
          deleteWhere: { id: { _is_null: true } },
          objects: [
            {
              subcontractor_id: subcontractorId,
              worker_id: workerId,
            },
          ],
        },
      }).then(() => {
        message.success(`Deleted worker`);
      });
    } catch (error) {
      notification.error({
        message: "ERROR: Couldn't terminate worker",
        description:
          error instanceof Error ? error.message : JSON.stringify(error),
      });
    }
    setLoading(false);
  };
  const [updateProjectWorkers] =
    useAsyncMutation<UpdateProjectWorkersForWorkerRegistrationWithHardHat_Mutation>(
      updateProjectWorkersMutation,
    );
  const addBackTerminatedWorker = async (
    workerId: string,
    prevSubId?: string,
  ) => {
    try {
      await upsertProjectWorkers({
        variables: {
          subId: subcontractorId,
          workerId,
          projectIds: workerProfileData.worker_projects.map(
            (p) => p.project.pk,
          ),
        },
      });
      await updateWorkerData({
        variables: {
          workerId,
          userSet: {},
          workerSet: { subcontractor_id: subcontractorId },
        },
      });
      upsertPreviousSubs({
        variables: {
          deleteWhere: {
            worker_id: { _eq: workerId },
            subcontractor_id: { _eq: subcontractorId },
          },
          objects: prevSubId
            ? [
                {
                  subcontractor_id: prevSubId,
                  worker_id: workerId,
                },
              ]
            : [],
        },
      }).catch((e) => console.log(e));
    } catch (error) {
      notification.error({
        message: "ERROR: Couldn't add back worker",
        description:
          error instanceof Error ? error.message : JSON.stringify(error),
      });
    }
  };

  const updateWorker = async (
    workerSet: worker_set_input,
    userSet: user_set_input,
  ) => {
    const res = await updateWorkerData({
      variables: { workerId, workerSet, userSet },
    })
    console.log(res, res.update_worker_by_pk);      
  };

  const workerProfileUIProps: WorkerProfileUIProps = {
    type: props.type,
    drugtestGCProps: props.type === "gc" ? props.drugtestGCProps : undefined,
    onCrossClick: onCrossClick,
    subcontractorId: subcontractorId,
    workerSubOptions:
      props.type === "gc" ? props.projectSubcontractors : undefined,
    tasksSigned: workerProfileData.user.task_signatures.map((data) => ({
      id: data.task?.pk ?? "",
      description: data.task?.description.en ?? "",
    })),
    workerData: workerData,

    //Write updater for these cases instead of refetching whole data each time
    updateData: {
      loadingStatus: isUpdating || isUpsertingImage || loading || isProjectWorkerUpdating,
      contacts: {
        email: (email: string) => {
          updateWorker({}, { email });
        },
        phoneNumber: async (phoneNumber: string) => {
          updateWorker({}, { phone_number: phoneNumber });
        },
      },

      name: async (newName: string) => {
        updateWorker({}, { name: newName });

        props.type === "gc" && props.onUpdateWorkerName(newName);
      },

      role: async (role: string) => {
        if (props.type === "gc" && props.projWorkerId) {
          await updateProjectWorker({
            variables: {
              id: props.projWorkerId,
              set: {
                worker_role: role,
              },
            },
          });

          commitMutation(environment, {
            mutation: updateProjectWorkerMutation,
            variables: {
              id: props.projWorkerId,
              set: {
                worker_role: role,
              },
            },
          });
        } else {
          await updateWorker({ current_worker_role: role }, {});
        }
      },
      title: async (titleId: string) => {
        setLoading(true);
        try {
          if (props.type === "gc" && props.projWorkerId) {
            await updateProjectWorker({
              variables: {
                id: props.projWorkerId,
                set: {
                  title_id: titleId,
                },
              },
            });

            commitMutation(environment, {
              mutation: updateProjectWorkerMutation,
              variables: {
                id: props.projWorkerId,
                set: {
                  title_id: titleId,
                },
              },
            });

          // commitLocalUpdate(environment, (store)=> {
          //   const conn1 = ConnectionHandler.getConnection()
          // }
          // )
          } else {
            await updateWorker({ worker_title_id: titleId }, {});
          }
        } finally {
          setLoading(false);
        } 
      },

      subcontractor: async (subcontractorId: string) => {
        if (props.type === "gc") {
          updateWorker({ subcontractor_id: subcontractorId }, {});
          await updateProjectWorkers({
            variables: {
              newSubId: subcontractorId,
              projectIds: [props.projectId],
              workerId,
              _set: {
                hard_hat_number:
                  workerProfileData.worker_projects.at(0)?.hard_hat_number,
                is_last: false,
              },
              newUpsertObjects: [
                {
                  project_id: props.projectId,
                  worker_id: workerId,
                  subcontractor_id: subcontractorId,
                  is_last: true,
                  hard_hat_number:
                    workerProfileData.worker_projects.at(0)?.hard_hat_number,
                  title_id: workerProfileData.worker_title_id,
                  worker_role: workerProfileData.current_worker_role,
                  deleted_at: null,
                  archived_at: null,
                },
              ],
            },
          });
          props.onSubChangeSuccess(subcontractorId);
          if (workerProfileData.subcontractor_id !== subcontractorId) {
            upsertPreviousSubs({
              variables: {
                deleteWhere: {
                  subcontractor_id: { _eq: subcontractorId },
                  worker_id: { _eq: workerId },
                },
                objects: workerProfileData.subcontractor_id
                  ? [
                      {
                        worker_id: workerId,
                        subcontractor_id: workerProfileData.subcontractor_id,
                      },
                    ]
                  : [],
              },
            });
          }
        }
      },
      birthDate: async (birthDate: dayjs.Dayjs) => {
        updateWorker({}, { birth_date: birthDate.format("YYYY-MM-DD") });
      },
      hireDate: async (hireDate: dayjs.Dayjs) => {
        updateWorker({ hire_date: hireDate.format("YYYY-MM-DD") }, {});
      },
      terminate: (workerId: string) => {
        if (workerProfileData.subcontractor_id === subcontractorId) {
          terminateWorker(workerId).then(() => {
            refetch();
            refetchWorkersList();
          });
        } else {
          message.info("This worker is no longer employed under your company ");
        }
      },
      addBackTerminatedWorker: (workerId) => {
        if (workerProfileData.subcontractor_id !== subcontractorId) {
          addBackTerminatedWorker(
            workerId,
            workerProfileData.subcontractor_id ?? undefined,
          ).then(() => {
            refetch();
            refetchWorkersList();
          });
        } else {
          message.info("This Worker is already under your company");
        }
      },
      refetchProfile: async () => refetch(),
      avatarImage: (imageUrl) => {
        if (!imageUrl) {
          // when we want to delete the profile pic
          updateWorker({}, { profile_picture_id: null });
        } else {
          const newImageid =
            workerProfileData.user.profile_picture_id ?? uuid.v4();
          upsertProfileImageUrl({
            variables: {
              object: {
                url: imageUrl,
                description: "Profile Picture",
                lg_url: imageUrl,
                created_by_user_id: auth.currentUser?.uid,
                id: newImageid,
              },
            },
          })
            .then(() =>
              updateWorker({}, { profile_picture_id: newImageid }).then(() =>
                refetch(),
              ),
            )
            .catch((e) => {
              message.error("Could not Insert new Image");
            });
        }
      },
      certificate: {
        remove: (certId: string) => {
          deleteWorkerCerts({
            variables: { where: { id: { _eq: certId } } },
          })
            .then(refetch)
            .catch((e) => message.error(e.message));
        },
      },
    },
    loading:
      loading ||
      isUpdating ||
      isUpsertingImage ||
      deletingCerts ||
      isProjectWorkerUpdating,
    workerRoleOptions: workerRoles.map((r) => ({
      label: r.node.translation.en,
      key: r.node.value,
      value: r.node.value,
    })),
    workerTitleOptions: workerTitles.map((t) => ({
      label: t.node.translation.en,
      value: t.node.pk,
      key: t.node.pk,
    })),
    gcName: props.type === "gc" ? props.gcName : undefined,
  };

  const projectsToBeAddedTo =
    props.type === "sub"
      ? props.projects.filter(
          (p) =>
            !workerProfileUIProps.workerData.projects.some(
              (p2) => p2.id === p.id,
            ),
        )
      : [];

  return (
    <WorkerProfileUI
      {...workerProfileUIProps}
      key={workerId}
      projectsToBeAddedTo={projectsToBeAddedTo}
    />
  );
};

export default withCustomSuspense(WorkerProfile);
