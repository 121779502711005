import { OrganizationLevelName } from "src/root/routes/views/general-contractor/hierarchy/utils/hierarchyTypes";
import { useMemo } from "react";
import FModal from "src/common/components/dialogs/FModal";
import getNormalSelectOptionsFilter from "src/common/functions/getNormalSelectOptionsFilter";
import {
  GetAdminObservationOptionsQuery,
  useGenerateObservationsSummaryPdfMutation,
} from "src/common/types/generated/apollo/graphQLTypes";
import dayjs from "dayjs";
import useSelectedIndicies from "src/common/hooks/useSelectedIndices";
import { Button, Checkbox, Drawer, Form } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import downloadFromUrl from "src/common/functions/downloadFromUrl";

type FormVals = {
  projectIds: Array<string>;
  subIds: Array<string>;
  risk_levels: Array<string>;
  statuses: Array<string>;
  categoryTypeIds: Array<string>;
  categoryIds: Array<string>;
  endDate: dayjs.Dayjs;
  startDate?: dayjs.Dayjs;
  includeShortSummary: boolean;
};
const ObservationReportCreator: React.FC<{
  reportModal: boolean;
  handleClose: () => void;
  organizationId: string;
  organizationLevel: OrganizationLevelName;
  options: GetAdminObservationOptionsQuery;
}> = ({
  reportModal,
  handleClose,
  options,
  organizationId,
  organizationLevel,
}) => {
  const [selectedCategories, handleCategorySelection] =
    useSelectedIndicies<string>();
  const [selectedProjects, handleProjectSelection] =
    useSelectedIndicies<string>();
  const [generateObsSummaryPdf, { loading }] =
    useGenerateObservationsSummaryPdfMutation();
  const [form] = Form.useForm<FormVals>();
  const subOptions = useMemo(
    () =>
      (organizationLevel === "project"
        ? options.subcontractor
        : options.subcontractor.filter(
            (sub) =>
              selectedProjects.size === 0 ||
              sub.subcontractor_projects.some((ps) =>
                selectedProjects.has(ps.project_id),
              ),
          )
      ).map((sub) => ({ label: sub.name, value: sub.id })),
    [organizationLevel, selectedProjects, options],
  );
  const subCategoryOptions = useMemo(
    () =>
      selectedCategories.size === 0
        ? []
        : options.observation_category_type
            .filter((cateType) => selectedCategories.has(cateType.id))
            .flatMap((cateType) =>
              cateType.observation_categories.map((cate) => ({
                value: cate.id,
                label: cate.name.en,
              })),
            ),
    [options, selectedCategories],
  );
  const showCompleted =
    organizationLevel !== "project" ||
    options.project[0]?.project_setting
      ?.require_gc_to_mark_observation_complete;
  const statusCheckboxOptions = useMemo(() => {
    const statusOptions = [
      { label: "Open", value: "open" },
      { label: "Corrected", value: "corrected" },
    ];
    if (showCompleted) {
      statusOptions.push({ label: "Completed", value: "completed" });
    }

    statusOptions.push({ label: "Safe", value: "safe" });
    statusOptions.push({ label: "All", value: "all" });
    return statusOptions;
  }, [showCompleted]);
  const handleDownload = async () => {
    const vals = await form.validateFields();
    console.log(vals);
    const projectIds = vals.projectIds?.length
      ? vals.projectIds
      : options.project.map((p) => p.id);
    const subcontractorIds = vals.subIds.length
      ? vals.subIds
      : subOptions.map((p) => p.value);
    const statuses =
      !vals.statuses || vals.statuses.includes("all")
        ? ["open", "closed", "safe", ...(showCompleted ? ["completed"] : [])]
        : vals.statuses;
    const riskLevels = vals.risk_levels.length
      ? vals.risk_levels
      : options.risk_level.map((r) => r.value);
    const categoryIds = vals.categoryIds.length
      ? vals.categoryIds
      : options.observation_category_type.flatMap((t) =>
          t.observation_categories.map((c) => c.id),
        );
    const categoryTypeIds = vals.categoryTypeIds.length
      ? vals.categoryTypeIds
      : options.observation_category_type.map((t) => t.id);
    const timezone = dayjs.tz.guess() || "America/New_York";

    const startTime = vals.startDate
      ? dayjs.tz(vals.startDate.format("YYYY-MM-DD"), timezone).toISOString()
      : null;
    const endTime = vals.endDate
      ? dayjs.tz(vals.endDate.format("YYYY-MM-DD"), timezone).toISOString()
      : null;
    console.log(startTime, endTime);
    const { data: res } = await generateObsSummaryPdf({
      variables: {
        input: {
          organizationId,
          organizationLevel,
          timezone: dayjs.tz.guess(),
          projectIds,
          subcontractorIds,
          riskLevels,
          statuses,
          categoryIds,
          categoryTypeIds,
          endTime,
          startTime,
          includeShortSummaryList: vals.includeShortSummary || false,
        },
      },
    });
    if (res) downloadFromUrl(res.generateObservationsSummaryPdf);

    handleClose();
  };
  return (
    <Drawer
      title="Report Creator"
      width={"60%"}
      open={reportModal}
      onClose={handleClose}
      destroyOnClose
      footer={
        <div className="flex gap-1">
          <Button loading={loading} onClick={handleClose}>
            Close
          </Button>
          <Button
            type={"primary"}
            loading={loading}
            icon={<DownloadOutlined />}
            onClick={handleDownload}
          >
            Download
          </Button>
        </div>
      }
    >
      <Form
        form={form}
        layout={`vertical`}
        initialValues={{
          projectIds: [],
          subIds: [],
          risk_levels: [],
          statuses: ["open"],
          categoryTypeIds: [],
          categoryIds: [],
          endDate: dayjs(),
          includeShortSummary: true,
        }}
      >
        <FModal.Select
          name={"projectIds"}
          className={organizationLevel === "project" ? "hidden" : undefined}
          label="Projects"
          props={{
            mode: "multiple",
            showSearch: true,
            filterOption: getNormalSelectOptionsFilter,
            placeholder: "All Projects Included",
            onSelect: (val) =>
              typeof val === "string" && handleProjectSelection(val),
            onDeselect: (val) =>
              typeof val === "string" && handleProjectSelection(val),
            options: options.project.map((proj) => ({
              value: proj.id,
              label: proj.name,
            })),
          }}
        />
        <FModal.Select
          name={"subIds"}
          label="Company"
          props={{
            mode: "multiple",
            placeholder: "All Companies Included",
            allowClear: true,
            showSearch: true,
            filterOption: getNormalSelectOptionsFilter,
            options: subOptions,
          }}
        />
        <FModal.Select
          name={"risk_levels"}
          label="Risk Levels"
          props={{
            mode: "multiple",
            placeholder: "All Risk Levels Included",
            options: options.risk_level.map((risk) => ({
              value: risk.value,
              allowClear: true,
              label: <div style={{ color: risk.color_hex }}>{risk.name}</div>,
            })),
          }}
        />
        <FModal.Select
          name={"categoryTypeIds"}
          label="Observation Categories"
          props={{
            mode: "multiple",
            showSearch: true,
            filterOption: getNormalSelectOptionsFilter,
            placeholder: "All Categories Included",
            onSelect: (val) =>
              typeof val === "string" && handleCategorySelection(val),
            onDeselect: (val) =>
              typeof val === "string" && handleCategorySelection(val),
            options: options.observation_category_type.map((cateType) => ({
              value: cateType.id,
              label: cateType.name.en,
            })),
          }}
        />
        <FModal.Select
          className={selectedCategories.size > 0 ? undefined : "hiddent"}
          name={"categoryIds"}
          label="Observation Sub-Categories"
          props={{
            mode: "multiple",
            showSearch: true,
            filterOption: getNormalSelectOptionsFilter,
            allowClear: true,
            placeholder: "All Included for selected categories",
            options: subCategoryOptions,
          }}
        />

        <Form.Item
          label={`Select statuses of observation`}
          name="statuses"
          rules={[
            { required: true, message: "Please select at least one option!" },
          ]}
        >
          <Checkbox.Group options={statusCheckboxOptions}></Checkbox.Group>
        </Form.Item>
        <FModal.Date name="startDate" label="Date Range Start"></FModal.Date>
        <FModal.Date name="endDate" label="Date Range End"></FModal.Date>
        <FModal.Checkbox name="includeShortSummary">
          Include Short Summary List
        </FModal.Checkbox>
      </Form>
    </Drawer>
  );
};
export default ObservationReportCreator;
